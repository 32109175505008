import React from "react";

const ErrorComponent = ({ error, message }) => {
  return (
    <>
      <div
        className="text-center"
        style={{
          padding: "10px",
          width: "100%",
        }}
      >
        {error === "unauthorized" ? (
          <div>
            <p
              className="text-silent"
              style={{
                marginBottom: "0px",
                fontSize: "1.25rem",
              }}
            >
              Something went wrong!
            </p>
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="btn btn-info"
            >
              Refresh
            </button>
          </div>
        ) : (
          <span className="text-silent">{message || "Something went wrong!"}</span>
        )}
      </div>
    </>
  );
};

export default ErrorComponent;
