import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LuArchiveRestore } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  ProjectCard,
  RSelect,
  Row,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import DotPagination from "../../components/pagination/DotPaginate";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  addNewBlogCategory,
  archiveBlogCategory,
  deleteBlogCategory,
  getAllBlogCategories,
  restoreBlogCategory,
  updateBlogCategory,
} from "../../redux/Apislices/blogCategoryslice";
import { capitalizeFirstLetter, convertToSlug, getScreenWidth } from "../../utils/Utils";
import styles from "./blogs.module.scss";

const itemsPerPageOptions = [
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const CategoryPage = () => {
  const dispatch = useDispatch();
  const {
    blogCategory: { blogCategories },
  } = useSelector((state) => ({
    blogCategory: state.blogCategory,
  }));

  const [selectedData, setSelectedData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [sm, updateSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [limit, setLimit] = useState({ value: 50, label: "50" });
  const [sort, setSort] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [status, setStatus] = useState(null);

  const [view, setView] = useState({ edit: false, add: false, details: null });
  const [editId, setEditedId] = useState(null);

  const [formData, setFormData] = useState({ name: "", slug: "" });

  const { errors, register, handleSubmit } = useForm();

  const onInputChange = (e) => {
    if (e.target.name === "name") {
      setFormData({
        ...formData,
        [e.target.name]: capitalizeFirstLetter(e.target.value),
        slug: convertToSlug(e.target.value),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    blogCategories?.data.forEach((item) => {
      if (item._id === id) {
        setFormData({ name: item.name, slug: item.slug });
      }
    });
    setEditedId(id);
    setView({ add: false, edit: true, details: false });
  };

  const resetForm = () => {
    setFormData({ name: "", slug: "" });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  const toggleModal = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  const handleGetBlogCategory = () => {
    dispatch(getAllBlogCategories({ page: currentPage, limit: itemPerPage, search: onSearchText, sort, status }));
  };

  //debounce getDevelopmentList for search
  useEffect(() => {
    const timer = setTimeout(
      () => {
        handleGetBlogCategory();
      },
      onSearchText ? 800 : 200
    );
    return () => clearTimeout(timer);
  }, [dispatch, currentPage, itemPerPage, onSearchText, sort, status]);

  useEffect(() => {
    if (typeof limit === "object" && limit?.value) setItemPerPage(limit?.value);
    else setItemPerPage(50);
  }, [limit]);

  // selects all the products
  const selectorCheck = (e) => {
    // add or remove all the items from development?.development?.data to selectedData
    if (e.currentTarget.checked) {
      setSelectedData(blogCategories?.data?.map((item) => item._id));
    } else {
      setSelectedData([]);
    }
  };

  // selects one product
  const onSelectChange = (e, id) => {
    // add or remove the selected item from selectedData
    if (e.currentTarget.checked) {
      setSelectedData([...selectedData, id]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== id));
    }
  };

  // toggle search input
  const toggle = () => setonSearch(!onSearch);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onFormSubmit = async () => {
    let data = { ...formData };
    if (!formData?.name) return toast.error("Name is required");
    if (!formData?.slug) return toast.error("Slug is required");

    dispatch(
      addNewBlogCategory(
        data,
        (res) => {
          onFormCancel();
          handleGetBlogCategory();
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const onEditSubmit = async () => {
    let data = { ...formData };
    if (!formData?.name) return toast.error("Name is required");
    if (!formData?.slug) return toast.error("Slug is required");

    dispatch(
      updateBlogCategory(
        editId,
        data,
        (res) => {
          onFormCancel();
          handleGetBlogCategory();
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleArchive = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your blog category will be archived!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          archiveBlogCategory(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetBlogCategory();
              }
              Swal.fire("Archived!", "Blog category has been archived.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleRestore = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You blog category will be restored!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Restore",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          restoreBlogCategory(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetBlogCategory();
              }
              Swal.fire("Archived!", "Blog category has been restored.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleDelete = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteBlogCategory(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetBlogCategory();
              }
              Swal.fire("Deleted!", "Blog category has been deleted.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Blog Categories"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Blog Categories</BlockTitle>
              <BlockDes className="text-soft">
                {blogCategories?.data?.length && blogCategories?.success ? (
                  <p>You have total {blogCategories?.total?.total} blog categories.</p>
                ) : null}
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        onClick={() => {
                          toggleModal("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>

                      <Button
                        className="toggle d-none btn-outline-primary btn-dim d-md-inline-flex"
                        onClick={() => {
                          toggleModal("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Blog Category</span>
                      </Button>
                    </li>

                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus(null);
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus(null);
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Blog Categories</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button className="toggle btn-icon d-md-none" color="primary" onClick={() => {}}>
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived Blog Categories</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row
            className={""}
            style={{ border: "1px solid #d2d3ec", margin: "0 0 10px 0px", padding: "10px 5px", borderRadius: "5px" }}
          >
            <Col sm={"12"} md={"6"} lg={"6"}></Col>
            <Col sm={"12"} md={"12"} lg={"6"} className={"card-tools mr-n1"}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <ul className="btn-toolbar gx-1">
                  {/* Search */}
                  <li>
                    <div
                      className="search-content"
                      style={{
                        border: "1px solid #dbdfea",
                        borderRadius: "4px",
                      }}
                    >
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="search"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by user or email"
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                      />
                      {onSearchText ? (
                        <Button
                          className="search-submit btn-icon"
                          onClick={() => {
                            setSearchText("");
                          }}
                        >
                          <Icon name="cross"></Icon>
                        </Button>
                      ) : null}
                    </div>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a">
                        <Button color={"primary"}>
                          <Icon className={"mr-1"} name="filter-fill"></Icon>
                          Filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu right className="filter-wg dropdown-menu-xl" style={{ overflow: "visible" }}>
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">Filter Blogs</span>
                        </div>
                        <div className="dropdown-body dropdown-body-rg">
                          <Row className="gx-6 gy-3">
                            <Col size="6">
                              <FormGroup>
                                <label className="overline-title overline-title-alt">Items Per Page</label>
                                <RSelect
                                  options={itemsPerPageOptions}
                                  onChange={(value) => {
                                    setLimit(value);
                                  }}
                                  value={limit}
                                  placeholder="Select"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                        <Icon name="sort-line"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("recently-updated");
                              }}
                              className={sort === "recently-updated" ? "text text-primary" : ""}
                            >
                              <span>Recently Updated</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("recently-added");
                              }}
                              className={sort === "recently-added" ? "text text-primary" : ""}
                            >
                              <span>Recently Added</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("oldest");
                              }}
                              className={sort === "oldest" ? "text text-primary" : ""}
                            >
                              <span>Oldest First</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("asc");
                              }}
                              className={sort === "asc" ? "text text-primary" : ""}
                            >
                              <span>0-9, A-Z</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSort("desc");
                              }}
                              className={sort === "desc" ? "text text-primary" : ""}
                            >
                              <span>9-0, Z-A</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="g-gs">
            {blogCategories?.loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : blogCategories?.data?.length && blogCategories?.success ? (
              blogCategories?.data.map((item, idx) => {
                return (
                  <Col sm="6" lg="4" xxl="3" key={item._id}>
                    <ProjectCard>
                      <div className={styles.blogDiv}>
                        <div>
                          <div className="project-head" style={{ display: "flex", alignItems: "center" }}>
                            <div className="project-title mb-0">
                              <div className="project-info">
                                <h6 className={clsx("title", styles.titleDiv)}>{item.name}</h6>
                                <span className={clsx("sub-text", styles.categoryDiv)}>
                                  {item.blogCategory?.map((li) => li?.name)?.join(", ")}
                                </span>
                              </div>
                            </div>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 mr-n1"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  {(typeof status === "object" && status?.value === "archive") ||
                                  status === "archive" ? (
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#view"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          handleRestore(item);
                                        }}
                                      >
                                        <LuArchiveRestore
                                          style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }}
                                        />
                                        <span>Restore</span>
                                      </DropdownItem>
                                    </li>
                                  ) : (
                                    <>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            onEditClick(item._id);
                                            toggleModal("edit");
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit this Blog category</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#view"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            handleArchive(item);
                                          }}
                                        >
                                          <Icon name="archived-fill"></Icon>
                                          <span>Archive</span>
                                        </DropdownItem>
                                      </li>
                                    </>
                                  )}
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#view"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        handleDelete(item);
                                      }}
                                    >
                                      <Icon name="trash"></Icon>
                                      <span>Delete Permanently</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                    </ProjectCard>
                  </Col>
                );
              })
            ) : blogCategories?.success && !blogCategories?.data?.length ? (
              <div
                className="text-center"
                style={{
                  padding: "20px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                }}
              >
                <span className="text-silent">No blog categories found</span>
              </div>
            ) : blogCategories?.error ? (
              <ErrorComponent error={blogCategories?.error} />
            ) : null}
          </Row>
          <div className="mt-3">
            {blogCategories?.success && blogCategories?.data?.length ? (
              <DotPagination
                itemPerPage={blogCategories?.total?.items_per_page}
                totalItems={blogCategories?.total?.total}
                paginate={paginate}
                currentPage={currentPage}
                siblingCount={2}
                screenWidth={screenWidth}
              />
            ) : null}
          </div>
        </Block>

        {/* Add & Edit */}
        <Modal
          isOpen={view.add || view.edit}
          toggle={() => {
            onFormCancel();
            if (view.edit) resetForm();
          }}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a href="#cancel" className="close">
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">{view.edit ? "Edit Blog Category" : "Add Blog Category"}</h5>
              <div className="mt-4">
                <form onSubmit={handleSubmit(view.edit ? onEditSubmit : onFormSubmit)}>
                  <Row className="g-3">
                    <Col size="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="product-title">
                          Name*
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData?.name}
                            onChange={(e) => onInputChange(e)}
                            ref={register({
                              required: "This field is required",
                            })}
                          />
                          {errors.name && <span className="invalid">{errors.name.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="sale-price">
                          Slug*
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="slug"
                            value={formData?.slug}
                            onChange={(e) => onInputChange(e)}
                            ref={register({ required: "This is required" })}
                          />
                          {errors.slug && <span className="invalid">{errors.slug.message}</span>}
                        </div>
                      </div>
                    </Col>

                    <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />
                    <Col size="12">
                      <Button color="primary" type="submit">
                        <span>{view.edit ? "Save" : "Save"}</span>
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default CategoryPage;
