import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dev1 from "../../components/Development/Dev1";
import Dev2 from "../../components/Development/Dev2";
import Dev3 from "../../components/Development/Dev3";
import Dev4 from "../../components/Development/Dev4";
import Dev5 from "../../components/Development/Dev5";
import Dev6 from "../../components/Development/Dev6";
import Dev7 from "../../components/Development/Dev7";
import Head from "../../layout/head/Head";
import { useDispatch } from "react-redux";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getDevelopmentBySlug, handleAddDevelopment, handleDevStep } from "../../redux/Apislices/developmentSlice";
import { useSelector } from "react-redux";

const AddDevelopment = () => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const { development } = useSelector((state) => state);

  const [activeIconTab, setActiveIconTab] = useState(1);

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) {
      setActiveIconTab(icontab);
      dispatch(handleDevStep(icontab));
    }
  };

  const toggleBack = () => {
    if (activeIconTab > 1) {
      setActiveIconTab(activeIconTab - 1);
      dispatch(handleDevStep(activeIconTab - 1));
    }
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  const toggleNext = () => {
    if (activeIconTab < 8) {
      setActiveIconTab(activeIconTab + 1);
      dispatch(handleDevStep(activeIconTab + 1));
    }
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (type === "edit" && id) dispatch(getDevelopmentBySlug(id));
  }, [dispatch, id, type, activeIconTab]);

  useEffect(() => {
    dispatch(getHomeType());
  }, [dispatch]);

  useEffect(() => {
    if (development?.addDevelopment?.devStep) setActiveIconTab(development?.addDevelopment?.devStep);
  }, [development?.addDevelopment]);

  return (
    <React.Fragment>
      <Head title={type === "add" ? "Add Development" : type === "edit" ? "Edit Development" : ""}></Head>

      <>
        {activeIconTab === 1 ? (
          <Dev1
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 2 ? (
          <Dev2
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 3 ? (
          <Dev3
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 4 ? (
          <Dev4
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 5 ? (
          <Dev5
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 6 ? (
          <Dev6
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 7 ? (
          <Dev7
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : null}
      </>
    </React.Fragment>
  );
};
export default AddDevelopment;
