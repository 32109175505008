import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, FormGroup, Input, Label } from "reactstrap";
import Content from "../../layout/content/Content";
import { getConstruction } from "../../redux/Apislices/constructionSlice";
import { editProject, getSingleProject, handleAddProject } from "../../redux/Apislices/projectSlice";
import { allMonths, getNextYears, yearsAfter, yearsBefore } from "../../utils/moment";
import { Block, BlockContent, BlockHead, BlockTitle, Button, Col, Row } from "../Component";
import ProjNav from "./ProjNav";
import ProjectHeader from "./ProjectHeader";
import styles from "./project.module.scss";

const communityStatusData = [
  { id: 1, name: "Active", value: "active" },
  { id: 2, name: "Inactive", value: "inactive" },
  { id: 3, name: "On Hold", value: "on hold" },
  { id: 4, name: "Sold", value: "sold" },
  { id: 5, name: "Pending", value: "pending" },
];

const adminStatusData = [
  { id: 1, name: "Active", value: "active" },
  { id: 2, name: "Inactive", value: "inactive" },
  { id: 3, name: "Oh Hold", value: "on hold" },
  { id: 4, name: "Sold", value: "sold" },
  { id: 5, name: "Pending", value: "pending" },
];

const Project2 = ({ toggleNext, toggleBack, activeIconTab, toggleIconTab }) => {
  const dispatch = useDispatch();
  const { construction: constructionObj, saleStatus: saleStatusObj, project } = useSelector((state) => state);

  const navigate = useNavigate();
  const { type, id } = useParams();

  const { addProject } = project;
  const { construction: constructionData } = constructionObj;

  const [projectData, setProjectData] = useState(false);

  const [communityStatus, setCommunityStatus] = useState("");

  const [constructionStatus, setConstructionStatus] = useState("");
  const [mandConstruction, setMandConstruction] = useState(false);
  const constRef = useRef(null);

  const [startYear, setstartYear] = useState("");
  const [mandConstStartYear, setMandConstStartYear] = useState(false);
  const constStartYearRef = useRef(null);

  const [startMonth, setstartMonth] = useState("");
  const [mandConstStartMonth, setMandConstStartMonth] = useState(false);
  const constStartMonthRef = useRef(null);

  const [endYear, setendYear] = useState("");
  const [mandCompYear, setMandCompYear] = useState(false);
  const compYearRef = useRef(null);

  const [endMonth, setendMonth] = useState("");
  const [mandCompMonth, setMandCompMonth] = useState(false);
  const compMonthRef = useRef(null);

  const [bedroomRangeFrom, setbedroomRangeFrom] = useState("");
  const [bedroomRangeTo, setbedroomRangeTo] = useState("");
  const [bedroomUpdate, setbedroomUpdate] = useState(false);
  const [bedErrorMessage, setBedErrorMessage] = useState(false);

  const [bathroomRangeFrom, setbathroomRangeFrom] = useState("");
  const [bathroomRangeTo, setbathroomRangeTo] = useState("");
  const [bathroomUpdate, setbathroomUpdate] = useState(false);
  const [bathErrorMessage, setBathErrorMessage] = useState(false);

  const [floorAreaRangeFrom, setfloorAreaRangeFrom] = useState("");
  const [floorAreaRangeTo, setfloorAreaRangeTo] = useState("");
  const [floorAreaUpdate, setfloorAreaUpdate] = useState(false);
  const [areaErrorMessage, setAreaErrorMessage] = useState(false);

  const [priceRangeFrom, setPriceRangeFrom] = useState("");
  const [priceRangeTo, setPriceRangeTo] = useState("");
  const [priceUpdate, setPriceUpdate] = useState(false);
  const [priceErrorMessage, setPriceErrorMessage] = useState(false);

  useEffect(() => {
    dispatch(getConstruction());
  }, [dispatch]);

  useEffect(() => {
    if (addProject?.data) {
      setProjectData(addProject?.data);
    }
  }, [addProject]);

  // prefill all fields
  useEffect(() => {
    if (projectData) {
      console.log("projectData 2: ", projectData);
      setCommunityStatus(projectData?.status);
      setConstructionStatus(projectData?.construction?.constructionStatus);
      setstartYear(
        projectData?.construction?.constructionStart?.year && projectData?.construction?.constructionStart?.year !== "0"
          ? projectData?.construction?.constructionStart?.year
          : ""
      );
      setstartMonth(projectData?.construction?.constructionStart?.month);
      setendYear(
        projectData?.construction?.constructionEnd?.year && projectData?.construction?.constructionEnd?.year !== "0"
          ? projectData?.construction?.constructionEnd?.year
          : ""
      );
      setendMonth(projectData?.construction?.constructionEnd?.month);

      if (projectData.bedrooms) {
        if (projectData?.bedrooms?.autoUpdate === true) {
          setbedroomUpdate(projectData?.bedrooms?.autoUpdate);
        } else if (projectData?.bedrooms?.autoUpdate === false) {
          setbedroomUpdate(projectData?.bedrooms?.autoUpdate);
        } else {
          setbedroomUpdate(bedroomUpdate);
        }
        setbedroomRangeFrom(projectData?.bedrooms?.min);
        setbedroomRangeTo(projectData?.bedrooms?.max);
      }
      if (projectData.bathrooms) {
        if (projectData?.bathrooms?.autoUpdate === true) {
          setbathroomUpdate(projectData?.bathrooms?.autoUpdate);
        } else if (projectData?.bathrooms?.autoUpdate === false) {
          setbathroomUpdate(projectData?.bathrooms?.autoUpdate);
        } else {
          setbathroomUpdate(bathroomUpdate);
        }
        setbathroomRangeFrom(projectData?.bathrooms?.min);
        setbathroomRangeTo(projectData?.bathrooms?.max);
      }
      if (projectData.floorArea) {
        if (projectData?.floorArea?.autoUpdate === true) {
          setfloorAreaUpdate(projectData?.floorArea?.autoUpdate);
        } else if (projectData?.floorArea?.autoUpdate === false) {
          setfloorAreaUpdate(projectData?.floorArea?.autoUpdate);
        } else {
          setfloorAreaUpdate(floorAreaUpdate);
        }
        setfloorAreaRangeFrom(projectData?.floorArea?.min);
        setfloorAreaRangeTo(projectData?.floorArea?.max);
      }
      if (projectData?.price?.price) {
        setPriceRangeFrom(projectData?.price?.price?.from);
        setPriceRangeTo(projectData?.price?.price?.to);
        if (projectData?.price?.price?.autoUpdate === true) {
          setPriceUpdate(projectData?.price?.price?.autoUpdate);
        } else if (projectData?.price?.price?.autoUpdate === false) {
          setPriceUpdate(projectData?.price?.price?.autoUpdate);
        } else {
          setPriceUpdate(priceUpdate);
        }
      }
    }
  }, [projectData]);

  const handlePayload = () => {
    let data = {
      ...projectData,
      status: communityStatus,
      construction: {
        constructionStatus: constructionStatus,
        constructionStart: {
          year: startYear,
          month: startMonth,
        },
        constructionEnd: {
          year: endYear,
          month: endMonth,
        },
      },
      bedrooms: {
        min: bedroomRangeFrom ? +bedroomRangeFrom : bedroomRangeFrom === "0" ? 0 : null,
        max: bedroomRangeTo ? +bedroomRangeTo : bedroomRangeTo === "0" ? 0 : null,
        autoUpdate: bedroomUpdate,
      },
      bathrooms: {
        min: bathroomRangeFrom ? +bathroomRangeFrom : bathroomRangeFrom === "0" ? 0 : null,
        max: bathroomRangeTo ? +bathroomRangeTo : bathroomRangeTo === "0" ? 0 : null,
        autoUpdate: bathroomUpdate,
      },
      floorArea: {
        min: floorAreaRangeFrom ? +floorAreaRangeFrom : floorAreaRangeFrom === "0" ? 0 : null,
        max: floorAreaRangeTo ? +floorAreaRangeTo : floorAreaRangeTo === "0" ? 0 : null,
        autoUpdate: floorAreaUpdate,
      },
      price: {
        price: {
          from: priceRangeFrom ? +priceRangeFrom : priceRangeFrom === "0" ? 0 : null,
          to: priceRangeTo ? +priceRangeTo : priceRangeTo === "0" ? 0 : null,
          autoUpdate: priceUpdate,
        },
      },
      type: "project",
    };
    return data;
  };

  const handleBack = () => {
    toggleBack();
  };
  const handleAddUpdate = (successCB) => {
    let data = handlePayload();
    console.log("data: ", data);
    if (!constructionStatus) {
      if (!constructionStatus) {
        setMandConstruction(true);
      }

      if (!constructionStatus) {
        constRef?.current?.focus();
      }
      toast.error("Please fill all mandatory data");
    } else {
      if (constructionStatus && (!startYear || !endYear)) {
        toast.error("Please fill all mandatory data");
        if (!startYear) {
          setMandConstStartYear(true);
        }
        if (!endYear) {
          setMandCompYear(true);
        }

        if (!startYear) {
          constStartYearRef?.current?.focus();
        } else if (!endYear) {
          compYearRef?.current?.focus();
        }
      } else if (bedErrorMessage || bathErrorMessage || areaErrorMessage) {
        toast.error("Please enter valid range");
      } else {
        if (
          (startYear !== "To be announced" && startYear !== "Undisclosed" && !startMonth) ||
          (endYear !== "To be announced" && endYear !== "Undisclosed" && !endMonth)
        ) {
          toast.error("Please fill all mandatory data");
          if (!endMonth) {
            setMandCompMonth(true);
          }

          if (!endMonth) {
            compMonthRef?.current?.focus();
          }
        } else {
          if (type === "edit") {
            dispatch(
              editProject(
                id,
                data,
                (res) => {
                  dispatch(getSingleProject({ id: res?.data?.slug, query: { requestFrom: "admin" } }));
                  successCB({ success: true });
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            dispatch(handleAddProject(data));
            successCB({ success: true });
          }
        }
      }
    }
  };

  const handleNext = async () => {
    await handleAddUpdate((res) => {
      if (res?.success) {
        toggleNext();
      }
    });
  };

  const addToDraft = () => {
    let data = handlePayload();
    if (type === "edit") {
      data.status = communityStatus;
    } else {
      data.status = "draft";
    }
    if (type === "edit") {
      dispatch(
        editProject(
          id,
          data,
          (res) => {
            toast.success("Community updated");
            dispatch(handleAddProject(null));
            navigate("/community");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(
        addProject(
          data,
          async (res) => {
            toast.success("Community added as draft");
            dispatch(handleAddProject(null));
            navigate("/community");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  return (
    <>
      <Content>
        <ProjectHeader
          title={projectData?.name ? projectData.name : type === "edit" ? "Edit Master Community" : "Add Community"}
          addToDraft={addToDraft}
          btnText={type === "edit" ? "Update Community" : "Save as Draft"}
        />

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <ProjNav toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Status</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row className={styles.row}>
                        {/* Community Status */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="homeType" className="form-label">
                              Community Status
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <Input
                                  className={`form-control `}
                                  type="select"
                                  id="homeType"
                                  // ref={homeTypeRef}
                                  value={communityStatus}
                                  name="homeType"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setCommunityStatus(value);
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  {communityStatusData?.map((item, index) => {
                                    return (
                                      <option key={`${item?.id}`} value={item?.value}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Construction Status */}
                        <Col sm={"12"}>
                          <Row>
                            <Col sm={"6"}>
                              <FormGroup>
                                <Label htmlFor="constructionStatus" className="form-label">
                                  Construction Status*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      id="constructionStatus"
                                      className={mandConstruction ? "error" : ""}
                                      ref={constRef}
                                      value={constructionStatus}
                                      name="constructionStatus"
                                      onChange={(e) => {
                                        setConstructionStatus(e.target.value);
                                        setstartMonth("");
                                        setstartYear("");
                                        setendMonth("");
                                        setendYear("");
                                        if (e.target.value) {
                                          setMandConstruction(false);
                                        }
                                      }}
                                    >
                                      <option value="">Select Option</option>
                                      {constructionData?.map((item) => (
                                        <option key={item?._id} value={item?._id}>
                                          {item?.name}
                                        </option>
                                      ))}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>

                        {constructionStatus === "63cbb64d860eaa6e22264e84" ||
                        constructionStatus === "63cbb656860eaa6e22264e86" ||
                        constructionStatus === "63cbb662860eaa6e22264e88" ? (
                          <>
                            <Col sm={"6"}>
                              <FormGroup>
                                <Label htmlFor="constructionStart" className="form-label">
                                  {constructionStatus === "63cbb656860eaa6e22264e86" ||
                                  constructionStatus === "63cbb662860eaa6e22264e88"
                                    ? "Construction Started"
                                    : "Construction Start"}
                                  *
                                </Label>
                                <Row>
                                  <Col sm={"6"}>
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <Input
                                          type="select"
                                          id="constructionStart"
                                          className={mandConstStartYear ? "error" : ""}
                                          ref={constStartYearRef}
                                          value={startYear}
                                          name="startYear"
                                          onChange={(e) => {
                                            setstartYear(e.target.value);
                                            if (
                                              e.target.value === "To be announced" ||
                                              e.target.value === "Undisclosed"
                                            ) {
                                              setstartMonth("");
                                            }
                                            setMandConstStartYear(false);
                                          }}
                                        >
                                          <option value="">Select year</option>
                                          <option
                                            value={
                                              constructionStatus === "63cbb64d860eaa6e22264e84"
                                                ? "To be announced"
                                                : "Undisclosed"
                                            }
                                          >
                                            {constructionStatus === "63cbb64d860eaa6e22264e84"
                                              ? "To be announced"
                                              : "Undisclosed"}
                                          </option>
                                          {constructionStatus === "63cbb64d860eaa6e22264e84"
                                            ? yearsAfter(5)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            : yearsBefore(5)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                        </Input>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm={"6"}>
                                    {startYear === "To be announced" || startYear === "Undisclosed" ? null : (
                                      <div className="form-control-wrap">
                                        <div className="form-control-select">
                                          <Input
                                            type="select"
                                            id="constructionStatus"
                                            className={mandConstStartMonth ? "error" : ""}
                                            ref={constStartMonthRef}
                                            value={startMonth}
                                            name="startMonth"
                                            onChange={(e) => {
                                              setstartMonth(e.target.value);
                                              setMandConstStartMonth(false);
                                            }}
                                          >
                                            <option value="">Season/Month</option>
                                            {["Spring", "Summer", "Fall", "Winter", ...allMonths]?.map((item) => (
                                              <option key={item} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </Input>
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>

                            <Col sm={"6"}>
                              <FormGroup>
                                <Label htmlFor="constructionEnd" className="form-label">
                                  {constructionStatus === "63cbb662860eaa6e22264e88"
                                    ? "Completed *"
                                    : "Estimated Completion *"}
                                </Label>
                                <Row>
                                  <Col sm={"6"}>
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <Input
                                          type="select"
                                          id="constructionEnd"
                                          className={mandCompYear ? "error" : ""}
                                          ref={compYearRef}
                                          value={endYear}
                                          name="endYear"
                                          onChange={(e) => {
                                            setendYear(e.target.value);
                                            if (
                                              e.target.value === "To be announced" ||
                                              e.target.value === "Undisclosed"
                                            ) {
                                              setendMonth("");
                                            }
                                            if (e.target.value) {
                                              setMandCompYear(false);
                                            }
                                          }}
                                        >
                                          <option value="">Select year</option>
                                          {startYear ? (
                                            <>
                                              <option
                                                value={
                                                  constructionStatus === "63cbb662860eaa6e22264e88"
                                                    ? "Undisclosed"
                                                    : "To be announced"
                                                }
                                              >
                                                {constructionStatus === "63cbb662860eaa6e22264e88"
                                                  ? "Undisclosed"
                                                  : "To be announced"}
                                              </option>
                                              {constructionStatus === "63cbb662860eaa6e22264e88"
                                                ? yearsBefore(5, new Date().getFullYear())?.map((item) => (
                                                    <option key={item} value={item}>
                                                      {item}
                                                    </option>
                                                  ))
                                                : yearsAfter(5, new Date().getFullYear())?.map((item) => (
                                                    <option key={item} value={item}>
                                                      {item}
                                                    </option>
                                                  ))}
                                            </>
                                          ) : startYear &&
                                            (startYear !== "Undisclosed" || startYear !== "To be announced") ? (
                                            constructionStatus === "63cbb662860eaa6e22264e88" ? (
                                              getNextYears(5, +startYear)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            ) : constructionStatus === "63cbb662860eaa6e22264e86" ? (
                                              yearsAfter(5, +startYear)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            ) : (
                                              yearsAfter(5, +startYear)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            )
                                          ) : (
                                            <option value="" disabled>
                                              Please select construction start year
                                            </option>
                                          )}
                                        </Input>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm={"6"}>
                                    {endYear === "To be announced" || endYear === "Undisclosed" ? null : (
                                      <div className="form-control-wrap">
                                        <div className="form-control-select">
                                          <Input
                                            type="select"
                                            className={mandCompMonth ? "error" : ""}
                                            id="constructionStatus"
                                            ref={compMonthRef}
                                            value={endMonth}
                                            name="endMonth"
                                            onChange={(e) => {
                                              setendMonth(e.target.value);
                                              if (e.target.value) {
                                                setMandCompMonth(false);
                                              }
                                            }}
                                          >
                                            <option value="">Season/Month</option>
                                            {["Spring", "Summer", "Fall", "Winter", ...allMonths]?.map((item) => (
                                              <option key={item} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </Input>
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Construction Details</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row>
                        <Col sm={"12"}>
                          <FormGroup>
                            <Label htmlFor="bedroomRange" className="form-label">
                              Bedroom range of available units
                            </Label>
                            <Row className={styles.row}>
                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        From
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={bedroomRangeFrom}
                                      name="bedroomRangeFrom"
                                      // onChange={handleChange}
                                      disabled={bedroomUpdate}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setbedroomRangeFrom(value);
                                          if (value && bedroomRangeTo && +value > +bedroomRangeTo) {
                                            setBedErrorMessage(`Bedrooms range From cannot be greater than To`);
                                          } else if (value && bedroomRangeTo && +value <= +bedroomRangeTo) {
                                            setBedErrorMessage(false);
                                          } else {
                                            setBedErrorMessage(false);
                                          }
                                        } else {
                                          return;
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        To
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={bedroomRangeTo}
                                      name="bedroomRangeTo"
                                      disabled={bedroomUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setbedroomRangeTo(e.target.value);
                                          if (bedroomRangeFrom && e.target.value && bedroomRangeFrom > e.target.value) {
                                            setBedErrorMessage(`Bedrooms range From cannot be greater than To`);
                                          } else if (
                                            bedroomRangeFrom &&
                                            e.target.value &&
                                            bedroomRangeFrom <= e.target.value
                                          ) {
                                            setBedErrorMessage(false);
                                          } else {
                                            setBedErrorMessage(false);
                                          }
                                        } else return;
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {bedErrorMessage ? (
                              <label
                                style={{
                                  color: "tomato",
                                  // padding: "2px",
                                  fontSize: "14px",
                                }}
                              >
                                {bedErrorMessage}
                              </label>
                            ) : null}
                            <div className="preview-block mb-2">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="bedroomCheck"
                                  value={bedroomUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setbedroomUpdate(true);
                                    } else {
                                      setbedroomUpdate(false);
                                    }
                                  }}
                                  checked={bedroomUpdate}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="bedroomCheck"
                                  style={{ fontSize: "14px" }}
                                >
                                  Auto Update
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col sm={"12"}>
                          <FormGroup>
                            <Label htmlFor="bedroomRange" className="form-label">
                              Bathroom range of available units
                            </Label>
                            <Row className={styles.row}>
                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        From
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={bathroomRangeFrom}
                                      disabled={bathroomUpdate}
                                      name="bathroomRangeFrom"
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setbathroomRangeFrom(e.target.value);
                                          if (e.target.value && bathroomRangeTo && +e.target.value > +bathroomRangeTo) {
                                            setBathErrorMessage(`Bathrooms range From cannot be greater than To`);
                                          } else if (
                                            e.target.value &&
                                            bathroomRangeTo &&
                                            +e.target.value <= +bathroomRangeTo
                                          ) {
                                            setBathErrorMessage(false);
                                          } else {
                                            setBathErrorMessage(false);
                                          }
                                        } else return;
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        To
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={bathroomRangeTo}
                                      name="bathroomRangeTo"
                                      disabled={bathroomUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setbathroomRangeTo(e.target.value);
                                          if (
                                            bathroomRangeFrom &&
                                            e.target.value &&
                                            +bathroomRangeFrom > +e.target.value
                                          ) {
                                            setBathErrorMessage(`Bathrooms range From cannot be greater than To`);
                                          } else if (
                                            bathroomRangeFrom &&
                                            e.target.value &&
                                            +bathroomRangeFrom <= +e.target.value
                                          ) {
                                            setBathErrorMessage(false);
                                          } else {
                                            setBathErrorMessage(false);
                                          }
                                        } else return;
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {bathErrorMessage ? (
                              <label
                                htmlFor=""
                                style={{
                                  color: "tomato",
                                  padding: "2px",
                                  fontSize: "14px",
                                }}
                              >
                                {bathErrorMessage}
                              </label>
                            ) : null}
                            <div className="preview-block mb-2">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="bathroomRange"
                                  value={bathroomUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setbathroomUpdate(true);
                                    } else {
                                      setbathroomUpdate(false);
                                    }
                                  }}
                                  checked={bathroomUpdate}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="bathroomRange"
                                  style={{ fontSize: "14px" }}
                                >
                                  Auto Update
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col sm={"12"}>
                          <FormGroup>
                            <Label htmlFor="bedroomRange" className="form-label">
                              Floor Area range of available units
                            </Label>
                            <Row className={styles.row}>
                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        From
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={floorAreaRangeFrom}
                                      name="floorAreaRangeFrom"
                                      disabled={floorAreaUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setfloorAreaRangeFrom(e.target.value);
                                          if (
                                            e.target.value &&
                                            floorAreaRangeTo &&
                                            +e.target.value > +floorAreaRangeTo
                                          ) {
                                            setAreaErrorMessage(`Floor Area range From cannot be greater than To`);
                                          } else if (
                                            e.target.value &&
                                            floorAreaRangeTo &&
                                            +e.target.value <= +floorAreaRangeTo
                                          ) {
                                            setAreaErrorMessage(false);
                                          } else {
                                            setAreaErrorMessage(false);
                                          }
                                        } else return;
                                      }}
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                        Sqft
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        To
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={floorAreaRangeTo}
                                      name="floorAreaRangeTo"
                                      disabled={floorAreaUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setfloorAreaRangeTo(e.target.value);
                                          if (
                                            floorAreaRangeFrom &&
                                            e.target.value &&
                                            +floorAreaRangeFrom > +e.target.value
                                          ) {
                                            setAreaErrorMessage(`Floor Area range From cannot be greater than To`);
                                          } else if (
                                            floorAreaRangeFrom &&
                                            e.target.value &&
                                            +floorAreaRangeFrom <= +e.target.value
                                          ) {
                                            setAreaErrorMessage(false);
                                          } else {
                                            setAreaErrorMessage(false);
                                          }
                                        } else return;
                                      }}
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                        Sqft
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {areaErrorMessage ? (
                              <label
                                htmlFor=""
                                style={{
                                  color: "tomato",
                                  padding: "2px",
                                  fontSize: "14px",
                                }}
                              >
                                {areaErrorMessage}
                              </label>
                            ) : null}
                            <div className="preview-block mb-2">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control error"
                                  id="areaRange"
                                  value={floorAreaUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setfloorAreaUpdate(true);
                                    } else {
                                      setfloorAreaUpdate(false);
                                    }
                                  }}
                                  checked={floorAreaUpdate}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="areaRange"
                                  style={{ fontSize: "14px" }}
                                >
                                  Auto Update
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col sm={"12"}>
                          <FormGroup>
                            <Label htmlFor="bedroomRange" className="form-label">
                              Price range of available units
                            </Label>
                            <Row className={styles.row}>
                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        From
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={priceRangeFrom}
                                      name="priceRangeFrom"
                                      disabled={priceUpdate}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setPriceRangeFrom(e.target.value);
                                          if (e.target.value && priceRangeTo && +e.target.value > +priceRangeTo) {
                                            setPriceErrorMessage(`Price range From cannot be greater than To`);
                                          } else if (
                                            e.target.value &&
                                            priceRangeTo &&
                                            +e.target.value <= +priceRangeTo
                                          ) {
                                            setPriceErrorMessage(false);
                                          } else {
                                            setPriceErrorMessage(false);
                                          }
                                        } else return;
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        To
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      value={priceRangeTo}
                                      name="priceRangeTo"
                                      disabled={priceUpdate}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.match(/^[0-9]*\.?[0-9]*$/)) {
                                          setPriceRangeTo(e.target.value);
                                          if (priceRangeFrom && e.target.value && +priceRangeFrom > +e.target.value) {
                                            setPriceErrorMessage(`Price range From cannot be greater than To`);
                                          } else if (
                                            priceRangeFrom &&
                                            e.target.value &&
                                            +priceRangeFrom <= +e.target.value
                                          ) {
                                            setPriceErrorMessage(false);
                                          } else {
                                            setPriceErrorMessage(false);
                                          }
                                        } else return;
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {priceErrorMessage ? (
                              <label
                                htmlFor=""
                                style={{
                                  color: "tomato",
                                  padding: "2px",
                                  fontSize: "14px",
                                }}
                              >
                                {priceErrorMessage}
                              </label>
                            ) : null}
                            <div className="preview-block mb-2">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control error"
                                  id="priceRange"
                                  value={priceUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setPriceUpdate(true);
                                    } else {
                                      setPriceUpdate(false);
                                    }
                                  }}
                                  checked={priceUpdate}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="priceRange"
                                  style={{ fontSize: "14px" }}
                                >
                                  Auto Update
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>

          <div
            style={{
              margin: "15px 0",
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              color="secondary"
              outline
              onClick={handleBack}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>Back</span>
            </Button>

            <Button
              color={"info"}
              onClick={handleNext}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>Next</span>
            </Button>
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Project2;
