import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import { addListing, getListingById } from "../../redux/Apislices/listingSlice";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Head from "../../layout/head/Head";
import Assignment1 from "../../components/assignment/Assignment1";
import Assignment2 from "../../components/assignment/Assignment2";
import Assignment3 from "../../components/assignment/Assignment3";
import Assignment4 from "../../components/assignment/Assignment4";
import Assignment5 from "../../components/assignment/Assignment5";
import Assignment6 from "../../components/assignment/Assignment6";
import Assignment7 from "../../components/assignment/Assignment7";

const AddAssignment = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[1];
  const dispatch = useDispatch();
  const { payment } = useSelector((state) => ({
    payment: state?.payment?.payment,
  }));

  const [formStep, setFormStep] = useState(0);
  const [unsaved, setUnsaved] = useState(false);

  const handleSaveDraft = () => {
    let data = {};
    if (unsaved && getSession("assignment")) {
      data = getSession("assignment");

      data["status"] = "draft";

      dispatch(
        addListing(
          data,
          (res) => {
            // handleUnsavedChanges("No");
            removeSession("assignment");
            toast.success("Saved as draft");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  const nextFormStep = () => {
    setFormStep((currentStep) => currentStep + 1);
    setSession("assign-step", formStep + 1);

    if (getSession("assign-max-step")) {
      let step = +getSession("assign-max-step");
      if (step >= formStep + 1) {
        setSession("assign-max-step", step);
      } else {
        setSession("assign-max-step", formStep + 1);
      }
    } else {
      setSession("assign-max-step", formStep + 1);
    }
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  const stepGoTo = (value) => {
    setFormStep(value);
    setSession("assign-step", value);
  };

  const prevFormStep = () => {
    setFormStep((currentStep) => currentStep - 1);
    setSession("assign-step", formStep - 1);
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (getSession("assign-step")) {
      setFormStep(getSession("assign-step"));
    }
  }, []);

  useEffect(() => {
    if (type === "edit" && id) {
      dispatch(getListingById(id));
      setSession("assign-max-step", 6);
    }
  }, [type, id, dispatch, formStep]);

  return (
    <>
      <Head
        title={
          mainPath === "assignment"
            ? type === "add"
              ? "Add an Assignment"
              : type === "edit"
              ? "Edit an Assignment"
              : ""
            : mainPath === "new-homes"
            ? type === "add"
              ? "Add a New Home"
              : type === "edit"
              ? "Edit a New Home"
              : ""
            : ""
        }
      />

      {formStep === 0 ? (
        <Assignment1 formStep={formStep} nextFormStep={nextFormStep} prevFormStep={prevFormStep} stepGoTo={stepGoTo} />
      ) : formStep === 1 ? (
        <Assignment2 formStep={formStep} nextFormStep={nextFormStep} prevFormStep={prevFormStep} stepGoTo={stepGoTo} />
      ) : formStep === 2 ? (
        <Assignment3 formStep={formStep} nextFormStep={nextFormStep} prevFormStep={prevFormStep} stepGoTo={stepGoTo} />
      ) : formStep === 3 ? (
        <Assignment4 formStep={formStep} nextFormStep={nextFormStep} prevFormStep={prevFormStep} stepGoTo={stepGoTo} />
      ) : formStep === 4 ? (
        <Assignment5 formStep={formStep} nextFormStep={nextFormStep} prevFormStep={prevFormStep} stepGoTo={stepGoTo} />
      ) : formStep === 5 ? (
        <Assignment6 formStep={formStep} nextFormStep={nextFormStep} prevFormStep={prevFormStep} stepGoTo={stepGoTo} />
      ) : formStep === 6 ? (
        <Assignment7 formStep={formStep} nextFormStep={nextFormStep} prevFormStep={prevFormStep} stepGoTo={stepGoTo} />
      ) : null}
    </>
  );
};

export default AddAssignment;
