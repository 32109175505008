import React, { useEffect, useRef, useState } from "react";
import { Card, FormGroup, Input, Label, Spinner } from "reactstrap";
import Content from "../../layout/content/Content";
import { Block, BlockContent, BlockHead, BlockTitle, Button, Col, Row } from "../Component";
import ComHeader from "./ComHeader";
import ComNav from "./ComNav";
import styles from "./community.module.scss";
import { useDispatch } from "react-redux";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getDevelopmentType } from "../../redux/Apislices/developmentTypeSlice";
import { getLandTitle } from "../../redux/Apislices/landTitleSlice";
import { getconstructionMethod } from "../../redux/Apislices/constructionMethodSlice";
import { getBuilder, getMarketingCompany } from "../../redux/Apislices/companySlice";
import {
  addCommunity,
  editCommunity,
  getCommunity,
  getSingleCommunity,
  handleAddCommunity,
  handleCommunityAddReset,
} from "../../redux/Apislices/communitySlice";
import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getConstruction } from "../../redux/Apislices/constructionSlice";
import { getSaleStatus } from "../../redux/Apislices/saleStatusSlice";
import { allMonths, getNextYears, yearsAfter, yearsBefore } from "../../utils/moment";
import ConstructionStatus from "../common/ConstructionStatus";
import HomeTypes from "../common/HomeTypes";
import MapComponent from "../map/MapComponent";
import { getLocationData } from "../../utils/location";
import { getCountry } from "../../redux/Apislices/countrySlice";
import { getprovinceByCountry } from "../../redux/Apislices/provinceSlice";
import { getcityByProvince } from "../../redux/Apislices/citySlice";
import { getneighbourhoodByCity } from "../../redux/Apislices/neighbourhoodSlice";
import JoditPreview from "../../pages/components/forms/rich-editor/JoditPreview";
import axios from "axios";
import { toast } from "react-toastify";

const communityStatusData = [
  { id: 1, name: "Active", value: "active" },
  { id: 2, name: "Inactive", value: "inactive" },
  { id: 3, name: "Oh Hold", value: "on hold" },
  { id: 4, name: "Sold", value: "sold" },
];

const adminStatusData = [
  { id: 1, name: "Active", value: "active" },
  { id: 2, name: "Inactive", value: "inactive" },
  { id: 3, name: "Oh Hold", value: "on hold" },
  { id: 4, name: "Sold", value: "sold" },
  { id: 5, name: "Pending", value: "pending" },
];

const Community1 = ({ toggleNext, toggleBack, activeIconTab, toggleIconTab }) => {
  const { type, id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    homeType: homeTypeObj,
    country: countryObj,
    province: provinceObj,
    city: cityObj,
    neighbourhood: neighbourhoodObj,
    community,
    construction: constructionObj,
  } = useSelector((state) => state);

  const { homeType: homeTypeData } = homeTypeObj;
  const {
    countries: { data: countryData },
  } = countryObj;
  const {
    provinceByCountry: { data: provinceData },
  } = provinceObj;
  const {
    cityByProvince: { data: cityData },
  } = cityObj;
  const {
    neighbourhoodByCity: { data: neighbourhoodData },
  } = neighbourhoodObj;
  const { construction: constructionData } = constructionObj;

  const [comData, setComData] = useState(null);

  const [name, setName] = useState("");
  const [mandName, setMandName] = useState(false);
  const nameRef = useRef(null);

  const [communityStatus, setCommunityStatus] = useState("");

  const [adminStatus, setAdminStatus] = useState("");

  const [units, setUnits] = useState("");
  const [stories, setStories] = useState("");

  const [homeType, setHomeType] = useState("");
  const [mandHomeType, setMandHomeType] = useState(false);
  const [homeTypeArray, setHomeTypeArray] = useState("");

  const [summary, setSummary] = useState("");

  const [slug, setSlug] = useState("");

  const [constructionStatus, setConstructionStatus] = useState("");
  const [mandConstruction, setMandConstruction] = useState(false);
  const constRef = useRef(null);
  const [startYear, setstartYear] = useState("");
  const [mandConstStartYear, setMandConstStartYear] = useState(false);
  const constStartYearRef = useRef(null);
  const [startMonth, setstartMonth] = useState("");
  const constStartMonthRef = useRef(null);
  const [endYear, setendYear] = useState("");
  const [mandCompYear, setMandCompYear] = useState(false);
  const compYearRef = useRef(null);
  const [endMonth, setendMonth] = useState("");
  const [mandConstStartMonth, setMandConstStartMonth] = useState(false);
  const compMonthRef = useRef(null);

  const [marker, setMarker] = useState({});
  const [zoom, setZoom] = useState(10);

  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [neighbourhood, setNeighbourhood] = useState("");
  const [MandCountry, setMandCountry] = useState(false);
  const [MandProvince, setMandProvince] = useState(false);
  const [MandCity, setMandCity] = useState(false);
  const countryRef = useRef(null);
  const provinceRef = useRef(null);
  const cityRef = useRef(null);

  const [zipcode, setZipcode] = useState("");
  const [address, setAddress] = useState("");

  const [locationData, setLocationData] = useState(false);

  const handlePlaceChanged = (places, searchBox) => {
    if (places?.length) {
      setAddress(places[0].formatted_address);
      setZoom(15);

      setMarker({
        lat: places?.[0]?.geometry?.location?.lat(),
        lng: places?.[0]?.geometry?.location?.lng(),
      });
      let data = getLocationData(places[0]);
      setZipcode(data?.zipcode);
      setLocationData(data);
    }
  };

  // dispatching all the data needed for page
  useEffect(() => {
    dispatch(getHomeType());
    dispatch(getConstruction());
    dispatch(getCountry());
  }, [dispatch]);

  // setting Canada as default country
  useEffect(() => {
    if (countryData?.length) {
      setCountry("63d76e99b005363c54606967");
    }
  }, [countryData]);

  // get province list from country
  useEffect(() => {
    dispatch(getprovinceByCountry(country));
  }, [country, dispatch]);

  // get city list from province
  useEffect(() => {
    dispatch(getcityByProvince(province));
  }, [province, dispatch]);

  // get neighbourhood list from city
  useEffect(() => {
    dispatch(getneighbourhoodByCity(city));
  }, [city, dispatch]);

  // set country by address or marker
  useEffect(() => {
    if (locationData?.country && countryData?.length) {
      let a = countryData?.filter((li) => li?.name === locationData?.country);
      if (a?.length) {
        setCountry(a[0]?._id);
      } else {
        setCountry("");
      }
    }
  }, [locationData?.country, countryData]);

  // set province by address or marker
  useEffect(() => {
    if (locationData?.province && provinceData?.length) {
      let a = provinceData?.filter((li) => li?.name === locationData?.province);
      if (a?.length) {
        setProvince(a[0]?._id);
      } else {
        setProvince("");
      }
    }
  }, [locationData?.province, provinceData, community?.communities]);

  // set city by address or marker
  useEffect(() => {
    if (locationData?.city && cityData?.length) {
      let a = cityData?.filter((li) => li?.name === locationData?.city);
      if (a?.length) {
        setCity(a[0]?._id);
      } else {
        setCity("");
      }
    }
  }, [locationData?.city, cityData, community?.communities]);

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );
      const addresses = response.data.results[0];
      let data = getLocationData(addresses);
      if (!address) {
        setAddress(data?.address);
        setLocationData(data);
      }
      if (!zipcode) {
        setZipcode(data?.zipcode);
      }
      setZoom(18);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    if (marker?.lat && marker?.lng) {
      fetchAddress(marker?.lat, marker?.lng);
    } else {
      setLocationData(false);
    }
  }, [marker?.lat, marker?.lng]);

  const handleBack = async () => {
    dispatch(handleCommunityAddReset());
    navigate("/master-community");
  };

  let handlePayload = () => {
    let data = {
      ...comData,
      name,
      communityStatus,
      status: adminStatus,
      units: +units,
      stories: +stories,
      description: summary,
      construction: {
        constructionStatus: constructionStatus,
        constructionStart: { year: startYear, month: startMonth },
        constructionEnd: { year: endYear, month: endMonth },
      },
    };
    if (slug) data = { ...data, slug: slug };
    if (homeTypeArray?.length) data = { ...data, homeTypes: homeTypeArray };
    if (address) data = { ...data, address: { ...data?.address, firstLine: address } };
    if (neighbourhood) data = { ...data, address: { ...data?.address, neighbourhood: neighbourhood } };
    else data = { ...data, address: { ...data?.address, neighbourhood: null } };
    if (city) data = { ...data, address: { ...data?.address, city: city } };
    if (province) data = { ...data, address: { ...data?.address, province: province } };
    if (country) data = { ...data, address: { ...data?.address, country: country } };
    if (zipcode) data = { ...data, address: { ...data?.address, zipcode: zipcode } };
    else data = { ...data, address: { ...data?.address, zipcode: null } };
    if (marker && marker.lng && marker.lat)
      data = {
        ...data,
        address: { ...data?.address, location: { type: "Point", coordinates: [+marker.lng, +marker.lat] } },
      };
    return data;
  };

  const handleAddUpdate = (callback) => {
    let data = handlePayload();

    if (name && homeTypeArray?.length && constructionStatus && startYear && endYear && country && province && city) {
      if (type === "edit") {
        dispatch(
          editCommunity(
            id,
            data,
            (res) => {
              dispatch(getSingleCommunity({ slug: res?.data?.slug }));
              if (id !== res?.data?.slug) navigate(`/master-community/edit/${res?.data?.slug}`);
              callback({ success: true });
            },
            (res) => {
              toast.success(res.message);
            }
          )
        );
      } else {
        dispatch(handleAddCommunity({ ...community?.addCommunity?.data, ...data }));
        callback({ success: true });
      }
    } else {
      if (!name) setMandName(true);
      if (!homeTypeArray?.length) setMandHomeType(true);
      if (!constructionStatus) setMandConstruction(true);
      if (!startYear) setMandConstStartYear(true);
      if (!endYear) setMandCompYear(true);
      if (!country) setMandCountry(true);
      if (!province) setMandProvince(true);
      if (!city) setMandCity(true);

      if (!name) nameRef?.current?.focus();
      else if (!constructionStatus) constRef?.current?.focus();
      else if (!startYear) constStartYearRef?.current?.focus();
      else if (!endYear) compYearRef?.current?.focus();
      else if (!country) countryRef?.current?.focus();
      else if (!province) provinceRef?.current?.focus();
      else if (!city) cityRef?.current?.focus();

      toast.error("Please fill all the mandatory fields");
      callback({ success: false });
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        toggleNext();
      }
    });
  };

  const addToDraft = () => {
    let data = handlePayload();

    if (name && homeTypeArray?.length && constructionStatus && startYear && endYear && country && province && city) {
      if (type === "edit") {
        dispatch(
          editCommunity(
            id,
            data,
            (res) => {
              dispatch(handleCommunityAddReset());
              navigate("/master-community");
              toast.success("Master Community Updated");
            },
            (res) => {
              toast.success(res.message);
            }
          )
        );
      } else {
        dispatch(
          addCommunity(
            data,
            (res) => {
              dispatch(handleCommunityAddReset());
              navigate("/master-community");
              toast.success("Master Community saved as draft");
            },
            (res) => {
              toast.success(res.message);
            }
          )
        );
      }
    } else {
      if (!name) setMandName(true);
      if (!homeTypeArray?.length) setMandHomeType(true);
      if (!constructionStatus) setMandConstruction(true);
      if (!startYear) setMandConstStartYear(true);
      if (!endYear) setMandCompYear(true);
      if (!country) setMandCountry(true);
      if (!province) setMandProvince(true);
      if (!city) setMandCity(true);

      if (!name) nameRef?.current?.focus();
      else if (!constructionStatus) constRef?.current?.focus();
      else if (!startYear) constStartYearRef?.current?.focus();
      else if (!endYear) compYearRef?.current?.focus();
      else if (!country) countryRef?.current?.focus();
      else if (!province) provinceRef?.current?.focus();
      else if (!city) cityRef?.current?.focus();

      toast.error("Please fill all the mandatory fields");
    }
  };

  useEffect(() => {
    if (community?.addCommunity?.data) {
      let data = community?.addCommunity?.data;
      setComData(data);
    } else if (community?.communityBySlug) {
      setComData(community?.communityBySlug);
    }
  }, [community]);

  useEffect(() => {
    if (comData) {
      let data = comData;
      setName(data?.name);
      setCommunityStatus(data?.communityStatus);
      setAdminStatus(data?.status);
      setUnits(data?.units);
      setSummary(data?.description);
      setConstructionStatus(data?.construction?.constructionStatus);
      setstartYear(data?.construction?.constructionStart?.year);
      setstartMonth(data?.construction?.constructionStart?.month);
      setendYear(data?.construction?.constructionEnd?.year);
      setendMonth(data?.construction?.constructionEnd?.month);
      setHomeTypeArray(data?.homeTypes?.length ? data?.homeTypes : []);
      setMarker({ lat: data?.address?.location?.coordinates?.[1], lng: data?.address?.location?.coordinates?.[0] });
      setAddress(data?.address?.firstLine);
      setNeighbourhood(data?.address?.neighbourhood);
      setZipcode(data?.address?.zipcode);
      setCountry(data?.address?.country);
      setProvince(data?.address?.province);
      setCity(data?.address?.city);
    }
  }, [comData]);

  return (
    <>
      <Content>
        <ComHeader
          title={comData?.name ? comData.name : type === "edit" ? "Edit Master Community" : "Add Master Community"}
          btnText={type === "edit" ? "Update" : "Add as Draft"}
          addToDraft={() => {
            addToDraft();
          }}
        />

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <ComNav toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Summary</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row className="gy-4">
                        {/* Community */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <FormGroup>
                            <Label htmlFor="name" className="form-label">
                              Master Community Name*
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className={`form-control ${mandName ? "error" : ""}`}
                                type="text"
                                ref={nameRef}
                                id="name"
                                placeholder="Master Community Name"
                                value={name}
                                autoComplete="off"
                                name="name"
                                onChange={(e) => {
                                  setName(e.target.value);
                                  setMandName(false);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Master Community Status */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <FormGroup>
                            <Label htmlFor="homeType" className="form-label">
                              Master Community Status
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <Input
                                  className={`form-control `}
                                  type="select"
                                  id="homeType"
                                  // ref={homeTypeRef}
                                  value={communityStatus}
                                  name="homeType"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setCommunityStatus(value);
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  {communityStatusData?.map((item, index) => {
                                    return (
                                      <option key={`${item?.id}`} value={item?.value}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Admin Status */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <FormGroup>
                            <Label htmlFor="homeType" className="form-label">
                              Admin Status
                            </Label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <Input
                                  className={`form-control `}
                                  type="select"
                                  id="homeType"
                                  // ref={homeTypeRef}
                                  value={adminStatus}
                                  name="homeType"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setAdminStatus(value);
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  {adminStatusData?.map((item, index) => {
                                    return (
                                      <option key={`${item?.id}`} value={item?.value}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Home Type(s) */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <HomeTypes
                            homeType={homeType}
                            setHomeTypeArray={setHomeTypeArray}
                            homeTypeArray={homeTypeArray}
                            homeTypeData={homeTypeData}
                            setMandHomeType={setMandHomeType}
                          />
                        </Col>

                        {/* Total Units */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <FormGroup>
                            <Label htmlFor="units" className="form-label">
                              Total Units
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className={`form-control `}
                                type="text"
                                // ref={nameRef}
                                id="units"
                                placeholder="Total Units"
                                value={units}
                                autoComplete="off"
                                name="units"
                                onChange={(e) => {
                                  // only numbers and .
                                  const re = /^[0-9\b.]+$/;
                                  if (e.target.value === "" || re.test(e.target.value)) {
                                    setUnits(e.target.value);
                                  }
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        {/* Floors */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <FormGroup>
                            <Label htmlFor="units" className="form-label">
                              Floors
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className={`form-control `}
                                type="text"
                                // ref={nameRef}
                                id="stories"
                                placeholder="Floors"
                                value={stories}
                                autoComplete="off"
                                name="stories"
                                onChange={(e) => {
                                  // only numbers and .
                                  const re = /^[0-9\b.]+$/;
                                  if (e.target.value === "" || re.test(e.target.value)) {
                                    setStories(e.target.value);
                                  }
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* Construction Status */}
                      <ConstructionStatus
                        constructionData={constructionData}
                        constructionStatus={constructionStatus}
                        setConstructionStatus={setConstructionStatus}
                        mandConstruction={mandConstruction}
                        setMandConstruction={setMandConstruction}
                        startYear={startYear}
                        setstartYear={setstartYear}
                        mandConstStartYear={mandConstStartYear}
                        setMandConstStartYear={setMandConstStartYear}
                        startMonth={startMonth}
                        setstartMonth={setstartMonth}
                        mandConstStartMonth={mandConstStartMonth}
                        setMandConstStartMonth={setMandConstStartMonth}
                        endYear={endYear}
                        setendYear={setendYear}
                        mandCompYear={mandCompYear}
                        setMandCompYear={setMandCompYear}
                        endMonth={endMonth}
                        setendMonth={setendMonth}
                        constRef={constRef}
                        constStartYearRef={constStartYearRef}
                        constStartMonthRef={constStartMonthRef}
                        compYearRef={compYearRef}
                        compMonthRef={compMonthRef}
                      />

                      <Row className="gy-4">
                        {/* Project Summary */}
                        <Col sm={"12"} md={"12"} lg={"12"} size={"12"}>
                          <FormGroup>
                            <Label htmlFor="projectSummary" className="form-label">
                              Project Summary
                            </Label>
                            <div className="form-control-wrap">
                              <JoditPreview
                                initialValue={summary}
                                getValue={setSummary}
                                placeholder={"Concise Master Community Summary with highlights..."}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Location</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row className="gy-4">
                        {/* Map */}
                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <MapComponent zoom={zoom} marker={marker} setMarker={setMarker} />
                          <p>Drag and drop the pin on map to find exact location</p>
                        </Col>

                        <Col sm={"12"} md={"6"} lg={"6"}>
                          <Row className={"gy-4"}>
                            {/* Address */}
                            <Col sm={"12"} md={"12"} lg={"12"}>
                              <FormGroup>
                                <Label htmlFor="address" className="form-label">
                                  Address
                                </Label>
                                <div className="form-control-wrap">
                                  <StandaloneSearchBox
                                    onLoad={(searchBox) => {
                                      searchBox.addListener("places_changed", () =>
                                        handlePlaceChanged(searchBox.getPlaces(), searchBox)
                                      );
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Master Community address"
                                      className={`form-control`}
                                      value={address}
                                      onChange={(e) => {
                                        setAddress(e.target.value);
                                      }}
                                      data-error="Text is required."
                                    />
                                  </StandaloneSearchBox>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Postal Code */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="postalCode" className="form-label">
                                  Postal Code
                                </Label>
                                <div className="form-control-wrap">
                                  <input
                                    className="form-control"
                                    autoComplete="off"
                                    type="text"
                                    id="postalCode"
                                    placeholder={"Postal code"}
                                    value={zipcode}
                                    name="zipcode"
                                    onChange={(e) => {
                                      setZipcode(e.target.value);
                                    }}
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Country* */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="country" className="form-label">
                                  Country*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      className={`form-control ${MandCountry ? "error" : ""}`}
                                      id="country"
                                      style={{
                                        background: country === "63d76e99b005363c54606967" ? "#e0e0e0" : "",
                                      }}
                                      ref={countryRef}
                                      value={country}
                                      name="country"
                                      onChange={(e) => setCountry(e.target.value)}
                                    >
                                      <option value="">Select</option>
                                      {countryData?.length
                                        ? countryData?.map((item) => (
                                            <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                              {item?.name}
                                            </option>
                                          ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Province* */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="Province*" className="form-label">
                                  Province*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      id="Province*"
                                      className={`form-control ${MandProvince ? "error" : ""}`}
                                      ref={provinceRef}
                                      value={province}
                                      name="province"
                                      onChange={(e) => {
                                        setProvince(e.target.value);
                                        setMandProvince(false);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {provinceData?.length ? (
                                        provinceData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select country first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* City* */}
                            <Col sm={"12"} md={"6"} lg={"6"}>
                              <FormGroup>
                                <Label htmlFor="City*" className="form-label">
                                  City*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      className={`form-control ${MandCity ?"error" : ""}`}
                                      id="City*"
                                      ref={cityRef}
                                      value={city}
                                      name="city"
                                      onChange={(e) => {
                                        setCity(e.target.value);
                                        setMandCity(false);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {cityData?.length ? (
                                        cityData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select province first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {/* Neighbourhood */}
                            <Col sm={"12"} md={"12"} lg={"12"}>
                              <FormGroup>
                                <Label htmlFor="Neighbourhood*" className="form-label">
                                  Neighbourhood
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      id="Neighbourhood*"
                                      value={neighbourhood}
                                      name="neighbourhood"
                                      onChange={(e) => setNeighbourhood(e.target.value)}
                                    >
                                      <option value="">Select</option>
                                      {neighbourhoodData?.length ? (
                                        neighbourhoodData?.map((item) => (
                                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                                            {item?.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Please select city first</option>
                                      )}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <FormGroup>
                            <Label htmlFor="postalCode" className="form-label">
                              Slug
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                autoComplete="off"
                                type="text"
                                id="slug"
                                placeholder={"Slug for the community"}
                                value={slug}
                                name="slug"
                                onChange={(e) => {
                                  setSlug(e.target.value);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>

          <div
            style={{
              margin: "15px 0",
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              color="secondary"
              outline
              onClick={handleBack}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color={"info"}
              onClick={handleNext}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>Next</span>
            </Button>
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Community1;
