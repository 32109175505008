import React from "react";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import AssociateCostAccordion from "../../components/Accordion/AssociateCostAccordion";

const AssociateCost = () => {
  return (
    <div>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page tag="h1">
              Associate Subscription
            </BlockTitle>
            <BlockDes className="text-soft"></BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <div className="mt-3">
        <AssociateCostAccordion />
      </div>
    </div>
  );
};

export default AssociateCost;
