import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getCommunity = createAsyncThunk("community/communityList", async (query) => {
  try {
    const response = await getMethod(`/api/admin/community`, query);
    return response;
  } catch (error) {
    throw error;
  }
});

export const getSingleCommunity = createAsyncThunk("community/community", async (query) => {
  try {
    const response = await getMethod(`/api/admin/single-community`,query);
    return response;
  } catch (error) {
    throw error;
  }
});

export const searchCommunity = createAsyncThunk("community/searchCommunity", async (query) => {
  const response = await getMethod(`/api/community-search`, query);
  return response;
});

export const getCommunityById = createAsyncThunk("community/communityById", async (id) => {
  try {
    const response = await getMethod(`/api/admin/community/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  communities: {
    loading: false,
    success: false,
    data: null,
    total: null,
    error: false,
  },
  community: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  communitySearch: {
    
  },
  addCommunity: {
    loading: false,
    success: false,
    data: null,
    error: false,
    step: 1,
    maxStep: 1,
    "com-aws": [],
    "info-folder": [],
  },
};

const communitySlice = createSlice({
  name: "community",
  initialState,

  reducers: {
    isLoading: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccessful: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    // editCommunityData: (state, action) => {
    //   state.communityBySlug = action.payload;
    // },
    handleAddCommunity: (state, action) => {
      state.addCommunity.data = action.payload;
    },
    handleComAws: (state, action) => {
      state.addCommunity["com-aws"] = action.payload;
    },
    handleComInfo: (state, action) => {
      state.addCommunity["info-folder"] = action.payload;
    },
    handleComStep: (state, action) => {
      state.addCommunity.step = action.payload.step;
      state.addCommunity.maxStep = action.payload.maxStep;
    },

    handleCommunityAddReset: (state, action) => {
      state.addCommunity = initialState.addCommunity;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCommunity.pending, (state) => {
      state.communities.loading = true;
      state.communities.success = false;
      state.communities.error = false;
    });
    builder.addCase(getCommunity.fulfilled, (state, action) => {
      state.communities.loading = false;
      state.communities.success = true;
      state.communities.error = false;
      state.communities.data = action.payload.data;
      state.communities.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getCommunity.rejected, (state, action) => {
      state.communities.loading = false;
      state.communities.success = false;
      state.communities.error = action.error.message;
    });

    builder.addCase(getSingleCommunity.pending, (state) => {
      state.addCommunity.loading = true;
      state.addCommunity.success = false;
      state.addCommunity.error = false;
    });
    builder.addCase(getSingleCommunity.fulfilled, (state, action) => {
      state.addCommunity.loading = false;
      state.addCommunity.success = true;
      state.addCommunity.error = false;
      state.addCommunity.data = action.payload.data;
    });
    builder.addCase(getSingleCommunity.rejected, (state, action) => {
      state.addCommunity.loading = false;
      state.addCommunity.success = false;
      state.addCommunity.data = null;
      state.addCommunity.error = action.error.message;
    });

    builder.addCase(searchCommunity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchCommunity.fulfilled, (state, action) => {
      state.loading = false;
      state.communitySearch = action.payload.data;
    });
    builder.addCase(searchCommunity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(getCommunityById.pending, (state) => {
      state.community.loading = true;
      state.community.success = false;
      state.community.error = false;
      state.community.data = null;
    });
    builder.addCase(getCommunityById.fulfilled, (state, action) => {
      state.community.loading = false;
      state.community.success = true;
      state.community.error = false;
      state.community.data = action.payload.data;
    });
    builder.addCase(getCommunityById.rejected, (state, action) => {
      state.community.loading = false;
      state.community.success = false;
      state.community.data = null;
      state.community.error = action.error.message;
    });
  },
});

export const {
  isLoading,
  isSuccessful,
  isError,
  handleComAws,
  handleComStep,
  handleAddCommunity,
  handleComInfo,
  handleCommunityAddReset,
} = communitySlice.actions;

export default communitySlice.reducer;

export const addCommunity = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await postMethod("api/community", data).then((response) => {
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  });
};

export const editCommunity = (id, data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await putMethod(`api/admin/community/${id}`, data).then((response) => {
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  });
};

export const deleteCommunity = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await deleteMethod(`api/admin/community/delete/${id}`).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const deleteMultipleCommunity = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/community/delete-multiple`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const restoreCommunity = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await putMethod(`api/admin/community/restore/${id}`).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const restoremultipleCommunity = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/community/restore-multiple`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const deleteCommunityPermanently = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await deleteMethod(`api/admin/community/delete-permanently/${id}`).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};

export const deleteMultipleCommunityPermanently = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  await postMethod(`api/admin/community/delete-multiple-permanently`, data).then((response) => {
    if (response?.success) {
      dispatch(isSuccessful());
      successCB(response);
    } else {
      dispatch(isError());
      errorCB(response);
    }
  });
};
