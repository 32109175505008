import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addListing, editListing } from "../../redux/Apislices/listingSlice";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import FooterBtn from "../common/FooterBtn";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  PreviewCard,
  Row,
} from "../Component";
import { Card } from "reactstrap";
import AssignNavTab from "./AssignNavTab";
import clsx from "clsx";
import styles from "./assignment.module.scss";
import { assignmentData as sizeData } from "./Data/data";
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";

const Assignment4 = ({ formStep, nextFormStep, prevFormStep, stepGoTo }) => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[1];
  const dispatch = useDispatch();
  const { listing } = useSelector((state) => ({
    listing: state?.listing?.listingById?.data,
  }));

  const [assignmentData, setAssignmentData] = useState(false);

  const [isCondo, setIsCondo] = useState(false);
  const [isTownHome, setIsTownHome] = useState(false);
  const [duplex, setDuplex] = useState(false);
  const [detached, setDetached] = useState(false);
  const [rowHouse, setRowHouse] = useState(false);

  const [lotSize, setLotSize] = useState("");
  const [frontage, setFrontage] = useState("");
  const [depth, setDepth] = useState("");
  const [floorAreaTotal, setFloorAreaTotal] = useState("");
  const [mainFloorArea, setMainFloorArea] = useState("");
  const [finishedFloorUp1, setFinishedFloorUp1] = useState("");
  const [finishedFloorUp2, setFinishedFloorUp2] = useState("");
  const [finishedFloorDown, setFinishedFloorDown] = useState("");
  const [unfinishedFloor, setUnfinishedFloor] = useState("");
  const [rooftopDeck, setRooftopDeck] = useState("");
  const [balcony, setBalcony] = useState("");
  const [patio, setPatio] = useState("");

  const [mandArea, setMandArea] = useState(false);
  const [mandLotSize, setMandLotSize] = useState(false);
  const [mandFrontage, setMandFrontage] = useState(false);

  const [helper, setHelper] = useState("");
  const [helperError, setHelperError] = useState({ error: false, message: "" });
  const [suiteType, setSuiteType] = useState("");
  const [suiteTypeError, setSuiteTypeError] = useState({ error: false, message: "" });
  const [finish, setFinish] = useState("");
  const [finishError, setFinishError] = useState({ error: false, message: "" });
  const [bedrooms, setbedrooms] = useState("");
  const [bedroomsError, setBedroomsError] = useState({ error: false, message: "" });
  const [bathrooms, setBathrooms] = useState("");
  const [bathroomsError, setBathroomsError] = useState({ error: false, message: "" });
  const [mortgageFeatures, setMortgageFeatures] = useState("");
  const [mortgageFeaturesArray, setMortgageFeaturesArray] = useState([]);
  const [otherDetails, setOtherDetails] = useState("");

  const [isEditMortgage, setIsEditMortgage] = useState(false);
  const [helperEdit, setHelperEdit] = useState("");
  const [suiteTypeEdit, setSuiteTypeEdit] = useState("");
  const [finishEdit, setFinishEdit] = useState("");
  const [bedroomsEdit, setbedroomsEdit] = useState("");
  const [bathroomsEdit, setBathroomsEdit] = useState("");
  const [mortgageFeaturesEdit, setMortgageFeaturesEdit] = useState("");
  const [mortgageFeaturesArrayEdit, setMortgageFeaturesArrayEdit] = useState([]);
  const [otherDetailsEdit, setOtherDetailsEdit] = useState("");

  const [mortgageHelperArray, setMortgageHelperArray] = useState([]);

  const [floor, setFloor] = useState("");
  const [roomType, setRoomType] = useState("");
  const [widthFeet, setWidthFeet] = useState("");
  const [widthInch, setWidthInch] = useState("");
  const [roomDepthFeet, setRoomDepthFeet] = useState("");
  const [roomDepthInch, setRoomDepthInch] = useState("");
  const [roomDetailsArray, setRoomDetailsArray] = useState([]);

  const [isEditRoom, setIsEditRoom] = useState(false);
  const [floorEdit, setFloorEdit] = useState("");
  const [roomTypeEdit, setRoomTypeEdit] = useState("");
  const [widthFeetEdit, setWidthFeetEdit] = useState("");
  const [widthInchEdit, setWidthInchEdit] = useState("");
  const [roomDepthFeetEdit, setRoomDepthFeetEdit] = useState("");
  const [roomDepthInchEdit, setRoomDepthInchEdit] = useState("");

  const handleBackFun = () => {
    prevFormStep();
  };

  const handlePayload = () => {
    let data = {};
    if (isCondo) {
      if (floorAreaTotal) data = { ...data, floorArea: +floorAreaTotal };
      else data = { ...data, floorArea: null };
      if (balcony) data = { ...data, balcony: +balcony };
      else data = { ...data, balcony: null };
      data = { ...data, lotSize: null };
      data = { ...data, frontage: null };
      data = { ...data, depth: null };
      data = { ...data, mainFloorArea: null };
      data = { ...data, finishedFloorUp1: null };
      data = { ...data, finishedFloorUp2: null };
      data = { ...data, finishedFloorDown: null };
      data = { ...data, unfinishedFloor: null };
      data = { ...data, rooftopDeck: null };
      data = { ...data, patio: null };
    } else if (isTownHome) {
      if (floorAreaTotal) data = { ...data, floorArea: +floorAreaTotal };
      else data = { ...data, floorArea: null };
      if (mainFloorArea) data = { ...data, mainFloorArea: +mainFloorArea };
      else data = { ...data, mainFloorArea: null };
      if (finishedFloorUp1) data = { ...data, finishedFloorUp1: +finishedFloorUp1 };
      else data = { ...data, finishedFloorUp1: null };
      if (finishedFloorUp2) data = { ...data, finishedFloorUp2: +finishedFloorUp2 };
      else data = { ...data, finishedFloorUp2: null };
      if (finishedFloorDown) data = { ...data, finishedFloorDown: +finishedFloorDown };
      else data = { ...data, finishedFloorDown: null };
      if (balcony) data = { ...data, balcony: +balcony };
      else data = { ...data, balcony: null };
      if (rooftopDeck) data = { ...data, rooftopDeck: +rooftopDeck };
      else data = { ...data, rooftopDeck: null };
      if (patio) data = { ...data, patio: +patio };
      else data = { ...data, patio: null };
      data = { ...data, lotSize: null };
      data = { ...data, frontage: null };
      data = { ...data, depth: null };
      data = { ...data, unfinishedFloor: null };
    } else {
      if (lotSize) data = { ...data, lotSize: +lotSize };
      else data = { ...data, lotSize: null };
      if (frontage) data = { ...data, frontage: +frontage };
      else data = { ...data, frontage: null };
      if (depth) data = { ...data, depth: +depth };
      else data = { ...data, depth: null };
      if (floorAreaTotal) data = { ...data, floorArea: +floorAreaTotal };
      else data = { ...data, floorArea: null };
      if (mainFloorArea) data = { ...data, mainFloorArea: +mainFloorArea };
      else data = { ...data, mainFloorArea: null };
      if (finishedFloorUp1) data = { ...data, finishedFloorUp1: +finishedFloorUp1 };
      else data = { ...data, finishedFloorUp1: null };
      if (finishedFloorUp2) data = { ...data, finishedFloorUp2: +finishedFloorUp2 };
      else data = { ...data, finishedFloorUp2: null };
      if (finishedFloorDown) data = { ...data, finishedFloorDown: +finishedFloorDown };
      else data = { ...data, finishedFloorDown: null };
      if (unfinishedFloor) data = { ...data, unfinishedFloor: +unfinishedFloor };
      else data = { ...data, unfinishedFloor: null };
      if (rooftopDeck) data = { ...data, rooftopDeck: +rooftopDeck };
      else data = { ...data, rooftopDeck: null };
      if (balcony) data = { ...data, balcony: +balcony };
      else data = { ...data, balcony: null };
      if (patio) data = { ...data, patio: +patio };
      else data = { ...data, patio: null };
    }
    return data;
  };

  const handleAddUpdate = (successCB) => {
    let data = {
      ...assignmentData,
    };
    let areaData = handlePayload();
    if (areaData) data = { ...data, ...areaData };
    data = { ...data, mortgageHelper: mortgageHelperArray };
    data = { ...data, roomSizes: roomDetailsArray };
    console.log("data: ", data);
    if ((detached || duplex) && (!lotSize || !frontage || !floorAreaTotal)) {
      if (!floorAreaTotal) setMandArea(true);
      if (!lotSize) setMandLotSize(true);
      if (!frontage) setMandFrontage(true);
      if (!floorAreaTotal) document.getElementById("floorAreaTotal").focus();
      else if (!lotSize) document.getElementById("lotSize").focus();
      else if (!frontage) document.getElementById("frontage").focus();
      toast.error("Please complete all the mandatory fields.");
    } else if (!floorAreaTotal) {
      if (!floorAreaTotal) {
        setMandArea(true);
        document.getElementById("floorAreaTotal").focus();
      }
      toast.error("Please complete all the mandatory fields.");
    } else {
      if (type === "edit") {
        dispatch(
          editListing(
            id,
            data,
            (res) => {
              successCB({ success: true });
            },
            (res) => {
              console.log("res: ", res);
            }
          )
        );
      } else {
        if (getSession("assignment")) {
          let mortgage = getSession("assignment");
          let currData = { ...mortgage, ...data };
          setSession("assignment", currData);
        } else {
          setSession("assignment", { ...data });
        }
        successCB({ success: true });
      }
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        nextFormStep();
      }
    });
  };

  const handleDraft = () => {
    let data = { ...assignmentData };
    let areaData = handlePayload();
    if (areaData) data = { ...data, ...areaData };
    data = { ...data, mortgageHelper: mortgageHelperArray };
    data = { ...data, roomSizes: roomDetailsArray };
    if (type === "edit") data = { ...data, status: data?.status };
    else data = { ...data, status: "draft" };
    console.log("data: ", data);
    if ((detached || duplex) && (!lotSize || !frontage || !floorAreaTotal)) {
      if (!floorAreaTotal) setMandArea(true);
      if (!lotSize) setMandLotSize(true);
      if (!frontage) setMandFrontage(true);
      if (!floorAreaTotal) document.getElementById("floorAreaTotal").focus();
      else if (!lotSize) document.getElementById("lotSize").focus();
      else if (!frontage) document.getElementById("frontage").focus();
      toast.error("Please complete all the mandatory fields.");
    } else if (!floorAreaTotal) {
      if (!floorAreaTotal) {
        setMandArea(true);
        document.getElementById("floorAreaTotal").focus();
      }
      toast.error("Please complete all the mandatory fields.");
    } else {
      if (type === "edit") {
        dispatch(
          editListing(
            id,
            data,
            (res) => {
              navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
              toast.success("Listing Updated");
            },
            (res) => {
              toast?.error(res?.message);
            }
          )
        );
      } else {
        dispatch(
          addListing(
            data,
            (res) => {
              removeSession("assignment");
              navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
              toast.success("Saved as draft");
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    if (listing) {
      setAssignmentData(listing);
    } else {
      if (getSession("assignment")) {
        let data = getSession("assignment");
        setAssignmentData(data);
      }
    }
  }, [listing]);

  useEffect(() => {
    if (assignmentData) {
      console.log("assignmentData 4: ", assignmentData);
      let data = assignmentData;
      setIsCondo(data?.homeType === "63c66c47cec8665e878ce4df" ? true : false);
      setIsTownHome(data?.homeType === "63c683e55178561202fd7ee7" ? true : false);
      setDetached(data?.homeType === "63d89edf1a9dc578f88e0237" ? true : false);
      setDuplex(data?.homeType === "63db401e4efacab1f76aecba" ? true : false);
      setRowHouse(data?.homeType === "63db40244efacab1f76aecbc" ? true : false);
      if (data?.homeType === "63c66c47cec8665e878ce4df") {
        setFloorAreaTotal(data?.floorArea || floorAreaTotal);
        setBalcony(data?.balcony || balcony);
      }
      if (setIsTownHome(data?.homeType === "63c683e55178561202fd7ee7" ? true : false)) {
        setFloorAreaTotal(data?.floorArea || floorAreaTotal);
        setMainFloorArea(data?.mainFloorArea || mainFloorArea);
        setFinishedFloorUp1(data?.finishedFloorUp1 || finishedFloorUp1);
        setFinishedFloorUp2(data?.finishedFloorUp2 || finishedFloorUp2);
        setFinishedFloorDown(data?.finishedFloorDown || finishedFloorDown);
        setRooftopDeck(data?.rooftopDeck || rooftopDeck);
        setBalcony(data?.balcony || balcony);
        setPatio(data?.patio || patio);
      } else {
        setLotSize(data?.lotSize || lotSize);
        setFrontage(data?.frontage || frontage);
        setDepth(data?.depth || depth);
        setFloorAreaTotal(data?.floorArea || floorAreaTotal);
        setMainFloorArea(data?.mainFloorArea || mainFloorArea);
        setFinishedFloorUp1(data?.finishedFloorUp1 || finishedFloorUp1);
        setFinishedFloorUp2(data?.finishedFloorUp2 || finishedFloorUp2);
        setFinishedFloorDown(data?.finishedFloorDown || finishedFloorDown);
        setUnfinishedFloor(data?.unfinishedFloor || unfinishedFloor);
        setRooftopDeck(data?.rooftopDeck || rooftopDeck);
        setBalcony(data?.balcony || balcony);
        setPatio(data?.patio || patio);
      }
      setMortgageHelperArray(data?.mortgageHelper?.map((li) => li) || mortgageHelperArray);
      setRoomDetailsArray(data?.roomSizes?.map((li) => li) || roomDetailsArray);
    }
  }, [assignmentData]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {assignmentData?.title
                  ? assignmentData?.title
                  : `Add ${mainPath === "assignment" ? "Assignment" : "New Home"}`}{" "}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleDraft();
                }}
              >
                <span>{type === "edit" ? "Update Listing" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <AssignNavTab stepGoTo={stepGoTo} update={handleAddUpdate} />
                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Area</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        {isCondo ? (
                          <>
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Floor Area Total (sqft)*</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField, mandArea ? "error" : null)}
                                    id={"floorAreaTotal"}
                                    value={floorAreaTotal}
                                    name="floorAreaTotal"
                                    onChange={(e) => {
                                      setFloorAreaTotal(e.target.value);
                                      if (e.target?.value) {
                                        setMandArea(false);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Balcony (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Balcony (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={balcony}
                                    name="balcony"
                                    onChange={(e) => setBalcony(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : isTownHome ? (
                          <>
                            {/* Floor Area Total (sqft)* */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Floor Area Total (sqft)*</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField, mandArea ? "error" : null)}
                                    id={"floorAreaTotal"}
                                    value={floorAreaTotal}
                                    name="floorAreaTotal"
                                    onChange={(e) => {
                                      setFloorAreaTotal(e.target.value);
                                      if (e.target?.value) {
                                        setMandArea(false);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Main Floor Area (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Main Floor Area (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={mainFloorArea}
                                    name="mainFloorArea"
                                    onChange={(e) => {
                                      setMainFloorArea(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Finished Floor Up 1 (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Finished Floor Up 1 (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={finishedFloorUp1}
                                    name="finishedFloorUp1"
                                    onChange={(e) => setFinishedFloorUp1(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Finished Floor Up 2 (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Finished Floor Up 2 (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={finishedFloorUp2}
                                    name="finishedFloorUp2"
                                    onChange={(e) => setFinishedFloorUp2(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Finished Floor Down (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Finished Floor Down (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={finishedFloorDown}
                                    name="finishedFloorDown"
                                    onChange={(e) => setFinishedFloorDown(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Rooftop Deck (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Rooftop Deck (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={rooftopDeck}
                                    name="rooftopDeck"
                                    onChange={(e) => setRooftopDeck(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Balcony (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Balcony (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={balcony}
                                    name="balcony"
                                    onChange={(e) => setBalcony(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Patio (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Patio (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={patio}
                                    name="patio"
                                    onChange={(e) => setPatio(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            {detached || duplex ? (
                              <>
                                {/* Lot Size (sqft)* */}
                                <Col sm={"6"} md={"3"} lg={"3"}>
                                  <div className={clsx(styles.askingPriceDiv)}>
                                    <p>Lot Size (sqft)*</p>
                                    <div className="input-group-meta form-group">
                                      <input
                                        type="number"
                                        className={clsx("form-control", styles.textField, mandLotSize ? "error" : null)}
                                        value={lotSize}
                                        id="lotSize"
                                        name="lotSize"
                                        onChange={(e) => {
                                          setLotSize(e.target.value);
                                          if (e.target.value) {
                                            setMandLotSize(false);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                {/* Frontage (feet)* */}
                                <Col sm={"6"} md={"3"} lg={"3"}>
                                  <div className={clsx(styles.askingPriceDiv)}>
                                    <p>Frontage (feet)*</p>
                                    <div className="input-group-meta form-group">
                                      <input
                                        type="number"
                                        className={clsx(
                                          "form-control",
                                          styles.textField,
                                          mandFrontage ? "error" : null
                                        )}
                                        value={frontage}
                                        id="frontage"
                                        name="frontage"
                                        onChange={(e) => {
                                          setFrontage(e.target.value);
                                          if (e.target.value) {
                                            setMandFrontage(false);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </>
                            ) : (
                              <>
                                {/* Lot Size (sqft) */}
                                <Col sm={"6"} md={"3"} lg={"3"}>
                                  <div className={clsx(styles.askingPriceDiv)}>
                                    <p>Lot Size (sqft)</p>
                                    <div className="input-group-meta form-group">
                                      <input
                                        type="number"
                                        className={clsx("form-control", styles.textField)}
                                        value={lotSize}
                                        name="lotSize"
                                        onChange={(e) => setLotSize(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                {/* Frontage (feet) */}
                                <Col sm={"6"} md={"3"} lg={"3"}>
                                  <div className={clsx(styles.askingPriceDiv)}>
                                    <p>Frontage (feet)</p>
                                    <div className="input-group-meta form-group">
                                      <input
                                        type="number"
                                        className={clsx("form-control", styles.textField)}
                                        value={frontage}
                                        name="frontage"
                                        onChange={(e) => setFrontage(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}

                            {/* Depth (feet) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Depth (feet)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={depth}
                                    name="depth"
                                    onChange={(e) => setDepth(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Floor Area Total (sqft)* */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Floor Area Total (sqft)*</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField, mandArea ? "error" : null)}
                                    id={"floorAreaTotal"}
                                    value={floorAreaTotal}
                                    name="floorAreaTotal"
                                    onChange={(e) => {
                                      setFloorAreaTotal(e.target.value);
                                      if (e.target?.value) {
                                        setMandArea(false);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Main Floor Area (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Main Floor Area (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={mainFloorArea}
                                    name="mainFloorArea"
                                    onChange={(e) => {
                                      setMainFloorArea(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Finished Floor Up 1 (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Finished Floor Up 1 (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={finishedFloorUp1}
                                    name="finishedFloorUp1"
                                    onChange={(e) => setFinishedFloorUp1(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Finished Floor Up 2 (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Finished Floor Up 2 (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={finishedFloorUp2}
                                    name="finishedFloorUp2"
                                    onChange={(e) => setFinishedFloorUp2(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Finished Floor Down (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Finished Floor Down (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={finishedFloorDown}
                                    name="finishedFloorDown"
                                    onChange={(e) => setFinishedFloorDown(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Unfinished Floor (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Unfinished Floor (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={unfinishedFloor}
                                    name="unfinishedFloor"
                                    onChange={(e) => setUnfinishedFloor(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Rooftop Deck (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Rooftop Deck (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={rooftopDeck}
                                    name="rooftopDeck"
                                    onChange={(e) => setRooftopDeck(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Balcony (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Balcony (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={balcony}
                                    name="balcony"
                                    onChange={(e) => setBalcony(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Patio (sqft) */}
                            <Col sm={"6"} md={"3"} lg={"3"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p>Patio (sqft)</p>
                                <div className="input-group-meta form-group">
                                  <input
                                    type="number"
                                    className={clsx("form-control", styles.textField)}
                                    value={patio}
                                    name="patio"
                                    onChange={(e) => setPatio(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Room(s) Information</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        {/* Floor */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Floor</p>
                            <div className="input-group-meta form-group">
                              <select
                                className={clsx("arrows", styles.selectTag)}
                                style={{
                                  padding: "7px 15px",
                                  border: "0.2px solid #dddddd",
                                }}
                                value={floor}
                                name={"floor"}
                                onChange={(e) => setFloor(e.target.value)}
                              >
                                <option value="">Select</option>
                                {sizeData?.floor?.map((li, i) => (
                                  <option key={li} value={li}>
                                    {li}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        {/* Room Type */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Room Type</p>
                            <div className="input-group-meta form-group">
                              <select
                                className={clsx("arrows", styles.selectTag)}
                                style={{
                                  padding: "7px 15px",
                                  border: "0.2px solid #dddddd",
                                }}
                                value={roomType}
                                name={"roomType"}
                                onChange={(e) => setRoomType(e.target.value)}
                              >
                                <option value="">Select</option>
                                {sizeData?.roomType?.sort()?.map((li, i) => (
                                  <option key={li} value={li}>
                                    {li}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        {/* Width */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Width</p>
                            <Row>
                              <Col sm={"6"}>
                                <input
                                  type="number"
                                  className={clsx("form-control", styles.textField)}
                                  placeholder="Feet"
                                  value={widthFeet}
                                  name="widthFeet"
                                  onChange={(e) => setWidthFeet(e.target.value)}
                                />
                              </Col>
                              <Col sm={"6"}>
                                <input
                                  type="number"
                                  className={clsx("form-control", styles.textField)}
                                  placeholder="Inches"
                                  value={widthInch}
                                  name="widthInch"
                                  onChange={(e) => setWidthInch(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        {/* Depth */}
                        <Col sm={"6"} md={"3"} lg={"3"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Depth</p>
                            <Row>
                              <Col sm={"6"}>
                                <input
                                  type="number"
                                  className={clsx("form-control", styles.textField)}
                                  placeholder="Feet"
                                  value={roomDepthFeet}
                                  name="roomDepthFeet"
                                  onChange={(e) => setRoomDepthFeet(e.target.value)}
                                />
                              </Col>
                              <Col sm={"6"}>
                                <input
                                  type="number"
                                  className={clsx("form-control", styles.textField)}
                                  placeholder="inches"
                                  value={roomDepthInch}
                                  name="roomDepthInch"
                                  onChange={(e) => setRoomDepthInch(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"3"} lg={"3"} className={"mt-2"}>
                          <Button
                            // style={{  height: "100%",  padding: "0.75rem",}}
                            color={"info"}
                            onClick={() => {
                              if (floor && roomType && widthFeet && roomDepthFeet) {
                                setRoomDetailsArray([
                                  ...roomDetailsArray,
                                  {
                                    floor,
                                    roomType,
                                    width: { feet: widthFeet, inches: widthInch || 0 },
                                    depth: { feet: roomDepthFeet, inches: roomDepthInch || 0 },
                                    editId: Math.random(),
                                  },
                                ]);
                                setFloor("");
                                setRoomType("");
                                setWidthFeet("");
                                setWidthInch("");
                                setRoomDepthFeet("");
                                setRoomDepthInch("");
                              } else {
                                toast.error("Please fill all room details");
                              }
                            }}
                          >
                            <span>Add</span>
                          </Button>
                        </Col>
                      </Row>
                      {roomDetailsArray?.length ? (
                        <table className={clsx("table table-bordered", styles.arrayTable)}>
                          <thead>
                            <tr>
                              <th>Floor</th>
                              <th>Room Type</th>
                              <th>Width</th>
                              <th>Depth</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {roomDetailsArray.map((row) => {
                              return (
                                <tr key={row?.editId || row?._id}>
                                  <td>
                                    {isEditRoom === row?.editId || isEditRoom === row?._id ? (
                                      <select
                                        className={clsx("arrows", styles.selectTag)}
                                        style={{
                                          padding: "7px 15px",
                                          border: "0.2px solid #dddddd",
                                        }}
                                        value={floorEdit}
                                        name={"floorEdit"}
                                        onChange={(e) => setFloorEdit(e.target.value)}
                                      >
                                        <option value="">Select</option>
                                        {sizeData?.floor?.map((li, i) => (
                                          <option key={i} value={li}>
                                            {li}
                                          </option>
                                        ))}
                                      </select>
                                    ) : (
                                      row?.floor
                                    )}
                                  </td>
                                  <td>
                                    {isEditRoom === row?.editId || isEditRoom === row?._id ? (
                                      <select
                                        className={clsx("arrows", styles.selectTag)}
                                        style={{
                                          padding: "7px 15px",
                                          border: "0.2px solid #dddddd",
                                        }}
                                        value={roomTypeEdit}
                                        name={"roomTypeEdit"}
                                        onChange={(e) => setRoomTypeEdit(e.target.value)}
                                      >
                                        <option value="">Select</option>
                                        {sizeData?.roomType?.map((li, i) => (
                                          <option key={i} value={li}>
                                            {li}
                                          </option>
                                        ))}
                                      </select>
                                    ) : (
                                      row?.roomType
                                    )}
                                  </td>
                                  <td>
                                    {isEditRoom === row?.editId || isEditRoom === row?._id ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "0.5rem",
                                          fontSize: "16px",
                                        }}
                                      >
                                        <span>Ft: </span>
                                        <span>
                                          <input
                                            type="number"
                                            className={clsx("form-control", styles.textField)}
                                            style={{
                                              maxWidth: "80px",
                                            }}
                                            placeholder="Feet"
                                            value={widthFeetEdit}
                                            name="widthFeetEdit"
                                            onChange={(e) => setWidthFeetEdit(e.target.value)}
                                          />
                                        </span>
                                        <span>In:</span>
                                        <span>
                                          <input
                                            type="number"
                                            className={clsx("form-control", styles.textField)}
                                            style={{
                                              maxWidth: "80px",
                                            }}
                                            placeholder="Inches"
                                            value={widthInchEdit}
                                            name="widthInchEdit"
                                            onChange={(e) => setWidthInchEdit(e.target.value)}
                                          />
                                        </span>
                                      </div>
                                    ) : (
                                      `${row?.width?.feet}ft ${row?.width?.inches ? `${row?.width?.inches}in` : ""}`
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {isEditRoom === row?.editId || isEditRoom === row?._id ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "0.5rem",
                                          fontSize: "16px",
                                        }}
                                      >
                                        <span>Ft:</span>
                                        <span>
                                          <input
                                            type="number"
                                            className={clsx("form-control", styles.textField)}
                                            style={{
                                              maxWidth: "80px",
                                            }}
                                            placeholder="Feet"
                                            value={roomDepthFeetEdit}
                                            name="roomDepthFeetEdit"
                                            onChange={(e) => setRoomDepthFeetEdit(e.target.value)}
                                          />
                                        </span>
                                        <span>In:</span>
                                        <span>
                                          <input
                                            type="number"
                                            className={clsx("form-control", styles.textField)}
                                            style={{
                                              maxWidth: "80px",
                                            }}
                                            placeholder="inched"
                                            value={roomDepthInchEdit}
                                            name="roomDepthInchEdit"
                                            onChange={(e) => setRoomDepthInchEdit(e.target.value)}
                                          />
                                        </span>
                                      </div>
                                    ) : (
                                      `${row?.depth?.feet}ft ${row?.depth?.inches ? `${row?.depth?.inches}in` : ""}`
                                    )}
                                  </td>
                                  <td className={styles.closeIconDiv}>
                                    {" "}
                                    {isEditRoom === row?.editId || isEditRoom === row?._id ? (
                                      <>
                                        <AiOutlineCheckCircle
                                          className={styles.arrayCloseIcon}
                                          style={{
                                            fontSize: "2.5rem",
                                            height: "38px",
                                          }}
                                          onClick={() => {
                                            setIsEditRoom(false);
                                            let a = roomDetailsArray?.map((li) => {
                                              return li?.editId === isEditRoom || li?._id === isEditRoom
                                                ? {
                                                    ...li,
                                                    floor: floorEdit,
                                                    roomType: roomTypeEdit,
                                                    width: {
                                                      feet: widthFeetEdit,
                                                      inches: widthInchEdit || 0,
                                                    },
                                                    depth: {
                                                      feet: roomDepthFeetEdit,
                                                      inches: roomDepthInchEdit || 0,
                                                    },
                                                  }
                                                : li;
                                            });
                                            setRoomDetailsArray(a);
                                          }}
                                        />
                                        <AiOutlineCloseCircle
                                          className={styles.arrayCloseIcon}
                                          style={{
                                            fontSize: "2.5rem",
                                            height: "38px",
                                          }}
                                          onClick={() => {
                                            setIsEditRoom(false);
                                            setFloorEdit("");
                                            setRoomTypeEdit("");
                                            setWidthFeetEdit("");
                                            setWidthInchEdit("");
                                            setRoomDepthFeetEdit("");
                                            setRoomDepthInchEdit("");
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <AiOutlineEdit
                                          className={styles.arrayCloseIcon}
                                          onClick={() => {
                                            setIsEditRoom(row?._id || row?.editId);
                                            setFloorEdit(row?.floor);
                                            setRoomTypeEdit(row?.roomType);
                                            setWidthFeetEdit(row?.width?.feet);
                                            setWidthInchEdit(row?.width?.inches);
                                            setRoomDepthFeetEdit(row?.depth?.feet);
                                            setRoomDepthInchEdit(row?.depth?.inches);
                                          }}
                                        />
                                        <AiOutlineCloseCircle
                                          className={styles.arrayCloseIcon}
                                          onClick={() => {
                                            let a = roomDetailsArray?.indexOf(row);
                                            if (a > -1) {
                                              roomDetailsArray?.splice(a, 1);
                                              setRoomDetailsArray([...roomDetailsArray]);
                                            }
                                          }}
                                        />
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : null}
                    </BlockContent>
                  </Block>

                  {isCondo || isTownHome ? null : (
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h4">Mortgage Helper(s)</BlockTitle>
                      </BlockHead>
                      <BlockContent>
                        <Row className="">
                          <Col sm={"6"} md={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>Suite</p>
                              <div className="input-group-meta form-group">
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: helperError?.error ? "2px solid #ce6048" : "1px solid #dddddd",
                                  }}
                                  value={helper}
                                  name={"helper"}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      setHelperError({ error: false });
                                    }
                                    setHelper(e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  {sizeData?.mortgageHeplers?.map((li, i) => (
                                    <option key={li} value={li}>
                                      {li}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col sm={"6"} md={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>Suite Type</p>
                              <div className="input-group-meta form-group">
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: suiteTypeError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                  }}
                                  value={suiteType}
                                  name={"suiteType"}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      setSuiteTypeError({ error: false });
                                    }
                                    setSuiteType(e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  {sizeData?.helpersSuiteType?.map((li, i) => (
                                    <option key={li} value={li}>
                                      {li}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col sm={"6"} md={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>Finish</p>
                              <div className="input-group-meta form-group">
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: finishError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                  }}
                                  value={finish}
                                  name={"finish"}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      setFinishError({ error: false });
                                    }
                                    setFinish(e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  {sizeData?.mortgageHelperFinish?.map((li, i) => (
                                    <option key={li} value={li}>
                                      {li}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col sm={"6"} md={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>Bedrooms</p>
                              <div className="input-group-meta form-group">
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: bedroomsError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                  }}
                                  value={bedrooms}
                                  name={"bedrooms"}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      setBedroomsError({ error: false });
                                    }
                                    setbedrooms(e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col sm={"6"} md={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p>Bathrooms</p>
                              <div className="input-group-meta form-group">
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: bedroomsError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                  }}
                                  value={bathrooms}
                                  name={"bathrooms"}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      setBathroomsError({ error: false });
                                    }
                                    setBathrooms(e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col sm={"6"} md={"3"}>
                            <div className={clsx(styles.askingPriceDiv)}>
                              <p className={styles.para1}>Features</p>
                              <div className="input-group-meta form-group">
                                <select
                                  className={clsx("arrows", styles.selectTag)}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  value={mortgageFeatures}
                                  name={"mortgageFeatures"}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    if (value) {
                                      setMortgageFeaturesArray([...mortgageFeaturesArray, value]);
                                    }
                                  }}
                                >
                                  <option value="">Select</option>
                                  {sizeData?.mortgageHelperFeatures?.sort()?.map((li, i) => (
                                    <>
                                      {!mortgageFeaturesArray?.includes(li) ? (
                                        <option key={li} value={li}>
                                          {li}
                                        </option>
                                      ) : null}
                                    </>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className={clsx(styles.arrayItemDiv, "")}>
                              {mortgageFeaturesArray?.map((item, index) => (
                                <p
                                  key={`${Math.random()}-${index}`}
                                  className={styles.para2}
                                  onClick={() => {
                                    let a = mortgageFeaturesArray?.indexOf(item);
                                    if (a > -1) {
                                      mortgageFeaturesArray?.splice(a, 1);
                                      setMortgageFeaturesArray([...mortgageFeaturesArray]);
                                    }
                                  }}
                                >
                                  <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                  <span>{item}</span>
                                </p>
                              ))}
                            </div>
                          </Col>
                          <Col sm={"12"} md={"12"}>
                            <Row>
                              <Col sm={"12"} md={"6"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p>Other Details</p>
                                  <div className="input-group-meta form-group">
                                    <textarea
                                      className={clsx("form-control", styles.textField)}
                                      rows={4}
                                      value={otherDetails}
                                      name="otherDetails"
                                      onChange={(e) => setOtherDetails(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <hr />
                          <Col sm={"6"} md={"3"} className={"mt-2"}>
                            <Button
                              color={"info"}
                              onClick={() => {
                                if (helper && suiteType && finish && bedrooms && bathrooms) {
                                  setMortgageHelperArray([
                                    ...mortgageHelperArray,
                                    {
                                      helper,
                                      suiteType,
                                      finish,
                                      bedrooms,
                                      bathrooms,
                                      otherDetails,
                                      features: mortgageFeaturesArray,
                                      editId: Math.random(),
                                    },
                                  ]);
                                  setHelper("");
                                  setSuiteType("");
                                  setFinish("");
                                  setbedrooms("");
                                  setBathrooms("");
                                  setOtherDetails("");
                                  setMortgageFeaturesArray([]);
                                } else {
                                  if (!helper) setHelperError({ error: true });
                                  if (!suiteType) setSuiteTypeError({ error: true });
                                  if (!finish) setFinishError({ error: true });
                                  if (!bedrooms) setBedroomsError({ error: true });
                                  if (!bathrooms) setBathroomsError({ error: true });
                                  toast.error("Please fill all mortgage helper's details");
                                }
                              }}
                            >
                              <span>Add</span>
                            </Button>
                          </Col>
                        </Row>
                        {mortgageHelperArray?.length ? (
                          <table className={clsx("table table-bordered", styles.arrayTable)}>
                            <thead>
                              <tr>
                                <th>Helper</th>
                                <th>Suite Type</th>
                                <th>Finish</th>
                                <th>Bedrooms</th>
                                <th>Bathrooms</th>
                                <th>Features</th>
                                <th>Other details</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {mortgageHelperArray.map((row) => {
                                return (
                                  <tr key={row?.editId || row?._id}>
                                    <td>
                                      {isEditMortgage === row?._id || isEditMortgage === row?.editId ? (
                                        <select
                                          className={clsx("arrows", styles.selectTag)}
                                          style={{
                                            padding: "7px 15px",
                                            border: helperError?.error ? "2px solid #ce6048" : "1px solid #dddddd",
                                          }}
                                          value={helperEdit}
                                          name={"helperEdit"}
                                          onChange={(e) => {
                                            setHelperEdit(e.target.value);
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {sizeData?.mortgageHeplers?.map((li, i) => (
                                            <option key={li} value={li}>
                                              {li}
                                            </option>
                                          ))}
                                        </select>
                                      ) : (
                                        row?.helper
                                      )}
                                    </td>
                                    <td>
                                      {isEditMortgage === row?._id || isEditMortgage === row?.editId ? (
                                        <select
                                          className={clsx("arrows", styles.selectTag)}
                                          style={{
                                            padding: "7px 15px",
                                            border: suiteTypeError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                          }}
                                          value={suiteTypeEdit}
                                          name={"suiteTypeEdit"}
                                          onChange={(e) => {
                                            setSuiteTypeEdit(e.target.value);
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {sizeData?.helpersSuiteType?.map((li, i) => (
                                            <option key={i} value={li}>
                                              {li}
                                            </option>
                                          ))}
                                        </select>
                                      ) : (
                                        row?.suiteType
                                      )}
                                    </td>
                                    <td>
                                      {isEditMortgage === row?._id || isEditMortgage === row?.editId ? (
                                        <select
                                          className={clsx("arrows", styles.selectTag)}
                                          style={{
                                            padding: "7px 15px",
                                            border: finishError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                          }}
                                          value={finishEdit}
                                          name={"finishEdit"}
                                          onChange={(e) => {
                                            setFinishEdit(e.target.value);
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {sizeData?.mortgageHelperFinish?.map((li, i) => (
                                            <option key={li} value={li}>
                                              {li}
                                            </option>
                                          ))}
                                        </select>
                                      ) : (
                                        row?.finish
                                      )}
                                    </td>
                                    <td>
                                      {isEditMortgage === row?._id || isEditMortgage === row?.editId ? (
                                        <select
                                          className={clsx("arrows", styles.selectTag)}
                                          style={{
                                            padding: "7px 15px",
                                            border: bedroomsError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                          }}
                                          value={bedroomsEdit}
                                          name={"bedroomsEdit"}
                                          onChange={(e) => {
                                            setbedroomsEdit(e.target.value);
                                          }}
                                        >
                                          <option value="">Select</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                        </select>
                                      ) : (
                                        row?.bedrooms
                                      )}
                                    </td>
                                    <td>
                                      {isEditMortgage === row?._id || isEditMortgage === row?.editId ? (
                                        <select
                                          className={clsx("arrows", styles.selectTag)}
                                          style={{
                                            padding: "7px 15px",
                                            border: bedroomsError?.error ? "2px solid #ce6048" : "0.2px solid #dddddd",
                                          }}
                                          value={bathroomsEdit}
                                          name={"bathroomsEdit"}
                                          onChange={(e) => {
                                            setBathroomsEdit(e.target.value);
                                          }}
                                        >
                                          <option value="">Select</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                        </select>
                                      ) : (
                                        row?.bathrooms
                                      )}
                                    </td>
                                    <td>
                                      {isEditMortgage === row?._id || isEditMortgage === row?.editId ? (
                                        <div>
                                          <select
                                            className={clsx("arrows", styles.selectTag)}
                                            style={{
                                              padding: "7px 15px",
                                              border: "0.2px solid #dddddd",
                                            }}
                                            value={mortgageFeaturesEdit}
                                            name={"mortgageFeaturesEdit"}
                                            onChange={(e) => {
                                              let value = e.target.value;
                                              setMortgageFeaturesArrayEdit([...mortgageFeaturesArrayEdit, value]);
                                            }}
                                          >
                                            <option value="">Select</option>
                                            {sizeData?.mortgageHelperFeatures?.map((li, i) => (
                                              <>
                                                {!mortgageFeaturesArrayEdit?.includes(li) ? (
                                                  <option key={li} value={li}>
                                                    {li}
                                                  </option>
                                                ) : null}
                                              </>
                                            ))}
                                          </select>

                                          <div
                                            className={clsx(styles.arrayItemDiv, "")}
                                            style={{
                                              flexDirection: "column",
                                              gap: "0",
                                              marginTop: "5px",
                                            }}
                                          >
                                            {mortgageFeaturesArrayEdit?.map((item, index) => (
                                              <p
                                                key={`${Math.random()}-${index}`}
                                                className={styles.para2}
                                                onClick={() => {
                                                  let a = mortgageFeaturesArrayEdit?.indexOf(item);
                                                  if (a > -1) {
                                                    mortgageFeaturesArrayEdit?.splice(a, 1);
                                                    setMortgageFeaturesArrayEdit([...mortgageFeaturesArrayEdit]);
                                                  }
                                                }}
                                              >
                                                <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                                <span>{item}</span>
                                              </p>
                                            ))}
                                          </div>
                                        </div>
                                      ) : row?.features ? (
                                        row?.features?.map(
                                          (li, i) => `${li}${i !== row?.features?.length - 1 ? ", " : ""}`
                                        )
                                      ) : null}
                                    </td>
                                    <td>
                                      {isEditMortgage === row?._id || isEditMortgage === row?.editId ? (
                                        <input
                                          className={clsx("form-control", styles.textField)}
                                          value={otherDetailsEdit}
                                          name="otherDetailsEdit"
                                          autoFocus
                                          onChange={(e) => setOtherDetailsEdit(e.target.value)}
                                        />
                                      ) : (
                                        row?.otherDetails
                                      )}
                                    </td>
                                    <td className={styles.closeIconDiv}>
                                      {isEditMortgage ? (
                                        <>
                                          <AiOutlineCheckCircle
                                            className={styles.arrayCloseIcon}
                                            onClick={() => {
                                              let a = mortgageHelperArray?.map((li, i) => {
                                                return li?._id === isEditMortgage || li?.editId === isEditMortgage
                                                  ? {
                                                      ...li,
                                                      helper: helperEdit,
                                                      suiteType: suiteTypeEdit,
                                                      finish: finishEdit,
                                                      bedrooms: bedroomsEdit,
                                                      features: mortgageFeaturesArrayEdit,
                                                      otherDetails: otherDetailsEdit,
                                                    }
                                                  : li;
                                              });
                                              setMortgageHelperArray(a);
                                              setHelperEdit("");
                                              setSuiteTypeEdit("");
                                              setFinishEdit("");
                                              setbedroomsEdit("");
                                              setMortgageFeaturesArrayEdit([]);
                                              setOtherDetailsEdit("");
                                              setIsEditMortgage(false);
                                            }}
                                          />
                                          <AiOutlineCloseCircle
                                            className={styles.arrayCloseIcon}
                                            onClick={() => {
                                              setHelperEdit("");
                                              setSuiteTypeEdit("");
                                              setFinishEdit("");
                                              setbedroomsEdit("");
                                              setMortgageFeaturesArrayEdit([]);
                                              setOtherDetailsEdit("");
                                              setIsEditMortgage(false);
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <AiOutlineEdit
                                            className={styles.arrayCloseIcon}
                                            onClick={() => {
                                              setHelperEdit(row?.helper);
                                              setSuiteTypeEdit(row?.suiteType);
                                              setFinishEdit(row?.finish);
                                              setbedroomsEdit(row?.bedrooms);
                                              setMortgageFeaturesArrayEdit(row?.features);
                                              setOtherDetailsEdit(row?.otherDetails);
                                              setIsEditMortgage(row?.editId || row?._id);
                                            }}
                                          />
                                          <AiOutlineCloseCircle
                                            className={styles.arrayCloseIcon}
                                            onClick={() => {
                                              let a = mortgageHelperArray?.indexOf(row);
                                              if (a > -1) {
                                                mortgageHelperArray?.splice(a, 1);
                                                setMortgageHelperArray([...mortgageHelperArray]);
                                              }
                                            }}
                                          />
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : null}
                      </BlockContent>
                    </Block>
                  )}
                </div>
              </div>
            </div>
          </Card>
          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Back"} color={"secondary"} outline onClick={handleBackFun} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Assignment4;
