import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import styles from "./common.module.scss";

const HomeTypes = ({ homeType, setHomeTypeArray, homeTypeArray, homeTypeData, setMandHomeType, MandHomeTypes }) => {
  return (
    <>
      <FormGroup>
        <Label htmlFor="homeType" className="form-label">
          Home Type(s)*
        </Label>
        <div className="form-control-wrap">
          <div className="form-control-select">
            <Input
              className={`form-control ${MandHomeTypes ? "error" : ""} `}
              type="select"
              id="homeType"
              // ref={homeTypeRef}
              value={homeType}
              name="homeType"
              onChange={(e) => {
                const value = e.target.value;
                setHomeTypeArray([...homeTypeArray, value]);
                setMandHomeType(false);
                // setBuildingType("");
              }}
            >
              <option value={""}>Select</option>
              {homeTypeData?.map((item) => {
                return !homeTypeArray?.includes(item?._id) ? (
                  <option key={`${item?._id}`} value={item?._id}>
                    {item?.name}
                  </option>
                ) : null;
              })}
            </Input>
          </div>
        </div>
        <div className={styles.arrayItemDiv}>
          {homeTypeArray?.length
            ? homeTypeArray?.map((item, index) => {
                return (
                  <p
                    key={`${Math.random()}-${index}`}
                    className={styles.para2}
                    onClick={() => {
                      let a = homeTypeArray?.indexOf(item);
                      if (a > -1) {
                        homeTypeArray?.splice(a, 1);
                        setHomeTypeArray([...homeTypeArray]);
                      }
                    }}
                  >
                    <em className="icon ni ni-cross-circle"></em>
                    <span>{homeTypeData?.filter((li) => li?._id === item)[0]?.name}</span>
                  </p>
                );
              })
            : null}
        </div>
      </FormGroup>
    </>
  );
};

export default HomeTypes;
