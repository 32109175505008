import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { fetchProfileData } from "../redux/Apislices/authSlice";

const AuthRoute = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);

  return auth?.profile && ["idle", "loading", "success"]?.includes(auth?.status) && auth?.success ? (
    <Navigate to="/" />
  ) : auth?.error ? (
    <Outlet />
  ) : null;
};

export default AuthRoute;
