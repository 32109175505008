import React, { useState } from "react";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { usePagination } from "../../utils/customHooks/usePaginate";
import { PreviewCard } from "../Component";
import Icon from "../icon/Icon";

const DotPagination = (props) => {
  const { itemPerPage, totalItems, paginate, currentPage, siblingCount = 1, screenWidth = false } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount: totalItems,
    siblingCount,
    pageSize: itemPerPage,
  });

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  let firstPage = paginationRange[0];

  return (
    <>
      <PreviewCard bodyClass={"rounded-none"}>
        <Pagination aria-label="Page navigation example">
          {/* prev */}
          {currentPage !== firstPage ? (
            <PaginationItem>
              <PaginationLink
                className="page-link-prev"
                href="#prev"
                onClick={(ev) => {
                  ev.preventDefault();
                  prevPage();
                }}
              >
                <Icon name="chevrons-left" />
                <span>Prev</span>
              </PaginationLink>
            </PaginationItem>
          ) : null}

          {screenWidth > 400
            ? paginationRange.map((pageNumber, index) => {
                if (pageNumber === "DOTS") {
                  return (
                    <div key={Math.random() + pageNumber + index}>
                      <PaginationItem>
                        <PaginationLink tag="span">
                          <Icon name="more-h" />
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                  );
                }

                return (
                  <div key={Math.random() + pageNumber + index}>
                    <PaginationItem>
                      <PaginationLink
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          paginate(pageNumber);
                        }}
                        style={{
                          background: pageNumber === currentPage ? "#f5f5f5" : "",
                          color: pageNumber === currentPage ? "dodgerblue" : "",
                          borderRadius: "0px",
                        }}
                      >
                        {pageNumber}
                      </PaginationLink>
                    </PaginationItem>
                  </div>
                );
              })
            : null}

          {lastPage !== currentPage ? (
            <PaginationItem>
              <PaginationLink
                className="page-link-next"
                href="#next"
                onClick={(ev) => {
                  ev.preventDefault();
                  nextPage();
                }}
              >
                <span>Next</span>
                <Icon name="chevrons-right" />
              </PaginationLink>
            </PaginationItem>
          ) : null}
        </Pagination>
      </PreviewCard>
    </>
  );
};

export default DotPagination;
