import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod } from "../../utils/apiServices";

export const getAgents = createAsyncThunk("agents/agentsList", async (thunkApi) => {
  const response = await getMethod(`/api/agents-list`);
  return response;
});

export const searchAgents = createAsyncThunk("agents/agentsSearchList", async (query, thunkApi) => {
  const response = await getMethod(`/api/agents-search`, query);
  return response;
});

export const getMultipleAgents = createAsyncThunk("agents/multipleAgentsList", async (query, thunkApi) => {
  const response = await getMethod(`/api/multiple-agents`, query);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  agents: null,
  agentSelectOptions: null,
  agentSearch: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
  multipleAgents: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
};

const agentsSlice = createSlice({
  name: "agents",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAgents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAgents.fulfilled, (state, action) => {
      state.loading = false;
      state.agents = action.payload.data;
      state.agentSelectOptions = action.payload.data?.map((li) => {
        return { label: `${li?.firstName} ${li?.lastName}`, value: li?._id };
      });
    });
    builder.addCase(getAgents.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(searchAgents.pending, (state, action) => {
      state.agentSearch.loading = true;
      state.agentSearch.success = false;
      state.agentSearch.data = null;
      state.agentSearch.error = false;
    });
    builder.addCase(searchAgents.fulfilled, (state, action) => {
      state.agentSearch.loading = false;
      state.agentSearch.success = true;
      state.agentSearch.data = action.payload.data;
    });
    builder.addCase(searchAgents.rejected, (state, action) => {
      state.agentSearch.loading = false;
      state.agentSearch.success = false;
      state.agentSearch.data = null;
      state.agentSearch.error = action.error.message;
    });

    builder.addCase(getMultipleAgents.pending, (state) => {
      state.multipleAgents.loading = true;
      state.multipleAgents.success = false;
      state.multipleAgents.error = false;
    });
    builder.addCase(getMultipleAgents.fulfilled, (state, action) => {
      state.multipleAgents.loading = false;
      state.multipleAgents.success = true;
      state.multipleAgents.data = action.payload.data;
    });
    builder.addCase(getMultipleAgents.rejected, (state, action) => {
      state.multipleAgents.loading = false;
      state.multipleAgents.success = false;
      state.multipleAgents.error = action.error.message;
    });
  },
});

export default agentsSlice.reducer;
