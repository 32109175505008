import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getCompany = createAsyncThunk("company/fetchCompanies", async (query) => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/company`, query);
  return response;
});

export const getBuilder = createAsyncThunk("company/fetchCompany/builder", async () => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/company/builder`);
  return response;
});
export const searchBuilder = createAsyncThunk("company/builderSearch", async (query) => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/company/builder-search`, query);
  return response;
});

export const getMarketingCompany = createAsyncThunk("company/fetchCompany/marketing", async (query) => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/company/marketing`, query);
  return response;
});
export const searchMarketing = createAsyncThunk("company/marketingSearch", async (query) => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/company/marketing-search`, query);
  return response;
});

export const getBrokerage = createAsyncThunk("company/fetchCompany/brokerage", async (query) => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/company/brokerage`, query);
  return response;
});
export const searchBrokerage = createAsyncThunk("company/brokerageSearch", async (query) => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/company/brokerage-search`, query);
  return response;
});

export const checkCompanyEmail = createAsyncThunk("company/checkEmail", async (query) => {
  const response = await getMethod("api/company/check", {
    email: query,
  });
  return response;
});
export const checkCompanyDomain = createAsyncThunk("company/checkDomain", async (query) => {
  const response = await getMethod("api/company/domain-check", {
    domain: query,
  });
  return response;
});

export const getCompanyList = createAsyncThunk("company/fetchCompaniesList", async (query) => {
  try {
    setAuthorizationToken(true);
    const response = await getMethod(`/api/admin/company`, query);
    return response;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  company: null,
  companies: {
    loading: false,
    success: false,
    data: null,
    total: null,
    error: false,
  },
  companySelectOptions: null,
  builder: null,
  builderSearch: null,
  builderSelectOptions: null,
  marketing: null,
  marketingSearch: null,
  marketingSelectOptions: null,
  brokerage: null,
  brokerageSearch: null,
  brokerageSelectOptions: null,
  loadingEmail: false,
  email: false,
  checkLoading: false,
  checkDomain: null,
  checkError: false,
};

const companySlice = createSlice({
  name: "company",

  initialState,

  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    addBuilder: (state, action) => {
      state.loading = false;
      state.success = true;
      state.builder = action.payload;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    resetCheck: (state) => {
      state.loadingEmail = false;
      state.email = false;
      state.error = false;
      state.checkLoading = false;
      state.checkDomain = false;
      state.checkError = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.company = action.payload.data;
      state.companySelectOptions = action.payload.data?.map((li) => {
        return { label: li?.name, value: li?._id };
      });
    });
    builder.addCase(getCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(getBuilder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBuilder.fulfilled, (state, action) => {
      state.loading = false;
      state.builder = action.payload.data;
      state.builderSelectOptions = action.payload.data?.map((li) => {
        return {
          label: `<p style="color:#5d5d5d;font-size:14px;">${li?.name} 
          ${
            li?.address?.firstLine
              ? `<br/> <span style="color:#aeaeae;font-size:12px;">(${li?.address?.firstLine})</span>`
              : ""
          }
          </p>`,
          value: li?._id,
        };
      });
    });
    builder.addCase(getBuilder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(searchBuilder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchBuilder.fulfilled, (state, action) => {
      state.loading = false;
      state.builderSearch = action.payload.data;
    });
    builder.addCase(searchBuilder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(getMarketingCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMarketingCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.marketing = action.payload.data;
      state.marketingSelectOptions = action.payload.data?.map((li) => {
        return {
          label: `<p style="color:#5d5d5d;font-size:14px;">${li?.name} ${
            li?.address?.firstLine
              ? `<br/> <span style="color:#aeaeae;font-size:12px;">(${li?.address?.firstLine})</span>`
              : ""
          }</p>`,
          value: li?._id,
        };
      });
    });
    builder.addCase(getMarketingCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(searchMarketing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchMarketing.fulfilled, (state, action) => {
      state.loading = false;
      state.marketingSearch = action.payload.data;
    });
    builder.addCase(searchMarketing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(getBrokerage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrokerage.fulfilled, (state, action) => {
      state.loading = false;
      state.brokerage = action.payload.data;
    });
    builder.addCase(getBrokerage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(searchBrokerage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchBrokerage.fulfilled, (state, action) => {
      state.loading = false;
      state.brokerageSearch = action.payload.data;
    });
    builder.addCase(searchBrokerage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(checkCompanyEmail.pending, (state) => {
      state.loadingEmail = true;
    });
    builder.addCase(checkCompanyEmail.fulfilled, (state, action) => {
      state.loadingEmail = false;
      state.email = action?.payload?.data?.email;
    });
    builder.addCase(checkCompanyEmail.rejected, (state, action) => {
      state.loadingEmail = false;
      state.error = true;
    });
    builder.addCase(checkCompanyDomain.pending, (state) => {
      state.checkLoading = true;
    });
    builder.addCase(checkCompanyDomain.fulfilled, (state, action) => {
      state.checkLoading = false;
      state.checkDomain = action?.payload?.username;
    });
    builder.addCase(checkCompanyDomain.rejected, (state, action) => {
      state.checkLoading = false;
      state.checkError = true;
    });

    builder.addCase(getCompanyList.pending, (state) => {
      state.companies.loading = true;
      state.companies.success = false;
      state.companies.data = null;
      state.companies.total = null;
      state.companies.error = false;
    });
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.companies.loading = false;
      state.companies.success = true;
      state.companies.data = action.payload.data;
      state.companies.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
      state.companies.error = false;
    });
    builder.addCase(getCompanyList.rejected, (state, action) => {
      state.companies.loading = false;
      state.companies.success = false;
      state.companies.data = null;
      state.companies.total = null;
      state.companies.error = action.error.message;
    });
  },
});

export const { resetCheck } = companySlice.actions;

export default companySlice.reducer;

export const addCompany = (data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await postMethod("api/company", data).then((response) => {
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  });
};

export const editCompany = (id, data, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await putMethod("api/admin/company/" + id, data).then((response) => {
    if (response?.success) successCB(response);
    else errorCB(response);
  });
};

export const archiveCompany = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await deleteMethod(`/api/admin/company/archive/${id}`)
    .then((response) => {
      successCB(response);
    })
    .catch((error) => {
      errorCB(error);
    });
};

export const restoreCompany = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await deleteMethod(`/api/admin/company/restore/${id}`)
    .then((response) => {
      successCB(response);
    })
    .catch((error) => {
      errorCB(error);
    });
};

export const deleteCompany = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await deleteMethod(`/api/admin/company/delete/${id}`)
    .then((response) => {
      successCB(response);
    })
    .catch((error) => {
      errorCB(error);
    });
};

export const archiveMultipleCompany = (ids, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await postMethod(`/api/admin/company/archive-multiple`, ids)
    .then((response) => {
      successCB(response);
    })
    .catch((error) => {
      errorCB(error);
    });
};

export const restoreMultipleCompany = (ids, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await postMethod(`/api/admin/company/restore-multiple`, ids)
    .then((response) => {
      successCB(response);
    })
    .catch((error) => {
      errorCB(error);
    });
};

export const deleteMultipleCompany = (ids, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  await postMethod(`/api/admin/company/delete-multiple`, ids)
    .then((response) => {
      successCB(response);
    })
    .catch((error) => {
      errorCB(error);
    });
};
