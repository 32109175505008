import React from "react";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button } from "../Component";

const ProjectHeader = ({ title, addToDraft, btnText }) => {
  return (
    <>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              {title ? title : "Add Community"}
            </BlockTitle>
          </BlockHeadContent>

          <BlockHeadContent>
            {btnText ? (
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  addToDraft();
                }}
              >
                <span>{btnText}</span>
              </Button>
            ) : null}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    </>
  );
};

export default ProjectHeader;
