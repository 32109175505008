import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getListingList = createAsyncThunk("listing/list", async (query) => {
  try {
    const response = await getMethod(`/api/admin/listing`, query);
    console.log("response: ", response);
    return response;
  } catch (error) {
    console.log("error: ", error);
    throw error;
  }
});

export const getDevelopmentForListing = createAsyncThunk("listing/getDevelopmentForListing", async (params) => {
  setAuthorizationToken(true);
  const response = await getMethod(`/api/agent/development/${params}`);
  return response;
});

export const getCommunityForListing = createAsyncThunk("listing/getCommunityForListing", async (params) => {
  setAuthorizationToken(true);
  const response = await getMethod(`/api/development`, params);
  return response;
});

export const getListingById = createAsyncThunk("listings/fetchListingBySlug", async (slug) => {
  setAuthorizationToken(true);
  const response = await getMethod(`api/agents/listings/${slug}`);
  return response;
});

export const checkMls = createAsyncThunk("listings/checkMls", async (query) => {
  setAuthorizationToken(true);
  const response = await getMethod(`api/mls-check`, query);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  listings: {
    loading: false,
    success: false,
    data: null,
    total: null,
    error: false,
  },
  listingById: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  addListing: {},
  mls: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  development: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
  community: {
    loading: false,
    success: false,
    data: null,
    error: false,
  },
};

export const listingSlice = createSlice({
  name: "listing",

  initialState,

  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.listings = action.payload;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    handleResetListing: (state) => {
      state.addListing = initialState.addListing;
      state.listings = initialState.listings;
      state.listingById = initialState.listingById;
      state.mls = initialState.mls;
      state.development = initialState.development;
      state.community = initialState.community;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getListingList.pending, (state) => {
      state.listings.loading = true;
      state.listings.success = false;
      state.listings.data = null;
      state.listings.total = null;
      state.listings.error = false;
    });
    builder.addCase(getListingList.fulfilled, (state, action) => {
      state.listings.loading = false;
      state.listings.success = true;
      state.listings.data = action.payload.data;
      state.listings.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
      state.listings.error = false;
    });
    builder.addCase(getListingList.rejected, (state, action) => {
      state.listings.loading = false;
      state.listings.success = false;
      state.listings.data = null;
      state.listings.total = null;
      state.listings.error = action.error.message;
    });
    builder.addCase(getListingById.pending, (state) => {
      state.listingById.loading = true;
      state.listingById.success = false;
      state.listingById.data = null;
      state.listingById.error = false;
    });
    builder.addCase(getListingById.fulfilled, (state, action) => {
      state.listingById.loading = false;
      state.listingById.success = true;
      state.listingById.data = action.payload.data;
      state.listingById.error = false;
    });
    builder.addCase(getListingById.rejected, (state, action) => {
      state.listingById.loading = false;
      state.listingById.success = false;
      state.listingById.data = null;
      state.listingById.error = action.error.message;
    });

    builder.addCase(checkMls.pending, (state) => {
      state.mls.loading = true;
      state.mls.success = false;
      state.mls.data = null;
      state.mls.error = false;
    });
    builder.addCase(checkMls.fulfilled, (state, action) => {
      state.mls.loading = false;
      state.mls.success = true;
      state.mls.data = action.payload.message;
      state.mls.error = false;
    });
    builder.addCase(checkMls.rejected, (state, action) => {
      state.mls.loading = false;
      state.mls.success = false;
      state.mls.data = null;
      state.mls.error = action.error?.message;
    });

    builder.addCase(getDevelopmentForListing.pending, (state) => {
      state.development.loading = true;
      state.development.success = false;
      state.development.data = null;
      state.development.error = false;
    });
    builder.addCase(getDevelopmentForListing.fulfilled, (state, action) => {
      state.development.loading = false;
      state.development.success = true;
      state.development.data = action.payload.data;
      state.development.error = false;
    });
    builder.addCase(getDevelopmentForListing.rejected, (state, action) => {
      state.development.loading = false;
      state.development.success = false;
      state.development.data = null;
      state.development.error = action.error?.message;
    });

    builder.addCase(getCommunityForListing.pending, (state) => {
      state.community.loading = true;
      state.community.success = false;
      state.community.data = null;
      state.community.error = false;
    });
    builder.addCase(getCommunityForListing.fulfilled, (state, action) => {
      state.community.loading = false;
      state.community.success = true;
      state.community.data = action.payload.data;
      state.community.error = false;
    });
    builder.addCase(getCommunityForListing.rejected, (state, action) => {
      state.community.loading = false;
      state.community.success = false;
      state.community.data = null;
      state.community.error = action.error?.message;
    });
  },
});

export const { isLoading, isError, isSuccess, handleResetListing } = listingSlice.actions;

export default listingSlice.reducer;

export const addListing = (file, successCB, errorCB) => async (dispatch) => {
  dispatch(isLoading());
  setAuthorizationToken(true);
  try {
    const response = await postMethod(`/api/agent-listings`, file);
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const editListing = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    // dispatch(isLoading());
    const response = await putMethod(`/api/admin/listing/${id}`, data);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    // dispatch(isError());
  }
};

export const duplicateListing = (id, successCB, errorCB) => async (dispatch) => {
  setAuthorizationToken(true);
  try {
    const response = await postMethod(`/api/listings-duplicate/${id}`);
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const deleteListing = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    // dispatch(isLoading());
    const response = await deleteMethod(`/api/admin/listing/delete/${id}`);
    if (response?.success) {
      SuccessCallback(response);
      // dispatch(isSuccess());
    } else {
      ErrorCallback(response);
      // dispatch(isError());
    }
  } catch (error) {
    // dispatch(isError());
  }
};

export const deleteMultipleListing = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    // dispatch(isLoading());
    const response = await postMethod(`/api/admin/listing/delete-multiple`, data);
    if (response?.success) {
      SuccessCallback(response);
      // dispatch(isSuccess());
    } else {
      ErrorCallback(response);
      // dispatch(isError());
    }
  } catch (error) {
    // dispatch(isError());
  }
};

export const restoreListing = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    // dispatch(isLoading());
    const response = await putMethod(`/api/admin/listing/restore/${id}`);
    if (response?.success) {
      SuccessCallback(response);
      // dispatch(isSuccess());
    } else {
      ErrorCallback(response);
      // dispatch(isError());
    }
  } catch (error) {
    // dispatch(isError());
  }
};

export const restoreMultipleListing = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    // dispatch(isLoading());
    const response = await postMethod(`/api/admin/listing/restore-multiple`, data);
    if (response?.success) {
      SuccessCallback(response);
      // dispatch(isSuccess());
    } else {
      ErrorCallback(response);
      // dispatch(isError());
    }
  } catch (error) {
    // dispatch(isError());
  }
};

export const permanentDeleteListing = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    // dispatch(isLoading());
    const response = await deleteMethod(`/api/admin/listing/delete-permanently/${id}`);
    if (response?.success) {
      SuccessCallback(response);
      // dispatch(isSuccess());
    } else {
      ErrorCallback(response);
      // dispatch(isError());
    }
  } catch (error) {
    // dispatch(isError());
  }
};

export const permanentDeleteMultipleListing = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    // dispatch(isLoading());
    const response = await postMethod(`/api/admin/listing/delete-multiple-permanently`, data);
    if (response?.success) {
      SuccessCallback(response);
      // dispatch(isSuccess());
    } else {
      ErrorCallback(response);
      // dispatch(isError());
    }
  } catch (error) {
    // dispatch(isError());
  }
};
