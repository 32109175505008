import React from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Error404Classic from "./pages/error/404-classic";
import PrivateRoute from "./route/PrivateRoute";
// import { RedirectAs404 } from "./utils/Utils";
// import Error404Modern from "./pages/error/404-modern";
// import Error504Classic from "./pages/error/504-classic";
// import Error504Modern from "./pages/error/504-modern";

// import Faq from "./pages/others/Faq";
// import Terms from "./pages/others/Terms";
// import InvoicePrint from "./pages/pre-built/invoice/InvoicePrint";

import "react-phone-input-2/lib/style.css";
import "./assets/scss/global.scss";
import AddProject from "./components/project/AddProject";
import Agents from "./pages/Agents/Agents";
import AddAssignment from "./pages/Assignment/AddAssignment";
import Assignment from "./pages/Assignment/Assignment";
import AddBlog from "./pages/Blogs/AddBlog";
import BlogsPage from "./pages/Blogs/Blogs";
import Category from "./pages/Blogs/Category";
import Tags from "./pages/Blogs/Tags";
import Brokerages from "./pages/Brokerages/Brokerages";
import Builders from "./pages/Builders/Builders";
import BuildingCompanies from "./pages/BuildingCompany/BuildingCompanies";
import AddCommunity from "./pages/Community/AddCommunity";
import Community from "./pages/Community/Community";
import AddDevelopment from "./pages/Development/AddDevelopment";
import Development from "./pages/Development/Development";
import Homepage from "./pages/Homepage";
import Invoice from "./pages/Invoices/Invoice";
import Invoices from "./pages/Invoices/Invoices";
import MarketingAgents from "./pages/MarketingAgents/MarketingAgents";
import MarketingCompany from "./pages/MarketingCompany/MarketingCompany";
import NewHomes from "./pages/NewHomes/NewHomes";
import Project from "./pages/Project/Project";
import Settings from "./pages/Settings/Settings";
import Team from "./pages/Users/Team";
import Users from "./pages/Users/Users";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Success from "./pages/auth/Success";
import City from "./pages/location/City";
import Country from "./pages/location/Country";
import Neighbourood from "./pages/location/Neighbourood";
import Province from "./pages/location/Province";
import UserProfileActivityPage from "./pages/pre-built/user-manage/UserProfileActivity";
import UserProfileLayout from "./pages/pre-built/user-manage/UserProfileLayout";
import UserProfileNotificationPage from "./pages/pre-built/user-manage/UserProfileNotification";
import UserProfileRegularPage from "./pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSettingPage from "./pages/pre-built/user-manage/UserProfileSetting";
import VipAgents from "./pages/vip-agent/VipAgents";
import AuthRoute from "./route/AuthRoute";
import NoAdminRoute, { SettingsLayout } from "./route/NoAdminRoute";
import VipSubscription from "./pages/Settings/VipSubscription/VipSubscription";
import Amenities from "./pages/Settings/Amenities/Amenities";
import UnitFeature from "./pages/Settings/UnitFeature/UnitFeature";
import UnitPreferencePage from "./pages/Settings/UnitPreference/UnitPreference";
import AssociateCost from "./pages/AssociateCost/AssociateCost";
import Feedbacks from "./pages/Feedbacks/Feedbacks";
import Testimony from "./pages/Testimony/Testimony";
import AssociatePage from "./pages/Associate/Associate";

const App = () => {
  return (
    <>
      <Routes>
        {/* Auth Pages */}
        <Route path="/auth-success" element={<Success />} />
        <Route element={<AuthRoute />}>
          <Route path="/auth-reset" element={<ForgotPassword />} />
          <Route path="/auth-register" element={<Register />} />
          <Route path="/auth-login" element={<Login />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/development" element={<Development />} />
          <Route path="/development/:type" element={<AddDevelopment />} />
          <Route path="/development/:type/:id" element={<AddDevelopment />} />

          <Route path="/assignment" element={<Assignment />} />
          <Route path="/assignment/:type" element={<AddAssignment />} />
          <Route path="/assignment/:type/:id" element={<AddAssignment />} />

          <Route path="/new-homes" element={<NewHomes />} />
          <Route path="/new-homes/:type" element={<AddAssignment />} />
          <Route path="/new-homes/:type/:id" element={<AddAssignment />} />

          <Route path="/community" element={<Project />} />
          <Route path="/community/:type" element={<AddProject />} />
          <Route path="/community/:type/:id" element={<AddProject />} />

          <Route path="/master-community" element={<Community />} />
          <Route path="/master-community/:type" element={<AddCommunity />} />
          <Route path="/master-community/:type/:id" element={<AddCommunity />} />

          <Route path="/users" element={<Users />} />
          <Route path="/builders" element={<Builders />} />
          <Route path="/marketing-agents" element={<MarketingAgents />} />
          <Route path="/agents" element={<Agents />} />
          <Route path="/vip-agents" element={<VipAgents />} />
          <Route path="/associates" element={<AssociatePage />} />

          <Route path="/building-company" element={<BuildingCompanies />} />
          <Route path="/marketing-company" element={<MarketingCompany />} />
          <Route path="/brokerages" element={<Brokerages />} />

          <Route path="/location/country" element={<Country />} />
          <Route path="/location/province" element={<Province />} />
          <Route path="/location/city" element={<City />} />
          <Route path="/location/neighbourhood" element={<Neighbourood />} />

          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/category" element={<Category />} />
          <Route path="/blogs/tags" element={<Tags />} />
          <Route path="/blog/:type" element={<AddBlog />} />
          <Route path="/blog/:type/:id" element={<AddBlog />} />

          <Route path="/invoices" element={<Invoices />} />
          <Route path="/invoice/:id" element={<Invoice />} />

          <Route path="/feedbacks" element={<Feedbacks />} />

          <Route path="/testimony" element={<Testimony />} />

          <Route path="/settings" element={<SettingsLayout />}>
            <Route index element={<Settings />} />
            <Route path="amenities" element={<Amenities />} />
            <Route path="unit-features" element={<UnitFeature />} />
            <Route path="unit-preferences" element={<UnitPreferencePage />} />
            <Route path="admin-team" element={<Team />} />
            <Route path="vip-agent-subscription" element={<VipSubscription />} />
            <Route path="associate-subscription" element={<AssociateCost />} />
          </Route>

          <Route path="/profile" element={<UserProfileLayout />}>
            <Route index element={<UserProfileRegularPage />} />
            <Route path="information" element={<UserProfileRegularPage />} />
            <Route path="notification" element={<UserProfileNotificationPage />} />
            <Route path="activity" element={<UserProfileActivityPage />} />
            <Route path="settings" element={<UserProfileSettingPage />} />
          </Route>

          <Route path="*" element={<Error404Classic />} />
        </Route>
      </Routes>

      <ToastContainer
        position="bottom-center"
        toastStyle={{ padding: "2px 5px" }}
        bodyStyle={{
          fontWeight: "600",
          fontSize: "16px",
        }}
        closeButton={false}
        autoClose={3000}
        pauseOnHover={false}
        closeOnClick
        pauseOnFocusLoss={false}
        hideProgressBar={true}
      />
    </>
  );
};
export default App;

// {/* Error Pages */}
// <Route path="/errors/404-classic" element={<Error404Classic />} />
// <Route path="/errors/504-modern" element={<Error504Modern />} />
// <Route path="/errors/404-modern" element={<Error404Modern />} />
// <Route path="/errors/504-classic" element={<Error504Classic />} />
