import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styles from "./assignment.module.scss";

const SoldModal = (props) => {
  const { handleSubmit, price, setPrice, priceError, setPriceError, date, setDate, dateError, setDateError } = props;

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className={styles.soldModal}>
      <ModalHeader closeButton>Change Status</ModalHeader>
      <ModalBody>
        <div className={styles.modalForm}>
          <div>
            <label htmlFor="price">Sold Price*</label>
            {/* <OutlinedInput
              className={clsx(`form-control ${priceError ? "inputError" : ""}`, styles.dollarInput)}
              id="price"
              startAdornment={
                <InputAdornment position="start" className={clsx(styles.doller)}>
                  $
                </InputAdornment>
              }
              value={price}
              name="askingPrice"
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setPrice(e.target.value);
                  setPriceError(false);
                }
              }}
            /> */}
          </div>
          <div>
            <label htmlFor="date">Sold Date*</label>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={date}
                fullWidth
                onChange={(newValue) => {
                  setDate(newValue?.$d);
                  setDateError(false);
                }}
                maxDate={new Date()}
                PopperProps={{
                  style: {
                    fontSize: 16,
                  },
                }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    padding: "10px 8px",
                  },
                }}
                InputLabelProps={{
                  style: {
                    borderRadius: "0",
                    fontSize: 14,
                    color: "#8B8B8B",
                  },
                }}
                clearable={true}
                renderInput={(params) => (
                  <TextField
                    id="date"
                    style={{
                      border: dateError ? "2px solid #ce6048" : "",
                      borderRadius: "5px",
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider> */}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <div>
          <button onClick={handleSubmit}>Change Status</button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SoldModal;
