import React from "react";
import Select from "react-select";

const RSelect = ({ ...props }) => {
  return (
    <div className="">
      <Select className={`react-select-container ${props.className ? props.className : ""}`} {...props} />
    </div>
  );
};

export default RSelect;
