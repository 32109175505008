import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getUnitPreference = createAsyncThunk("unitPreference/fetchUnitPreference", async () => {
  setAuthorizationToken(true);
  const response = await getMethod(`/api/unitPreference`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  unitPreference: null,
};

const unitPreferenceSlice = createSlice({
  name: "unitPreference",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUnitPreference.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnitPreference.fulfilled, (state, action) => {
        state.loading = false;
        state.unitPreference = action.payload.data;
      })
      .addCase(getUnitPreference.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default unitPreferenceSlice.reducer;

export const addUnitPreference = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/unitPreference`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};

export const updateUnitPreference = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/unitPreference/${id}`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};

export const deleteUnitPreference = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/unitPreference/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
