import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getAllTestimony = createAsyncThunk("testimonies/testimonyList", async (data) => {
  const response = await getMethod(`/api/admin/testimony`, data);
  return response;
});

export const getSingleTestimony = createAsyncThunk("testimonies/testimony", async (id) => {
  const response = await getMethod(`/api/admin/testimony/${id}`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  testimonies: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
  singleTestimony: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
};

const testimonySlice = createSlice({
  name: "testimony",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllTestimony.pending, (state) => {
        state.testimonies.loading = true;
        state.testimonies.success = false;
        state.testimonies.error = false;
      })
      .addCase(getAllTestimony.fulfilled, (state, action) => {
        state.testimonies.loading = false;
        state.testimonies.success = true;
        state.testimonies.error = false;
        state.testimonies.data = action.payload.data;
        state.testimonies.total = {
          total: action?.payload?.total,
          total_pages: action?.payload?.total_pages,
          items_per_page: action?.payload?.items_per_page,  
        };
      })
      .addCase(getAllTestimony.rejected, (state, action) => {
        state.testimonies.loading = false;
        state.testimonies.success = false;
        state.testimonies.error = action?.error?.message;
      });

    builder
      .addCase(getSingleTestimony.pending, (state) => {
        state.singleTestimony.loading = true;
        state.singleTestimony.success = false;
        state.singleTestimony.error = false;
      })
      .addCase(getSingleTestimony.fulfilled, (state, action) => {
        state.singleTestimony.loading = false;
        state.singleTestimony.success = true;
        state.singleTestimony.error = false;
        state.singleTestimony.data = action.payload.data;
      })
      .addCase(getSingleTestimony.rejected, (state, action) => {
        state.singleTestimony.loading = false;
        state.singleTestimony.success = false;
        state.singleTestimony.error = action?.error?.message;
      });
  },
});

export const { isLoading, isSuccess, isError, isReset, handleBlogCategoryReset } = testimonySlice.actions;

export default testimonySlice.reducer;

export const addNewTestimony = (data, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/testimony`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const updateTestimony = (id, data, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/testimony/${id}`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const archiveTestimony = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/testimony/archive/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const restoreTestimony = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/testimony/restore/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const deleteTestimony = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/testimony/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
