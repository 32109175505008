import React, { useEffect, useRef, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewCard,
  Rating,
  Row,
  UserAvatar,
} from "../../components/Component";
import { MdErrorOutline, MdOutlineCancel, MdOutlineVerified } from "react-icons/md";
import { LuArchiveRestore } from "react-icons/lu";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteReview, editReview, getAllReviews } from "../../redux/Apislices/reviewSlice";
import styles from "./feedbacks.module.scss";
import PlaceholderImg from "../../assets/profile_avatar.png";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import clsx from "clsx";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const statusOptions = [
  { value: "Approved", label: "Approve" },
  { value: "On Hold", label: "On Hold" },
  { value: "Rejected", label: "Reject" },
];

const Feedbacks = () => {
  const dispatch = useDispatch();
  const { review } = useSelector((state) => ({ review: state.review?.reviews }));

  const [limit, setLimit] = useState({ value: 25, label: "25" });
  const [sort, setSort] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const [status, setStatus] = useState(null);

  const handleGetData = () => {
    dispatch(getAllReviews({ status: status, page: currentPage, limit: itemPerPage, sort }));
  };

  useEffect(() => {
    handleGetData();
  }, [status, sort, currentPage, itemPerPage]);

  return (
    <>
      <Head title="Feedbacks"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h1"}>
                Feedbacks
              </BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content" style={{}}>
                  <ul className="nk-block-tools g-3">
                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Feedbacks</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block className={"mt-4"}>
          {review?.loading ? (
            <div
              style={{
                height: "200px",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner type="grow" color="dark" />
            </div>
          ) : review?.data?.length ? (
            <Row className={styles.cardContainer}>
              {review?.data?.map((li, i) => {
                let companyData = null;
                if (li?.agent) {
                  companyData = {
                    name: `${li?.agent?.firstName} ${li?.agent?.lastName}`,
                    slug: li?.agent?.username,
                    company: li?.agent?.brokerage,
                    img: li?.agent?.profilePhoto,
                  };
                } else if (li?.builder) {
                  companyData = { name: `${li?.builder?.name}`, slug: li?.builder?.username, img: li?.builder?.logo };
                } else if (li?.marketing) {
                  companyData = {
                    name: `${li?.marketing?.name}`,
                    slug: li?.marketing?.username,
                    img: li?.marketing?.logo,
                  };
                } else if (li?.associate) {
                  companyData = {
                    name: `${li?.associate?.firstName} ${li?.associate?.lastName}`,
                    slug: li?.associate?.username,
                    company: li?.associate?.brokerage,
                    img: li?.associate?.profilePhoto,
                  };
                }
                console.log("companyData: ", companyData);
                return (
                  <FeedbackCardBody
                    key={li?._id}
                    li={li}
                    companyData={companyData}
                    handleGetData={handleGetData}
                    status={status}
                  />
                );
                // return (
                // <h1>Hello</h1>
                // )
              })}
            </Row>
          ) : (
            <div
              className="text-center"
              style={{
                padding: "20px",
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
              }}
            >
              <span className="text-silent">No feedbacks found</span>
            </div>
          )}
        </Block>
      </Content>
    </>
  );
};

export const FeedbackCardBody = ({ li, companyData, status, handleGetData }) => {
  const dispatch = useDispatch();

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const descriptionRef = useRef(null);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement && li?.comment) {
      const { clientHeight, scrollHeight } = descriptionElement;
      setIsButtonVisible(scrollHeight > clientHeight);
    }
  }, [li?.comment]);

  const handleUpdate = (id, data) => {
    dispatch(
      editReview(
        id,
        data,
        (res) => {
          handleGetData();
          toast.success("Feedback updated successfully");
        },
        (res) => {
          console.log("res error: ", res);
          toast.error("Failed to update feedback");
        }
      )
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteReview(
            id,
            (res) => {
              if (res?.success) {
                dispatch(handleGetData());
              }
              Swal.fire("Deleted!", "Feedback has been deleted.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  return (
    <Col md={6} lg={4} key={li?._id}>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <div>
            <UserAvatar
              className="sq"
              theme={"transparent"}
              image={companyData?.img ? `${process.env.REACT_APP_IMGURL}${companyData?.img}` : PlaceholderImg}
            />
            <div className="project-info">
              <h6 className="title">
                {companyData?.name}{" "}
                <span
                  className={styles.status}
                  style={{
                    color:
                      li?.status === "Pending"
                        ? "#f3a638"
                        : li?.status === "Approved"
                        ? "#77c720"
                        : li?.status === "Rejected"
                        ? "#ce6048"
                        : li?.status === "On Hold"
                        ? "#f3a638"
                        : "",
                  }}
                >
                  {li?.status === "Pending" ? (
                    <MdErrorOutline />
                  ) : li?.status === "Approved" ? (
                    <MdOutlineVerified />
                  ) : li?.status === "Rejected" ? (
                    <MdOutlineCancel />
                  ) : li?.status === "On Hold" ? (
                    <MdErrorOutline />
                  ) : null}
                  {li?.status}
                </span>
              </h6>
              <span className="sub-text">{companyData?.company?.name ? companyData?.company?.name : ""}</span>
            </div>
          </div>

          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 mr-n1">
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  {li?.status === "Pending" || li?.status === "Requested" ? null : (
                    <li>
                      <Button id={"toggler"} className={styles.btn}>
                        <Icon name="chevron-down" className={styles.icon}></Icon>
                        <span>Change Status</span>
                      </Button>
                      <UncontrolledCollapse toggler="#toggler">
                        <Card>
                          <CardBody className={styles.drpDwnBody}>
                            <ul>
                              {statusOptions
                                ?.filter((item) => item.value !== li?.status)
                                ?.map((list, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      handleUpdate(li?._id, { status: list?.value });
                                    }}
                                  >
                                    {list?.label}
                                  </li>
                                ))}
                            </ul>
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    </li>
                  )}

                  {status === "archive" ? (
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#view"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleUpdate(li?._id, { isDeleted: false });
                        }}
                      >
                        <LuArchiveRestore style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }} />
                        <span>Restore</span>
                      </DropdownItem>
                    </li>
                  ) : (
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#view"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleUpdate(li?._id, { isDeleted: true });
                        }}
                      >
                        <Icon name="archived-fill"></Icon>
                        <span>Archive</span>
                      </DropdownItem>
                    </li>
                  )}

                  <li>
                    <DropdownItem
                      tag="a"
                      href="#view"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleDelete(li?._id);
                      }}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete Permanently</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>

        <div className={styles.cardBody}>
          <div className={styles.l1}>
            <p className={styles.p1}>
              {li?.status === "Requested"
                ? `${li?.request?.firstName} ${li?.request?.lastName}`
                : `${li?.user?.firstName} ${li?.user?.lastName}`}
            </p>
            <p className={styles.p2}>{moment(li?.createdAt).format("DD MMM, YYYY")}</p>
          </div>

          <div className={styles.l2}>
            {li?.propertyType && li?.developmentName && li?.yearOfDeal ? (
              <p className={styles.title}>
                {`Brought ${li?.propertyType?.name} at ${li?.developmentName} in ${li?.yearOfDeal}`}
              </p>
            ) : null}
          </div>

          <div className={clsx(styles.starDiv, styles.l3)}>
            {li?.rating ? <Rating type="star" initVal={li?.rating}></Rating> : null}
          </div>

          <div>
            <div ref={descriptionRef} className={clsx(`${styles.l4} ${showFullDescription ? styles.expanded : ""}`)}>
              <p className={styles.p3}>{li?.comment}</p>
            </div>
            {isButtonVisible ? (
              <button className={clsx(styles.showMoreBtn)} onClick={toggleDescription}>
                {showFullDescription ? "Show Less" : "Show More"}
              </button>
            ) : null}
          </div>

          {li?.status === "Pending" ? (
            <div className={styles.statusDiv}>
              <button
                className={styles.approveBtn}
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate(li?._id, { status: "Approved" });
                }}
              >
                <MdOutlineVerified />
                <span>Approve</span>
              </button>
              <button
                className={styles.rejectBtn}
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate(li?._id, { status: "Rejected" });
                }}
              >
                <MdOutlineCancel />
                <span>Reject</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </Col>
  );
};

export default Feedbacks;
