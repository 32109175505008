import React from "react";
import { Button } from "../Component";

const FooterBtn = ({ onClick, text, type, ...rest }) => {
  return (
    <Button
      color={rest?.color || "info"}
      onClick={onClick}
      type={type || "button"}
      style={{ ...rest?.syle, fontSize: "16px", padding: "6px 25px" }}
      {...rest}
    >
      <span>{text}</span>
    </Button>
  );
};

export default FooterBtn;
