import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styles from "./development.module.scss";

const DevNavTab = ({ activeIconTab, toggleIconTab, update }) => {
  const [data, setData] = useState([
    { id: 1, label: "Basic Info" },
    { id: 2, label: "Status" },
    { id: 3, label: "Media" },
    { id: 4, label: "Facts & Features" },
    { id: 5, label: "Floor Plans" },
    { id: 6, label: "Price & Contact" },
    { id: 7, label: "Agent Package" },
  ]);

  useEffect(() => {
    if (activeIconTab >= 7) {
      setData([...data, { id: 8, label: "Payment" }]);
    }
  }, [activeIconTab]);

  const handleToggle = (li) => {
    update(async (res) => {
      if (res?.success) {
        toggleIconTab(li?.id);
      }
    });
  };

  return (
    <Nav tabs className={classNames("px-4", styles.nav)}>
      {data?.map((li) => (
        <NavItem key={li?.id}>
          <NavLink
            tag="div"
            className={classNames({ active: activeIconTab === li?.id })}
            style={{
              cursor: "pointer",
            }}
            onClick={(ev) => {
              ev.preventDefault();
              handleToggle(li);
            }}
          >
            <span>{li?.label}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default DevNavTab;
