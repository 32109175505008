import clsx from "clsx";
import update from "immutability-helper";
import { useCallback, useState } from "react";
import styles from "./draggable.module.scss";
import { Card } from "./Card";

const style = {
  // width: 400,
  // fontSize: "16px",
  // display: "flex",
  // gap: "3rem",
};

export const Container = ({
  cards = [],
  setCards,
  deleteArray,
  coverImg,
  setDeleteArray,
  handleCoverImg,
  handleDelete,
  handleMultiple,
}) => {
  {
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
    }, []);

    const renderCard = useCallback(
      (card, index) => {
        return (
          <Card
            key={card}
            cards={cards}
            index={index}
            id={card}
            card={card}
            moveCard={moveCard}
            deleteArray={deleteArray}
            setDeleteArray={setDeleteArray}
            handleCoverImg={handleCoverImg}
            coverImg={coverImg}
            handleDelete={handleDelete}
            handleMultiple={handleMultiple}
          />
        );
      },
      [deleteArray, setDeleteArray, handleCoverImg, coverImg, handleDelete, handleMultiple, moveCard]
    );

    return (
      <>
        <div
          className={clsx("customScrollbar draggableCard", styles.imageGallery)}
          // style={style}
        >
          {cards.map((card, i) => {
            return renderCard(card, i);
          })}
        </div>
      </>
    );
  }
};
