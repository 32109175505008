import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod } from "../../utils/apiServices";

export const getConstruction = createAsyncThunk("construction/fetchConstruction", async () => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/construction`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  construction: null,
};

const constructionSlice = createSlice({
  name: "construction",
  initialState,
  // reducers: {
  //   isLoading: (state) => {
  //     state.loading = true;
  //     state.success = false;
  //     state.error = false;
  //   },
  //   isSuccess: (state, action) => {
  //     state.loading = false;
  //     state.success = true;
  //     state.error = false;
  //     state.construction = action.payload;
  //   },
  //   isError: (state) => {
  //     state.loading = false;
  //     state.success = false;
  //     state.error = true;
  //   },
  // },
  extraReducers: (builder) => {
    builder.addCase(getConstruction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConstruction.fulfilled, (state, action) => {
      state.loading = false;
      state.construction = action.payload.data;
    });
    builder.addCase(getConstruction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

// export const { isLoading, isSuccess, isError } = constructionSlice.actions;

export default constructionSlice.reducer;

// export const getConstruction = () => async (dispatch) => {
//   try {
//     dispatch(isLoading());
//     const response = await getMethod(`/api/construction`);
//     dispatch(isSuccess(response?.data));
//   } catch (error) {
//     dispatch(isError());
//   }
// };
