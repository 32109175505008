import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getCountry = createAsyncThunk("country/fetchCountry", async (query) => {
  const response = await getMethod(`/api/country`, query);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  countries: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.country = action.payload;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    countryReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.country = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCountry.pending, (state) => {
      state.countries.loading = true;
      state.countries.success = false;
      state.countries.error = false;
      state.countries.data = null;
    });
    builder.addCase(getCountry.fulfilled, (state, action) => {
      state.countries.loading = false;
      state.countries.success = true;
      state.countries.error = false;
      state.countries.data = action.payload.data;
      state.countries.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getCountry.rejected, (state, action) => {
      state.countries.loading = false;
      state.countries.success = false;
      state.countries.error = action?.error?.message || "Something went wrong";
      state.countries.data = null;
    });
  },
});

export const { isLoading, isSuccess, isError, countryReset } = countrySlice.actions;

export default countrySlice.reducer;

export const addCountry = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/country`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const updateCountry = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/country/${id}`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const deleteCountry = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/country/${id}`);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};
