import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, FormGroup, Input, Label } from "reactstrap";
import Swal from "sweetalert2";
import Content from "../../layout/content/Content";
import DevNavTab from "../../pages/Development/DevNavTab";
import { getConstruction } from "../../redux/Apislices/constructionSlice";
import {
  addNewDevelopment,
  editDevelopment,
  handleAddDevelopment,
  handleDevAddReset,
} from "../../redux/Apislices/developmentSlice";
import { getSaleStatus } from "../../redux/Apislices/saleStatusSlice";
import { allMonths, getNextYears, yearsAfter, yearsBefore } from "../../utils/moment";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
} from "../Component";
import FooterBtn from "../common/FooterBtn";
import styles from "./dev.module.scss";

const Dev2 = ({ toggleNext, toggleBack, toggleIconTab, activeIconTab }) => {
  const dispatch = useDispatch();
  const {
    construction: constructionObj,
    saleStatus: saleStatusObj,
    development,
    profile,
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const { type, id } = useParams();

  const { developmentById, addDevelopment } = development;
  const { construction: constructionData } = constructionObj;
  const { saleStatus: saleStatusData } = saleStatusObj;

  const [devData, setDevData] = useState(false);

  const [mandConstruction, setMandConstruction] = useState(false);
  const [mandConstStartYear, setMandConstStartYear] = useState(false);
  const [mandConstStartMonth, setMandConstStartMonth] = useState(false);
  const [mandCompYear, setMandCompYear] = useState(false);
  const [mandCompMonth, setMandCompMonth] = useState(false);

  const [mandSales, setMandSales] = useState(false);
  const [mandStartYear, setMandStartYear] = useState(false);
  const [mandStartMonth, setMandStartMonth] = useState(false);

  const [bedErrorMessage, setBedErrorMessage] = useState(false);
  const [bathErrorMessage, setBathErrorMessage] = useState(false);
  const [areaErrorMessage, setAreaErrorMessage] = useState(false);

  const constRef = useRef(null);
  const constStartYearRef = useRef(null);
  const constStartMonthRef = useRef(null);
  const compYearRef = useRef(null);
  const compMonthRef = useRef(null);

  const salesRef = useRef(null);
  const startYearRef = useRef(null);
  const startMonthRef = useRef(null);

  const [percentSold, setPercentSold] = useState("");

  const [constructionStatus, setConstructionStatus] = useState("");
  const [startYear, setstartYear] = useState("");
  const [startMonth, setstartMonth] = useState("");
  const [endYear, setendYear] = useState("");
  const [endMonth, setendMonth] = useState("");
  const [saleStatus, setsaleStatus] = useState("");
  const [saleYear, setsaleYear] = useState("");
  const [saleMonth, setsaleMonth] = useState("");
  const [saleDay, setsaleDay] = useState("");
  const [bedroomRangeFrom, setbedroomRangeFrom] = useState("");
  const [bedroomRangeTo, setbedroomRangeTo] = useState("");
  const [bedroomUpdate, setbedroomUpdate] = useState(false);
  const [bathroomRangeFrom, setbathroomRangeFrom] = useState("");
  const [bathroomRangeTo, setbathroomRangeTo] = useState("");
  const [bathroomUpdate, setbathroomUpdate] = useState(false);
  const [floorAreaRangeFrom, setfloorAreaRangeFrom] = useState("");
  const [floorAreaRangeTo, setfloorAreaRangeTo] = useState("");
  const [floorAreaUpdate, setfloorAreaUpdate] = useState(false);

  const handleChange = (e) => {
    if (e.target.value === "63dce41caea28f56b0aa6fa7") {
      Swal.fire({
        title: "Are you sure you want to proceed?",
        text: "The status “Sold Out” will auto-update Prices, Floor Plans status, Special Incentives, and Sales Centre contact information.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setsaleStatus(e.target.value);
        } else {
          setsaleStatus("");
        }
      });
    }
    setsaleStatus(e.target.value);
  };

  const handleBack = () => {
    toggleBack();
  };

  const handlePayload = () => {
    let data = {
      ...devData,
      percentSold,
      construction: {
        constructionStatus: constructionStatus,
        constructionStart: {
          year: startYear,
          month: startMonth,
        },
        constructionEnd: {
          year: endYear,
          month: endMonth,
        },
      },
      saleStatus: {
        saleStatus: saleStatus,
        saleStart: {
          year: saleYear,
          month: saleMonth,
          day: +saleDay,
        },
      },
      bedrooms: {
        min: bedroomRangeFrom ? +bedroomRangeFrom : bedroomRangeFrom === "0" || bedroomRangeFrom === 0 ? 0 : null,
        max: bedroomRangeTo ? +bedroomRangeTo : bedroomRangeTo === "0" || bedroomRangeTo === 0 ? 0 : null,
        autoUpdate: bedroomUpdate,
      },
      bathrooms: {
        min: bathroomRangeFrom ? +bathroomRangeFrom : bathroomRangeFrom === "0" || bathroomRangeFrom === 0 ? 0 : null,
        max: bathroomRangeTo ? +bathroomRangeTo : bathroomRangeTo === "0" || bathroomRangeTo === 0 ? 0 : null,
        autoUpdate: bathroomUpdate,
      },
      floorArea: {
        min: floorAreaRangeFrom
          ? +floorAreaRangeFrom
          : floorAreaRangeFrom === "0" || floorAreaRangeFrom === 0
          ? 0
          : null,
        max: floorAreaRangeTo ? +floorAreaRangeTo : floorAreaRangeTo === "0" || floorAreaRangeTo === 0 ? 0 : null,
        autoUpdate: floorAreaUpdate,
      },
      type: "presale",
    };

    if (saleStatus === "63dce41caea28f56b0aa6fa7") {
      // if (data?.presentationCenter) {
      data = {
        ...data,
        presentationCenter: {
          ...data.presentationCenter,
          timelines: {
            ...data?.presentationCenter?.timelines,
            ByAppointment: false,
            openStatus: "closedPermanently",
          },
        },
      };
      // }

      if (devData?.floorPlans?.floorPlans?.length) {
        let fpd = devData?.floorPlans?.floorPlans?.map((li) => {
          return { ...li, status: "sold" };
        });
        data = {
          ...data,
          floorPlans: {
            ...data?.floorPlans,
            floorPlans: fpd,
          },
        };
      }
    }

    if (saleStatus === "655c9a741e3f004db169e9cc") {
      data = {
        ...data,
        presentationCenter: {
          ...data.presentationCenter,
          timelines: {
            ...data?.presentationCenter?.timelines,
            openStatus: "closedTemporarily",
          },
        },
      };
    }
    return data;
  };

  const handleAddUpdate = (successCB) => {
    let data = handlePayload();
    if (type === "edit") {
      data.status = devData?.status;
    } else {
      data.status = "pending";
    }
    console.log("data: ", data);

    if (!constructionStatus || !saleStatus) {
      if (!constructionStatus) {
        setMandConstruction(true);
      }
      if (!saleStatus) {
        setMandSales(true);
      }

      if (!constructionStatus) {
        constRef?.current?.focus();
      } else if (!saleStatus) {
        salesRef?.current?.focus();
      }
      toast.error("Please fill all mandatory data");
    } else {
      if (
        (constructionStatus || saleStatus) &&
        (!startYear || !endYear || (!saleYear && saleStatus !== "63dce41caea28f56b0aa6fa7"))
      ) {
        toast.error("Please fill all mandatory data");
        if (!startYear) {
          setMandConstStartYear(true);
        }
        if (!endYear) {
          setMandCompYear(true);
        }
        if (!saleYear) {
          setMandStartYear(true);
        }

        if (!startYear) {
          constStartYearRef?.current?.focus();
        } else if (!endYear) {
          compYearRef?.current?.focus();
        } else if (!saleYear) {
          startYearRef?.current?.focus();
        }
      } else if (bedErrorMessage || bathErrorMessage || areaErrorMessage) {
        toast.error("Please enter valid range");
      } else {
        if (
          // (startYear !== "To be announced" && startYear !== "Undisclosed" && !startMonth) ||
          // (endYear !== "To be announced" && endYear !== "Undisclosed" && !endMonth) ||
          saleYear !== "To be announced" &&
          saleYear !== "Undisclosed" &&
          !saleMonth &&
          saleStatus !== "63dce41caea28f56b0aa6fa7"
        ) {
          toast.error("Please fill all mandatory data");
          // if (!endMonth) {
          //   setMandCompMonth(true);
          // }
          if (!saleMonth) {
            setMandStartMonth(true);
          }

          // if (!endMonth) {
          //   compMonthRef?.current?.focus();
          // } else
          if (!saleMonth) {
            startMonthRef?.current?.focus();
          }
        } else {
          if (type === "edit" || type === "duplicate") {
            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  dispatch(handleDevAddReset());
                  successCB({ success: true });
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            dispatch(handleAddDevelopment({ ...addDevelopment, data }));
            successCB({ success: true });
          }
        }
      }
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        toggleNext();
      }
    });
  };

  const addToDraft = () => {
    let updateData = handlePayload();
    if (profile?.profile?.role === "associate") {
      updateData = {
        ...updateData,
        company: {
          ...updateData?.company,
          associate: {
            associate: profile?.profile?._id,
            byAssociate: true,
          },
        },
      };
    }

    if (saleStatus === "63dce41caea28f56b0aa6fa7" && type === "edit") {
      // updateData.status = "sold";
      if (devData?.floorPlans?.floorPlans?.length) {
        let fpd = devData?.floorPlans?.floorPlans?.map((li) => {
          return { ...li, status: "sold" };
        });
        updateData = {
          ...updateData,
          floorPlans: {
            ...updateData?.floorPlans,
            floorPlans: fpd,
          },
        };
      }
    } else if (
      devData?.saleStatus?.saleStatus === "63dce41caea28f56b0aa6fa7" &&
      saleStatus !== "63dce41caea28f56b0aa6fa7"
    ) {
      updateData.status = "active";
    } else if (type === "edit" || type === "duplicate") {
      updateData.status = devData?.status;
    } else {
      updateData.status = "draft";
    }
    console.log("updateData: ", updateData);
    if (!constructionStatus || !saleStatus) {
      if (!constructionStatus) {
        setMandConstruction(true);
      }
      if (!saleStatus) {
        setMandSales(true);
      }

      if (!constructionStatus) {
        constRef?.current?.focus();
      } else if (!saleStatus) {
        salesRef?.current?.focus();
      }
      toast.error("Please fill all mandatory data");
    } else {
      if (
        (constructionStatus || saleStatus) &&
        (!startYear || !endYear || (!saleYear && saleStatus !== "63dce41caea28f56b0aa6fa7"))
      ) {
        toast.error("Please fill all mandatory data");
        if (!startYear) {
          setMandConstStartYear(true);
        }
        if (!endYear) {
          setMandCompYear(true);
        }
        if (!saleYear) {
          setMandStartYear(true);
        }

        if (!startYear) {
          constStartYearRef?.current?.focus();
        } else if (!endYear) {
          compYearRef?.current?.focus();
        } else if (!saleYear) {
          startYearRef?.current?.focus();
        }
      } else if (bedErrorMessage || bathErrorMessage || areaErrorMessage) {
        toast.error("Please enter valid range");
      } else {
        if (
          // (startYear !== "To be announced" && startYear !== "Undisclosed" && !startMonth) ||
          // (endYear !== "To be announced" && endYear !== "Undisclosed" && !endMonth) ||
          saleYear !== "To be announced" &&
          saleYear !== "Undisclosed" &&
          !saleMonth &&
          saleStatus !== "63dce41caea28f56b0aa6fa7"
        ) {
          toast.error("Please fill all mandatory data");
          // if (!endMonth) {
          //   setMandCompMonth(true);
          // }
          if (!saleMonth) {
            setMandStartMonth(true);
          }

          // if (!endMonth) {
          //   compMonthRef?.current?.focus();
          // } else
          if (!saleMonth) {
            startMonthRef?.current?.focus();
          }
        } else {
          if (type === "edit" || type === "duplicate") {
            dispatch(
              editDevelopment(
                id,
                updateData,
                (res) => {
                  toast.success("Development updated");
                  dispatch(handleDevAddReset());
                  navigate("/development");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            dispatch(
              addNewDevelopment(
                updateData,
                async (res) => {
                  toast.success("Development added as draft");
                  dispatch(handleDevAddReset());
                  navigate("/development");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getConstruction());
    dispatch(getSaleStatus());
  }, [dispatch]);

  // if data exist in session or if its edit then save it to devData
  useEffect(() => {
    if (developmentById?.data) setDevData(developmentById?.data);
    else if (addDevelopment?.data) setDevData(addDevelopment?.data);
  }, [developmentById?.data, addDevelopment?.data]);

  // prefill all fields
  useEffect(() => {
    if (devData) {
      console.log("devData 2: ", devData);
      setConstructionStatus(devData?.construction?.constructionStatus);
      setstartYear(
        devData?.construction?.constructionStart?.year && devData?.construction?.constructionStart?.year !== "0"
          ? devData?.construction?.constructionStart?.year
          : ""
      );
      setstartMonth(devData?.construction?.constructionStart?.month);
      setendYear(
        devData?.construction?.constructionEnd?.year && devData?.construction?.constructionEnd?.year !== "0"
          ? devData?.construction?.constructionEnd?.year
          : ""
      );
      setendMonth(devData?.construction?.constructionEnd?.month);
      setsaleStatus(devData?.saleStatus?.saleStatus);
      setsaleYear(devData?.saleStatus?.saleStart?.year);
      setsaleMonth(devData?.saleStatus?.saleStart?.month);
      setsaleDay(devData?.saleStatus?.saleStart?.day);

      setPercentSold(devData?.percentSold);

      if (devData.bedrooms) {
        if (devData?.bedrooms?.autoUpdate === true) {
          setbedroomUpdate(devData?.bedrooms?.autoUpdate);
        } else if (devData?.bedrooms?.autoUpdate === false) {
          setbedroomUpdate(devData?.bedrooms?.autoUpdate);
        } else {
          setbedroomUpdate(bedroomUpdate);
        }
        setbedroomRangeFrom(devData?.bedrooms?.min);
        setbedroomRangeTo(devData?.bedrooms?.max);
      }
      if (devData.bathrooms) {
        if (devData?.bathrooms?.autoUpdate === true) {
          setbathroomUpdate(devData?.bathrooms?.autoUpdate);
        } else if (devData?.bathrooms?.autoUpdate === false) {
          setbathroomUpdate(devData?.bathrooms?.autoUpdate);
        } else {
          setbathroomUpdate(bathroomUpdate);
        }
        setbathroomRangeFrom(devData?.bathrooms?.min);
        setbathroomRangeTo(devData?.bathrooms?.max);
      }
      if (devData.floorArea) {
        if (devData?.floorArea?.autoUpdate === true) {
          setfloorAreaUpdate(devData?.floorArea?.autoUpdate);
        } else if (devData?.floorArea?.autoUpdate === false) {
          setfloorAreaUpdate(devData?.floorArea?.autoUpdate);
        } else {
          setfloorAreaUpdate(floorAreaUpdate);
        }
        setfloorAreaRangeFrom(devData?.floorArea?.min);
        setfloorAreaRangeTo(devData?.floorArea?.max);
      }
    }
  }, [devData]);

  useEffect(() => {
    if (devData?.floorPlans?.floorPlans?.length) {
      let floorPlans = devData?.floorPlans?.floorPlans;
      if (bedroomUpdate) {
        setbedroomRangeFrom(
          Math.min(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms))
        );
        setbedroomRangeTo(
          Math.max(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms))
        );
      }

      if (bathroomUpdate) {
        setbathroomRangeFrom(
          Math.min(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms))
        );
        setbathroomRangeTo(
          Math.max(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms))
        );
      }

      if (floorAreaUpdate) {
        setfloorAreaRangeFrom(
          Math.min(
            ...floorPlans
              ?.filter((li) => li?.status === "available")
              ?.map((li) => {
                let min = li?.size?.from || li?.size?.size;
                return min;
              })
          )
        );
        setfloorAreaRangeTo(
          Math.max(
            ...floorPlans
              ?.filter((li) => li?.status === "available")
              ?.map((li) => {
                let max = li?.size?.to || li?.size?.size;
                return max;
              })
          )
        );
      }
    }
  }, [devData?.floorPlans?.floorPlans, bedroomUpdate, bathroomUpdate, floorAreaUpdate]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {devData?.name ? devData?.name : type === "add" ? "Add Development" : "Edit Development"}
              </BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  addToDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <DevNavTab toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Status</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      {/* Construction Status */}
                      <Row className={styles.row}>
                        <Col sm={"12"}>
                          <Row>
                            <Col sm={"6"}>
                              <FormGroup>
                                <Label htmlFor="constructionStatus" className="form-label">
                                  Construction Status*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      id="constructionStatus"
                                      className={mandConstruction ? "error" : ""}
                                      ref={constRef}
                                      value={constructionStatus}
                                      name="constructionStatus"
                                      onChange={(e) => {
                                        setConstructionStatus(e.target.value);
                                        setstartMonth("");
                                        setstartYear("");
                                        setendMonth("");
                                        setendYear("");
                                        if (e.target.value) {
                                          setMandConstruction(false);
                                        }
                                      }}
                                    >
                                      <option value="">Select Option</option>
                                      {constructionData?.map((item) => (
                                        <option key={item?._id} value={item?._id}>
                                          {item?.name}
                                        </option>
                                      ))}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>

                        {constructionStatus === "63cbb64d860eaa6e22264e84" ||
                        constructionStatus === "63cbb656860eaa6e22264e86" ||
                        constructionStatus === "63cbb662860eaa6e22264e88" ? (
                          <>
                            <Col sm={"6"}>
                              <FormGroup>
                                <Label htmlFor="constructionStart" className="form-label">
                                  {constructionStatus === "63cbb656860eaa6e22264e86" ||
                                  constructionStatus === "63cbb662860eaa6e22264e88"
                                    ? "Construction Started"
                                    : "Construction Start"}
                                  *
                                </Label>
                                <Row>
                                  <Col sm={"6"}>
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <Input
                                          type="select"
                                          id="constructionStart"
                                          className={mandConstStartYear ? "error" : ""}
                                          ref={constStartYearRef}
                                          value={startYear}
                                          name="startYear"
                                          onChange={(e) => {
                                            setstartYear(e.target.value);
                                            if (
                                              e.target.value === "To be announced" ||
                                              e.target.value === "Undisclosed"
                                            ) {
                                              setstartMonth("");
                                            }
                                            setMandConstStartYear(false);
                                          }}
                                        >
                                          <option value="">Select year</option>
                                          <option
                                            value={
                                              constructionStatus === "63cbb64d860eaa6e22264e84"
                                                ? "To be announced"
                                                : "Undisclosed"
                                            }
                                          >
                                            {constructionStatus === "63cbb64d860eaa6e22264e84"
                                              ? "To be announced"
                                              : "Undisclosed"}
                                          </option>
                                          {constructionStatus === "63cbb64d860eaa6e22264e84"
                                            ? yearsAfter(5)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            : yearsBefore(5)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                        </Input>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm={"6"}>
                                    {startYear === "To be announced" || startYear === "Undisclosed" ? null : (
                                      <div className="form-control-wrap">
                                        <div className="form-control-select">
                                          <Input
                                            type="select"
                                            id="constructionStatus"
                                            className={mandConstStartMonth ? "error" : ""}
                                            ref={constStartMonthRef}
                                            value={startMonth}
                                            name="startMonth"
                                            onChange={(e) => {
                                              setstartMonth(e.target.value);
                                              setMandConstStartMonth(false);
                                            }}
                                          >
                                            <option value="">Season/Month</option>
                                            {["Spring", "Summer", "Fall", "Winter", ...allMonths]?.map((item) => (
                                              <option key={item} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </Input>
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>

                            <Col sm={"6"}>
                              <FormGroup>
                                <Label htmlFor="constructionEnd" className="form-label">
                                  {constructionStatus === "63cbb662860eaa6e22264e88"
                                    ? "Completed *"
                                    : "Estimated Completion *"}
                                </Label>
                                <Row>
                                  <Col sm={"6"}>
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <Input
                                          type="select"
                                          id="constructionEnd"
                                          className={mandCompYear ? "error" : ""}
                                          ref={compYearRef}
                                          value={endYear}
                                          name="endYear"
                                          onChange={(e) => {
                                            setendYear(e.target.value);
                                            if (
                                              e.target.value === "To be announced" ||
                                              e.target.value === "Undisclosed"
                                            ) {
                                              setendMonth("");
                                            }
                                            if (e.target.value) {
                                              setMandCompYear(false);
                                            }
                                          }}
                                        >
                                          <option value="">Select year</option>
                                          {startYear ? (
                                            <>
                                              <option
                                                value={
                                                  constructionStatus === "63cbb662860eaa6e22264e88"
                                                    ? "Undisclosed"
                                                    : "To be announced"
                                                }
                                              >
                                                {constructionStatus === "63cbb662860eaa6e22264e88"
                                                  ? "Undisclosed"
                                                  : "To be announced"}
                                              </option>
                                              {constructionStatus === "63cbb662860eaa6e22264e88"
                                                ? yearsBefore(5, new Date().getFullYear())?.map((item) => (
                                                    <option key={item} value={item}>
                                                      {item}
                                                    </option>
                                                  ))
                                                : yearsAfter(5, new Date().getFullYear())?.map((item) => (
                                                    <option key={item} value={item}>
                                                      {item}
                                                    </option>
                                                  ))}
                                            </>
                                          ) : startYear &&
                                            (startYear !== "Undisclosed" || startYear !== "To be announced") ? (
                                            constructionStatus === "63cbb662860eaa6e22264e88" ? (
                                              getNextYears(5, +startYear)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            ) : constructionStatus === "63cbb662860eaa6e22264e86" ? (
                                              yearsAfter(5, +startYear)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            ) : (
                                              yearsAfter(5, +startYear)?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))
                                            )
                                          ) : (
                                            <option value="" disabled>
                                              Please select construction start year
                                            </option>
                                          )}
                                        </Input>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm={"6"}>
                                    {endYear === "To be announced" || endYear === "Undisclosed" ? null : (
                                      <div className="form-control-wrap">
                                        <div className="form-control-select">
                                          <Input
                                            type="select"
                                            className={mandCompMonth ? "error" : ""}
                                            id="constructionStatus"
                                            ref={compMonthRef}
                                            value={endMonth}
                                            name="endMonth"
                                            onChange={(e) => {
                                              setendMonth(e.target.value);
                                              if (e.target.value) {
                                                setMandCompMonth(false);
                                              }
                                            }}
                                          >
                                            <option value="">Season/Month</option>
                                            {["Spring", "Summer", "Fall", "Winter", ...allMonths]?.map((item) => (
                                              <option key={item} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </Input>
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </>
                        ) : null}
                      </Row>

                      {/* Sale Status */}
                      <Row>
                        <Col sm={"12"}>
                          <Row className={styles.row}>
                            <Col sm={"6"}>
                              <FormGroup>
                                <Label htmlFor="saleStatus" className="form-label">
                                  Sale Status*
                                </Label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <Input
                                      type="select"
                                      id="saleStatus"
                                      ref={salesRef}
                                      value={saleStatus}
                                      name="saleStatus"
                                      className={mandSales ? "error" : ""}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setsaleYear("");
                                        setsaleMonth("");
                                        setsaleDay("");
                                        if (e.target.value === "63dce41caea28f56b0aa6fa7") {
                                          setsaleYear("");
                                          setsaleMonth("");
                                          setsaleDay("");
                                        }
                                        if (e.target.value) {
                                          setMandSales(false);
                                        }
                                      }}
                                    >
                                      <option value="">Select Option</option>
                                      {saleStatusData?.map((item) => (
                                        <option key={item?._id} value={item?._id}>
                                          {item?.name}
                                        </option>
                                      ))}
                                    </Input>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>

                            {saleStatus === "63dce3f4aea28f56b0aa6f9f" ||
                            saleStatus === "63dce401aea28f56b0aa6fa1" ||
                            saleStatus === "63dce40faea28f56b0aa6fa5" ? (
                              <Col sm={"6"}>
                                <FormGroup>
                                  <Label htmlFor="saleStatus" className="form-label">
                                    {saleStatus === "63dce40faea28f56b0aa6fa5" ? "Sale Started*" : "Sale Start*"}
                                  </Label>
                                  <Row>
                                    <Col sm={"6"}>
                                      <div className="form-control-wrap">
                                        <div className="form-control-select">
                                          <Input
                                            type="select"
                                            id="saleStatus"
                                            className={mandStartYear ? "error" : ""}
                                            ref={startYearRef}
                                            value={saleYear}
                                            name="saleYear"
                                            onChange={(e) => {
                                              setsaleYear(e.target.value);
                                              if (
                                                e.target.value === "To be announced" ||
                                                e.target.value === "Undisclosed"
                                              ) {
                                                setsaleMonth("");
                                              }
                                              if (e.target.value) {
                                                setMandStartYear(false);
                                              }
                                            }}
                                          >
                                            <option value="">Select year</option>
                                            <option
                                              value={
                                                saleStatus === "63dce40faea28f56b0aa6fa5"
                                                  ? "Undisclosed"
                                                  : "To be announced"
                                              }
                                            >
                                              {saleStatus === "63dce40faea28f56b0aa6fa5"
                                                ? "Undisclosed"
                                                : "To be announced"}
                                            </option>
                                            {saleStatus === "63dce40faea28f56b0aa6fa5"
                                              ? yearsBefore(5)?.map((item) => (
                                                  <option key={item} value={item}>
                                                    {item}
                                                  </option>
                                                ))
                                              : yearsAfter(5)?.map((item) => (
                                                  <option key={item} value={item}>
                                                    {item}
                                                  </option>
                                                ))}
                                          </Input>
                                        </div>
                                      </div>
                                    </Col>

                                    {saleYear === "To be announced" || saleYear === "Undisclosed" ? null : (
                                      <Col
                                        sm={
                                          !saleMonth ||
                                          saleMonth === "Spring" ||
                                          saleMonth === "Summer" ||
                                          saleMonth === "Fall" ||
                                          saleMonth === "Winter"
                                            ? "6"
                                            : "4"
                                        }
                                      >
                                        <div className="form-control-wrap">
                                          <div className="form-control-select">
                                            <Input
                                              type="select"
                                              id="saleStatus"
                                              ref={startMonthRef}
                                              value={saleMonth}
                                              className={mandStartMonth ? "error" : ""}
                                              name="saleMonth"
                                              onChange={(e) => {
                                                setsaleMonth(e.target.value);
                                                if (e.target.value) {
                                                  setMandStartMonth(false);
                                                }
                                              }}
                                            >
                                              <option value="">Season/Month</option>
                                              {["Spring", "Summer", "Fall", "Winter", ...allMonths]?.map((item) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                            </Input>
                                          </div>
                                        </div>
                                      </Col>
                                    )}

                                    {saleMonth ? (
                                      saleMonth === "Spring" ||
                                      saleMonth === "Summer" ||
                                      saleMonth === "Fall" ||
                                      saleMonth === "Winter" ? null : (
                                        <Col sm={"2"}>
                                          <div className="form-control-wrap">
                                            <input
                                              className="form-control"
                                              id="default-0"
                                              type="text"
                                              maxLength="2"
                                              placeholder="Day"
                                              value={saleDay}
                                              onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (/^\d*$/.test(inputValue)) {
                                                  if (+inputValue <= 31) {
                                                    setsaleDay(inputValue);
                                                  }
                                                }
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      )
                                    ) : null}
                                  </Row>
                                </FormGroup>
                              </Col>
                            ) : null}
                          </Row>
                        </Col>

                        {/* Percentage of homes sold */}
                        <Col sm={"6"}>
                          <FormGroup>
                            <Label htmlFor="percentSold" className="form-label">
                              Percentage of homes sold
                            </Label>
                            <div className={`form-control-wrap`}>
                              <div className="input-group input-group">
                                <div className="input-group-prepend">
                                  <span className={`input-group-text`} id="inputGroup-sizing-md">
                                    %
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={percentSold}
                                  name="percent"
                                  inputMode="numeric"
                                  maxLength={3}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^\d*$/.test(inputValue)) {
                                      if (+inputValue <= 100) {
                                        setPercentSold(inputValue);
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Construction Details</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row>
                        <Col sm={"12"}>
                          <FormGroup>
                            <Label htmlFor="bedroomRange" className="form-label">
                              Bedroom range of available units
                            </Label>
                            <Row className={styles.row}>
                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        From
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={bedroomRangeFrom}
                                      name="bedroomRangeFrom"
                                      // onChange={handleChange}
                                      disabled={bedroomUpdate}
                                      onChange={(e) => {
                                        setbedroomRangeFrom(e.target.value);
                                        if (e.target.value && bedroomRangeTo && +e.target.value > +bedroomRangeTo) {
                                          setBedErrorMessage(`Bedrooms range From cannot be greater than To`);
                                        } else if (
                                          e.target.value &&
                                          bedroomRangeTo &&
                                          +e.target.value <= +bedroomRangeTo
                                        ) {
                                          setBedErrorMessage(false);
                                        } else {
                                          setBedErrorMessage(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        To
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={bedroomRangeTo}
                                      name="bedroomRangeTo"
                                      disabled={bedroomUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        setbedroomRangeTo(e.target.value);
                                        if (bedroomRangeFrom && e.target.value && bedroomRangeFrom > e.target.value) {
                                          setBedErrorMessage(`Bedrooms range From cannot be greater than To`);
                                        } else if (
                                          bedroomRangeFrom &&
                                          e.target.value &&
                                          bedroomRangeFrom <= e.target.value
                                        ) {
                                          setBedErrorMessage(false);
                                        } else {
                                          setBedErrorMessage(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {bedErrorMessage ? (
                              <label
                                style={{
                                  color: "tomato",
                                  // padding: "2px",
                                  fontSize: "14px",
                                }}
                              >
                                {bedErrorMessage}
                              </label>
                            ) : null}
                            <div className="preview-block mb-2">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="bedroomCheck"
                                  value={bedroomUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setbedroomUpdate(true);
                                    } else {
                                      setbedroomUpdate(false);
                                    }
                                  }}
                                  checked={bedroomUpdate}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="bedroomCheck"
                                  style={{ fontSize: "14px" }}
                                >
                                  Auto Update
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col sm={"12"}>
                          <FormGroup>
                            <Label htmlFor="bedroomRange" className="form-label">
                              Bathroom range of available units
                            </Label>
                            <Row className={styles.row}>
                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        From
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={bathroomRangeFrom}
                                      disabled={bathroomUpdate}
                                      name="bathroomRangeFrom"
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        setbathroomRangeFrom(e.target.value);
                                        if (e.target.value && bathroomRangeTo && +e.target.value > +bathroomRangeTo) {
                                          setBathErrorMessage(`Bathrooms range From cannot be greater than To`);
                                        } else if (
                                          e.target.value &&
                                          bathroomRangeTo &&
                                          +e.target.value <= +bathroomRangeTo
                                        ) {
                                          setBathErrorMessage(false);
                                        } else {
                                          setBathErrorMessage(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        To
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={bathroomRangeTo}
                                      name="bathroomRangeTo"
                                      disabled={bathroomUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        setbathroomRangeTo(e.target.value);
                                        if (
                                          bathroomRangeFrom &&
                                          e.target.value &&
                                          +bathroomRangeFrom > +e.target.value
                                        ) {
                                          setBathErrorMessage(`Bathrooms range From cannot be greater than To`);
                                        } else if (
                                          bathroomRangeFrom &&
                                          e.target.value &&
                                          +bathroomRangeFrom <= +e.target.value
                                        ) {
                                          setBathErrorMessage(false);
                                        } else {
                                          setBathErrorMessage(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {bathErrorMessage ? (
                              <label
                                htmlFor=""
                                style={{
                                  color: "tomato",
                                  padding: "2px",
                                  fontSize: "14px",
                                }}
                              >
                                {bathErrorMessage}
                              </label>
                            ) : null}
                            <div className="preview-block mb-2">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="bathroomRange"
                                  value={bathroomUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setbathroomUpdate(true);
                                    } else {
                                      setbathroomUpdate(false);
                                    }
                                  }}
                                  checked={bathroomUpdate}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="bathroomRange"
                                  style={{ fontSize: "14px" }}
                                >
                                  Auto Update
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col sm={"12"}>
                          <FormGroup>
                            <Label htmlFor="bedroomRange" className="form-label">
                              Floor Area range of available units
                            </Label>
                            <Row className={styles.row}>
                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        From
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={floorAreaRangeFrom}
                                      name="floorAreaRangeFrom"
                                      disabled={floorAreaUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        setfloorAreaRangeFrom(e.target.value);
                                        if (e.target.value && floorAreaRangeTo && +e.target.value > +floorAreaRangeTo) {
                                          setAreaErrorMessage(`Floor Area range From cannot be greater than To`);
                                        } else if (
                                          e.target.value &&
                                          floorAreaRangeTo &&
                                          +e.target.value <= +floorAreaRangeTo
                                        ) {
                                          setAreaErrorMessage(false);
                                        } else {
                                          setAreaErrorMessage(false);
                                        }
                                      }}
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                        Sqft
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col sm={"6"}>
                                <div className={`form-control-wrap`}>
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text`} id="inputGroup-sizing-md">
                                        To
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={floorAreaRangeTo}
                                      name="floorAreaRangeTo"
                                      disabled={floorAreaUpdate}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        setfloorAreaRangeTo(e.target.value);

                                        if (
                                          floorAreaRangeFrom &&
                                          e.target.value &&
                                          +floorAreaRangeFrom > +e.target.value
                                        ) {
                                          setAreaErrorMessage(`Floor Area range From cannot be greater than To`);
                                        } else if (
                                          floorAreaRangeFrom &&
                                          e.target.value &&
                                          +floorAreaRangeFrom <= +e.target.value
                                        ) {
                                          setAreaErrorMessage(false);
                                        } else {
                                          setAreaErrorMessage(false);
                                        }
                                      }}
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{ backgroundColor: "transparent" }}>
                                        Sqft
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="preview-block mb-2">
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control error"
                                  id="areaRange"
                                  value={floorAreaUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setfloorAreaUpdate(true);
                                    } else {
                                      setfloorAreaUpdate(false);
                                    }
                                  }}
                                  checked={floorAreaUpdate}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="areaRange"
                                  style={{ fontSize: "14px" }}
                                >
                                  Auto Update
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>

          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Back"} color={"secondary"} outline onClick={handleBack} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Dev2;
