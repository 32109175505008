import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { render } from "react-dom";
import { Container } from "./Container";

const Main = ({
  cards,
  setCards,
  deleteArray,
  coverImg,
  setDeleteArray,
  handleCoverImg,
  handleDelete,
  handleMultiple,
}) => {
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <Container
          cards={cards}
          setCards={setCards}
          deleteArray={deleteArray}
          coverImg={coverImg}
          setDeleteArray={setDeleteArray}
          handleCoverImg={handleCoverImg}
          handleDelete={handleDelete}
          handleMultiple={handleMultiple}
        />
      </DndProvider>
    </div>
  );
};

export default Main;
