/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useCallback } from "react";
import ProjectHeader from "./ProjectHeader";
import Content from "../../layout/content/Content";
import { Block, BlockContent, BlockHead, BlockTitle, Button, Col, Icon, Row } from "../Component";
import { Card, FormGroup, Input, Label } from "reactstrap";
import ProjNav from "./ProjNav";
import styles from "./project.module.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { allMonths, getNextYears, yearsAfter, yearsBefore } from "../../utils/moment";
import { getConstruction } from "../../redux/Apislices/constructionSlice";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import {
  addProject,
  editProject,
  getSingleProject,
  handleAddProject,
  handleProjAws,
} from "../../redux/Apislices/projectSlice";
import { deleteFile, deleteMultipleFiles, uploadFile } from "../../redux/Apislices/awsSlice";
import clsx from "clsx";
import Main from "../Draggable/Main";
import { v4 as uuid } from "uuid";
import { getcity } from "../../redux/Apislices/citySlice";

const Project3 = ({ toggleNext, toggleBack, activeIconTab, toggleIconTab }) => {
  const dispatch = useDispatch();
  const { profile, city, homeTypeData, project } = useSelector((state) => ({
    city: state?.city,
    profile: state?.profile,
    homeTypeData: state?.homeType,
    project: state.project,
  }));
  const navigate = useNavigate();
  const { type, id } = useParams();

  const [projectData, setProjectData] = useState(false);

  const [mediaArray, setMediaArray] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [vTour, setVTour] = useState("");

  const [coverImg, setCoverImg] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (trigger) {
      // setTimeout(() => {
      setTrigger(false);
      // }, 500);
    }
  }, [trigger]);

  const handleDelete = async (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 5000);
    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit") {
            let data = {
              images: projectData?.images?.filter((li) => li !== path),
              type: "project",
            };
            dispatch(
              editProject(
                id,
                data,
                (res) => {
                  dispatch(getSingleProject({ id: res?.data?.slug, query: { requestFrom: "admin" } }));
                  toast.dismiss(toastId);
                  setTrigger(true);
                  toast.success("Image Deleted");
                },
                (res) => {
                  toast.success(res.message);
                }
              )
            );
          } else {
            let filtered = mediaArray?.filter((li) => li !== path);
            dispatch(handleProjAws(filtered));
            toast.dismiss(toastId);
            toast.success("Image Deleted");
          }
        },
        (res) => {
          toast.success(res.message);
        }
      )
    );
  };

  const handleMultiple = (e) => {
    if (e.target.checked) {
      setDeleteArray([...deleteArray, e.target.value]);
    } else {
      let a = deleteArray?.filter((li) => li !== e.target.value);
      setDeleteArray(a);
    }
  };

  const deleteMultiples = async () => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 5000);
    dispatch(
      deleteMultipleFiles(
        { paths: deleteArray },
        (res) => {
          if (type === "edit") {
            let data = {
              images: projectData?.images?.filter((li) => !deleteArray?.includes(li)),
              type: "project",
            };
            dispatch(
              editProject(
                id,
                data,
                (res) => {
                  dispatch(getSingleProject({ id: res?.data?.slug, query: { requestFrom: "admin" } }));
                  toast.dismiss(toastId);
                  setDeleteArray([]);
                  toast.success("Image(s) Deleted");
                },
                (res) => {
                  toast.success(res.message);
                }
              )
            );
          } else {
            let ab = mediaArray?.filter((li) => !deleteArray?.includes(li));
            dispatch(handleProjAws(ab));
            toast.dismiss(toastId);
            toast.success("Images Deleted");
            setDeleteArray([]);
          }
        },
        (res) => {
          toast.dismiss(toastId);
          toast.success(res.message);
        }
      )
    );
  };

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
      setTrigger(true);
    }, 5000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (type === "edit") {
            let img = [];
            if (res?.data) {
              img = res?.data?.map((li) => li?.url);
            }
            let images = [...projectData?.images];
            let data = {
              images: [...images, ...img],
              type: "project",
            };
            dispatch(
              editProject(
                id,
                data,
                (res) => {
                  dispatch(getSingleProject({ id: res?.data?.slug, query: { requestFrom: "admin" } }));
                  setTrigger(true);
                  toast.dismiss(toastId);
                  toast.success("Image(s) Uploaded");
                },
                (res) => {
                  toast.success(res.message);
                }
              )
            );
          } else if (project?.addProject?.["proj-aws"]) {
            let image = project?.addProject?.["proj-aws"];
            image = [...image, ...res?.data?.map((li) => li?.url)];
            dispatch(handleProjAws(image));
            toast.dismiss(toastId);
            setMediaArray(image);
            setTrigger(true);
            toast.success("Image(s) Uploaded");
          } else if (!project?.addProject?.["proj-aws"]) {
            if (!mediaArray?.length) {
              dispatch(handleProjAws([...res?.data?.map((li) => li?.url)]));
              setMediaArray([...res?.data?.map((li) => li?.url)]);
            } else {
              dispatch(handleProjAws([...mediaArray, res?.data?.map((li) => li?.url)]));
              setMediaArray([...mediaArray, res?.data?.map((li) => li?.url)]);
            }
            setTrigger(true);
            toast.dismiss(toastId);
            toast.success("Image(s) Uploaded");
          }
        },
        (res) => {
          toast.error(res?.message);
          toast.dismiss(toastId);
        }
      )
    );
  };

  const handleCoverImg = (item, index) => {
    setCoverImg(item);

    const updatedArray = [...mediaArray];

    const elementToMove = updatedArray[index];

    updatedArray.splice(index, 1);

    updatedArray.splice(0, 0, elementToMove);
    setMediaArray(updatedArray);
    if (type === "edit" || type === "duplicate") {
      let data = {
        ...projectData,
        images: updatedArray,
        type: "project",
      };
      if (videoUrl) {
        data = { ...data, video: videoUrl };
      }
      if (vTour) {
        data = { ...data, vtour: vTour };
      }
      dispatch(
        editProject(
          id,
          data,
          (res) => {
            dispatch(getSingleProject({ id: res?.data?.slug, query: { requestFrom: "admin" } }));
            toast.success("Cover Image Set");
          },
          (res) => {
            toast.success(res.message);
          }
        )
      );
    } else {
      dispatch(handleProjAws(updatedArray));
      toast.success("Cover Image Set");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });
      console.log("acceptedFiles: ", acceptedFiles);

      const newImages = acceptedFiles.map((file, index) => {
        let devName = projectData?.name?.split(" ")?.join("-");

        let cityName = city?.city
          ?.find((li) => li?._id === projectData?.address?.city)
          ?.name?.split(" ")
          ?.join("-");

        let homeTypeName = projectData?.homeTypes?.[0]
          ? `-${homeTypeData?.homeType
              ?.find((li) => li?._id === projectData?.homeTypes?.[0])
              ?.name?.split(" ")
              ?.join("-")}`
          : "";

        const newName = `${devName}-${cityName}${homeTypeName}-${uuid()?.slice(0, 8)}-${
          !mediaArray?.length ? index + 1 : mediaArray?.length + index + 1
        }.${file.name.split(".").pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImages.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("folder", "community");
      formData.append("width", 1440);
      formData.append("height", 900);
      uploadImage(formData);
    },
    [
      city?.city,
      projectData?.address?.city,
      projectData?.homeTypes,
      projectData?.name,
      homeTypeData?.homeType,
      mediaArray?.length,
      uploadImage,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  useEffect(() => {
    dispatch(getcity());
  }, [dispatch]);

  useEffect(() => {
    if (mediaArray?.length) {
      setCoverImg(mediaArray?.[0]);
    }
  }, [mediaArray, coverImg]);

  useEffect(() => {
    if (project?.addProject?.data) {
      let mediaInfo = project?.addProject?.data;
      if (mediaInfo) {
        setProjectData(mediaInfo);
      }
    }
  }, [project?.addProject?.data]);

  useEffect(() => {
    if (projectData) {
      console.log("projectData3: ", projectData);
      setVideoUrl(projectData?.video || videoUrl);
      setVTour(projectData?.vtour || vTour);
      dispatch(handleProjAws(projectData?.images || []));
    }
  }, [projectData]);

  useEffect(() => {
    if (project?.addProject?.["proj-aws"]) {
      setMediaArray(project?.addProject?.["proj-aws"]);
    }
  }, [project?.addProject]);

  const handlePayload = () => {
    let data = {
      ...projectData,
      images: mediaArray,
      video: videoUrl,
      vtour: vTour,
      type: "project",
    };

    return data;
  };

  const handleBack = () => {
    toggleBack();
  };

  const handleAddUpdate = (successCB) => {
    let data = handlePayload();
    if (type === "edit") {
      dispatch(
        editProject(
          id,
          data,
          (res) => {
            successCB({ success: true });
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(handleAddProject(data));
      successCB({ success: true });
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        toggleNext();
      }
    });
  };

  const addToDraft = () => {
    let data = handlePayload();
    if (type === "edit") data.status = projectData?.status;
    else data.status = "draft";
    if (type === "edit") {
      dispatch(
        editProject(
          id,
          data,
          (res) => {
            toast.success("Community updated");
            dispatch(handleAddProject(null));
            navigate("/community");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(
        addProject(
          data,
          async (res) => {
            toast.success("Community added as draft");
            dispatch(handleAddProject(null));
            navigate("/community");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  return (
    <>
      <Content>
        <ProjectHeader
          title={projectData?.name ? projectData.name : type === "edit" ? "Edit Master Community" : "Add Community"}
          addToDraft={addToDraft}
          btnText={type === "edit" ? "Update Community" : "Save as Draft"}
        />

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <ProjNav toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Media</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row>
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <div className={clsx(styles.bannerWhite)}>
                            <h1 className="text-sm-start text-center">
                              Drag and drop the images to customize the image gallery order
                            </h1>
                            <div
                              {...getRootProps({ className: styles.bannerDiv })}
                            >
                              {/* <img src="/images/associate/8109268/8109268.png" alt="8109268" className="img-fluid" /> */}

                              <Icon
                                name="upload"
                                style={{
                                  fontSize: "48px",
                                  marginBottom: "10px",
                                }}
                              ></Icon>

                              <input
                                type="file"
                                id="profile1"
                                name="profile1"
                                style={{ display: "none" }}
                                {...getInputProps()}
                                readOnly
                              />
                              <p className={clsx(styles.para1)}>Drag and drop the images the gallery images here</p>
                              <p className={clsx(styles.para2)}>(Maximum size 1440x900)</p>
                              <button type="file" id="myfile" name="myfile">
                                Select and Upload
                              </button>
                            </div>

                            <p className={("text-sm-start text-center mt-4 mb-5", styles.para2)}>
                              Click on the star icon to select the cover image
                            </p>
                            {!trigger ? (
                              <Main
                                cards={mediaArray}
                                setCards={setMediaArray}
                                deleteArray={deleteArray}
                                coverImg={coverImg}
                                setDeleteArray={setDeleteArray}
                                handleCoverImg={handleCoverImg}
                                handleDelete={handleDelete}
                                handleMultiple={handleMultiple}
                              />
                            ) : null}

                            {deleteArray?.length ? (
                              <div className={clsx(styles.deleteBtn)}>
                                <button onClick={deleteMultiples}>Delete Selected</button>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  {/* <br />
                  <br /> */}

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Video Tour</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row className="gy-4">
                        {/* Video URL */}
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <FormGroup>
                            <Label htmlFor="name" className="form-label">
                              Video URL
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                className={`form-control `}
                                type="text"
                                id="videoUrl"
                                placeholder={"Enter Video URL such as YouTube, Vimeo, etc."}
                                value={videoUrl}
                                autoComplete="off"
                                name="videoUrl"
                                onChange={(e) => {
                                  setVideoUrl(e.target.value);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />
                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">360 Virtual Tour (3D Walkthrough)</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row className="gy-4">
                        {/* 360 Virtual Tour */}
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <FormGroup>
                            <Label htmlFor="name" className="form-label">
                              360 Virtual Tour
                            </Label>
                            <div className="form-control-wrap">
                              <textarea
                                className={`form-control `}
                                type="text"
                                id="vTour"
                                placeholder="Enter virtual tour embedded code. Example: https//"
                                value={vTour}
                                rows={4}
                                autoComplete="off"
                                name="vTour"
                                onChange={(e) => {
                                  setVTour(e.target.value);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>

          <div
            style={{
              margin: "15px 0",
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              color="secondary"
              outline
              onClick={handleBack}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>Back</span>
            </Button>

            <Button
              color={"info"}
              onClick={handleNext}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>Next</span>
            </Button>
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Project3;
