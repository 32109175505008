import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod } from "../../utils/apiServices";

export const getconstructionMethod = createAsyncThunk("constructionMethod/fetchConstructionMethod", async () => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/construction-method`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  constructionMethod: null,
};

const constructionMethodSlice = createSlice({
  name: "constructionMethod",
  initialState,
  // reducers: {
  //   isLoading: (state) => {
  //     state.loading = true;
  //     state.success = false;
  //     state.error = false;
  //   },
  //   isSuccess: (state, action) => {
  //     state.loading = false;
  //     state.success = true;
  //     state.error = false;
  //     state.constructionMethod = action.payload;
  //   },
  //   isError: (state) => {
  //     state.loading = false;
  //     state.success = false;
  //     state.error = true;
  //   },
  // },
  extraReducers: (builder) => {
    builder.addCase(getconstructionMethod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getconstructionMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.constructionMethod = action.payload.data;
    });
    builder.addCase(getconstructionMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export const { isLoading, isSuccess, isError } = constructionMethodSlice.actions;

export default constructionMethodSlice.reducer;

// export const getconstructionMethod = () => async (dispatch) => {
//   try {
//     dispatch(isLoading());
//     const response = await getMethod(`/api/construction-method`);
//     dispatch(isSuccess(response?.data));
//   } catch (error) {
//     dispatch(isError());
//   }
// };
