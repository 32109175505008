import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import {
  Button,
  Col,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  Row,
} from "../../../components/Component";
import { useForm } from "react-hook-form";
import { capitalizeFirstLetter, convertToSlug } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  editAdminPermissions,
  getAdminPermission,
  handleResetPermission,
  handleResetUser,
} from "../../../redux/Apislices/userSlice";

const Permission = ({ view, toggle,editId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user: { permission },
  } = useSelector((state) => ({ user: state?.user }));

  const [development, setDevelopment] = useState({ add: false, edit: false, archive: false, delete: false });
  const [community, setCommunity] = useState({ add: false, edit: false, archive: false, delete: false });
  const [masterCommunity, setMasterCommunity] = useState({ add: false, edit: false, archive: false, delete: false });
  const [assignment, setAssignment] = useState({ add: false, edit: false, archive: false, delete: false });
  const [newHomes, setNewHomes] = useState({ add: false, edit: false, archive: false, delete: false });
  const [buyer, setBuyer] = useState({ add: false, edit: false, archive: false, delete: false });
  const [seller, setSeller] = useState({ add: false, edit: false, archive: false, delete: false });
  const [agent, setAgent] = useState({ add: false, edit: false, archive: false, delete: false });
  const [vipAgent, setVipAgent] = useState({ add: false, edit: false, archive: false, delete: false });
  const [associate, setAssociate] = useState({ add: false, edit: false, archive: false, delete: false });
  const [developer, setDeveloper] = useState({ add: false, edit: false, archive: false, delete: false });
  const [marketing, setMarketing] = useState({ add: false, edit: false, archive: false, delete: false });
  const [architect, setArchitect] = useState({ add: false, edit: false, archive: false, delete: false });
  const [brokerage, setBrokerage] = useState({ add: false, edit: false, archive: false, delete: false });
  const [country, setCountry] = useState({ add: false, edit: false, archive: false, delete: false });
  const [province, setProvince] = useState({ add: false, edit: false, archive: false, delete: false });
  const [city, setCity] = useState({ add: false, edit: false, archive: false, delete: false });
  const [neighbourhood, setNeighbourhood] = useState({ add: false, edit: false, archive: false, delete: false });
  const [blogs, setBlogs] = useState({ add: false, edit: false, archive: false, delete: false });
  const [packages, setPackages] = useState({ add: false, edit: false, archive: false, delete: false });
  const [integration, setIntegration] = useState({ add: false, edit: false, archive: false, delete: false });

  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    if (editId) dispatch(getAdminPermission({ user: editId }));
  }, [editId, dispatch]);

  useEffect(() => {
    if (permission) {
      let data = permission?.data?.permissions;
      setDevelopment(data?.development || development);
      setCommunity(data?.community || community);
      setMasterCommunity(data?.masterCommunity || masterCommunity);
      setAssignment(data?.assignment || assignment);
      setNewHomes(data?.newHomes || newHomes);
      setBuyer(data?.buyer || buyer);
      setSeller(data?.seller || seller);
      setAgent(data?.agent || agent);
      setVipAgent(data?.vipAgent || vipAgent);
      setAssociate(data?.associate || associate);
      setDeveloper(data?.developer || developer);
      setMarketing(data?.marketing || marketing);
      setArchitect(data?.architect || architect);
      setBrokerage(data?.brokerage || brokerage);
      setCountry(data?.country || country);
      setProvince(data?.province || province);
      setCity(data?.city || city);
      setNeighbourhood(data?.neighbourhood || neighbourhood);
      setBlogs(data?.blogs || blogs);
      setPackages(data?.packages || packages);
      setIntegration(data?.integration || integration);
    }
  }, [permission]);

  const onFormSubmit = () => {
    let data = {
      development,
      community,
      masterCommunity,
      assignment,
      newHomes,
      buyer,
      seller,
      agent,
      vipAgent,
      associate,
      developer,
      marketing,
      architect,
      brokerage,
      country,
      province,
      city,
      neighbourhood,
      blogs,
      packages,
      integration,
    };

    dispatch(
      editAdminPermissions(
        permission?.data?._id,
        data,
        (res) => {
          handleClose();
        },
        (res) => {}
      )
    );
  };

  const handleClose = () => {
    dispatch(handleResetPermission());
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={view}
        toggle={handleClose}
        className="modal-dialog-centered"
        size="xl"
        fullscreen="xl"
      >
        <ModalBody>
          <form onSubmit={handleSubmit(onFormSubmit)} className="">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h5 className="title pb-0 mb-0">Permissions</h5>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}>
                <Button color="primary" type="submit">
                  Save
                </Button>
                <Button color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
            <div className="mt-4">
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Section
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Add
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Edit
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Archive
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Development
                      </th>
                      {Object.entries(development)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"development-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setDevelopment({ ...development, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"development-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Community
                      </th>
                      {Object.entries(community)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"community-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setCommunity({ ...community, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"community-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Master Community
                      </th>
                      {Object.entries(masterCommunity)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"masterCommunity-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setMasterCommunity({ ...masterCommunity, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"masterCommunity-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Assignment
                      </th>
                      {Object.entries(assignment)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"assignment-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setAssignment({ ...assignment, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"assignment-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        New Homes
                      </th>
                      {Object.entries(newHomes)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"newHomes-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setNewHomes({ ...newHomes, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"newHomes-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Buyer
                      </th>
                      {Object.entries(buyer)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"buyer-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setBuyer({ ...buyer, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"buyer-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Seller
                      </th>
                      {Object.entries(seller)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"seller-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setSeller({ ...seller, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"seller-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Agent
                      </th>
                      {Object.entries(agent)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"agent-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setAgent({ ...agent, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"agent-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Vip Agent
                      </th>
                      {Object.entries(vipAgent)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"vipAgent-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setVipAgent({ ...vipAgent, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"vipAgent-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Associate
                      </th>
                      {Object.entries(associate)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"associate-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setAssociate({ ...associate, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"associate-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Developer
                      </th>
                      {Object.entries(developer)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"developer-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setDeveloper({ ...developer, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"developer-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Marketing Company
                      </th>
                      {Object.entries(marketing)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"marketing-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setMarketing({ ...marketing, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"marketing-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Architect
                      </th>
                      {Object.entries(architect)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"architect-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setArchitect({ ...architect, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"architect-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Brokerage
                      </th>
                      {Object.entries(brokerage)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"brokerage-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setBrokerage({ ...brokerage, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"brokerage-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Country
                      </th>
                      {Object.entries(country)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"country-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setCountry({ ...country, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"country-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Province
                      </th>
                      {Object.entries(province)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"province-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setProvince({ ...province, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"province-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        City
                      </th>
                      {Object.entries(city)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"city-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setCity({ ...city, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"city-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Neighbourhood
                      </th>
                      {Object.entries(neighbourhood)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"neighbourhood-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setNeighbourhood({ ...neighbourhood, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"neighbourhood-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Blogs
                      </th>
                      {Object.entries(blogs)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"blogs-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setBlogs({ ...blogs, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"blogs-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Packages
                      </th>
                      {Object.entries(packages)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"packages-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setPackages({ ...packages, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"packages-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Integration
                      </th>
                      {Object.entries(integration)?.map((li, i) => (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div className="preview-block">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id={"integration-" + li[0]}
                                name={li[0]}
                                checked={li[1]}
                                onChange={(e) => {
                                  setIntegration({ ...integration, [e.target.name]: e.target.checked });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={"integration-" + li[0]}></label>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onFormSubmit}>
            Save
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Permission;
