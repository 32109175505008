import React from "react";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../components/Component";
import AssignmentSettings from "../../components/settings/AssignmentSettings";
import MortgageSettings from "../../components/settings/MortgageSettings";

const Settings = () => {
  return (
    <>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page tag={"h1"}>
              Settings
            </BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <AssignmentSettings />

        <MortgageSettings />
      </div>
    </>
  );
};

export default Settings;

export const MainSettings = ({ title, children, tag }) => {
  return (
    <div style={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "0 1rem", borderRadius: "10px" }}>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle
              page
              tag={tag ? tag : "h2"}
              style={{ fontSize: "1.25rem", fontWeight: "600", lineHeight: "1.5", color: "#656565" }}
            >
              {title}
            </BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>{children}</Block>
    </div>
  );
};
