import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getAllBlogCategories = createAsyncThunk("blog-category/blogCategoryList", async (data) => {
  const response = await getMethod(`/api/admin/blog-category`, data);
  return response;
});

export const getSingleBlogCategory = createAsyncThunk("blog-category/blogCategory", async (slug) => {
  const response = await getMethod(`/api/admin/blog-category/${slug}`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  blogCategories: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
  blogCategory: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
};

const blogCategorySlice = createSlice({
  name: "blog category",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.blogs = action.payload;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    handleBlogCategoryReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.blogCategories = initialState.blogCategories;
      state.blogCategory = initialState.blogCategory;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllBlogCategories.pending, (state) => {
      state.blogCategories.loading = true;
      state.blogCategories.success = false;
      state.blogCategories.error = false;
    });
    builder.addCase(getAllBlogCategories.fulfilled, (state, action) => {
      state.blogCategories.loading = false;
      state.blogCategories.success = true;
      state.blogCategories.error = false;
      state.blogCategories.data = action.payload.data;
      state.blogCategories.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getAllBlogCategories.rejected, (state, action) => {
      state.blogCategories.loading = false;
      state.blogCategories.success = false;
      state.blogCategories.error = action?.error?.message;
    });

    builder.addCase(getSingleBlogCategory.pending, (state) => {
      state.blogCategory.loading = true;
      state.blogCategory.success = false;
      state.blogCategory.error = false;
    });
    builder.addCase(getSingleBlogCategory.fulfilled, (state, action) => {
      state.blogCategory.loading = false;
      state.blogCategory.success = true;
      state.blogCategory.error = false;
      state.blogCategory.data = action.payload.data;
    });
    builder.addCase(getSingleBlogCategory.rejected, (state, action) => {
      state.blogCategory.loading = false;
      state.blogCategory.success = false;
      state.blogCategory.error = action?.error?.message;
    });
  },
});

export const { isLoading, isSuccess, isError, isReset, handleBlogCategoryReset } = blogCategorySlice.actions;

export default blogCategorySlice.reducer;

export const addNewBlogCategory = (data, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/blog-category`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const updateBlogCategory = (id, data, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/blog-category/${id}`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const archiveBlogCategory = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/blog-category/archive/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const restoreBlogCategory = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/blog-category/restore/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const deleteBlogCategory = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/blog-category/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
