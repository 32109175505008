import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod, putMethod } from "../../utils/apiServices";

export const getAdminSettings = createAsyncThunk("settings/adminSettings", async (initial) => {
  const { type } = initial;
  const response = await getMethod(`/api/admin/settings/${type}`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  assignment: null,
  mortgageCalculator: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAdminSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminSettings.fulfilled, (state, action) => {
        let type = action.meta.arg.type;
        state.loading = false;
        if (type === "assignment") state.assignment = action.payload.data;
        if (type === "mortgageCalculator") state.mortgageCalculator = action.payload.data;
      })
      .addCase(getAdminSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default settingsSlice.reducer;

export const updateSettings = (type, data, successCB, errorCB) => async (dispatch) => {
  try {
    const response = await putMethod(`/api/admin/settings/${type}`, data);
    successCB(response);
  } catch (error) {
    errorCB(error);
  }
};
