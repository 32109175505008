import moment from "moment";
import React, { useEffect, useState } from "react";
import { LuArchiveRestore } from "react-icons/lu";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import Swal from "sweetalert2";
import PlaceholderImg from "../../assets/profile_avatar.png";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
  Row,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import DotPagination from "../../components/pagination/DotPaginate";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  deleteMultipleUser,
  deleteMultipleUserPermanently,
  deleteUser,
  deleteUserPermanently,
  getUsers,
  restoreUser,
  restoremultipleUser,
} from "../../redux/Apislices/userSlice";
import { USDollar, getScreenWidth } from "../../utils/Utils";
import { useForm } from "react-hook-form";
import { getcity } from "../../redux/Apislices/citySlice";
import { getAllPayments } from "../../redux/Apislices/paymentSlice";
import { Link } from "react-router-dom";

const statusOptions = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "inative", label: "Inactive" },
  { value: "verified", label: "Verified" },
  { value: "unverified", label: "Unverified" },
];

const itemsPerPageOptions = [
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const bulkActionOptions = [
  { value: "", label: "Bulk Action" },
  { value: "archive", label: "Archive" },
  { value: "delete", label: "Delete" },
];

const bulkRestoreOptions = [
  { value: "", label: "Bulk Action" },
  { value: "restore", label: "Restore" },
  { value: "delete", label: "Delete" },
];

const InvoicesPage = () => {
  const dispatch = useDispatch();
  const { payment, profile } = useSelector((state) => ({ payment: state.payment, profile: state?.auth?.profile }));

  const {
    payments: { data, total, success, error },
  } = payment;
  console.log("data: ", data);

  const [loading, setLoading] = useState(false);

  const [selectedData, setSelectedData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");

  const [limit, setLimit] = useState({ value: 25, label: "25" });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);

  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");

  const [bulkAction, setBulkAction] = useState("");

  const [view, setView] = useState(false);
  const [city, setCity] = useState(null);

  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    if (typeof limit === "object" && limit?.value) setItemPerPage(limit?.value);
    else setItemPerPage(25);
  }, [limit]);

  const handleGetData = async () => {
    dispatch(
      getAllPayments({
        status: typeof status === "object" ? status?.value : status,
        page: currentPage,
        limit: itemPerPage,
        search: onSearchText,
        sort,
        role: "agent",
      })
    );
  };

  //debounce getDevelopmentList for search
  useEffect(() => {
    const timer = setTimeout(
      () => {
        handleGetData();
      },
      onSearchText ? 800 : 200
    );
    return () => clearTimeout(timer);
  }, [dispatch, currentPage, itemPerPage, onSearchText, sort, status]);

  // selects all the products
  const selectorCheck = (e) => {
    // add or remove all the items from development?.development?.data to selectedData
    if (e.currentTarget.checked) {
      setSelectedData(data?.map((item) => item._id));
    } else {
      setSelectedData([]);
    }
  };

  // selects one product
  const onSelectChange = (e, id) => {
    // add or remove the selected item from selectedData
    if (e.currentTarget.checked) {
      setSelectedData([...selectedData, id]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== id));
    }
  };

  // toggle search input
  const toggle = () => setonSearch(!onSearch);

  // bulk action change
  const onActionText = (e) => {
    setBulkAction(e);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleActiveDevelopment = (data) => {
    let payload = {
      ...data,
      type: data?.type,
    };

    if (data?.status === "active") {
      payload.status = "on hold";
    } else if (data?.status === "on hold" || data?.status === "pending") {
      payload.status = "active";
    }

    // dispatch(
    //   editDevelopment(
    //     data?.slug,
    //     payload,
    //     (res) => {
    //       handleGetData();
    //     },
    //     (res) => {
    //       toast.error(res?.message);
    //     }
    //   )
    // );
  };

  const handleBulkAction = (e) => {
    if (bulkAction?.value === "archive") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Archive",
      }).then((result) => {
        if (result.isConfirmed) {
          handleArchiveMultiple(
            () => {
              Swal.fire("Archived!", "Selected Agents has been archived.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteMultiple(
            () => {
              Swal.fire("Deleted!", "Selected Agents has been deleted.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    } else if (bulkAction?.value === "restore") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Restore",
      }).then((result) => {
        if (result.isConfirmed) {
          handleRestoreMultiple(
            () => {
              Swal.fire("Restored!", "Selected Agents has been restored.", "success");
            },
            () => {
              Swal.fire("Error!", "Something went wrong!", "error");
            }
          );
        }
      });
    }
  };

  const handleArchiveMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleUser(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleRestoreMultiple = async (successCB, errorCB) => {
    dispatch(
      restoremultipleUser(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleDeleteMultiple = async (successCB, errorCB) => {
    dispatch(
      deleteMultipleUserPermanently(
        { ids: selectedData },
        (res) => {
          if (res?.success) {
            handleGetData();
            setBulkAction("");
            setSelectedData([]);
            successCB();
          }
        },
        (err) => {
          errorCB();
          toast.error(err?.message);
        }
      )
    );
  };

  const handleArchive = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUser(
            item?._id,
            (res) => {
              console.log("res: ", res);
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Archived!", "Agent has been archived.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleRestore = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Restore",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          restoreUser(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Archived!", "Agent has been restored.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleDelete = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUserPermanently(
            item?._id,
            (res) => {
              if (res?.success) {
                handleGetData();
              }
              Swal.fire("Deleted!", "Agent has been deleted.", "success");
            },
            (err) => {
              Swal.fire("Error!", "Something went wrong!", "error");
              toast.error(err?.message);
            }
          )
        );
      }
    });
  };

  const handleVipAgent = async () => {
    let data = { agent: view, amount: city?.data?.vipCost, city, email: view?.email };
    console.log("data: ", data);
    setLoading(true);
    // dispatch(
    //   generateInvoice(
    //     data,
    //     (res) => {
    //       console.log("res: ", res);
    //       setLoading(false);
    //       setView(false);
    //       setCity(null);
    //     },
    //     (err) => {
    //       console.log("err: ", err);
    //       setLoading(false);
    //     }
    //   )
    // );
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Invoices"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Invoices</BlockTitle>
              <BlockDes className="text-soft">
                {data?.length && success ? <p>You have total {total?.total} invoices.</p> : null}
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Agents</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                {/* Bulk Actions */}
                {selectedData.length ? (
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <RSelect
                          options={
                            (typeof status === "object" && status?.value === "archive") || status === "archive"
                              ? bulkRestoreOptions
                              : bulkActionOptions
                          }
                          className="w-130px"
                          placeholder="Bulk Action"
                          onChange={(e) => onActionText(e)}
                          value={bulkAction}
                        />
                      </div>
                      <div className="btn-wrap">
                        <span className="d-none d-md-block">
                          <Button
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            color="info"
                            outline
                            className="btn-dim"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            Apply
                          </Button>
                        </span>
                        <span className="d-md-none">
                          <Button
                            color="info"
                            outline
                            disabled={selectedData.length && bulkAction?.value ? false : true}
                            className="btn-dim btn-icon"
                            onClick={(e) => {
                              if (selectedData.length && bulkAction?.value) {
                                handleBulkAction(e);
                              }
                            }}
                          >
                            <Icon name="arrow-right"></Icon>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    {/* Search */}
                    <li>
                      <div
                        className="search-content"
                        style={{
                          border: "1px solid #dbdfea",
                          borderRadius: "4px",
                        }}
                      >
                        <Button
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="search"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Search by name or address"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        {onSearchText ? (
                          <Button
                            className="search-submit btn-icon"
                            onClick={() => {
                              setSearchText("");
                            }}
                          >
                            <Icon name="cross"></Icon>
                          </Button>
                        ) : null}
                      </div>
                    </li>

                    <li className="btn-toolbar-sep"></li>

                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            {/* Filter */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  {/* <div className="dot dot-primary"></div> */}
                                  <Icon name="filter-fill"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Invoices</span>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect
                                            options={statusOptions}
                                            onChange={(value) => {
                                              setStatus(value);
                                            }}
                                            value={status}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Items Per Page</label>
                                          <RSelect
                                            options={itemsPerPageOptions}
                                            onChange={(value) => {
                                              setLimit(value);
                                            }}
                                            value={limit}
                                            placeholder="Select"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="dropdown-foot between">
                                    {/* <button type="button" className="btn btn-secondary">
                                      Filter
                                    </button> */}
                                    <span></span>

                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setStatus("");
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            {/* Sort */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="sort-line"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-updated");
                                        }}
                                        className={sort === "recently-updated" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Updated</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("recently-added");
                                        }}
                                        className={sort === "recently-added" ? "text text-primary" : ""}
                                      >
                                        <span>Recently Added</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("oldest");
                                        }}
                                        className={sort === "oldest" ? "text text-primary" : ""}
                                      >
                                        <span>Oldest First</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("asc");
                                        }}
                                        className={sort === "asc" ? "text text-primary" : ""}
                                      >
                                        <span>0-9, A-Z</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSort("desc");
                                        }}
                                        className={sort === "desc" ? "text text-primary" : ""}
                                      >
                                        <span>9-0, Z-A</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap `}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : success && data?.length ? (
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Payment Id</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Date</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Billing For</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Billing Type</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Status</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Payment Mode</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span style={{ fontSize: "16px", color: "#5d5d5d", fontWeight: "600" }}>Amount</span>
                  </DataTableRow>
                  <DataTableRow></DataTableRow>
                  <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                </DataTableHead>
                {data?.length
                  ? data?.map((item) => {
                      return (
                        <DataTableItem key={item._id}>
                          <DataTableRow>
                            <span className="">{item?.invoiceData?.number}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{moment(item?.updatedAt).format("DD/MM/YYYY")}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{item?.invoiceData?.metadata?.billingFor}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{item?.invoiceData?.metadata?.billingType}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-odr-status">
                              <Badge
                                color={
                                  item?.invoiceData?.status === "paid"
                                    ? "success"
                                    : ["uncollectable", "void"]?.includes(item?.invoiceData?.status)
                                    ? "danger"
                                    : "warning"
                                }
                                style={{ textTransform: "capitalize" }}
                                className="badge-dot"
                              >
                                {item?.invoiceData?.status}
                              </Badge>
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{item?.method === "stripe" ? "Stripe Card" : null}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="tb-sub">{USDollar(item?.invoiceData?.subtotal / 100)}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="tb-odr-btns d-none d-sm-inline">
                              {/* <Link to={`${process.env.PUBLIC_URL}/invoice-print/${item.id}`} target="_blank">
                                <Button color="primary" size="sm" className="btn-icon btn-white btn-dim">
                                  <Icon name="printer-fill"></Icon>
                                </Button>
                              </Link> */}
                              <Link to={`/invoice/${item._id}`}>
                                <Button color="primary" size="sm" className="btn btn-dim">
                                  View
                                </Button>
                              </Link>
                            </div>
                            <Link to={`${process.env.PUBLIC_URL}/invoice-details/${item.id}`}>
                              <Button className="btn-pd-auto d-sm-none">
                                <Icon name="chevron-right"></Icon>
                              </Button>
                            </Link>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
            ) : success && !data?.length ? (
              <div className="text-center" style={{ padding: "20px", fontSize: "1rem", fontWeight: "600" }}>
                <span className="text-silent">No Invoices found</span>
              </div>
            ) : error ? (
              <ErrorComponent error={error} />
            ) : null}

            <div className="card-inner p-0">
              {success && data?.length ? (
                <DotPagination
                  itemPerPage={total?.items_per_page}
                  totalItems={total?.total}
                  paginate={paginate}
                  currentPage={currentPage}
                  siblingCount={2}
                  screenWidth={screenWidth}
                />
              ) : null}
            </div>
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default InvoicesPage;
