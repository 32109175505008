import clsx from "clsx";
import parse from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaEdit } from "react-icons/fa";
import { MdModeEditOutline, MdOutlineDelete, MdOutlineInfo, MdUpdate } from "react-icons/md";
import styles from "./dev.module.scss";
import JoditPreview from "../../pages/components/forms/rich-editor/JoditPreview";
import { Button, Col, Icon, Row } from "../Component";
import { toast } from "react-toastify";
import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const SpecialIncentivesSection = ({
  specialIncentive,
  setSpecialIncentive,
  incentiveDetail,
  setIncentiveDetail,
  incentiveStart,
  setIncentiveStart,
  incentiveEnd,
  setIncentiveEnd,
  incentiveArray,
  setIncentiveArray,
  incentStart,
  incentiveError,
  setIncentiveError,
}) => {
  const [show, setShow] = useState(5);
  const [array, setArray] = useState([]);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    if (incentiveArray?.length) {
      setArray(incentiveArray?.slice(0, show));
    }
  }, [incentiveArray, show]);

  return (
    <>
      <div className={clsx(styles.askingPriceDiv)}>
        <p className={clsx(styles.heightPara)}>{`Special Incentives`}</p>
        <Row>
          <Col sm={"12"} md={"6"}>
            <div className={styles.checkDiv}>
              <input
                type="checkbox"
                id="specialIncentive"
                name="specialIncentive"
                checked={specialIncentive}
                onChange={(e) => {
                  setSpecialIncentive(e.target.checked);
                }}
              />
              <label htmlFor="specialIncentive" className="form-check-label">
                Available
              </label>
            </div>

            {/* if avialable then show its details and validity */}
            {specialIncentive ? (
              <Row>
                <Col size={"12"}>
                  <JoditPreview
                    initialValue={incentiveDetail}
                    getValue={setIncentiveDetail}
                    placeholder={"Incentives Details"}
                  />
                </Col>
                <Col sm={"6"}>
                  <div className={clsx(styles.askingPriceDiv)}>
                    <p className={clsx(styles.heightPara)}>Incentive Start*</p>
                    <DatePicker
                      selected={incentiveStart}
                      onChange={(date) => {
                        setIncentiveError(false);
                        setIncentiveStart(date);
                      }}
                      isClearable
                      placeholderText="mm/dd/yyyy"
                      className="form-control date-picker"
                      wrapperClassName={clsx(incentiveError && styles.errorDate)}
                    />
                  </div>
                </Col>
                <Col sm={"6"}>
                  <div className={clsx(styles.askingPriceDiv)}>
                    <p className={clsx(styles.heightPara)}>Incentive End</p>
                    <DatePicker
                      selected={incentiveEnd}
                      className="form-control date-picker"
                      isClearable
                      placeholderText="mm/dd/yyyy"
                      minDate={incentiveStart}
                      onChange={(date) => {
                        setIncentiveEnd(date);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm={"12"} md={"6"}>
            {array?.length ? (
              <div className={styles.specialDiv}>
                <h5>History</h5>
                {array?.map((li, i) => (
                  <div key={i}>
                    <div>
                      <div className={styles.specialDetail}>{parse(li?.values)}</div>
                      <p>
                        ({moment(li?.start)?.format("DD MMM, YYYY")} -{" "}
                        {li?.end ? (
                          moment(li?.end)?.format("DD MMM, YYYY")
                        ) : !li?.end && i === 0 ? (
                          <span>Not Specifed</span>
                        ) : (
                          "Incentive Ended"
                        )}
                        )
                      </p>
                    </div>
                    {i === 0 ? (
                      <div className={styles.ctaBtn}>
                        <Button
                          color={"info"}
                          startIcon={<MdModeEditOutline style={{ fontSize: "16px" }} />}
                          onClick={() => {
                            setEditData(li?._id || li?.editId);
                          }}
                        >
                          Edit
                        </Button>
                        {li?.end ? null : (
                          <Button
                            color={"danger"}
                            startIcon={<MdUpdate style={{ fontSize: "20px" }} />}
                            onClick={() => {
                              let temp = [...incentiveArray];
                              temp[i] = {
                                ...temp[i],
                                end: new Date().toUTCString(),
                              };
                              setIncentiveArray([...temp]);
                            }}
                          >
                            End
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}

            {incentiveArray?.length > 5 && show < incentiveArray?.length ? (
              <span
                style={{
                  color: "#00aeff",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShow(incentiveArray?.length);
                }}
              >
                Show More
              </span>
            ) : incentiveArray?.length > 5 && show > 5 ? (
              <span
                style={{
                  color: "#00aeff",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShow(5);
                }}
              >
                Show Less
              </span>
            ) : null}
          </Col>
        </Row>
      </div>

      <EditModal
        show={editData ? true : false}
        editId={editData}
        onHide={() => {
          setEditData(null);
        }}
        incentiveArray={incentiveArray}
        setIncentiveArray={setIncentiveArray}
        incentiveError={incentiveError}
        setIncentiveError={setIncentiveError}
      />
    </>
  );
};

const EditModal = (props) => {
  const [data, setData] = useState(false);

  const [details, setDetails] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [startError, setStartError] = useState(false);

  useEffect(() => {
    if (props?.incentiveArray?.length) {
      let temp = props?.incentiveArray?.filter((li) => {
        let id = li?._id || li?.editId;
        return id === props?.editId;
      });
      setData(temp[0]);
    }
  }, [props?.incentiveArray, props?.editId]);

  useEffect(() => {
    if (data) {
      setDetails(data?.values);
      setStart(data?.start ? new Date(data?.start) : "");
      setEnd(data?.end ? new Date(data?.end) : "");
    }
  }, [data]);

  const handleSubmit = () => {
    if (!details) {
      toast.error("Please enter incentive details");
      return;
    } else if (!start) {
      setStartError(true);
      toast.error("Please enter incentive start date");
      return;
    }
    let temp = [...props?.incentiveArray];
    let index = temp.findIndex((li) => {
      let id = li?._id || li?.editId;
      return id === props?.editId;
    });
    temp[index] = {
      ...temp[index],
      values: details,
      start: start,
      end: end,
    };
    props.setIncentiveArray([...temp]);
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        isOpen={props.show}
        toggle={props.onHide()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={props.onHide()}>Edit Incentive</ModalHeader>
        <ModalBody>
          {data ? (
            <>
              <Row>
                <Col size={"12"}>
                  <JoditPreview initialValue={details} getValue={setDetails} placeholder={"Incentives Details"} />
                </Col>
                <Col sm={"6"}>
                  <div className={clsx(styles.askingPriceDiv)}>
                    <p className={clsx(styles.heightPara)}>Incentive Start*</p>
                    <DatePicker
                      selected={start}
                      onChange={(date) => {
                        setStartError(false);
                        setStart(date);
                      }}
                      isClearable
                      placeholderText="mm/dd/yyyy"
                      className="form-control date-picker"
                      wrapperClassName={clsx(startError && styles.errorDate)}
                    />
                  </div>
                </Col>
                <Col sm={"6"}>
                  <div className={clsx(styles.askingPriceDiv)}>
                    <p className={clsx(styles.heightPara)}>Incentive End</p>
                    <DatePicker
                      selected={end}
                      className="form-control date-picker"
                      isClearable
                      placeholderText="mm/dd/yyyy"
                      minDate={start}
                      onChange={(date) => {
                        setEnd(date);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color={"secondary"} style={{ fontSize: "14px", marginRight: "15px" }} onClick={props.onHide}>
            Close
          </Button>
          <Button
            color={"primary"}
            style={{ fontSize: "14px" }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SpecialIncentivesSection;
