import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  Row,
} from "../../../components/Component";
import { Card } from "../../../components/Draggable/Card";
import {
  addUnitPreference,
  deleteUnitPreference,
  getUnitPreference,
  updateUnitPreference,
} from "../../../redux/Apislices/unitPreferenceSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImg from "../../../assets/placeholder-img.png";
import { useDropzone } from "react-dropzone";
import { deleteFile, uploadFile } from "../../../redux/Apislices/awsSlice";
import { convertToSlug } from "../../../utils/Utils";

const UnitPreferencePage = () => {
  const dispatch = useDispatch();
  const { unitPreference: data, loading } = useSelector((state) => state.unitPreference);

  const [sm, updateSm] = useState(false);

  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");

  const [view, setView] = useState({ add: false, edit: false });
  const [editId, setEditId] = useState(null);

  const [trigger, setTrigger] = useState(false);

  const toggle = (view) => {
    view === "add"
      ? setView({ add: !view.add, edit: false })
      : view === "edit"
      ? setView({ add: false, edit: !view.edit })
      : setView({ add: false, edit: false });
    setName("");
    setIcon("");
  };

  useEffect(() => {
    if (trigger) setTrigger(false);
  }, [trigger]);

  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getUnitPreference());
  }, [dispatch]);

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
      setTrigger(true);
    }, 3000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          setIcon(res?.data?.[0]?.url);
          toast.success(res?.message);
          setTrigger(true);
          toast.dismiss(toastId);
        },
        (res) => {
          toast.error(res?.message);
          toast.dismiss(toastId);
        }
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImage = acceptedFiles.map((file, index) => {
        let imgName = convertToSlug(name);

        const newName = `${imgName ? imgName : "unit-preference"}-${uuid()?.slice(0, 8)}.${file.name.split(".").pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImage.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("path", "admin/unit-preferences");
      uploadImage(formData);
    },
    [name]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  const handleIconDelete = async (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 3000);
    dispatch(
      deleteFile(
        { path },
        (res) => {
          setIcon("");
          toast.success(res?.message);
          toast.dismiss(toastId);
        },
        () => {
          toast.error("Failed to delete image");
          toast.dismiss(toastId);
        }
      )
    );
  };
  const handleAdd = () => {
    let data = { name, icon };

    dispatch(
      addUnitPreference(
        data,
        (res) => {
          dispatch(getUnitPreference());
          toggle();
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item._id === id) {
        setName(item.name);
        setIcon(item.icon);
        setEditId(id);
      }
    });
  };

  const handleEdit = () => {
    let data = { name, icon };

    dispatch(
      updateUnitPreference(
        editId,
        data,
        (res) => {
          toast.success(res?.message);
          dispatch(getUnitPreference());
          toggle();
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleDelete = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        if (item?.icon) {
          dispatch(
            deleteFile(
              { path: item.icon },
              (res) => {
                dispatch(
                  deleteUnitPreference(
                    item._id,
                    (res) => {
                      dispatch(getUnitPreference());
                      toast.success(res?.message);
                      toggle();
                    },
                    (res) => {
                      toast.error(res?.message);
                    }
                  )
                );
              },
              () => {
                toast.error("Failed to delete image");
              }
            )
          );
        } else {
          dispatch(
            deleteUnitPreference(
              item._id,
              (res) => {
                dispatch(getUnitPreference());
                toast.success(res?.message);
                toggle();
              },
              (res) => {
                toast.error(res?.message);
              }
            )
          );
        }
      }
    });
  };

  return (
    <div>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page tag="h1">
              Unit Preferences
            </BlockTitle>
            {data?.length ? (
              <BlockDes className="text-soft">List of total {data.length} unit preferences</BlockDes>
            ) : null}
          </BlockHeadContent>

          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="more-v" />
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                    <Button
                      color="primary"
                      onClick={() => {
                        toggle("add");
                      }}
                    >
                      <Icon name="plus" />
                      <span>Add Unit preferences</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block className={"mt-4"}>
        {loading ? (
          <div
            style={{
              height: "200px",
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spinner type="grow" color="dark" />
          </div>
        ) : data?.length ? (
          <div className="cardContainer">
            {data?.map((item, index) => (
              <div key={index} className="">
                <div className="preferenceDiv">
                  <span className="tb-product lazy-image">
                    <LazyLoadImage
                      src={item?.icon ? `${process.env.REACT_APP_IMGURL}${item.icon}` : PlaceholderImg}
                      alt="unit_preference_image"
                      effect="blur"
                      className="thumb"
                      placeholderSrc={PlaceholderImg}
                      style={{ width: "70px", height: "45px", borderRadius: "4px", objectFit: "contain" }}
                    />
                  </span>
                  <p>{item.name}</p>
                </div>
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                      <Icon name="setting"></Icon>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-xs">
                      <ul className="link-check">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle("edit");
                              onEditClick(item._id);
                            }}
                          >
                            <Icon name="edit"></Icon>
                            <span>Edit</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              handleDelete(item);
                            }}
                          >
                            <Icon name="trash"></Icon>
                            <span>Delete</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </Block>

      {/* Add & Edit */}
      <Modal
        isOpen={view.add || view.edit}
        toggle={() => {
          setName("");
          toggle();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                setName("");
                toggle();
              }}
            ></Icon>
          </a>

          <div className="p-2">
            <h5 className="title">{view.edit ? "Edit Amenity" : "Add Amenity"}</h5>
            <div className="mt-4">
              <form onSubmit={handleSubmit(view.edit ? handleEdit : handleAdd)}>
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Name*
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={(e) => {
                            const str = e.target.value;
                            const con = str
                              .split(" ")
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                              .join(" ");
                            setName(con);
                          }}
                          autoFocus={view?.add || view?.edit ? true : false}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Icon
                      </label>
                      <div className="form-control-wrap">
                        {icon && !trigger ? (
                          <div className={"dropzonePreview"}>
                            <img src={`${process.env.REACT_APP_IMGURL}${icon}`} alt="preview" />
                            <div>
                              <Icon name="cross" onClick={() => handleIconDelete(icon)} />
                            </div>
                          </div>
                        ) : (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dropzone upload-zone dz-clickable"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <input {...getInputProps()} />

                              <div className="dz-message">
                                <span className="dz-message-text">Drag and drop file</span>
                                <span className="dz-message-or">or</span>
                                <Button color="primary" type={"button"}>
                                  SELECT
                                </Button>
                              </div>
                            </div>
                          </section>
                        )}
                      </div>
                    </div>
                  </Col>

                  <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

                  <Col size="12">
                    <Button color="primary" type="submit">
                      <span>{"Save"}</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UnitPreferencePage;
