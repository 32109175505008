import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../../components/Component";
import { countryOptions, userData } from "./UserData";
import { getDateStructured } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateOwnProfile } from "../../../redux/Apislices/userSlice";
import { fetchProfileData } from "../../../redux/Apislices/authSlice";
import { useDispatch } from "react-redux";

const UserProfileRegularPage = ({ setProfileName }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => ({ profile: state?.auth?.profile }));
  const [sm, updateSm] = useState(false);

  const [modalTab, setModalTab] = useState("1");
  const [userInfo, setUserInfo] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    mobile: "",
    email: "",
    role: "",
  });
  const [modal, setModal] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth >= 990) {
      updateSm(false);
    }
  };

  useEffect(() => {
    if (profile) {
      setFormData({
        ...formData,
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        role: profile?.role,
        username: profile?.username,
        email: profile?.email,
        mobile: profile?.mobile,
      });
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = () => {
    let submitData = {
      ...formData,
    };
    dispatch(
      updateOwnProfile(
        submitData,
        (res) => {
          fetchProfileData();
          toast.success("Profile updated successfully");
          window.location.reload();
        },
        (res) => {
          toast.error("Failed to update profile");
        }
      )
    );
  };

  return (
    <React.Fragment>
      <Head title={`${formData?.firstName} ${formData?.lastName} - Profile`}></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <form className="nk-data data-list" onSubmit={handleSubmit(submitForm)}>
          <div className="row g-4">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="product-title">
                  First Name*
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={formData?.firstName}
                    onChange={onInputChange}
                    ref={register({ required: "This field is required" })}
                  />
                  {errors.firstName && <span className="invalid">{errors.firstName.message}</span>}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="product-title">
                  Last Name*
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={formData?.lastName}
                    onChange={onInputChange}
                    ref={register({ required: "This field is required" })}
                  />
                  {errors.lastName && <span className="invalid">{errors.lastName.message}</span>}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="product-title">
                  Username*
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={formData?.username}
                    onChange={onInputChange}
                    ref={register({ required: "This field is required" })}
                  />
                  {errors.username && <span className="invalid">{errors.username.message}</span>}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="product-title">
                  Email*
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control disabled-input"
                    name="email"
                    value={formData?.email}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="product-title">
                  Mobile
                </label>
                <div className="form-control-wrap">
                  <PhoneInput
                    country={"ca"}
                    value={formData?.mobile}
                    onChange={(value, data, event, formattedValue) => {
                      setFormData({ ...formData, mobile: formattedValue });
                    }}
                    inputStyle={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="product-title">
                  Role
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control disabled-input"
                    name="email"
                    value={formData?.role}
                    style={{ textTransform: "capitalize" }}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 data-col-end">
              <Button
                color="primary"
                type="submit"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1rem",
                }}
              >
                <span>Update</span>
              </Button>
            </div>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
