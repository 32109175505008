import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getneighbourhood = createAsyncThunk("neighbourhood/fetchNeighbourhood", async (query) => {
  const response = await getMethod(`/api/neighbourhood`, query);
  return response;
});

export const getAllNeighbourhood = createAsyncThunk("neighbourhood/fetchAllNeighbourhood", async (query) => {
  const response = await getMethod(`/api/neighbourhood`);
  return response;
});

export const getneighbourhoodByCity = createAsyncThunk("neighbourhood/fetchNeighbourhoodByCity", async (id) => {
  const response = await getMethod(`/api/neighbourhood-city/${id}`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  allNeighbourhoods: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
  neighbourhoods: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
  neighbourhoodByCity: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
};

const neighbourhoodSlice = createSlice({
  name: "neighbourhood",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    neighbourhoodReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getneighbourhood.pending, (state) => {
      state.neighbourhoods.loading = true;
      state.neighbourhoods.success = false;
      state.neighbourhoods.error = false;
      state.neighbourhoods.data = null;
      state.neighbourhoods.total = null;
    });
    builder.addCase(getneighbourhood.fulfilled, (state, action) => {
      state.neighbourhoods.loading = false;
      state.neighbourhoods.success = true;
      state.neighbourhoods.error = false;
      state.neighbourhoods.data = action.payload.data;
      state.neighbourhoods.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getneighbourhood.rejected, (state, action) => {
      state.neighbourhoods.loading = false;
      state.neighbourhoods.success = false;
      state.neighbourhoods.data = null;
      state.neighbourhoods.total = null;
      state.neighbourhoods.error = action.error.message;
    });

    builder
      .addCase(getAllNeighbourhood.pending, (state) => {
        state.allNeighbourhoods.loading = true;
        state.allNeighbourhoods.success = false;
        state.allNeighbourhoods.error = false;
        state.allNeighbourhoods.data = null;
        state.allNeighbourhoods.total = null;
      })
      .addCase(getAllNeighbourhood.fulfilled, (state, action) => {
        state.allNeighbourhoods.loading = false;
        state.allNeighbourhoods.success = true;
        state.allNeighbourhoods.error = false;
        state.allNeighbourhoods.data = action.payload.data;
        state.allNeighbourhoods.total = action.payload.data?.length;
      })
      .addCase(getAllNeighbourhood.rejected, (state, action) => {
        state.allNeighbourhoods.loading = false;
        state.allNeighbourhoods.success = false;
        state.allNeighbourhoods.data = null;
        state.allNeighbourhoods.total = null;
        state.allNeighbourhoods.error = action.error.message || true;
      });

    builder
      .addCase(getneighbourhoodByCity.pending, (state) => {
        state.neighbourhoodByCity.loading = true;
        state.neighbourhoodByCity.success = false;
        state.neighbourhoodByCity.error = false;
        state.neighbourhoodByCity.data = null;
        state.neighbourhoodByCity.total = null;
      })
      .addCase(getneighbourhoodByCity.fulfilled, (state, action) => {
        state.neighbourhoodByCity.loading = false;
        state.neighbourhoodByCity.success = true;
        state.neighbourhoodByCity.error = false;
        state.neighbourhoodByCity.data = action.payload.data;
        state.neighbourhoodByCity.total = {
          total: action?.payload?.total,
          total_pages: action?.payload?.total_pages,
          items_per_page: action?.payload?.items_per_page,
        };
      })
      .addCase(getneighbourhoodByCity.rejected, (state, action) => {
        state.neighbourhoodByCity.loading = false;
        state.neighbourhoodByCity.success = false;
        state.neighbourhoodByCity.data = null;
        state.neighbourhoodByCity.total = null;
        state.neighbourhoodByCity.error = action.error.message;
      });
  },
});

export const { isLoading, isSuccess, isError, neighbourhoodReset } = neighbourhoodSlice.actions;

export default neighbourhoodSlice.reducer;

export const addNeighbourhood = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/neighbourhood`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const updateNeighbourhood = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/neighbourhood/${id}`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const deleteNeighbourhood = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/neighbourhood/${id}`);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};
