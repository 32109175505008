import Axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

//APP_URL for api front url defined
const AxiosInstance = Axios.create({
  baseURL: API_URL,
  timeout: process.env.REACT_APP_MODE === "development" ? 60000 : 30000,
  headers: {
    // withCredentials:true,
    //  'Content-Type': 'application/json',
    //  'Accept':'application/json',
    //  "Access-Control-Allow-Origin": "*",
    //  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  },
});
//here axios instance create

const setAuthorizationToken = (True) => {
  AxiosInstance.defaults.withCredentials = true;
  // if(lang){
  //     AxiosInstance.defaults.headers.common.locale = lang;
  // }
};

//set token for authorization

async function postMethod(endpoint, data) {
  return new Promise((resolve) => {
    var config = {
      method: "post",
      url: endpoint,
      data: data,
    };
    AxiosInstance(config).then(
      (response) => {
        // console.log("solve==>",response)
        resolve(response.data);
      },
      (error) => {
        if (error?.response?.data) {
          console.log("err1==>", error);
          resolve(error?.response?.data ? error?.response?.data : { success: false, message: "failed!" });
        } else {
          console.log("err2==>", error);
          resolve({ success: false, message: "failed!" });
        }
      }
    );
  });
}
//postmethod create function

async function getMethod(path, params = {}) {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: path,
      params: params,
    };

    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (!error.response || error.code === "ECONNABORTED") {
          reject({ success: false, message: "error!" });
        } else {
          reject(
            error?.response?.data
              ? { ...error?.response?.data, status: error?.response?.status }
              : { success: false, message: "failed!" }
          );
        }
      }
    );
  });
}
//getmethod create function

async function putMethod(endpoint, data) {
  return new Promise((resolve) => {
    var config = {
      credentials: "include",
      method: "put",
      url: endpoint,
      data: data,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (!error.response || error.code === "ECONNABORTED") {
          resolve({ success: false, message: "error!" });
        } else {
          resolve(error?.response?.data ? error?.response?.data : { success: false, message: "failed!" });
        }
      }
    );
  });
}

async function deleteMethod(endpoint, data) {
  return new Promise((resolve) => {
    var config = {
      method: "delete",
      url: endpoint,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        resolve(error.response.data);
      }
    );
  });
}

//putmethod create function
export { postMethod, setAuthorizationToken, getMethod, putMethod, deleteMethod, API_URL };
