import React, { useEffect, useState } from "react";
import { MainSettings } from "../../pages/Settings/Settings";
import { Col, Row } from "../Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAdminSettings, updateSettings } from "../../redux/Apislices/settings";
import { toast } from "react-toastify";

const AssignmentSettings = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => ({ settings: state?.settings?.assignment?.assignment }));
  console.log("settings: ", settings);

  const [showPayment, setShowPayment] = useState(false);

  useEffect(() => {
    dispatch(getAdminSettings({ type: "assignment" }));
  }, [dispatch]);

  useEffect(() => {
    if (settings) setShowPayment(settings.showPayment);
  }, [settings]);

  const handleChange = (e) => {
    let toastId = toast.loading("updating...");

    let data = { assignment: { showPayment: e.target.checked } };

    dispatch(
      updateSettings(
        "assignment",
        data,
        (res) => {
          dispatch(getAdminSettings({ type: "assignment" }));
          toast.dismiss(toastId);
          toast.success("Updated successfully");
        },
        () => {
          toast.dismiss(toastId);
          toast.error("Failed to update");
        }
      )
    );
  };

  return (
    <MainSettings title={"Assignment"}>
      <Row>
        <Col sm={12}>
          <div className="preview-block">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input form-control"
                id="customSwitch1"
                checked={showPayment}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <label className="custom-control-label" htmlFor="customSwitch1">
                Show assignment payment page
              </label>
            </div>
          </div>
        </Col>
      </Row>
    </MainSettings>
  );
};

export default AssignmentSettings;
