import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod, setAuthorizationToken } from "../../utils/apiServices";

export const fetchProfileData = createAsyncThunk("user/fetchProfileData", async () => {
  setAuthorizationToken(true);
  try {
    const response = await getMethod("/api/admin/profile");
    return response;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  status: "idle",
  loading: false,
  success: false,
  error: false,
  profile: null,
  user: null,
  token: null,
  isOtpSend: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },

    isSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    otpSend: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.isOtpSend = action.payload;
    },

    login: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.token = action.payload;
    },

    getUser: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.user = action.payload;
    },

    logout: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.token = null;
      state.user = null;
    },
    isError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchProfileData.pending, (state) => {
      state.status = "loading";
      state.loading = true;
      state.success = false;
      state.profile = null;
      state.error = false;
    });
    builder.addCase(fetchProfileData.fulfilled, (state, action) => {
      state.status = "success";
      state.loading = false;
      state.success = true;
      state.profile = action.payload.data;
    });
    builder.addCase(fetchProfileData.rejected, (state, action) => {
      state.status = "failed";
      state.loading = false;
      state.success = false;
      state.profile = null;
      state.error = action?.error?.message;
    });
  },
});

export default authSlice.reducer;

export const { isLoading, isSuccess, isError, otpSend, login, getUser, logout } = authSlice.actions;
