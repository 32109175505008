import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button } from "../../components/Component";
import Head from "../../layout/head/Head";
import { useParams } from "react-router-dom";
import ComHeader from "../../components/Community/ComHeader";
import { useDispatch } from "react-redux";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { useSelector } from "react-redux";
import { getSingleCommunity, handleComStep } from "../../redux/Apislices/communitySlice";
import Community1 from "../../components/Community/Community1";
import Community2 from "../../components/Community/Community2";
import Community3 from "../../components/Community/Community3";
import { useLoadScript } from "@react-google-maps/api";
import { Spinner } from "reactstrap";

const libraries = ["places"];

const AddCommunity = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  const { type, id } = useParams();

  const dispatch = useDispatch();
  const { community } = useSelector((state) => state);

  const { addCommunity: stepData } = community;
  console.log("commData:==> ", stepData);

  const [activeIconTab, setActiveIconTab] = useState(1);

  useEffect(() => {
    if (type === "edit" && id) {
      dispatch(getSingleCommunity({ slug: id }));
    }
  }, [dispatch, id, type, activeIconTab]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(handleComStep({ step: activeIconTab, maxStep: 3 }));
    }
  }, [type, activeIconTab, dispatch]);

  const toggleIconTab = (icontab) => {
    if (activeIconTab === icontab) {
      return;
    } else {
      let maxStep;
      if (stepData?.maxStep < icontab) maxStep = icontab;
      setActiveIconTab(icontab);
      dispatch(handleComStep({ step: icontab, maxStep: maxStep ? maxStep : stepData?.maxStep }));
    }
  };

  const toggleBack = () => {
    if (activeIconTab > 1) {
      setActiveIconTab(activeIconTab - 1);
      dispatch(handleComStep({ step: activeIconTab - 1, maxStep: stepData?.maxStep }));
    }
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleNext = () => {
    let maxStep;

    if (stepData?.maxStep < activeIconTab + 1) {
      maxStep = activeIconTab + 1;
    }

    if (activeIconTab < 4) {
      setActiveIconTab(activeIconTab + 1);
      dispatch(handleComStep({ step: activeIconTab + 1, maxStep: maxStep ? maxStep : stepData?.maxStep }));
    }
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (stepData?.step) {
      setActiveIconTab(stepData?.step);
    }
  }, [stepData?.step]);

  useEffect(() => {
    dispatch(getHomeType());
  }, [dispatch]);

  if (loadError) return "Error...";
  if (!isLoaded)
    return (
      <div className="loadingDiv" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spinner type="grow" color="dark" style={{ width: "50px", height: "50px" }} />
      </div>
    );

  return (
    <>
      <Head title={type === "add" ? "Add Master Community" : type === "edit" ? "Edit Master Community" : ""} />

      <>
        {activeIconTab === 1 ? (
          <Community1
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 2 ? (
          <Community2
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 3 ? (
          <Community3
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : null}
      </>
    </>
  );
};

export default AddCommunity;
