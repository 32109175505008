/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addListing, editListing } from "../../redux/Apislices/listingSlice";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import FooterBtn from "../common/FooterBtn";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
} from "../Component";
import { Card } from "reactstrap";
import AssignNavTab from "./AssignNavTab";
import clsx from "clsx";
import styles from "./assignment.module.scss";
import { deleteFile, deleteMultipleFiles, uploadFile } from "../../redux/Apislices/awsSlice";
import { editDevelopment } from "../../redux/Apislices/developmentSlice";
import { convertToSlug } from "../../utils/Utils";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getcity } from "../../redux/Apislices/citySlice";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";
import Main from "../Draggable/Main";
import { IoImagesOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Assignment5 = ({ formStep, nextFormStep, prevFormStep, stepGoTo }) => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const pathArray = window.location.pathname.split("/");
  const mainPath = pathArray[1];
  const dispatch = useDispatch();
  const { listing, homeTypeData, city } = useSelector((state) => ({
    listing: state?.listing?.listingById?.data,
    homeTypeData: state?.homeType,
    city: state?.city?.cities?.data,
  }));

  const [assignmentData, setAssignmentData] = useState(false);

  const [mediaArray, setMediaArray] = useState([]);
  const [coverImg, setCoverImg] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);
  const [planImage, setPlanImage] = useState([]);
  const [planUploadArray, setPlanUploadArray] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [vTour, setVTour] = useState("");
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (trigger) setTrigger(false);
  }, [trigger]);

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
      setTrigger(true);
    }, 10000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (type === "edit") {
            let img = [];
            if (res?.data) img = res?.data?.map((li) => li?.url);
            let images = [...mediaArray];
            let data = { ...assignmentData, images: [...images, ...img] };
            dispatch(
              editListing(
                id,
                data,
                (res) => {
                  setMediaArray(res?.data?.images);
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                  setTrigger(true);
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            if (getSession("assign-media")) {
              let image = getSession("assign-media");
              image = [...image, ...res?.data?.map((li) => li?.url)];
              setSession("assign-media", image);
              toast.dismiss(toastId);
              setMediaArray(image);
              setTrigger(true);
              toast.success("Image(s) Uploaded");
            } else if (!getSession("assign-media")) {
              if (!mediaArray?.length) {
                setSession("assign-media", [...res?.data?.map((li) => li?.url)]);
                setMediaArray([...res?.data?.map((li) => li?.url)]);
              } else {
                setSession("assign-media", [...mediaArray, ...res?.data?.map((li) => li?.url)]);
                setMediaArray([...mediaArray, ...res?.data?.map((li) => li?.url)]);
              }
              setTrigger(true);
              toast.dismiss(toastId);
              toast.success("Image(s) Uploaded");
            }
          }
        },
        (res) => {
          toast.error(res?.message);
          toast.dismiss(toastId);
        }
      )
    );
    // }
  };

  const handleDelete = async (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit") {
            let data = { ...assignmentData };
            data = { ...data, images: mediaArray?.filter((li) => li !== path) };
            dispatch(
              editListing(
                id,
                data,
                (res) => {
                  setMediaArray(res?.data?.images);
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let a = mediaArray.findIndex((li) => li === path);
            if (a !== -1) {
              mediaArray.splice(a, 1);
              setMediaArray([...mediaArray]);
              setSession("assign-media", mediaArray);
            }
            toast.dismiss(toastId);
            toast.success("Image Deleted");
          }
        },
        () => {}
      )
    );
  };

  const handleMultiple = (e) => {
    if (e.target.checked) {
      setDeleteArray([...deleteArray, e.target.value]);
    } else {
      let a = deleteArray?.filter((li) => li !== e.target.value);
      setDeleteArray(a);
    }
  };

  const deleteMultiples = async () => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    dispatch(
      deleteMultipleFiles(
        { paths: deleteArray },
        (res) => {
          if (type === "edit") {
            let data = { ...assignmentData, images: mediaArray?.filter((li) => !deleteArray?.includes(li)) };
            dispatch(
              editListing(
                id,
                data,
                (res) => {
                  setMediaArray(res?.data?.images);
                  setDeleteArray([]);
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let ab = mediaArray?.filter((li) => !deleteArray?.includes(li));
            setMediaArray([...ab]);
            setSession("assign-media", ab);
            toast.dismiss(toastId);
            toast.success("Images Deleted");
            setDeleteArray([]);
          }
        },
        () => {
          toast.dismiss(toastId);
        }
      )
    );
  };

  const handleCoverImg = (item, index) => {
    setCoverImg(item);
    const updatedArray = [...mediaArray];
    const elementToMove = updatedArray[index];
    updatedArray.splice(index, 1);
    updatedArray.splice(0, 0, elementToMove);
    setMediaArray(updatedArray);
    if (type === "edit") {
      let data = {
        ...assignmentData,
        images: updatedArray,
        projectType: mainPath === "assignment" ? "assignment" : "resale",
      };
      if (videoUrl) data = { ...data, video: videoUrl };
      if (vTour) data = { ...data, vtour: vTour };
      dispatch(
        editDevelopment(
          id,
          data,
          (result) => {
            setAssignmentData(result?.data);
            toast.success("Cover Image Set");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      setSession("dev-aws", updatedArray);
      toast.success("Cover Image Set");
    }
  };

  useEffect(() => {
    if (mediaArray?.length) setCoverImg(mediaArray?.[0]);
  }, [mediaArray, coverImg]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          console.log("err: ", err);
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImages = acceptedFiles.map((file, index) => {
        let devName = convertToSlug(assignmentData?.title);

        let cityName = city
          ?.find((li) => li?._id === assignmentData?.address?.city)
          ?.name?.split(" ")
          ?.join("-");

        let homeTypeName = assignmentData?.homeType
          ? `-${homeTypeData?.homeType
              ?.find((li) => li?._id === assignmentData?.homeType)
              ?.name?.split(" ")
              ?.join("-")}`
          : "";

        const newName = `${devName}-${cityName}${homeTypeName}-${uuid()?.slice(0, 8)}-${
          !mediaArray?.length ? index + 1 : mediaArray?.length + index + 1
        }.${file.name.split(".").pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImages.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("folder", "assignment");
      formData.append("width", 1440);
      formData.append("height", 900);
      uploadImage(formData);
    },
    [assignmentData, homeTypeData?.homeType, city, mediaArray, uploadImage]
  );

  useEffect(() => {
    dispatch(getHomeType());
    dispatch(getcity());
  }, [dispatch]);

  useEffect(() => {
    if (getSession("assign-media")) setMediaArray(getSession("assign-media"));
  }, []);

  const planImageUpload = (file) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
      setTrigger(true);
    }, 4000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (type === "edit") {
            let img = [];
            if (res?.data) img = res?.data?.map((li) => li?.url);
            let images = [...planUploadArray];
            let data = { ...assignmentData, floorPlanImages: [...images, ...img] };
            dispatch(
              editListing(
                id,
                data,
                (res) => {
                  setTrigger(true);
                  setPlanUploadArray(res?.data?.floorPlanImages);
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                  setPlanImage([]);
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else if (getSession("assign-planImage")) {
            let image = getSession("assign-planImage");
            image = [...image, ...res?.data?.map((li) => li?.url)];
            setSession("assign-planImage", image);
            toast.dismiss(toastId);
            setPlanUploadArray(image);
            setTrigger(true);
            toast.success("Image(s) Uploaded");
            setPlanImage([]);
          } else if (!getSession("assign-planImage")) {
            if (!planUploadArray?.length) {
              setSession("assign-planImage", [...res?.data?.map((li) => li?.url)]);
              setPlanUploadArray([...res?.data?.map((li) => li?.url)]);
            } else {
              setSession("assign-planImage", [...planUploadArray, res?.data?.map((li) => li?.url)]);
              setPlanUploadArray([...planUploadArray, res?.data?.map((li) => li?.url)]);
            }
            setTrigger(true);
            toast.dismiss(toastId);
            toast.success("Image(s) Uploaded");
            setPlanImage([]);
          }
        },
        (res) => {
          toast.error(res?.message);
          toast.dismiss(toastId);
        }
      )
    );
  };

  useEffect(() => {
    if (getSession("assign-planImage")) setPlanUploadArray(getSession("assign-planImage"));
  }, []);

  const planImageDelete = async (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 5000);
    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit") {
            let data = { ...assignmentData };
            data = { ...data, floorPlanImages: planUploadArray?.filter((li) => li !== path) };
            dispatch(
              editListing(
                id,
                data,
                (res) => {
                  setPlanUploadArray(res?.data?.floorPlanImages);
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let a = planUploadArray.findIndex((li) => li === path);
            if (a !== -1) {
              planUploadArray.splice(a, 1);
              setPlanUploadArray([...planUploadArray]);
              setSession("assign-planImage", planUploadArray);
            }
            toast.dismiss(toastId);
            toast.success("Image Deleted");
          }
        },
        () => {}
      )
    );
  };

  const floorPlanOnDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImages = acceptedFiles.map((file, index) => {
        let devName = convertToSlug(assignmentData?.title);

        let cityName = city
          ?.find((li) => li?._id === assignmentData?.address?.city)
          ?.name?.split(" ")
          ?.join("-");

        let homeTypeName = assignmentData?.homeType
          ? `-${homeTypeData?.homeType
              ?.find((li) => li?._id === assignmentData?.homeType)
              ?.name?.split(" ")
              ?.join("-")}`
          : "";

        const newName = `${devName}-${cityName}${homeTypeName}-${uuid()?.slice(0, 8)}-${
          !planUploadArray?.length ? index + 1 : planUploadArray?.length + index + 1
        }.${file.name.split(".").pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImages.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("folder", "assignment");
      formData.append("width", 1440);
      formData.append("height", 900);
      planImageUpload(formData);
    },
    [city, homeTypeData?.homeType, planImageUpload, assignmentData, planUploadArray]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // maxFiles: 5,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  const { getRootProps: uploadRootProp, getInputProps: uploadInputProp } = useDropzone({
    onDrop: floorPlanOnDrop,
    // maxFiles: 20,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  const handleBackFun = () => {
    prevFormStep();
  };

  const handleAddUpdate = (successCB) => {
    let data = { ...assignmentData, video: videoUrl, vtour: vTour };
    if (mediaArray?.length) data = { ...data, images: mediaArray };
    if (planUploadArray) data = { ...data, floorPlanImages: planUploadArray };

    if (type === "edit") {
      dispatch(
        editListing(
          id,
          data,
          (res) => {
            removeSession("assign-planImage");
            removeSession("assign-media");
            successCB({ success: true });
          },
          (res) => {
            console.log("res: ", res);
          }
        )
      );
    } else {
      if (getSession("assignment")) {
        let uploads = getSession("assignment");
        let currData = { ...uploads, ...data };
        setSession("assignment", currData);
      } else {
        setSession("assignment", { ...data });
      }
      removeSession("assign-planImage");
      removeSession("assign-media");
      successCB({ success: true });
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) nextFormStep();
    });
  };

  const handleDraft = () => {
    let data = { ...assignmentData, video: videoUrl, vtour: vTour };
    if (mediaArray?.length) data = { ...data, images: mediaArray };
    if (planUploadArray) data = { ...data, floorPlanImages: planUploadArray };
    if (type === "edit") data = { ...data, status: data?.status };
    else data = { ...data, status: "draft" };
    if (type === "edit") {
      dispatch(
        editListing(
          id,
          data,
          (res) => {
            navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
            toast.success("Listing Updated");
          },
          (res) => {
            toast?.error(res?.message);
          }
        )
      );
    } else {
      dispatch(
        addListing(
          data,
          (res) => {
            removeSession("assignment");
            navigate(mainPath === "assignment" ? "/assignment" : "/new-homes");
            toast.success("Saved as draft");
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  useEffect(() => {
    if (listing) {
      setAssignmentData(listing);
    } else {
      if (getSession("assignment")) {
        let data = getSession("assignment");
        setAssignmentData(data);
      }
    }
  }, [listing]);

  useEffect(() => {
    if (assignmentData) {
      console.log("assignmentData 5: ", assignmentData);
      let data = assignmentData;
      setVideoUrl(data?.video || videoUrl);
      setVTour(data?.vtour || vTour);
      setMediaArray(data?.images || mediaArray);
      setPlanUploadArray(data?.floorPlanImages || planUploadArray);
    }
  }, [assignmentData]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {assignmentData?.title
                  ? assignmentData?.title
                  : `Add ${mainPath === "assignment" ? "Assignment" : "New Home"}`}{" "}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleDraft();
                }}
              >
                <span>{type === "edit" ? "Update Listing" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <AssignNavTab stepGoTo={stepGoTo} update={handleAddUpdate} />
                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Media</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"12"} md={"12"}>
                          <div className={clsx(styles.bannerWhite)}>
                            <p className="text-sm-start mb-0">
                              Drag and drop the images to customize the image gallery order
                            </p>
                            <div {...getRootProps({ className: styles.bannerDiv })}>
                              <IoImagesOutline />
                              <input
                                type="file"
                                id="profile1"
                                name="profile1"
                                style={{ display: "none" }}
                                {...getInputProps()}
                              />
                              <p className={clsx(styles.para1)}>Drag and drop the images here</p>
                              <p className={clsx(styles.para2)}>(Minimum size 1440x900)</p>
                              <Button color={"info"} type="file" id="myfile" name="myfile">
                                Select and Upload
                              </Button>
                            </div>

                            <p className={("text-sm-start text-center mt-4 mb-5", styles.para2)}>
                              Click on the star icon to select the cover image
                            </p>

                            {!trigger ? (
                              <Main
                                cards={mediaArray}
                                setCards={setMediaArray}
                                deleteArray={deleteArray}
                                coverImg={coverImg}
                                setDeleteArray={setDeleteArray}
                                handleCoverImg={handleCoverImg}
                                handleDelete={handleDelete}
                                handleMultiple={handleMultiple}
                              />
                            ) : null}

                            {deleteArray?.length ? (
                              <div className={clsx(styles.deleteBtn)}>
                                <Button color={"info"} onClick={deleteMultiples}>
                                  Delete Selected
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Floor Plan Images</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"12"} md={"12"}>
                          <div className={clsx(styles.bannerWhite)}>
                            <p className="text-sm-start mb-0">Plan Image(s)</p>
                            <div className="flex align-items-center">
                              <div className={clsx("", styles.searchDiv1)}>
                                <div {...uploadRootProp()} className={styles.uploadInput}>
                                  <p>Select the floor plan image (png or jpeg)</p>
                                  <input className={clsx("form-control")} type="file" {...uploadInputProp()} />
                                </div>
                              </div>
                            </div>
                            <div className={clsx("customScrollbar", styles.imageGallery)}>
                              {planUploadArray?.length
                                ? planUploadArray?.map((item) => (
                                    <div key={`${Math.random()}`}>
                                      <div className={styles.imgDiv}>
                                        <img src={`${process.env.REACT_APP_IMGURL}${item}`} alt="media_image" />
                                        <div className={styles.overlay}>
                                          <div
                                            className={styles.delete}
                                            onClick={() => {
                                              planImageDelete(item);
                                            }}
                                          >
                                            <AiOutlineCloseCircle /> Delete
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Video URL</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"12"} md={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>Video URL</p>
                            <div className="input-group-meta form-group">
                              <input
                                className={clsx("form-control")}
                                placeholder={"Enter Video URL such as YouTube, Vimeo, etc."}
                                value={videoUrl}
                                name="videoUrl"
                                onChange={(e) => setVideoUrl(e.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">360 Virtual Tour (3D Walkthrough)</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row className="">
                        <Col sm={"12"} md={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p>360 Virtual Tour</p>
                            <div className="input-group-meta form-group">
                              <textarea
                                className={clsx("form-control")}
                                rows={4}
                                text={"Enter virtual tour embedded code. Example: https//"}
                                onChange={(e) => {
                                  setVTour(e.target.value);
                                }}
                                value={vTour}
                                name="vTour"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>
          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Back"} color={"secondary"} outline onClick={handleBackFun} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Assignment5;
