import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod, postMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getMultiMedia = createAsyncThunk("file/uploadFile", async (file) => {
  setAuthorizationToken(true);
  const response = await getMethod(`/api/aws/multi-media`, file);
  return response;
});

// export const deleteFile = createAsyncThunk("file/deleteFile", async (file) => {
//   setAuthorizationToken(true);
//   const response = await postMethod(`/api/aws/s3/delete-file`, file);
//   return response;
// });

const initialState = {
  loading: false,
  success: false,
  error: false,
  awsData: null,
};

const awsSlice = createSlice({
  name: "aws",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isuploadSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isuploadError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.awsData = action.payload;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMultiMedia.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMultiMedia.fulfilled, (state, action) => {
      state.loading = false;
      state.awsData = action.payload.data;
    });
    builder.addCase(getMultiMedia.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // builder.addCase(deleteFile.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(deleteFile.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.awsData = action.payload.data;
    // });
    // builder.addCase(deleteFile.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.message;
    // });
  },
});

const { isError, isLoading, isSuccess, isuploadError, isuploadSuccess } = awsSlice?.actions;

export default awsSlice.reducer;

export const uploadProfilePicture = (file, successCB, errorCB) => async (dispatch) => {
  dispatch(isLoading());
  setAuthorizationToken(true);
  try {
    const response = await postMethod(`/api/aws/s3/upload-profile`, file);
    if (response?.success) {
      dispatch(isuploadSuccess());
      successCB(response);
    } else {
      dispatch(isuploadError());
      errorCB(response);
    }
  } catch (error) {
    dispatch(isuploadError());
  }
};

export const uploadFile = (file, successCB, errorCB) => async (dispatch) => {
  dispatch(isLoading());
  setAuthorizationToken(true);
  try {
    const response = await postMethod(`/api/aws/s3/upload`, file);
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const deleteFile = (file, successCB, errorCB) => async (dispatch) => {
  dispatch(isLoading());
  setAuthorizationToken(true);
  try {
    const response = await postMethod(`/api/aws/s3/delete-file`, file);
    if (response?.success) {
      dispatch(isuploadSuccess());
      successCB(response);
    } else {
      dispatch(isuploadError());
      errorCB(response);
    }
  } catch (error) {
    dispatch(isuploadError());
  }
};

export const deleteMultipleFiles = (file, successCB, errorCB) => async (dispatch) => {
  dispatch(isLoading());
  setAuthorizationToken(true);
  try {
    const response = await postMethod(`/api/aws/s3/delete-multiple-files`, file);
    if (response?.success) {
      dispatch(isuploadSuccess());
      successCB(response);
    } else {
      dispatch(isuploadError());
      errorCB(response);
    }
  } catch (error) {
    dispatch(isuploadError());
  }
};
