import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import { Spinner } from "reactstrap";
import Project1 from "./Project1";
import Project2 from "./Project2";
import Project3 from "./Project3";
import { getSingleProject, handleProjStep } from "../../redux/Apislices/projectSlice";
import Project4 from "./Project4";

const libraries = ["places"];

const AddProject = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  const { type, id } = useParams();

  const dispatch = useDispatch();
  const { project } = useSelector((state) => state);

  const { addProject: stepData } = project;

  const [activeIconTab, setActiveIconTab] = useState(1);

  useEffect(() => {
    if (type === "edit" && id) {
      dispatch(getSingleProject({ id, query: { requestFrom: "admin" } }));
    }
  }, [dispatch, id, type, activeIconTab]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(handleProjStep({ step: activeIconTab, maxStep: 3 }));
    }
  }, [type, activeIconTab, dispatch]);

  const toggleIconTab = (icontab) => {
    if (activeIconTab === icontab) {
      return;
    } else {
      let maxStep;
      if (stepData?.maxStep < icontab) maxStep = icontab;
      setActiveIconTab(icontab);
      dispatch(handleProjStep({ step: icontab, maxStep: maxStep ? maxStep : stepData?.maxStep }));
    }
  };

  const toggleBack = () => {
    if (activeIconTab > 1) {
      setActiveIconTab(activeIconTab - 1);
      dispatch(handleProjStep({ step: activeIconTab - 1, maxStep: stepData?.maxStep }));
    }
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleNext = () => {
    let maxStep;

    if (stepData?.maxStep < activeIconTab + 1) {
      maxStep = activeIconTab + 1;
    }

    if (activeIconTab < 4) {
      setActiveIconTab(activeIconTab + 1);
      dispatch(handleProjStep({ step: activeIconTab + 1, maxStep: maxStep ? maxStep : stepData?.maxStep }));
    }
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (stepData?.step) {
      setActiveIconTab(stepData?.step);
    }
  }, [stepData?.step]);

  useEffect(() => {
    dispatch(getHomeType());
  }, [dispatch]);

  if (loadError) return "Error...";
  if (!isLoaded)
    return (
      <div className="loadingDiv" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spinner type="grow" color="dark" style={{ width: "50px", height: "50px" }} />
      </div>
    );

  return (
    <>
      <Head title={type === "add" ? "Add Community" : type === "edit" ? "Edit Community" : ""} />

      <>
        {activeIconTab === 1 ? (
          <Project1
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 2 ? (
          <Project2
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 3 ? (
          <Project3
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : activeIconTab === 4 ? (
          <Project4
            toggleNext={toggleNext}
            toggleBack={toggleBack}
            activeIconTab={activeIconTab}
            toggleIconTab={toggleIconTab}
          />
        ) : null}
      </>
    </>
  );
};

export default AddProject;
