import clsx from "clsx";
import React from "react";
import styles from "./dev.module.scss";

const SortingBar = ({ search, setSearch, sortBy, setSortBy, sortByBeds, setSortByBeds, allPlans, floorPlans }) => {
  return (
    <>
      <div className={clsx("row justify-content-between m-0 p-0 gy-3", styles.sortDiv)}>
        <div className={clsx("col-lg-6 col-md-6 col-sm-12", styles.searchDiv)}>
          <input
            type={"search"}
            value={search}
            onChange={(e) => {
              let value = e.target.value;
              setSearch(value);
            }}
          />
        </div>

        <div className={clsx("col-lg-5 col-md-5 col-sm-12 row m-0 p-0 gy-3", styles.sortBy)}>
          <div className="col-lg-6 col-md-6 col-sm-6 col-sx-6">
            <label htmlFor="sortByBeds">Sort by Beds:</label>
            <select
              className={clsx("arrows", styles.selectTag)}
              style={{
                padding: "7px 15px",
                border: "0.2px solid #dddddd",
              }}
              value={sortByBeds}
              id="sortByBeds"
              name="sortByBeds"
              onChange={(e) => {
                setSortByBeds(e.target.value);
              }}
            >
              <option value={"all"}>All</option>
              {[...new Set(floorPlans?.map((li) => li?.bedrooms))]?.sort()?.map((list) => (
                <option key={list} value={`${list}`}>
                  {list}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-sx-6">
            <label htmlFor="sortBy">Sort by:</label>
            <select
              className={clsx("arrows", styles.selectTag)}
              style={{
                padding: "7px 15px",
                border: "0.2px solid #dddddd",
              }}
              value={sortBy}
              id="sortBy"
              name="sortBy"
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
            >
              <option value={"all"}>All</option>
              <option value={"available"}>
                available ({floorPlans?.filter((li) => li?.status === "available")?.length})
              </option>
              <option value={"sold"}>Sold ({floorPlans?.filter((li) => li?.status === "sold")?.length})</option>
              <option value={"onHold"}>On Hold ({floorPlans?.filter((li) => li?.status === "onHold")?.length})</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default SortingBar;
