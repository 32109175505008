import React from "react";
import { Col, Row } from "../Component";
import { FormGroup, Input, Label } from "reactstrap";
import styles from "./common.module.scss";
import { allMonths, getNextYears, yearsAfter, yearsBefore } from "../../utils/moment";

const ConstructionStatus = ({
  constructionData,
  constructionStatus,
  setConstructionStatus,
  mandConstruction,
  setMandConstruction,
  startYear,
  setstartYear,
  mandConstStartYear,
  setMandConstStartYear,
  startMonth,
  setstartMonth,
  mandConstStartMonth,
  setMandConstStartMonth,
  endYear,
  setendYear,
  mandCompYear,
  setMandCompYear,
  endMonth,
  setendMonth,
  mandCompMonth,
  setMandCompMonth,
  constRef,
  constStartYearRef,
  constStartMonthRef,
  compYearRef,
  compMonthRef,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <Col sm={"12"}>
          <Row>
            <Col sm={"6"}>
              <FormGroup>
                <Label htmlFor="constructionStatus" className="form-label">
                  Construction Status*
                </Label>
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <Input
                      type="select"
                      id="constructionStatus"
                      className={mandConstruction ? "error" : ""}
                      ref={constRef}
                      value={constructionStatus}
                      name="constructionStatus"
                      onChange={(e) => {
                        setConstructionStatus(e.target.value);
                        setstartMonth("");
                        setstartYear("");
                        setendMonth("");
                        setendYear("");
                        if (e.target.value) {
                          setMandConstruction(false);
                        }
                      }}
                    >
                      <option value="">Select Option</option>
                      {constructionData?.map((item) => (
                        <option key={item?._id} value={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>

        {constructionStatus === "63cbb64d860eaa6e22264e84" ||
        constructionStatus === "63cbb656860eaa6e22264e86" ||
        constructionStatus === "63cbb662860eaa6e22264e88" ? (
          <>
            <Col sm={"6"}>
              <FormGroup>
                <Label htmlFor="constructionStart" className="form-label">
                  {constructionStatus === "63cbb656860eaa6e22264e86" ||
                  constructionStatus === "63cbb662860eaa6e22264e88"
                    ? "Construction Started"
                    : "Construction Start"}
                  *
                </Label>
                <Row>
                  <Col sm={"6"}>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <Input
                          type="select"
                          id="constructionStart"
                          className={mandConstStartYear ? "error" : ""}
                          ref={constStartYearRef}
                          value={startYear}
                          name="startYear"
                          onChange={(e) => {
                            setstartYear(e.target.value);
                            if (e.target.value === "To be announced" || e.target.value === "Undisclosed") {
                              setstartMonth("");
                            }
                            setMandConstStartYear(false);
                          }}
                        >
                          <option value="">Select year</option>
                          <option
                            value={
                              constructionStatus === "63cbb64d860eaa6e22264e84" ? "To be announced" : "Undisclosed"
                            }
                          >
                            {constructionStatus === "63cbb64d860eaa6e22264e84" ? "To be announced" : "Undisclosed"}
                          </option>
                          {constructionStatus === "63cbb64d860eaa6e22264e84"
                            ? yearsAfter(5)?.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))
                            : yearsBefore(5)?.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                        </Input>
                      </div>
                    </div>
                  </Col>

                  <Col sm={"6"}>
                    {startYear === "To be announced" || startYear === "Undisclosed" ? null : (
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <Input
                            type="select"
                            id="constructionStatus"
                            className={mandConstStartMonth ? "error" : ""}
                            ref={constStartMonthRef}
                            value={startMonth}
                            name="startMonth"
                            onChange={(e) => {
                              setstartMonth(e.target.value);
                              setMandConstStartMonth(false);
                            }}
                          >
                            <option value="">Season/Month</option>
                            {["Spring", "Summer", "Fall", "Winter", ...allMonths]?.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col sm={"6"}>
              <FormGroup>
                <Label htmlFor="constructionEnd" className="form-label">
                  {constructionStatus === "63cbb662860eaa6e22264e88" ? "Completed *" : "Estimated Completion *"}
                </Label>
                <Row>
                  <Col sm={"6"}>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <Input
                          type="select"
                          id="constructionEnd"
                          className={mandCompYear ? "error" : ""}
                          ref={compYearRef}
                          value={endYear}
                          name="endYear"
                          onChange={(e) => {
                            setendYear(e.target.value);
                            if (e.target.value === "To be announced" || e.target.value === "Undisclosed") {
                              setendMonth("");
                            }
                            if (e.target.value) {
                              setMandCompYear(false);
                            }
                          }}
                        >
                          <option value="">Select year</option>
                          {startYear ? (
                            <>
                              <option
                                value={
                                  constructionStatus === "63cbb662860eaa6e22264e88" ? "Undisclosed" : "To be announced"
                                }
                              >
                                {constructionStatus === "63cbb662860eaa6e22264e88" ? "Undisclosed" : "To be announced"}
                              </option>
                              {constructionStatus === "63cbb662860eaa6e22264e88"
                                ? yearsBefore(5, new Date().getFullYear())?.map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))
                                : yearsAfter(5, new Date().getFullYear())?.map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))}
                            </>
                          ) : startYear && (startYear !== "Undisclosed" || startYear !== "To be announced") ? (
                            constructionStatus === "63cbb662860eaa6e22264e88" ? (
                              getNextYears(5, +startYear)?.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))
                            ) : constructionStatus === "63cbb662860eaa6e22264e86" ? (
                              yearsAfter(5, +startYear)?.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))
                            ) : (
                              yearsAfter(5, +startYear)?.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))
                            )
                          ) : (
                            <option value="" disabled>
                              Please select construction start year
                            </option>
                          )}
                        </Input>
                      </div>
                    </div>
                  </Col>

                  <Col sm={"6"}>
                    {endYear === "To be announced" || endYear === "Undisclosed" ? null : (
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <Input
                            type="select"
                            className={mandCompMonth ? "error" : ""}
                            id="constructionStatus"
                            ref={compMonthRef}
                            value={endMonth}
                            name="endMonth"
                            onChange={(e) => {
                              setendMonth(e.target.value);
                              if (e.target.value) {
                                setMandCompMonth(false);
                              }
                            }}
                          >
                            <option value="">Season/Month</option>
                            {["Spring", "Summer", "Fall", "Winter", ...allMonths]?.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default ConstructionStatus;
