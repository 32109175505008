import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Content from "../../layout/content/Content";
import { getAmenities } from "../../redux/Apislices/amenitySlice";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { getcity } from "../../redux/Apislices/citySlice";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { Block, BlockContent, BlockHead, BlockTitle, Button, Col, Row } from "../Component";
import ComHeader from "./ComHeader";
import ComNav from "./ComNav";
import styles from "./community.module.scss";
import {
  addCommunity,
  editCommunity,
  getSingleCommunity,
  handleComInfo,
  handleCommunityAddReset,
} from "../../redux/Apislices/communitySlice";

const Community3 = ({ toggleNext, toggleBack, activeIconTab, toggleIconTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const { amenity: amenityObj, city, homeType: homeTypeData, community } = useSelector((state) => state);

  const { amenity: amenityData } = amenityObj;

  const [comData, setComData] = useState({});

  const [loading, setLoading] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [amenities, setAmenities] = useState("");
  const [amenitiesArray, setAmenitiesArray] = useState([]);

  const [mediaData, setMediaData] = useState("");
  const [mediaName, setMediaName] = useState("");
  const mediaRef = useRef();
  const [mediaArray, setMediaArray] = useState([]);
  const [isEditable, setIsEditable] = useState("");
  const [edit, setEdit] = useState("");

  const toggle = () => {
    setIsEditable(null);
  };

  useEffect(() => {
    dispatch(getAmenities());
    dispatch(getcity());
    dispatch(getHomeType());
  }, [dispatch]);

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading");
    setTimeout(() => {
      toast.dismiss(toastId);
      // setTrigger(true);
    }, 10000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (type === "edit") {
            // let data = {
            //   ...comData,
            //   amenities: amenitiesArray,
            //   informationFolder: [
            //     ...comData?.informationFolder,
            //     ...res?.data?.map((li) => ({
            //       label: mediaName,
            //       fileUrl: li?.url,
            //     })),
            //   ],
            // };
            // if (additionalData) {
            //   data = {
            //     ...data,
            //     additionalInformation: [
            //       ...Object.entries(additionalData)?.map(([key, value]) => {
            //         return value;
            //       }),
            //       {
            //         title: "HOME WARRANTY",
            //         value: homeWarranty,
            //       },
            //       ...otherDetailsArray,
            //     ]?.map((li) => {
            //       return {
            //         label: li?.title,
            //         description: li?.value,
            //       };
            //     }),
            //   };
            // }
            // data.type = "presale";
            // console.log("data: ", data);
            // dispatch(
            //   editDevelopment(
            //     router?.query?.project || router?.query?.duplicate,
            //     data,
            //     (result) => {
            //       dispatch(getDevelopmentBySlug(router?.query?.project || router?.query?.duplicate));
            //       toast.dismiss(toastId);
            //       toast.success("File(s) Uploaded");
            //       setMediaData("");
            //       setMediaName("");
            //     },
            //     (res) => {
            //       toast.error(res?.message);
            //     }
            //   )
            // );
          } else {
            let a = res?.data?.map((li, i) => ({
              editId: `${i}${Math.random()}`,
              label: mediaName,
              fileUrl: li?.url,
            }));
            console.log("a: ", a);

            if (community?.addCommunity?.["info-folder"]?.length) {
              dispatch(handleComInfo([...community?.addCommunity?.["info-folder"], ...a]));
            } else {
              dispatch(handleComInfo([...a]));
            }
            toast.dismiss(toastId);
            toast.success("File(s) Uploaded");
            setMediaData("");
            setMediaName("");
          }
        },
        () => {
          toast.dismiss(toastId);
          toast.error("Something Went Wrong! Try Again");
        }
      )
    );
  };

  const handleDelete = async (path) => {
    const toastId = toast.loading("Deleting...");

    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit") {
            // let data = {
            //   ...comData,
            //   features: featureSheet,
            //   amenities: amenitiesArray,
            //   unitFeatures: commonFeaturesArray,
            //   informationFolder: comData?.informationFolder?.filter((li) => li?.fileUrl !== path),
            //   type: "presale",
            // };
            // if (additionalData) {
            //   data = {
            //     ...data,
            //     additionalInformation: [
            //       ...Object.entries(additionalData)?.map(([key, value]) => {
            //         return value;
            //       }),
            //       {
            //         title: "HOME WARRANTY",
            //         value: homeWarranty,
            //       },
            //       ...otherDetailsArray,
            //     ]?.map((li) => {
            //       return {
            //         label: li?.title,
            //         description: li?.value,
            //       };
            //     }),
            //   };
            // }
            // console.log("data: ", data);
            // dispatch(
            //   editDevelopment(
            //     router?.query?.project || router?.query?.duplicate,
            //     data,
            //     (res) => {
            //       dispatch(getDevelopmentBySlug(router?.query?.project || router?.query?.duplicate));
            //       toast.dismiss(toastId);
            //       toast.success("File Deleted");
            //     },
            //     (res) => {
            //       toast.error(res?.message);
            //     }
            //   )
            // );
          } else {
            let a = community?.addCommunity?.["info-folder"]?.filter((li) => li?.fileUrl !== path);
            dispatch(handleComInfo(a));
            toast.dismiss(toastId);
            toast.success("File Deleted");
          }
        },
        (res) => {
          toast.dismiss(toastId);
          toast.error(res?.message);
        }
      )
    );
  };

  useEffect(() => {
    if (community?.addCommunity?.data) {
      setComData(community?.addCommunity?.data);
    }
  }, [community?.addCommunity?.data]);

  useEffect(() => {
    if (comData) {
      dispatch(handleComInfo(comData?.informationFolder?.length ? comData?.informationFolder : []));
      setAmenitiesArray(comData?.amenities?.length ? comData?.amenities : []);
    }
  }, [comData, dispatch]);

  useEffect(() => {
    if (community?.addCommunity?.["info-folder"]) {
      setMediaArray(community?.addCommunity?.["info-folder"]);
    }
  }, [community?.addCommunity]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 5 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      setMediaData(acceptedFiles?.[0]);
      setMediaName(acceptedFiles?.[0]?.name);
      if (acceptedFiles?.[0]) {
        setTimeout(() => {
          // document.getElementById("mediaName").focus();
          mediaRef.current.focus();
        }, 500);
      }
    },
    [comData]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 5242880,
  });

  const handleBack = () => {
    toggleBack();
  };

  const handlePayload = () => {
    let data = {
      ...comData,
      amenities: amenitiesArray,
      informationFolder: mediaArray,
    };
    console.log("data: ", data);
    return data;
  };

  const handleAddUpdate = (CB) => {
    setLoading(true);
    let data = handlePayload();
    if (type === "edit") {
      dispatch(
        editCommunity(
          id,
          data,
          (res) => {
            dispatch(getSingleCommunity({slug:res?.data?.slug}));
            CB({ success: true, res: res });
          },
          (res) => {
            CB({ success: false, res: res });
          }
        )
      );
    } else {
      dispatch(
        addCommunity(
          data,
          (res) => {
            CB({ success: true, res: res });
          },
          (res) => {
            CB({ success: false, res: res });
          }
        )
      );
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) {
        toast.success("Community Added Successfully");
        setLoading(false);
        dispatch(handleCommunityAddReset());
        navigate("/master-community");
      } else {
        toast.error(res?.res?.message);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Content>
        <ComHeader
          title={comData?.name ? comData.name : type === "edit" ? "Edit Master Community" : "Add Master Community"}
        />

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <ComNav toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Amenities</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row>
                        <Col sm={"6"}>
                          <Select
                            options={
                              amenityData?.length
                                ? amenityData?.map((item) =>
                                    !amenitiesArray?.includes(item?._id)
                                      ? {
                                          label: item?.name,
                                          value: item?._id,
                                        }
                                      : {
                                          label: item?.name,
                                          value: item?._id,
                                          isDisabled: true,
                                        }
                                  )
                                : []
                            }
                            onInputChange={(e) => {
                              setInputValue(e);
                            }}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                            menuIsOpen={inputValue}
                            className={styles.searchSelect}
                            value={amenities}
                            id="amenities"
                            onChange={(value) => {
                              setAmenitiesArray([...amenitiesArray, value.value]);
                              setAmenities("");
                              document.getElementById("amenities").focus();
                            }}
                          />
                          <div className={styles.arrayItemDiv}>
                            {amenitiesArray?.length
                              ? amenitiesArray?.map((item, index) => {
                                  return (
                                    <p
                                      key={`${Math.random()}-${index}`}
                                      className={styles.para2}
                                      onClick={() => {
                                        let a = amenitiesArray?.indexOf(item);
                                        if (a > -1) {
                                          amenitiesArray?.splice(a, 1);
                                          setAmenitiesArray([...amenitiesArray]);
                                        }
                                      }}
                                    >
                                      <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                      <span>{amenityData?.filter((li) => li?._id === item)?.[0]?.name}</span>
                                    </p>
                                  );
                                })
                              : null}
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  <br />
                  <br />

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Information Folder</BlockTitle>
                    </BlockHead>

                    <BlockContent>
                      <Row>
                        <Col sm={"12"}>
                          <div className={clsx(styles.bannerWhite)}>
                            <h1 className="text-sm-start text-center">
                              You can attach Site plan, Location Map, Feature Sheet, Floor Plate, Brochure, etc. (pdf,
                              png, or jpeg only)
                            </h1>
                            {mediaData || mediaName ? (
                              <div className={clsx(styles.bannerDiv)}>
                                <p className={clsx(styles.para1)}>Change Name of the file uploaded</p>
                                <input
                                  type="text"
                                  id="mediaName"
                                  name="profile1"
                                  ref={mediaRef}
                                  value={mediaName}
                                  className={clsx(styles.uploadInput)}
                                  onChange={(e) => {
                                    setMediaName(e.target.value);
                                  }}
                                  onFocus={(e) => e.target.select()}
                                  onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                      if (mediaData) {
                                        let devName = comData?.name?.split(" ")?.join("-");

                                        let cityName = city?.city
                                          ?.find((li) => li?._id === comData?.address?.city)
                                          ?.name?.split(" ")
                                          ?.join("-");

                                        let homeTypeName = comData?.homeTypes?.[0]
                                          ? `-${homeTypeData?.homeType
                                              ?.find((li) => li?._id === comData?.homeTypes?.[0])
                                              ?.name?.split(" ")
                                              ?.join("-")}`
                                          : "";

                                        let ext = mediaName?.split(".")?.pop();

                                        let newName = `${devName}-${cityName}-${homeTypeName}-info-file-${mediaName
                                          ?.split(".")?.[0]
                                          ?.split(" ")
                                          ?.join("-")}-${mediaArray?.length + 1}.${ext}`;

                                        const updatedFile = new File([mediaData], newName, { type: mediaData.type });
                                        let formData = new FormData();
                                        formData.append("file", updatedFile);
                                        formData.append("folder", "development");
                                        uploadImage(formData);
                                      }
                                    } else if (e.key === "Escape") {
                                      setMediaData("");
                                      setMediaName("");
                                    }
                                  }}
                                />
                                <Button
                                  type="file"
                                  id="myfile"
                                  className={clsx(styles.uploadBtn)}
                                  name="myfile"
                                  onClick={() => {
                                    if (mediaData) {
                                      let devName = comData?.name?.split(" ")?.join("-");

                                      let cityName = city?.city
                                        ?.find((li) => li?._id === comData?.address?.city)
                                        ?.name?.split(" ")
                                        ?.join("-");

                                      let homeTypeName = comData?.homeTypes?.[0]
                                        ? `-${homeTypeData?.homeType
                                            ?.find((li) => li?._id === comData?.homeTypes?.[0])
                                            ?.name?.split(" ")
                                            ?.join("-")}`
                                        : "";

                                      let ext = mediaName?.split(".")?.pop();

                                      let newName = `${devName}-${cityName}-${homeTypeName}-info-file-${mediaName
                                        ?.split(".")?.[0]
                                        ?.split(" ")
                                        ?.join("-")}-${mediaArray?.length + 1}.${ext}`;

                                      const updatedFile = new File([mediaData], newName, { type: mediaData.type });
                                      let formData = new FormData();
                                      formData.append("file", updatedFile);
                                      formData.append("folder", "development");
                                      uploadImage(formData);
                                    }
                                  }}
                                >
                                  Change and Upload
                                </Button>
                                <Button
                                  variant="text"
                                  className={clsx(styles.cancelBtn)}
                                  onClick={() => {
                                    setMediaData("");
                                    setMediaName("");
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              <div
                                // className={clsx(styles.bannerDiv)}
                                {...getRootProps({ className: styles.bannerDiv })}
                              >
                                <FiUpload className={styles.icon} />

                                <input
                                  type="file"
                                  id="profile1"
                                  name="profile1"
                                  style={{ display: "none" }}
                                  {...getInputProps()}
                                />
                                <p className={clsx(styles.para1)}>Drag and drop the files here</p>
                                <p className={clsx(styles.para2)}>(Maximum size 5 MB)</p>
                                <Button className={clsx(styles.uploadBtn)} type="file" id="myfile" name="myfile">
                                  Select and Upload
                                </Button>
                              </div>
                            )}

                            <p className={("text-sm-start text-center mt-4 mb-5", styles.para2)}>
                              Uploaded Attachments
                            </p>

                            <div className={styles.arrayUploadItemDiv}>
                              {mediaArray?.length
                                ? mediaArray?.map((item, index) => {
                                    return (
                                      <div key={`${Math.random()}-${index}`} className={styles.para2}>
                                        <div className={styles.inputDiv}>
                                          {index + 1}.{" "}
                                          <span
                                            className={clsx(styles.name)}
                                            onClick={() => {
                                              window.open(`${process.env.IMGURL}${item?.fileUrl}`, "_blank");
                                            }}
                                          >
                                            {item?.label}
                                          </span>
                                        </div>

                                        <span>
                                          <BiEdit
                                            className={styles.arrayCloseIcon}
                                            style={{
                                              marginRight: "8px",
                                            }}
                                            onClick={() => {
                                              setIsEditable(item?._id || item?.editId);
                                              setEdit(item?.label);
                                            }}
                                          />
                                          <AiOutlineCloseCircle
                                            className={styles.arrayCloseIcon}
                                            onClick={() => {
                                              handleDelete(item?.fileUrl);
                                            }}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>

                            <Modal isOpen={isEditable} toggle={toggle} centered className={styles.modal}>
                              <ModalHeader toggle={toggle}>Edit Label</ModalHeader>
                              <ModalBody>
                                <div>
                                  <label htmlFor="edit">New Label</label>
                                  <input
                                    type="text"
                                    id={"edit"}
                                    value={edit}
                                    onChange={(e) => {
                                      setEdit(e.target.value);
                                    }}
                                    autoFocus
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter" && e.target.value) {
                                        let a = mediaArray?.map((li) =>
                                          li?._id === isEditable || li?.editId === isEditable
                                            ? { ...li, label: edit }
                                            : li
                                        );
                                        setMediaArray(a);
                                        setIsEditable("");
                                        setEdit("");
                                      } else if (e.key === "Escape") {
                                        setIsEditable("");
                                        setEdit("");
                                      }
                                    }}
                                  />
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <button
                                  onClick={() => {
                                    toggle();
                                    setEdit("");
                                  }}
                                  className={styles.closeBtn}
                                >
                                  Close
                                </button>
                                <button
                                  className={styles.submitBtn}
                                  onClick={() => {
                                    let a = mediaArray?.map((li) =>
                                      li?._id === isEditable || li?.editId === isEditable ? { ...li, label: edit } : li
                                    );
                                    setMediaArray(a);
                                    setIsEditable("");
                                    setEdit("");
                                  }}
                                >
                                  Save Changes
                                </button>
                              </ModalFooter>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>
                  
                </div>
              </div>
            </div>
          </Card>

          <div
            style={{
              margin: "15px 0",
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              color="secondary"
              outline
              onClick={handleBack}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>Back</span>
            </Button>

            <Button
              color={"info"}
              onClick={handleNext}
              disabled={loading}
              style={{
                fontSize: "16px",
                padding: "6px 25px",
              }}
            >
              <span>{type === "add" ? "Submit Master Community" : "Update Master Community"}</span>
            </Button>
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Community3;
