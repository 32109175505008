import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getprovince = createAsyncThunk("province/fetchProvince", async (query) => {
  const response = await getMethod(`/api/province`, query);
  return response;
});

export const getAllProvince = createAsyncThunk("province/fetchAllProvince", async (query) => {
  const response = await getMethod(`/api/province`);
  return response;
});

export const getprovinceByCountry = createAsyncThunk("province/fetchProvinceByCountry", async (id) => {
  const response = await getMethod(`/api/province-country/${id}`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  allProvinces: { loading: false, success: false, error: false, data: null, total: null },
  provinces: { loading: false, success: false, error: false, data: null, total: null },
  provinceByCountry: { loading: false, success: false, error: false, data: null, total: null },
};

const provinceSlice = createSlice({
  name: "province",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      // state.province = action.payload;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    provinceReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      // state.province = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getprovince.pending, (state) => {
      state.provinces.loading = true;
      state.provinces.success = false;
      state.provinces.error = false;
      state.provinces.data = null;
      state.provinces.total = null;
    });
    builder.addCase(getprovince.fulfilled, (state, action) => {
      state.provinces.loading = false;
      state.provinces.success = true;
      state.provinces.error = false;
      state.provinces.data = action.payload.data;
      state.provinces.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getprovince.rejected, (state, action) => {
      state.provinces.loading = false;
      state.provinces.success = false;
      state.provinces.data = null;
      state.provinces.total = null;
      state.provinces.error = action?.error?.message;
    });

    builder
      .addCase(getAllProvince.pending, (state) => {
        state.allProvinces.loading = true;
        state.allProvinces.success = false;
        state.allProvinces.error = false;
        state.allProvinces.data = null;
        state.allProvinces.total = null;
      })
      .addCase(getAllProvince.fulfilled, (state, action) => {
        state.allProvinces.loading = false;
        state.allProvinces.success = true;
        state.allProvinces.error = false;
        state.allProvinces.data = action.payload.data;
        state.allProvinces.total = action.payload.data.length;
      })
      .addCase(getAllProvince.rejected, (state, action) => {
        state.allProvinces.loading = false;
        state.allProvinces.success = false;
        state.allProvinces.data = null;
        state.allProvinces.total = null;
        state.allProvinces.error = action?.error?.message || true;
      });

    builder.addCase(getprovinceByCountry.pending, (state) => {
      state.provinceByCountry.loading = true;
      state.provinceByCountry.success = false;
      state.provinceByCountry.error = false;
      state.provinceByCountry.data = null;
      state.provinceByCountry.total = null;
    });
    builder.addCase(getprovinceByCountry.fulfilled, (state, action) => {
      state.provinceByCountry.loading = false;
      state.provinceByCountry.success = true;
      state.provinceByCountry.error = false;
      state.provinceByCountry.data = action.payload.data;
      state.provinceByCountry.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getprovinceByCountry.rejected, (state, action) => {
      state.provinceByCountry.loading = false;
      state.provinceByCountry.success = false;
      state.provinceByCountry.data = null;
      state.provinceByCountry.total = null;
      state.provinceByCountry.error = action?.error?.message;
    });
  },
});

export const { isLoading, isSuccess, isError, provinceReset } = provinceSlice.actions;

export default provinceSlice.reducer;

export const addProvince = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const res = await postMethod(`/api/admin/province`, data);
  if (res?.success) {
    SuccessCallback(res);
  } else {
    ErrorCallback(res);
  }
};

export const updateProvince = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const res = await putMethod(`/api/admin/province/${id}`, data);
  if (res?.success) {
    SuccessCallback(res);
  } else {
    ErrorCallback(res);
  }
};

export const deleteProvince = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const res = await deleteMethod(`/api/admin/province/${id}`);
  if (res?.success) {
    SuccessCallback(res);
  } else {
    ErrorCallback(res);
  }
};
