import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import { Button, Icon } from "../../../components/Component";
import { useDispatch } from "react-redux";
import { addAdminTeam } from "../../../redux/Apislices/userSlice";
import { toast } from "react-toastify";

const AddTeam = ({ view, toggle, handleGetData }) => {
  const { errors, register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onFormSubmit = (e) => {
    dispatch(
      addAdminTeam(
        { ...e, type: "new" },
        (res) => {
          toggle();
          handleGetData();
          toast.success("Team member added successfully");
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleClose = () => {
    toggle();
  };

  return (
    <Modal isOpen={view} toggle={toggle} className="modal-dialog-centered" size="xl" fullscreen="xl">
      <ModalBody>
        <a href="#cancel" className="close">
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              toggle();
            }}
          ></Icon>
        </a>
        <div style={{}}>
          <h4 className="title pb-0 mb-0">Add Team Member</h4>
          <p className="sub-title">Add a new team member to your team</p>
        </div>
        <form action="" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="row my-3">
            <div className="col-md-6 mt-3">
              <div className="form-group">
                <label htmlFor="name" className="m-0 pb-1">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter first name"
                  name="firstName"
                  ref={register({})}
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="form-group">
                <label htmlFor="name" className="m-0 pb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Enter last name"
                  name="lastName"
                  ref={register({})}
                />
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="form-group">
                <label htmlFor="email" className="m-0 pb-1">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                  name="email"
                  ref={register({ required: "Email is required" })}
                />
                {errors.email && <p className="text-danger">{errors.email.message}</p>}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
            <Button color="primary" type="submit">
              Save
            </Button>
            <Button color="secondary" type={"button"} onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddTeam;
