import React, { useEffect, useState } from "react";
import { MainSettings } from "../../pages/Settings/Settings";
import { Col, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, Row } from "../Component";
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAdminSettings, updateSettings } from "../../redux/Apislices/settings";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const MortgageSettings = () => {
  const dispatch = useDispatch();

  const { settings } = useSelector((state) => ({
    settings: state?.settings?.mortgageCalculator?.mortgageCalculator,
  }));
  console.log("settings: ", settings);

  const [toggle, setToggle] = useState(false);
  const [editId, setEditId] = useState(false);
  const [term, setTerm] = useState("");
  const [interestRate, setInterestRate] = useState("");

  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getAdminSettings({ type: "mortgageCalculator" }));
  }, [dispatch]);

  const onEditClick = (id) => {
    if (settings?.rateInput?.length) {
      settings?.rateInput?.forEach((rate) => {
        if (rate._id === id) {
          setTerm(rate.label);
          setInterestRate(rate.interestRate);
          setEditId(id);
        }
      });
      setToggle(true);
    }
  };

  const resetForm = () => {
    setTerm("");
    setInterestRate("");
    setEditId("");
    setToggle(false);
  };

  const onFormSubmit = () => {
    const toastId = toast.loading("Updating...");
    let newRateInput = settings?.rateInput.map((rate) => {
      if (rate._id === editId) {
        return { ...rate, interestRate: +interestRate };
      }
      return rate;
    });
    dispatch(
      updateSettings(
        "mortgageCalculator",
        { mortgageCalculator: { rateInput: newRateInput } },
        (response) => {
          toast.dismiss(toastId);
          toast.success("Mortgage Interest Rate Updated Successfully");
          resetForm();
          dispatch(getAdminSettings({ type: "mortgageCalculator" }));
        },
        (error) => {
          toast.error("Something went wrong");
        }
      )
    );
  };

  return (
    <>
      <MainSettings title={"Mortgage Rate Inputs"}>
        {settings?.rateInput && settings?.rateInput?.length ? (
          <DataTableBody>
            <DataTableHead>
              <DataTableRow>Term</DataTableRow>
              <DataTableRow>Interest Rate</DataTableRow>
              <DataTableRow className="nk-tb-col-tools text-right"></DataTableRow>
            </DataTableHead>
            {settings?.rateInput.map((rate, index) => {
              return (
                <DataTableItem key={index}>
                  <DataTableRow>{rate.label}</DataTableRow>
                  <DataTableRow>{rate.interestRate}%</DataTableRow>
                  <DataTableRow className="nk-tb-col-tools text-right">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        href="#more"
                        onClick={(ev) => ev.preventDefault()}
                        className="dropdown-toggle btn btn-icon btn-trigger"
                      >
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#edit"
                              onClick={(ev) => {
                                ev.preventDefault();
                                onEditClick(rate?._id);
                              }}
                            >
                              <Icon name="edit"></Icon>
                              <span>Edit</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DataTableRow>
                </DataTableItem>
              );
            })}
          </DataTableBody>
        ) : null}
      </MainSettings>
      <Modal
        isOpen={toggle}
        toggle={() => {
          resetForm();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                resetForm();
              }}
            />
          </a>
          <div className="p-2">
            <h5 className="title">{"Edit"}</h5>
            <div className="mt-4">
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-3">
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">Term</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="term"
                        value={term}
                        disabled
                        onChange={(e) => setTerm(e.target.value)}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.term && <span className="text-danger">{errors.term.message}</span>}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">Interest Rate</label>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="interestRate"
                        maxLength={4}
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.interestRate && <span className="text-danger">{errors.interestRate.message}</span>}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <button type="submit" className="btn btn-lg btn-primary">
                      Save
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MortgageSettings;
