import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon, UserAvatar } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import { findUpper } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { deleteFile, uploadProfilePicture } from "../../../redux/Apislices/awsSlice";
import { updateOwnProfile } from "../../../redux/Apislices/userSlice";
import { fetchProfileData } from "../../../redux/Apislices/authSlice";

const UserProfileLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => ({ profile: state?.auth?.profile }));
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const [profilePhoto, setProfilePhoto] = useState(null);
  console.log("profilePhoto: ", profilePhoto);

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    if (profile) {
      setProfilePhoto(profile?.profilePhoto);
    }
  }, [profile]);

  const uploadData = async (file, path) => {
    // const toastid = toast.loading("Uploading...");
    if (path) {
      dispatch(
        deleteFile(
          { path },
          (res) => {
            dispatch(
              uploadProfilePicture(
                file,
                (res) => {
                  dispatch(
                    updateOwnProfile(
                      { profilePhoto: res?.data[0]?.url },
                      (res) => {
                        dispatch(
                          fetchProfileData(
                            () => {
                              // toast.dismiss(toastid);
                              toast.success("Profile picture uploaded");
                              window.location.reload();
                            },
                            (res) => {
                              toast.error(res?.message);
                            }
                          )
                        );
                      },
                      (res) => {
                        toast.error(res?.message);
                      }
                    )
                  );
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    } else {
      dispatch(
        uploadProfilePicture(
          file,
          (res) => {
            dispatch(
              updateOwnProfile(
                { profilePhoto: res?.data[0]?.url },
                (res) => {
                  dispatch(
                    fetchProfileData(
                      () => {
                        // toast.dismiss(toastid);
                        toast.success("Profile picture uploaded");
                        window.location.reload();
                      },
                      (res) => {
                        toast.error(res?.message);
                      }
                    )
                  );
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          },
          (res) => {
            toast.error(res?.message);
          }
        )
      );
    }
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          toast.error("File is larger than 20 MB");
        }
        if (err.code === "file-invalid-type") {
          toast.error(err.message);
        }
      });
    });
    const formData = new FormData();

    formData.append("file", acceptedFiles[0]);
    formData.append("folder", "profile");
    formData.append("width", 300);
    formData.append("height", 300);

    uploadData(formData, profile?.profilePhoto || false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    {profilePhoto ? (
                      <div className="profile-pic">
                        <img src={`${process.env.REACT_APP_IMGURL}${profilePhoto}`} alt="profile" />
                        <div {...getRootProps()}>
                          <Icon name="camera" style={{ fontSize: "24px" }} />
                          <input id="profile2" name="profile2" style={{ display: "none" }} {...getInputProps()} />
                        </div>
                      </div>
                    ) : (
                      <div {...getRootProps({ className: "upload-profile" })}>
                        <Icon name="user-c" style={{ fontSize: "100px" }} />
                        <button style={{}}>
                          {profile?.profile?.role === "company" ? "UPLOAD BUSINESS LOGO" : "UPLOAD PROFILE PICTURE"}
                        </button>
                        <input id="profile2" name="profile2" style={{ display: "none" }} {...getInputProps()} />
                        <p>Minimum size 300px X 300px</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <Outlet updateSm={updateSm} sm={sm} />
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileLayout;
