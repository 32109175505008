import classNames from "classnames";
import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import styles from "./community.module.scss";

const ComNav = ({ activeIconTab, toggleIconTab, update }) => {
  const [data, setData] = useState([
    {
      id: 1,
      label: "Basic Info",
    },
    {
      id: 2,
      label: "Media",
    },
    {
      id: 3,
      label: "Information",
    },
  ]);

  const handleToggle = (li) => {
    update(async (res) => {
      if (res?.success) {
        toggleIconTab(li?.id);
      }
    });
  };

  return (
    <Nav tabs className={classNames("px-4", styles.nav)}>
      {data?.map((li) => (
        <NavItem key={li?.id}>
          <NavLink
            tag="div"
            className={classNames({ active: activeIconTab === li?.id })}
            style={{
              cursor: "pointer",
            }}
            onClick={(ev) => {
              ev.preventDefault();
              handleToggle(li);
            }}
          >
            <span>{li?.label}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default ComNav;
