import clsx from "clsx";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiDuplicate, BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import styles from "./dev.module.scss";
import { useNavigate } from "react-router-dom";
import { Carousel, CarouselItem, UncontrolledCarousel } from "reactstrap";
import { USDollar } from "../../utils/Utils";
// import PlaceholderImg from "../../assets/placeholder-img.png";
import PlaceholderImg from "../../assets/placeholder-img.png";

const FloorPlanArray = ({ li, handleEdit, handleDuplicate, handleDelete }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homeTypeData, homeStyle, parkingData, garageLayoutData, development, city } = useSelector((state) => ({
    homeTypeData: state?.homeType,
    homeStyle: state?.homeStyle,
    parkingData: state?.parking,
    garageLayoutData: state?.garageLayout,
    development: state?.development,
    city: state?.city,
  }));

  return (
    <div className="">
      <div className={clsx("row", styles.dataDiv)}>
        <div className={clsx("col-sm-2 col-12", styles.image)}>
          {li?.images?.length ? (
            <UncontrolledCarousel
              items={li?.images?.map((list, index) => ({
                src: `${process.env.REACT_APP_IMGURL}${list}`,
                altText: "cover_image",
                key: index + 1,
              }))}
            />
          ) : (
            <UncontrolledCarousel items={[{ src: PlaceholderImg, altText: "placeholder", key: 1 }]} />
          )}
        </div>

        <div className={clsx("col-sm-10 col-12 row", styles.details)}>
          {li?.title ? <p className={clsx("col-lg-4 col-md-4 col-6")}>Title: {li?.title}</p> : null}

          {li?.homeType ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>
              Home Type: {homeTypeData?.homeType?.filter((list) => list?._id === li?.homeType)?.[0]?.name}
            </p>
          ) : null}

          {li?.type ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>
              Plan Type: {homeStyle?.allHomeStyle?.filter((list) => list?._id === li?.type)?.[0]?.name}
            </p>
          ) : null}

          {li?.status ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>
              Status:{" "}
              {li?.status === "available"
                ? "Available"
                : li?.status === "sold"
                ? "Sold"
                : li?.status === "onHold"
                ? "On Hold"
                : null}
            </p>
          ) : null}

          {li?.price?.price || (li?.price?.from && li?.price?.to) ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>
              Price:{" "}
              {li?.price?.range
                ? `${USDollar(li?.price?.from)} - ${USDollar(li?.price?.to)}`
                : `${li?.price?.priceLabel ? li?.price?.priceLabel : ""} ${USDollar(li?.price?.price)}`}
            </p>
          ) : null}

          <p className={clsx("col-lg-4 col-md-4 col-6")}>
            Size: {li?.size?.range ? `${li?.size?.from} sqft - ${li?.size?.to} sqft` : `${li?.size?.size} sqft`}
          </p>

          {li?.balcony?.from || li?.balcony?.to || li?.balcony?.size ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>
              Balcony Size:{" "}
              {li?.balcony?.range ? `${li?.balcony?.from} sqft - ${li?.balcony?.to} sqft` : `${li?.balcony?.size} sqft`}
            </p>
          ) : null}

          <p className={clsx("col-lg-4 col-md-4 col-6")}>Bedrooms: {li?.bedrooms}</p>

          <p className={clsx("col-lg-4 col-md-4 col-6")}>Bathrooms: {li?.bathrooms}</p>

          {li?.rooftopDeck ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>Rooftop Deck: {li?.rooftopDeck ? "Yes" : "No"}</p>
          ) : null}

          {li?.parking?.length ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>
              Parking:{" "}
              {parkingData?.parking
                ?.filter((list) => li?.parking?.includes(list?._id))
                ?.map((el, i) => `${el?.name}${i < li?.parking?.length - 1 ? `, ` : ""}`)}
            </p>
          ) : null}

          {li?.garageLayout ? (
            <p className={clsx("col-lg-4 col-md-4 col-6")}>
              GarageType: {garageLayoutData?.garageLayout?.filter((list) => list?._id === li?.garageLayout)?.[0]?.name}
            </p>
          ) : null}
        </div>
      </div>
      <div className={styles.cta}>
        <div
          onClick={() => {
            handleEdit(li);
          }}
        >
          <BiEdit />
          <label htmlFor="edit">Edit</label>
        </div>

        <div
          onClick={() => {
            handleDuplicate(li);
          }}
        >
          <BiDuplicate />
          <label htmlFor="edit">Duplicate</label>
        </div>

        <div
          onClick={() => {
            handleDelete(li);
          }}
        >
          <AiOutlineCloseCircle />

          <label htmlFor="edit">Delete</label>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default FloorPlanArray;
