import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Swal from "sweetalert2";
import PlaceholderImg from "../../assets/placeholder-img.png";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Row,
} from "../../components/Component";
import ErrorComponent from "../../components/error/ErrorComponent";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { addCountry, deleteCountry, getCountry, updateCountry } from "../../redux/Apislices/countrySlice";
import { convertToSlug } from "../../utils/Utils";

const Country = () => {
  const dispatch = useDispatch();
  const {
    countries: { loading, success, data, error, total },
    permission,
  } = useSelector((state) => ({
    countries: state.country?.countries,
    permission: state?.auth?.profile?.permissions?.country,
  }));
  const [sm, updateSm] = useState(false);

  const [formData, setFormData] = useState({ name: "", shortSymbol: "", slug: "" });
  let { name, shortSymbol, slug } = formData;

  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [sort, setSort] = useState("");
  const [view, setView] = useState({ edit: false, add: false, details: null });
  const [editId, setEditedId] = useState(null);

  const { errors, register, handleSubmit } = useForm();

  const onInputChange = (e) => {
    if (e.target.name === "name") {
      setFormData({ ...formData, [e.target.name]: e.target.value, slug: convertToSlug(e.target.value) });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false });
    resetForm();
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({ name: "", shortSymbol: "", slug: "" });
  };

  // toggle function to view product details
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
    });
  };

  // function to submit the form
  const onFormSubmit = (e) => {
    dispatch(
      addCountry(
        formData,
        (res) => {
          onFormCancel();
          dispatch(getCountry({ page: currentPage, limit: itemPerPage, sort, search: onSearchText }));
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const onEditSubmit = () => {
    dispatch(
      updateCountry(
        editId,
        formData,
        (res) => {
          onFormCancel();
          dispatch(getCountry({ page: currentPage, limit: itemPerPage, sort, search: onSearchText }));
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );

    setEditedId(null);
    resetForm();
    setView({ edit: false, add: false });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item._id === id) {
        setFormData({
          name: item.name,
          shortSymbol: item.shortSymbol,
          slug: item.slug,
        });
      }
    });
    setEditedId(id);
    setView({ add: false, edit: true });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteCountry(
            id,
            (res) => {
              dispatch(getCountry({ page: currentPage, limit: itemPerPage, sort, search: onSearchText }));
              toast.success(res?.message);
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(
      () => {
        dispatch(getCountry({ page: currentPage, limit: itemPerPage, sort, search: onSearchText }));
      },
      onSearchText ? 800 : 100
    );

    return () => clearTimeout(timer);
  }, [currentPage, itemPerPage, sort, onSearchText, dispatch]);

  return (
    <React.Fragment>
      <Head title="Country"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Country
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  List of the total {total?.total} {total?.total === 1 ? "country" : "countries"}
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by name..."
                          onChange={(e) => onFilterChange(e)}
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                        disabled={permission && !permission?.add}
                      >
                        <Icon name="plus" />
                        <span>Add Country</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              {loading ? (
                <div
                  style={{
                    height: "200px",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <Spinner type="grow" color="dark" />
                </div>
              ) : (
                <div className="card-inner p-0">
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow>
                        <span
                          onClick={() => {
                            setSort(sort === "asc" ? "desc" : sort === "desc" ? "asc" : "desc");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Name
                          <Icon name={sort === "asc" ? "sort-up-fill" : sort === "desc" ? "sort-down-fill" : "sort"} />
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>Short Symbol</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>Slug</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu right className="dropdown-menu-xs">
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              <li className={itemPerPage === 25 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(25);
                                  }}
                                >
                                  25
                                </DropdownItem>
                              </li>
                              <li className={itemPerPage === 50 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(50);
                                  }}
                                >
                                  50
                                </DropdownItem>
                              </li>
                              <li className={itemPerPage === 100 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(100);
                                  }}
                                >
                                  100
                                </DropdownItem>
                              </li>
                            </ul>
                            <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSort("asc");
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                              <li className={sort === "desc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSort("desc");
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </DataTableRow>
                    </DataTableHead>
                    {success && data?.length ? (
                      data?.map((item) => {
                        return (
                          <DataTableItem key={item?._id}>
                            <DataTableRow>
                              <div className="tb-product">
                                <img
                                  src={
                                    item.banner?.image
                                      ? `${process.env.REACT_APP_IMGURL}${item.banner?.image}`
                                      : PlaceholderImg
                                  }
                                  alt="product"
                                  className="thumb"
                                />
                                <span className="title">{item?.name}</span>
                              </div>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">{item?.shortSymbol}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">{item?.slug}</span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="mr-n1">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      href="#more"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <ul className="link-list-opt no-bdr">
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#edit"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              onEditClick(item._id);
                                              toggle("edit");
                                            }}
                                            disabled={permission && !permission?.edit}
                                          >
                                            <Icon name="edit"></Icon>
                                            <span>Edit</span>
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#remove"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              onDelete(item._id);
                                            }}
                                            disabled={permission && !permission?.delete}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete Permamently</span>
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                              </ul>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    ) : success && !data?.length ? (
                      <div
                        className="text-center"
                        style={{
                          padding: "20px",
                          fontSize: "1rem",
                          fontWeight: "600",
                        }}
                      >
                        <span className="text-silent">No Countries found</span>
                      </div>
                    ) : error ? (
                      <ErrorComponent error={error} />
                    ) : null}
                  </DataTableBody>
                  <div className="card-inner">
                    {data?.length ? (
                      <PaginationComponent
                        itemPerPage={total?.items_per_page || 25}
                        totalItems={total?.total}
                        paginate={paginate}
                        currentPage={currentPage || 1}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </Block>
      </Content>

      <Modal
        isOpen={view.add || view.edit}
        toggle={() => {
          onFormCancel();
          if (view.edit) resetForm();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
            ></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{view.edit ? "Edit Country" : "Add Country"}</h5>
            <div className="mt-4">
              <form onSubmit={handleSubmit(view.edit ? onEditSubmit : onFormSubmit)}>
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Country Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={(e) => onInputChange(e)}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="sale-price">
                        Slug
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="slug"
                          value={slug}
                          onChange={(e) => onInputChange(e)}
                          ref={register({ required: "This is required" })}
                        />
                        {errors.slug && <span className="invalid">{errors.slug.message}</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="regular-price">
                        Country Short Symbol
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="shortSymbol"
                          value={shortSymbol}
                          ref={register({ required: "This is required" })}
                          onChange={(e) => onInputChange(e)}
                          className="form-control"
                        />
                        {errors.shortSymbol && <span className="invalid">{errors.shortSymbol.message}</span>}
                      </div>
                    </div>
                  </Col>

                  <Col size="12">
                    <Button color="primary" type="submit">
                      <Icon className="plus"></Icon>
                      <span>{view.edit ? "Edit Country" : "Add Country"}</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Country;
