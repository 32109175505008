import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "../layout/Index";
import { fetchProfileData } from "../redux/Apislices/authSlice";
import Content from "../layout/content/Content";

const NoAdminRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);

  return auth?.profile && auth?.profile?.role === "superadmin" ? <Layout>{children}</Layout> : <Navigate to="/" />;
};

export default NoAdminRoute;

export const SettingsLayout = ({ children }) => {
  return (
    <Content>
      <Outlet />
    </Content>
  );
};
