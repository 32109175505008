export const assignmentData = {
  kitchenStoveType: ["Gas", "Electric"],

  laundry: ["In-Suite", "Shared Laundry", "Not Available"],

  fuelHeating: [
    "Baseboard",
    "Electric",
    "Forced Air",
    "Geothermal",
    "Heat Pump",
    "Hot Water",
    "Mixed",
    "Natural Gas",
    "Oil",
    "Propane Gas",
    "Radiant",
    "Solar",
    "Wood",
    "Other",
  ],

  appliances: [
    "Single Door Refrigerator",
    "Double Door Refrigerator",
    "Kitchen Stove",
    "Dishwasher",
    "Microwave",
    "Washer",
    "Integrated Dishwasher",
    "Integrated Refrigerator",
    "Cooktop",
    "Cooking Range",
    "Wall Oven",
    "Dryer",
    "Hood Fan",
    "Washer & Dryer Combo",
  ],

  airConditioning: ["Roughed-In Only", "Window A/C", "Split A/C", "Centralized A/C"],

  centralizedVaccum: ["Built-in", "Roughed-in"],

  firePlace: ["Yes", "No"],

  firePlaceFuel: ["Electric", "Gas - Propane", "Gas – Natural", "Pellet", "Wood", "Other"],

  flooring: ["Carpet", "Concrete", "Hardwood", "Laminate", "Mixed", "Other", "Tile", "Vinyl"],

  maintenanceIncludes: [
    "Cable/Satellite",
    "Caretaker",
    "Electricity",
    "Garbage Pickup",
    "Gardening",
    "Gas",
    "Geothermal",
    "Heat",
    "Hot Water",
    "Management",
    "Other",
    "Recreation Facility",
    "RV Parking",
    "Sewer",
    "Snow Removal",
    "Taxes",
  ],

  byLaws: [
    "Age Restrictions",
    "No Restrictions",
    "Pets Allowed",
    "Pets Allowed w/Restrictions",
    "Pets Not Allowed",
    "Rentals Allowed",
    "Rentals Allowed w/Restrictions",
    "Rentals Not Allowed",
    "Smoking Restrictions",
  ],

  roomType: [
    "Attic",
    "Master Bedroom",
    "Bedroom",
    "Flex/Den",
    "Bathroom",
    "Powder Room",
    "Bar Room",
    "Pantry",
    "Kitchen",
    "Spice Kitchen",
    "Living Room",
    "Family Room",
    "Dining Area",
    "Eating Area",
    "Porch (enclosed)",
    "Utility",
    "Library",
    "Cold Room",
    "Dressing Room",
    "Entrance Hall",
    "Games Room",
    "Recreation Room",
    "Steam Room",
    "Storage",
    "Study",
    "Walk-In Closet",
    "Workshop",
    "Sauna",
    "Solarium",
    "Gym",
    "Laundry",
    "Foyer",
    "Loft",
    "Media Room",
    "Mud Room",
    "Flex Space/Nook",
    "Office",
    "Balcony",
    "Deck/Patio",
    "Garage",
    "Other",
  ],

  floor: ["Main Floor", "Above Main Floor 1", "Above Main Floor 2", "Below Main Floor", "Basement"],

  serviceConnected: [
    "Community",
    "Electricity",
    "Natural Gas",
    "Sanitary Sewer",
    "Septic",
    "Storm Sewer",
    "Unknown",
    "Water",
  ],

  mortgageHeplers: ["Suite 1", "Suite 2", "Suite 3"],

  mortgageHelperFinish: ["Fully Finished", "Partly Finished", "Unfinished"],

  mortgageHelperFeatures: [
    "Separate Entry",
    "Ground Level",
    "Laundry",
    "Dishwasher",
    "Balcony",
    "Patio/Deck",
    "Separate Thermostat",
    "In Floor Heating System",
    "Walk-in Closet",
    "Smoke Detectors",
    "Sprinkler System",
    "Central Vacuum",
    "Air Conditioning",
    "Drapes/Windows Coverings",
    "Fireplace",
    "Gas Stove",
    "Kitchen Island",
    "Alarm System",
    "Ensuite",
  ],

  helpersSuiteType: ["Legal Suite", "Licensed Suite", "Unauthorized Suite"],

  basementArea: ["Fully Finished", "Partly Finished", "Unfinished", "Crawl", "None"],

  parkingFacility: ["Attached Garage", "Detached Garage", "Underground", "Street Parking", "Driveway", "Carport "],

  occupancy: ["Owner Occupied", "Tenant", "Under Construction", "Vacant"],
};
