import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getAmenities = createAsyncThunk("agents/agentsList", async (thunkApi) => {
  const response = await getMethod(`/api/amenity`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  amenity: null,
};

const amenitySlice = createSlice({
  name: "amenity",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAmenities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAmenities.fulfilled, (state, action) => {
        state.loading = false;
        state.amenity = action.payload.data;
      })
      .addCase(getAmenities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default amenitySlice.reducer;

export const addAmenities = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/amenity`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};

export const updateAmenities = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/amenity/${id}`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};

export const deleteAmenities = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/amenity/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
