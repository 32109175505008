export const getLocationData = (place) => {
  let address, city, province, country, zipcode, route, street;

  address = place?.formatted_address;

  for (let i = 0; i < place?.address_components?.length; i++) {
    for (let j = 0; j < place?.address_components[i]?.types?.length; j++) {
      switch (place?.address_components[i]?.types[j]) {
        case "route":
          route = place?.address_components[i]?.long_name;
          break;
        case "street_number":
          street = place?.address_components[i]?.long_name;
          break;
        // case "neighborhood":
        //   neighbourhood = place?.address_components[i]?.long_name;
        //   break;
        case "locality":
          city = place?.address_components[i]?.long_name;
          break;
        case "administrative_area_level_1":
          province = place?.address_components[i]?.long_name;
          break;
        case "country":
          country = place?.address_components[i]?.long_name;
          break;
        case "postal_code":
          zipcode = place?.address_components[i]?.long_name;
          break;

        default:
          break;
      }
    }
  }

  return {
    address,
    city,
    province,
    country,
    zipcode,
    street,
    route,
  };
};
