import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod } from "../../utils/apiServices";

export const getAllHomeStyle = createAsyncThunk(
  "homeStyle/fetchAllHomeStyle",
  async () => {
    // setAuthorizationToken(true);
    const response = await getMethod(`/api/homeStyle`);
    return response;
  }
);
export const getHomeStyleByType = createAsyncThunk(
  "homeStyle/fetchHomeStyle",
  async (id) => {
    // setAuthorizationToken(true);
    const response = await getMethod(`/api/homeStyle/${id}`);
    return response;
  }
);

const initialState = {
  loading: false,
  success: false,
  error: false,
  homeStyle: null,
  allHomeStyle: null,
};

const homeStyleSlice = createSlice({
  name: "homeStyle",
  initialState,
  // reducers: {
  //   isLoading: (state) => {
  //     state.loading = true;
  //     state.success = false;
  //     state.error = false;
  //   },
  //   isSuccess: (state, action) => {
  //     state.loading = false;
  //     state.success = true;
  //     state.error = false;
  //     state.homeStyle = action.payload;
  //   },
  //   isError: (state) => {
  //     state.loading = false;
  //     state.success = false;
  //     state.error = true;
  //   },
  // },

  extraReducers: (builder) => {
    builder.addCase(getHomeStyleByType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHomeStyleByType.fulfilled, (state, action) => {
      state.loading = false;
      state.homeStyle = action.payload.data;
    });
    builder.addCase(getHomeStyleByType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getAllHomeStyle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllHomeStyle.fulfilled, (state, action) => {
      state.loading = false;
      state.allHomeStyle = action.payload.data;
    });
    builder.addCase(getAllHomeStyle.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// export const { isLoading, isSuccess, isError } = homeStyleSlice.actions;

export default homeStyleSlice.reducer;

// export const getHomeType = () => async (dispatch) => {
//   try {
//     dispatch(isLoading);
//     const response = await getMethod(`/api/homeType`);
//     dispatch(isSuccess(response?.data));
//   } catch (error) {
//     dispatch(isError);
//   }
// };
