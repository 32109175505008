/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card } from "reactstrap";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import Content from "../../layout/content/Content";
import DevNavTab from "../../pages/Development/DevNavTab";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { getcity } from "../../redux/Apislices/citySlice";
import {
  addNewDevelopment,
  editDevelopment,
  handleAddDevelopment,
  handleDevAddReset,
} from "../../redux/Apislices/developmentSlice";
import { getGarageLayoutList } from "../../redux/Apislices/garageLayoutSlice";
import { getAllHomeStyle, getHomeStyleByType } from "../../redux/Apislices/homeStyleSlice";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getParkingList } from "../../redux/Apislices/parkingSlice";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import { convertToSlug, numberRegex } from "../../utils/Utils";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
} from "../Component";
import FooterBtn from "../common/FooterBtn";
import FloorPlanArray from "./FloorPlanArray";
import SortingBar from "./SortingBar";
import styles from "./dev.module.scss";
import FloorPlanEdit from "./FloorPlanEdit";

const Dev5 = ({ toggleNext, toggleBack, toggleIconTab, activeIconTab }) => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const { homeTypeData, homeStyle, parkingData, garageLayoutData, development, city, profile } = useSelector(
    (state) => ({
      homeTypeData: state?.homeType,
      homeStyle: state?.homeStyle,
      parkingData: state?.parking,
      garageLayoutData: state?.garageLayout,
      development: state?.development,
      city: state?.city,
      profile: state?.profile,
    })
  );
  const { developmentById, addDevelopment } = development;

  const [devData, setDevData] = useState(false);
  const [openEditModal, seOpenEditModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const handleOpenEditModal = () => seOpenEditModal(true);
  const handleCloseEditModal = () => {
    seOpenEditModal(false);
    setEditData(false);
  };
  useEffect(() => {
    if (!openEditModal) {
      setEditData(false);
    }
  }, [openEditModal]);

  const [checkBox, setCheckBox] = useState("comingSoon");
  const [moreFloorPlans, setMoreFloorPlans] = useState(false);
  const [status, setStatus] = useState("");
  const [homeType, setHomeType] = useState("");
  const [planTitle, setPlanTitle] = useState("");
  const [planType, setPlanType] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [planPriceFrom, setPlanPriceFrom] = useState("");
  const [planPriceTo, setPlanPriceTo] = useState("");
  const [planPriceRange, setPlanPriceRange] = useState(false);
  const [planPriceSelect, setplanPriceSelect] = useState("");
  const [planPrice, setplanPrice] = useState("");
  const [planSizeFrom, setPlanSizeFrom] = useState("");
  const [planSizeTo, setPlanSizeTo] = useState("");
  const [planSizeRange, setPlanSizeRange] = useState(false);
  const [planSize, setPlanSize] = useState("");
  const [parking, setParking] = useState("");
  const [parkingArray, setParkingArray] = useState([]);
  const [garageLayout, setGarageLayout] = useState("");
  const [rooftopDeck, setRooftopDeck] = useState("");
  const [balconySizeFrom, setBalconySizeFrom] = useState("");
  const [balconySizeTo, setBalconySizeTo] = useState("");
  const [balconySizeRange, setBalconySizeRange] = useState(false);
  const [balconySize, setBalconySize] = useState("");
  const [mandStatus, setMandStatus] = useState(false);
  const [mandType, setMandType] = useState(false);
  const [mandTitle, setMandTitle] = useState(false);
  const [mandPlanType, setMandPlanType] = useState(false);
  const [mandBedrooms, setMandBedrooms] = useState(false);
  const [mandBathrooms, setMandBathrooms] = useState(false);
  const [mandPlanSize, setMandPlanSize] = useState(false);
  const statusRef = useRef();
  const typeRef = useRef();
  const titleRef = useRef();
  const planTypeRef = useRef();
  const bedroomsRef = useRef();
  const bathroomsRef = useRef();
  const planSizeRef = useRef();
  const [planImage, setPlanImage] = useState("");
  const [planImageArray, setPlanImageArray] = useState([]);
  const [search, setSearch] = useState("");
  const [sortByBeds, setSortByBeds] = useState("all");
  const [sortBy, setSortBy] = useState("all");
  const [floorPlans, setFloorPlans] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [planTrigger, setPlanTrigger] = useState(false);

  useEffect(() => {
    setMandStatus(false);
    setMandType(false);
    setMandTitle(false);
    setMandPlanType(false);
    setMandBedrooms(false);
    setMandBathrooms(false);
    setMandPlanSize(false);
  }, []);

  const resetForm = () => {
    setStatus("");
    setHomeType("");
    setPlanTitle("");
    setPlanType("");
    setBedrooms("");
    setBathrooms("");
    setPlanPriceFrom("");
    setPlanPriceTo("");
    setPlanPriceRange(false);
    setplanPriceSelect("");
    setplanPrice("");
    setPlanSizeFrom("");
    setPlanSizeTo("");
    setPlanSizeRange(false);
    setPlanSize("");
    setParking("");
    setParkingArray([]);
    setGarageLayout("");
    setRooftopDeck("");
    setBalconySizeFrom("");
    setBalconySizeTo("");
    setBalconySizeRange(false);
    setBalconySize("");
    setPlanImage("");
    setPlanImageArray([]);
  };

  useEffect(() => {
    if (trigger) setTrigger(false);
  }, [trigger]);

  const uploadImage = (formData) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
      setTrigger(true);
    }, 5000);

    dispatch(
      uploadFile(
        formData,
        (res) => {
          toast.success("Image uploaded");
          setTrigger(true);
          toast.dismiss(toastId);
          if (development?.addDevelopment?.["dev-planImage"]) {
            let image = development?.addDevelopment?.["dev-planImage"];
            let newImages = res?.data?.map((li) => li?.url);
            let a = [...image, ...newImages];
            let b = [...new Set(a)];
            dispatch(handleAddDevelopment({ ...development?.addDevelopment, "dev-planImage": b }));
            setPlanImageArray(b);
          } else {
            if (planImageArray?.length) {
              let a = [...planImageArray, ...res?.data?.map((li) => li?.url)];
              let b = [...new Set(a)];
              dispatch(handleAddDevelopment({ ...development?.addDevelopment, "dev-planImage": b }));
            } else {
              dispatch(
                handleAddDevelopment({
                  ...development?.addDevelopment,
                  "dev-planImage": res?.data?.map((li) => li?.url),
                })
              );
            }
          }
        },
        () => {
          toast.dismiss(toastId);
          setTrigger(true);
        }
      )
    );
  };

  // deleting plan images from database and session storage
  const handleDelete = (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);

    dispatch(
      deleteFile(
        { path },
        (res) => {
          let a = planImageArray?.find((li) => li === path);
          if (a) {
            let b = planImageArray?.filter((li) => li !== path);
            setPlanImageArray([...b]);
            dispatch(handleAddDevelopment({ ...development?.addDevelopment, "dev-planImage": b }));
          }
          toast.success("Image Deleted");
          toast.dismiss(toastId);
          setTrigger(true);
        },
        () => {
          toast.dismiss(toastId);
          setTrigger(true);
        }
      )
    );
  };

  // uploading image
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImages = acceptedFiles.map((file, index) => {
        let devName = convertToSlug(devData?.name);

        let cityName = city?.cities?.data
          ?.find((li) => li?._id === devData?.address?.city)
          ?.name?.split(" ")
          ?.join("-");

        let homeTypeName =
          homeType || devData?.homeTypes?.[0]
            ? `-${homeTypeData?.homeType
                ?.find((li) => li?._id === homeType || devData?.homeTypes?.[0])
                ?.name?.split(" ")
                ?.join("-")}`
            : "";

        let planName = planTitle ? `-${convertToSlug(planTitle)}` : `-${uuid()?.slice(0, 8)}`;

        const newName = `${devName || "development"}-${cityName || "bc"}${
          homeTypeName || "homes"
        }-floor-plan${planName}-${!planImageArray?.length ? index + 1 : planImageArray?.length + index + 1}.${file.name
          .split(".")
          .pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImages.forEach((image) => {
        formData.append("file", image);
        formData.append("width", 1440);
        formData.append("height", 900);
        formData.append("folder", "development");
      });
      uploadImage(formData);
    },
    [devData, homeTypeData?.homeType, homeType, city?.city, planTitle, planImageArray?.length, uploadImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 10,
    accept: { "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] },
    maxSize: 20971520,
  });

  // getting plan images and all floor plans from session storage if an
  useEffect(() => {
    if (addDevelopment?.["dev-planImage"]) setPlanImageArray(addDevelopment?.["dev-planImage"]);
  }, [addDevelopment]);

  useEffect(() => {
    if (addDevelopment?.["dev-floorplans"]) setFloorPlans(addDevelopment?.["dev-floorplans"]);
  }, [addDevelopment]);

  // Handling event on CTA "Add More"
  const handleAddMorePlans = () => {
    let data = {
      status: status,
      homeType: homeType,
      title: planTitle,
      type: planType,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      images: planImageArray?.map((li) => li),
    };

    if (homeType === "63c66c47cec8665e878ce4df") {
      if (balconySizeRange === true)
        data = { ...data, balcony: { range: true, from: +balconySizeFrom, to: +balconySizeTo } };
      else data = { ...data, balcony: { range: false, size: +balconySize } };
    } else {
      delete data.balcony;
    }

    if (planSizeRange === true) data = { ...data, size: { range: true, from: +planSizeFrom, to: +planSizeTo } };
    else data = { ...data, size: { range: false, size: +planSize } };

    if (planPriceRange === true)
      data = { ...data, price: { ...data.price, range: true, price: 0, from: +planPriceFrom, to: +planPriceTo } };
    else data = { ...data, price: { ...data.price, range: false, price: +planPrice, priceLabel: planPriceSelect } };

    if (homeType !== "63c66c47cec8665e878ce4df") {
      if (parkingArray?.length) data = { ...data, parking: parkingArray?.map((li) => li) };
      if (garageLayout) data = { ...data, garageLayout: garageLayout };
      data = { ...data, rooftopDeck: rooftopDeck === "Yes" ? true : false };
    } else {
      delete data.parking;
      delete data.garageLayout;
      delete data.rooftopDeck;
    }
    data.editId = uuid().slice(0, 8);
    if (
      status &&
      homeType &&
      planTitle &&
      planType &&
      (bedrooms || bedrooms === 0) &&
      bathrooms &&
      (planSize || (planSizeFrom && planSizeTo))
    ) {
      if (addDevelopment?.["dev-floorplans"]) {
        let a = addDevelopment?.["dev-floorplans"];
        dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": [...a, data], "dev-planImage": null }));
      } else {
        dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": [data], "dev-planImage": null }));
      }
      setFloorPlans([...floorPlans, data]);
      resetForm();
    } else {
      if (!status) statusRef.current.focus();
      else if (!homeType) typeRef.current.focus();
      else if (!planTitle) titleRef.current.focus();
      else if (!planType) planTypeRef.current.focus();
      else if (!bedrooms) bedroomsRef?.current?.focus();
      else if (!bathrooms) bathroomsRef?.current?.focus();
      else if (!planSize) planSizeRef?.current?.focus();
      if (!status) setMandStatus(true);
      if (!homeType) setMandType(true);
      if (!planTitle) setMandTitle(true);
      if (!planType) setMandPlanType(true);
      if (!bedrooms) setMandBedrooms(true);
      if (!bathrooms) setMandBathrooms(true);
      if (!planSize) setMandPlanSize(true);
      toast.error("Please fill floor plan data");
    }
  };

  const handleEdit = (li) => {
    handleOpenEditModal();
    setEditData(li);
  };

  const handleDuplicate = (li) => {
    setPlanTitle(li?.title);
    setStatus(li?.status);
    setHomeType(li?.homeType);
    setPlanType(li?.type);
    setBedrooms(li?.bedrooms?.toString());
    setBathrooms(li?.bathrooms);
    setPlanPriceRange(li?.price?.range);
    setPlanPriceFrom(li?.price?.from);
    setPlanPriceTo(li?.price?.to);
    setplanPriceSelect(li?.price?.priceLabel);
    setplanPrice(li?.price?.price);
    setPlanSize(li?.size?.size);
    setPlanSizeFrom(li?.size?.from);
    setPlanSizeRange(li?.size?.range);
    setPlanSizeTo(li?.size?.to);
    setParkingArray(li?.parking?.map((li) => li) || []);
    setGarageLayout(li?.garageLayout);
    setRooftopDeck(li?.rooftopDeck ? "Yes" : "No");
    setBalconySize(li?.balcony?.size);
    setBalconySizeFrom(li?.balcony?.from);
    setBalconySizeRange(li?.balcony?.range);
    setBalconySizeTo(li?.balcony?.to);
    statusRef.current.focus();
  };

  const handleDeletePlan = (li) => {
    // remove selected object
    let a = floorPlans?.filter((list) => list !== li);
    setFloorPlans([...a]);
    dispatch(handleAddDevelopment({ ...development?.addDevelopment, "dev-floorplans": a }));
  };

  const handleAddUpdate = async (CB) => {
    let plan = { ...devData, floorPlans: { planAvailable: checkBox, floorPlans: floorPlans }, type: "presale" };
    if (checkBox) {
      plan = {
        ...plan,
        floorPlans: { ...plan.floorPlans, morePlans: checkBox === "comingSoon" ? false : moreFloorPlans },
      };
    } else {
      plan = { ...plan, floorPlans: { ...plan.floorPlans, morePlans: null } };
    }
    if (type === "edit") plan.status = devData?.status;
    else plan.status = "pending";
    if (checkBox === "available") {
      if (floorPlans?.length) {
        if (
          !devData?.floorArea?.autoUpdate ||
          !devData?.bedrooms?.autoUpdate ||
          !devData?.bathrooms?.autoUpdate ||
          !devData?.price?.price?.autoUpdate ||
          !devData?.price?.pricePerSqft?.autoUpdate
        ) {
          Swal.fire({
            title:
              "Do you want to auto-update Bedrooms, Bathrooms, FloorArea, Price and Price per sqft range as per floorplans?",
            icon: "question",
            confirmButtonText: "Yes",
            buttonsStyling: {},
            showDenyButton: true,
            denyButtonText: "No",
            denyButtonColor: "#5a8dee",
          }).then((result) => {
            if (result.isConfirmed) {
              if (floorPlans?.length) {
                plan = {
                  ...plan,
                  bedrooms: {
                    ...plan.bedrooms,
                    min: Math.min(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)
                    ),
                    max: Math.max(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)
                    ),
                    autoUpdate: true,
                  },
                };
                plan = {
                  ...plan,
                  bathrooms: {
                    ...plan.bathrooms,
                    min: Math.min(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)
                    ),
                    max: Math.max(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)
                    ),
                    autoUpdate: true,
                  },
                };
                plan = {
                  ...plan,
                  floorArea: {
                    ...plan.floorArea,
                    min: Math.min(
                      ...floorPlans
                        ?.filter((li) => li?.status === "available")
                        ?.map((li) => {
                          let min = li?.size?.from || li?.size?.size;
                          return min;
                        })
                    ),
                    max: Math.max(
                      ...floorPlans
                        ?.filter((li) => li?.status === "available")
                        ?.map((li) => {
                          let max = li?.size?.to || li?.size?.size;
                          return max;
                        })
                    ),
                    autoUpdate: true,
                  },
                };
                let data = floorPlans?.filter((li) => li?.status === "available");
                // Create an array of all the prices
                const prices = data?.map((plan) => {
                  if (plan?.price?.range) {
                    if (plan?.price?.from !== 0 && plan?.price?.to !== 0) {
                      return [Number(plan?.price?.from), Number(plan?.price?.to)];
                    } else {
                      return [0];
                    }
                  } else {
                    if (plan?.price?.price) {
                      return [Number(plan?.price?.price)];
                    } else {
                      return [0];
                    }
                  }
                });
                let flattenedPrices = [].concat(...prices);
                let filteredPrices = flattenedPrices.filter((price) => price !== 0);
                let min = Math.min(...filteredPrices);
                let max = Math.max(...filteredPrices);
                let floorAreaMin = [];
                let floorAreaMax = [];
                for (let i = 0; i < data?.length; i++) {
                  let minPrice;
                  let minSize;
                  let maxPrice;
                  let maxSize;
                  if (data?.[i]?.price?.price || data?.[i]?.price?.from)
                    minPrice = data?.[i]?.price?.price || data?.[i]?.price?.from;
                  else minPrice = +Infinity;
                  if (data?.[i]?.size?.size || data?.[i]?.size?.from)
                    minSize = data?.[i]?.size?.size || data?.[i]?.size?.from;
                  else minSize = +Infinity;
                  if (data?.[i]?.price?.price || data?.[i]?.price?.to)
                    maxPrice = data?.[i]?.price?.price || data?.[i]?.price?.to;
                  else maxPrice = -Infinity;
                  if (data?.[i]?.size?.size || data?.[i]?.size?.to)
                    maxSize = data?.[i]?.size?.size || data?.[i]?.size?.to;
                  else maxSize = -Infinity;
                  floorAreaMin.push(Math.round(minPrice / minSize));
                  floorAreaMax.push(Math.round(maxPrice / maxSize));
                }
                plan = {
                  ...plan,
                  price: { ...plan?.price, price: { ...plan?.price?.price, from: +min, to: +max, autoUpdate: true } },
                };
                plan = {
                  ...plan,
                  price: {
                    ...plan?.price,
                    pricePerSqft: {
                      ...plan?.price?.pricePerSqft,
                      from: Math.min(...floorAreaMin),
                      to: Math.max(...floorAreaMax),
                      autoUpdate: true,
                    },
                  },
                };
              }
              console.log("plan confirm: ", plan);
              if (type === "edit") {
                dispatch(
                  editDevelopment(
                    id,
                    plan,
                    (res) => {
                      dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": null }));
                      CB({ success: true });
                    },
                    (res) => {
                      console.log("res: ", res);
                    }
                  )
                );
              } else {
                dispatch(handleAddDevelopment({ ...addDevelopment, data: plan, "dev-floorplans": null }));
                CB({ success: true });
              }
            } else if (result.isDenied) {
              if (type === "edit") {
                dispatch(
                  editDevelopment(
                    id,
                    plan,
                    (res) => {
                      dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": null }));
                      CB({ success: true });
                    },
                    (res) => {
                      console.log("res: ", res);
                    }
                  )
                );
              } else {
                dispatch(handleAddDevelopment({ ...addDevelopment, data: plan, "dev-floorplans": null }));
                CB({ success: true });
              }
            } else {
              return;
            }
          });
        } else {
          if (floorPlans?.length) {
            if (devData?.bedrooms?.autoUpdate) {
              plan = {
                ...plan,
                bedrooms: {
                  ...plan.bedrooms,
                  min: Math.min(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)),
                  max: Math.max(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)),
                  autoUpdate: devData?.bedrooms?.autoUpdate,
                },
              };
            }
            if (devData?.bathrooms?.autoUpdate) {
              plan = {
                ...plan,
                bathrooms: {
                  ...plan.bathrooms,
                  min: Math.min(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)),
                  max: Math.max(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)),
                  autoUpdate: devData?.bathrooms?.autoUpdate,
                },
              };
            }
            if (devData?.floorArea?.autoUpdate) {
              plan = {
                ...plan,
                floorArea: {
                  ...plan.floorArea,
                  min: Math.min(
                    ...floorPlans
                      ?.filter((li) => li?.status === "available")
                      ?.map((li) => {
                        let min = li?.size?.from || li?.size?.size;
                        return min;
                      })
                  ),
                  max: Math.max(
                    ...floorPlans
                      ?.filter((li) => li?.status === "available")
                      ?.map((li) => {
                        let max = li?.size?.to || li?.size?.size;
                        return max;
                      })
                  ),
                  autoUpdate: devData?.floorArea?.autoUpdate,
                },
              };
            }
            let data = floorPlans?.filter((li) => li?.status === "available");
            // Create an array of all the prices
            const prices = data?.map((plan) => {
              if (plan?.price?.range) {
                if (plan?.price?.from !== 0 && plan?.price?.to !== 0) {
                  return [Number(plan?.price?.from), Number(plan?.price?.to)];
                } else {
                  return [0];
                }
              } else {
                if (plan?.price?.price) {
                  return [Number(plan?.price?.price)];
                } else {
                  return [0];
                }
              }
            });
            let flattenedPrices = [].concat(...prices);
            let filteredPrices = flattenedPrices.filter((price) => price !== 0);
            filteredPrices = filteredPrices.filter(function (value) {
              return !isNaN(value);
            });
            let min = Math.min(...filteredPrices);
            let max = Math.max(...filteredPrices);
            let floorAreaMin = [];
            let floorAreaMax = [];
            for (let i = 0; i < data?.length; i++) {
              let minPrice;
              let minSize;
              let maxPrice;
              let maxSize;
              if (data?.[i]?.price?.price || data?.[i]?.price?.from)
                minPrice = data?.[i]?.price?.price || data?.[i]?.price?.from;
              else minPrice = +Infinity;
              if (data?.[i]?.size?.size || data?.[i]?.size?.from)
                minSize = data?.[i]?.size?.size || data?.[i]?.size?.from;
              else minSize = +Infinity;
              if (data?.[i]?.price?.price || data?.[i]?.price?.to)
                maxPrice = data?.[i]?.price?.price || data?.[i]?.price?.to;
              else maxPrice = -Infinity;
              if (data?.[i]?.size?.size || data?.[i]?.size?.to) maxSize = data?.[i]?.size?.size || data?.[i]?.size?.to;
              else maxSize = -Infinity;
              floorAreaMin.push(Math.round(minPrice / minSize));
              floorAreaMax.push(Math.round(maxPrice / maxSize));
            }
            floorAreaMin = floorAreaMin.filter(function (value) {
              return !isNaN(value);
            });
            floorAreaMax = floorAreaMax.filter(function (value) {
              return !isNaN(value);
            });
            if (devData?.price?.price?.autoUpdate === true) {
              plan = {
                ...plan,
                price: { ...plan?.price, price: { ...plan?.price?.price, from: +min, to: +max, autoUpdate: true } },
              };
            } else if (devData?.price?.price?.autoUpdate === false) {
            } else {
              plan = {
                ...plan,
                price: { ...plan?.price, price: { ...plan?.price?.price, from: +min, to: +max, autoUpdate: true } },
              };
            }
            if (devData?.price?.pricePerSqft?.autoUpdate === true) {
              plan = {
                ...plan,
                price: {
                  ...plan?.price,
                  pricePerSqft: {
                    ...plan?.price?.pricePerSqft,
                    from: Math.min(...floorAreaMin),
                    to: Math.max(...floorAreaMax),
                    autoUpdate: true,
                  },
                },
              };
            } else if (devData?.price?.pricePerSqft?.autoUpdate === false) {
            } else {
              plan = {
                ...plan,
                price: {
                  ...plan?.price,
                  pricePerSqft: {
                    ...plan?.price?.pricePerSqft,
                    from: Math.min(...floorAreaMin),
                    to: Math.max(...floorAreaMax),
                    autoUpdate: true,
                  },
                },
              };
            }
          }
          console.log("plan if all auto available: ", plan);
          if (type === "edit") {
            dispatch(
              editDevelopment(
                id,
                plan,
                (res) => {
                  dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": null }));
                  CB({ success: true });
                },
                (res) => {
                  console.log("res: ", res);
                }
              )
            );
          } else {
            dispatch(handleAddDevelopment({ ...addDevelopment, data: plan, "dev-floorplans": null }));
            CB({ success: true });
          }
        }
      } else {
        statusRef.current.focus();
        toast.error("Please Add Floorplans");
      }
    } else if (checkBox === "comingSoon") {
      if (devData?.bedrooms?.autoUpdate)
        plan = { ...plan, bedrooms: { min: null, max: null, autoUpdate: devData?.bedrooms?.autoUpdate } };
      if (devData?.bathrooms?.autoUpdate)
        plan = { ...plan, bathrooms: { min: null, max: null, autoUpdate: devData?.bathrooms?.autoUpdate } };
      if (devData?.floorArea?.autoUpdate)
        plan = { ...plan, floorArea: { min: null, max: null, autoUpdate: devData?.floorArea?.autoUpdate } };
      if (devData?.price?.price?.autoUpdate)
        plan = {
          ...plan,
          price: { ...plan?.price, price: { ...plan?.price?.price, from: null, to: null, autoUpdate: false } },
        };

      if (devData?.price?.pricePerSqft?.autoUpdate)
        plan = {
          ...plan,
          price: {
            ...plan?.price,
            pricePerSqft: { ...plan?.price?.pricePerSqft, from: null, to: null, autoUpdate: false },
          },
        };
      console.log("plan: comingSoon", plan);
      if (type === "edit") {
        dispatch(
          editDevelopment(
            id,
            plan,
            (res) => {
              dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": null }));
              CB({ success: true });
            },
            (res) => {
              console.log("res: ", res);
            }
          )
        );
      } else {
        dispatch(handleAddDevelopment({ ...addDevelopment, data: plan, "dev-floorplans": null }));
        CB({ success: true });
      }
    }
  };

  const handleNext = () => {
    handleAddUpdate((res) => {
      if (res?.success) toggleNext();
    });
  };

  const addToDraft = () => {
    let plan = {
      ...devData,
      floorPlans: { planAvailable: checkBox, floorPlans: floorPlans },
    };
    if (profile?.profile?.role === "associate")
      plan = {
        ...plan,
        company: { ...plan?.company, associate: { associate: profile?.profile?._id, byAssociate: true } },
      };
    if (checkBox)
      plan = {
        ...plan,
        floorPlans: { ...plan.floorPlans, morePlans: checkBox === "comingSoon" ? false : moreFloorPlans },
      };
    else plan = { ...plan, floorPlans: { ...plan.floorPlans, morePlans: null } };
    plan.type = "presale";
    if (type === "edit") plan.status = devData?.status;
    else plan.status = "draft";
    if (checkBox === "available") {
      if (floorPlans?.length) {
        if (
          !devData?.floorArea?.autoUpdate ||
          !devData?.bedrooms?.autoUpdate ||
          !devData?.bathrooms?.autoUpdate ||
          !devData?.price?.price?.autoUpdate ||
          !devData?.price?.pricePerSqft?.autoUpdate
        ) {
          Swal.fire({
            title:
              "Do you want to auto-update Bedrooms, Bathrooms, FloorArea, Price and Price per sqft range as per floorplans?",
            icon: "question",
            confirmButtonText: "Yes",
            buttonsStyling: {},
            showDenyButton: true,
            denyButtonText: "No",
            denyButtonColor: "#5a8dee",
          }).then((result) => {
            if (result.isConfirmed) {
              if (floorPlans?.length) {
                plan = {
                  ...plan,
                  bedrooms: {
                    ...plan.bedrooms,
                    min: Math.min(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)
                    ),
                    max: Math.max(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)
                    ),
                    autoUpdate: true,
                  },
                };
                plan = {
                  ...plan,
                  bathrooms: {
                    ...plan.bathrooms,
                    min: Math.min(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)
                    ),
                    max: Math.max(
                      ...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)
                    ),
                    autoUpdate: true,
                  },
                };
                plan = {
                  ...plan,
                  floorArea: {
                    ...plan.floorArea,
                    min: Math.min(
                      ...floorPlans
                        ?.filter((li) => li?.status === "available")
                        ?.map((li) => {
                          let min = li?.size?.from || li?.size?.size;
                          return min;
                        })
                    ),
                    max: Math.max(
                      ...floorPlans
                        ?.filter((li) => li?.status === "available")
                        ?.map((li) => {
                          let max = li?.size?.to || li?.size?.size;
                          return max;
                        })
                    ),
                    autoUpdate: true,
                  },
                };
                let data = floorPlans?.filter((li) => li?.status === "available");
                // Create an array of all the prices
                const prices = data?.map((plan) => {
                  if (plan?.price?.range) {
                    if (plan?.price?.from !== 0 && plan?.price?.to !== 0) {
                      return [Number(plan?.price?.from), Number(plan?.price?.to)];
                    } else {
                      return [0];
                    }
                  } else {
                    if (plan?.price?.price) {
                      return [Number(plan?.price?.price)];
                    } else {
                      return [0];
                    }
                  }
                });
                let flattenedPrices = [].concat(...prices);
                let filteredPrices = flattenedPrices.filter((price) => price !== 0);
                let min = Math.min(...filteredPrices);
                let max = Math.max(...filteredPrices);
                let floorAreaMin = [];
                let floorAreaMax = [];
                for (let i = 0; i < data?.length; i++) {
                  let minPrice;
                  let minSize;
                  let maxPrice;
                  let maxSize;
                  if (data?.[i]?.price?.price || data?.[i]?.price?.from)
                    minPrice = data?.[i]?.price?.price || data?.[i]?.price?.from;
                  else minPrice = +Infinity;
                  if (data?.[i]?.size?.size || data?.[i]?.size?.from)
                    minSize = data?.[i]?.size?.size || data?.[i]?.size?.from;
                  else minSize = +Infinity;
                  if (data?.[i]?.price?.price || data?.[i]?.price?.to)
                    maxPrice = data?.[i]?.price?.price || data?.[i]?.price?.to;
                  else maxPrice = -Infinity;
                  if (data?.[i]?.size?.size || data?.[i]?.size?.to)
                    maxSize = data?.[i]?.size?.size || data?.[i]?.size?.to;
                  else maxSize = -Infinity;
                  floorAreaMin.push(Math.round(minPrice / minSize));
                  floorAreaMax.push(Math.round(maxPrice / maxSize));
                }
                plan = {
                  ...plan,
                  price: { ...plan?.price, price: { ...plan?.price?.price, from: +min, to: +max, autoUpdate: true } },
                };

                plan = {
                  ...plan,
                  price: {
                    ...plan?.price,
                    pricePerSqft: {
                      ...plan?.price?.pricePerSqft,
                      from: Math.min(...floorAreaMin),
                      to: Math.max(...floorAreaMax),
                      autoUpdate: true,
                    },
                  },
                };
              }
              console.log("plan confirm: ", plan);
              if (type === "edit") {
                dispatch(
                  editDevelopment(
                    id,
                    plan,
                    (res) => {
                      toast.success("Development updated");
                      dispatch(handleDevAddReset());
                      navigate("/development");
                    },
                    (res) => {
                      toast.error(res?.message);
                    }
                  )
                );
              } else {
                dispatch(
                  addNewDevelopment(
                    plan,
                    (res) => {
                      toast.success("Development added as draft");
                      dispatch(handleDevAddReset());
                      navigate("/development");
                    },
                    (res) => {
                      toast.error(res?.message);
                    }
                  )
                );
              }
            } else if (result.isDenied) {
              console.log("plan deny: ", plan);
              if (type === "edit") {
                dispatch(
                  editDevelopment(
                    id,
                    plan,
                    (res) => {
                      toast.success("Development updated");
                      dispatch(handleDevAddReset());
                      navigate("/development");
                    },
                    (res) => {
                      toast.error(res?.message);
                    }
                  )
                );
              } else {
                // handleUnsavedChanges("No");
                dispatch(
                  addNewDevelopment(
                    plan,
                    (res) => {
                      toast.success("Development added as draft");
                      dispatch(handleDevAddReset());
                      navigate("/development");
                    },
                    (res) => {
                      toast.error(res?.message);
                    }
                  )
                );
              }
            } else {
              return;
            }
          });
        } else {
          if (floorPlans?.length) {
            if (devData?.bedrooms?.autoUpdate) {
              plan = {
                ...plan,
                bedrooms: {
                  ...plan.bedrooms,
                  min: Math.min(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)),
                  max: Math.max(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bedrooms)),
                  autoUpdate: devData?.bedrooms?.autoUpdate,
                },
              };
            }
            if (devData?.bathrooms?.autoUpdate) {
              plan = {
                ...plan,
                bathrooms: {
                  ...plan.bathrooms,
                  min: Math.min(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)),
                  max: Math.max(...floorPlans?.filter((li) => li?.status === "available")?.map((li) => +li?.bathrooms)),
                  autoUpdate: devData?.bathrooms?.autoUpdate,
                },
              };
            }
            if (devData?.floorArea?.autoUpdate) {
              plan = {
                ...plan,
                floorArea: {
                  ...plan.floorArea,
                  min: Math.min(
                    ...floorPlans
                      ?.filter((li) => li?.status === "available")
                      ?.map((li) => {
                        let min = li?.size?.from || li?.size?.size;
                        return min;
                      })
                  ),
                  max: Math.max(
                    ...floorPlans
                      ?.filter((li) => li?.status === "available")
                      ?.map((li) => {
                        let max = li?.size?.to || li?.size?.size;
                        return max;
                      })
                  ),
                  autoUpdate: devData?.floorArea?.autoUpdate,
                },
              };
            }
            let data = floorPlans?.filter((li) => li?.status === "available");
            // Create an array of all the prices
            const prices = data?.map((plan) => {
              if (plan?.price?.range) {
                if (plan?.price?.from !== 0 && plan?.price?.to !== 0) {
                  return [Number(plan?.price?.from), Number(plan?.price?.to)];
                } else {
                  return [0];
                }
              } else {
                if (plan?.price?.price) {
                  return [Number(plan?.price?.price)];
                } else {
                  return [0];
                }
              }
            });
            let flattenedPrices = [].concat(...prices);
            let filteredPrices = flattenedPrices.filter((price) => price !== 0);
            let min = Math.min(...filteredPrices);
            let max = Math.max(...filteredPrices);
            let floorAreaMin = [];
            let floorAreaMax = [];
            for (let i = 0; i < data?.length; i++) {
              let minPrice;
              let minSize;
              let maxPrice;
              let maxSize;
              if (data?.[i]?.price?.price || data?.[i]?.price?.from)
                minPrice = data?.[i]?.price?.price || data?.[i]?.price?.from;
              else minPrice = +Infinity;
              if (data?.[i]?.size?.size || data?.[i]?.size?.from)
                minSize = data?.[i]?.size?.size || data?.[i]?.size?.from;
              else minSize = +Infinity;
              if (data?.[i]?.price?.price || data?.[i]?.price?.to)
                maxPrice = data?.[i]?.price?.price || data?.[i]?.price?.to;
              else maxPrice = -Infinity;
              if (data?.[i]?.size?.size || data?.[i]?.size?.to) maxSize = data?.[i]?.size?.size || data?.[i]?.size?.to;
              else maxSize = -Infinity;
              floorAreaMin.push(Math.round(minPrice / minSize));
              floorAreaMax.push(Math.round(maxPrice / maxSize));
            }
            if (devData?.price?.price?.autoUpdate === true) {
              plan = {
                ...plan,
                price: { ...plan?.price, price: { ...plan?.price?.price, from: +min, to: +max, autoUpdate: true } },
              };
            } else if (devData?.price?.price?.autoUpdate === false) {
            } else {
              plan = {
                ...plan,
                price: { ...plan?.price, price: { ...plan?.price?.price, from: +min, to: +max, autoUpdate: true } },
              };
            }

            if (devData?.price?.pricePerSqft?.autoUpdate === true) {
              plan = {
                ...plan,
                price: {
                  ...plan?.price,
                  pricePerSqft: {
                    ...plan?.price?.pricePerSqft,
                    from: Math.min(...floorAreaMin),
                    to: Math.max(...floorAreaMax),
                    autoUpdate: true,
                  },
                },
              };
            } else if (devData?.price?.pricePerSqft?.autoUpdate === false) {
            } else {
              plan = {
                ...plan,
                price: {
                  ...plan?.price,
                  pricePerSqft: {
                    ...plan?.price?.pricePerSqft,
                    from: Math.min(...floorAreaMin),
                    to: Math.max(...floorAreaMax),
                    autoUpdate: true,
                  },
                },
              };
            }
          }
          console.log("plan if all auto available: ", plan);
          if (type === "edit") {
            dispatch(
              editDevelopment(
                id,
                plan,
                (res) => {
                  toast.success("Development updated");
                  dispatch(handleDevAddReset());
                  navigate("/development");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            // handleUnsavedChanges("No");
            dispatch(
              addNewDevelopment(
                plan,
                (res) => {
                  toast.success("Development added as draft");
                  dispatch(handleDevAddReset());
                  navigate("/development");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          }
        }
      } else {
        statusRef.current.focus();
        toast.error("Please Add Floorplans");
      }
    } else if (checkBox === "comingSoon") {
      if (devData?.bedrooms?.autoUpdate)
        plan = { ...plan, bedrooms: { min: null, max: null, autoUpdate: devData?.bedrooms?.autoUpdate } };
      if (devData?.bathrooms?.autoUpdate)
        plan = { ...plan, bathrooms: { min: null, max: null, autoUpdate: devData?.bathrooms?.autoUpdate } };
      if (devData?.floorArea?.autoUpdate)
        plan = { ...plan, floorArea: { min: null, max: null, autoUpdate: devData?.floorArea?.autoUpdate } };
      if (devData?.price?.price?.autoUpdate) {
        plan = {
          ...plan,
          price: {
            ...plan?.price,
            price: { ...plan?.price?.price, from: null, to: null, autoUpdate: false },
          },
        };
      }
      if (devData?.price?.pricePerSqft?.autoUpdate) {
        plan = {
          ...plan,
          price: {
            ...plan?.price,
            pricePerSqft: {
              ...plan?.price?.pricePerSqft,
              from: null,
              to: null,
              autoUpdate: false,
            },
          },
        };
      }
      console.log("plan: comingSoon", plan);
      if (type === "edit") {
        dispatch(
          editDevelopment(
            id,
            plan,
            (res) => {
              toast.success("Development updated");
              dispatch(handleDevAddReset());
              navigate("/development");
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      } else {
        dispatch(
          addNewDevelopment(
            plan,
            (res) => {
              toast.success("Development added as draft");
              dispatch(handleDevAddReset());
              navigate("/development");
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      }
    }
  };

  const handleBack = () => {
    toggleBack();
  };

  useEffect(() => {
    dispatch(getHomeType());
    dispatch(getParkingList());
    dispatch(getAllHomeStyle());
    dispatch(getGarageLayoutList());
    dispatch(getcity());
  }, [dispatch]);

  useEffect(() => {
    if (homeType) dispatch(getHomeStyleByType(homeType));
  }, [dispatch, homeType]);

  useEffect(() => {
    if (developmentById?.data) setDevData(developmentById?.data);
    else if (addDevelopment?.data) setDevData(addDevelopment?.data);
  }, [developmentById, addDevelopment]);

  useEffect(() => {
    if (devData) {
      console.log("devData 5: ", devData);
      if (devData?.floorPlans?.planAvailable) setCheckBox(devData?.floorPlans?.planAvailable);
      if (devData?.floorPlans?.morePlans) setMoreFloorPlans(devData?.floorPlans?.morePlans);
      if (devData?.floorPlans?.floorPlans?.length && !addDevelopment?.["dev-floorplans"]) {
        setFloorPlans([...devData?.floorPlans?.floorPlans]);
        dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": devData?.floorPlans?.floorPlans }));
      }
    }
  }, [devData]);

  useEffect(() => {
    const updatedFilteredPlans = floorPlans.filter((plan) => {
      if (sortBy === "all" && sortByBeds === "all") return true;
      if (sortBy !== "all" && plan.status !== sortBy) return false;
      if (sortByBeds === "5+" && +plan.bedrooms > 5) return true;
      if (sortByBeds !== "all" && +plan.bedrooms !== +sortByBeds) return false;
      return true;
    });
    const searchedPlans = updatedFilteredPlans?.filter((plan) =>
      plan?.title?.toLowerCase()?.includes(search?.toLowerCase())
    );
    setAllPlans(searchedPlans);
  }, [floorPlans, sortBy, sortByBeds, search]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {devData?.name ? devData?.name : type === "add" ? "Add Development" : "Edit Development"}
              </BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-inline-flex"
                onClick={(ev) => {
                  ev.preventDefault();
                  addToDraft();
                }}
              >
                <span>{type === "edit" ? "Update Development" : "Save as Draft"}</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <DevNavTab toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">Floor Plans</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row>
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <div className={clsx("", styles.askingPriceDiv, styles.checkDiv)}>
                            <input
                              type="radio"
                              name="floorPlanRadio"
                              onChange={(e) => setCheckBox(e.target.value)}
                              checked={checkBox === "comingSoon"}
                              value={"comingSoon"}
                              id="flexRadioDefault1"
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                              Floor plans coming soon! Please register for updates.
                            </label>
                          </div>
                        </Col>
                        <Col sm={"12"} md={"12"} lg={"12"}>
                          <div className={clsx("", styles.askingPriceDiv, styles.checkDiv)}>
                            <input
                              type="radio"
                              name="floorPlanRadio"
                              checked={checkBox === "available"}
                              onChange={(e) => setCheckBox(e.target.value)}
                              value={"available"}
                              id="flexRadioDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                              Floor Plans Available
                            </label>
                          </div>
                        </Col>
                        {checkBox === "available" ? (
                          <Col sm={"12"} md={"12"} lg={"12"}>
                            <div
                              className={clsx("", styles.askingPriceDiv, styles.checkDiv)}
                              style={{ margin: "0 0 0 20px" }}
                            >
                              <input
                                id="moreFloorPlans"
                                type="checkbox"
                                name="priceAuto"
                                onChange={(e) => setMoreFloorPlans(e.target.checked)}
                                checked={moreFloorPlans}
                              />
                              <label className="form-check-label" htmlFor="moreFloorPlans">
                                More floor Plans Coming!
                              </label>
                            </div>
                          </Col>
                        ) : null}
                      </Row>

                      {checkBox === "available" ? (
                        <>
                          <br />
                          <hr style={{ backgroundColor: "#00aeff", width: "100%" }} />
                          <br />
                        </>
                      ) : null}

                      {checkBox === "available" ? (
                        <>
                          <Row>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Status*</p>
                                <select
                                  ref={statusRef}
                                  className={clsx("arrows", styles.selectTag, mandStatus ? "error" : "")}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  value={status}
                                  name="status"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                    setMandStatus(false);
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  <option value={"available"}>Available</option>
                                  <option value={"sold"}>Sold</option>
                                  <option value={"onHold"}>On Hold</option>
                                </select>
                              </div>
                            </Col>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Home Type*</p>

                                <select
                                  className={clsx("arrows", styles.selectTag, mandType ? "error" : "")}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  ref={typeRef}
                                  value={homeType}
                                  name="homeType"
                                  onChange={(e) => {
                                    setHomeType(e.target.value);
                                    setMandType(false);
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  {homeTypeData?.homeType?.length
                                    ? homeTypeData?.homeType?.map((li, i) =>
                                        devData?.homeTypes?.includes(li?._id) ? (
                                          <option key={`${li?._id}-${i}`} value={li?._id}>
                                            {li?.name}
                                          </option>
                                        ) : null
                                      )
                                    : null}
                                </select>
                              </div>
                            </Col>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Plan Title*</p>
                                <input
                                  type="text"
                                  placeholder={"Plan name"}
                                  className={clsx("form-control", styles.textField, mandTitle ? "error" : "")}
                                  ref={titleRef}
                                  value={planTitle}
                                  name="planTitle"
                                  onChange={(e) => {
                                    setPlanTitle(e.target.value);
                                    setMandTitle(false);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Plan Type*</p>
                                <select
                                  className={clsx("arrows", styles.selectTag, mandPlanType ? "error" : "")}
                                  style={{
                                    padding: "7px 15px",
                                    border: "0.2px solid #dddddd",
                                  }}
                                  ref={planTypeRef}
                                  value={planType}
                                  name="planType"
                                  onChange={(e) => {
                                    setPlanType(e.target.value);
                                    setMandPlanType(false);
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  {homeStyle?.homeStyle?.length ? (
                                    homeStyle?.homeStyle?.map((li, i) => (
                                      <option key={`${li?._id}-${i}`} value={li?._id}>
                                        {li?.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value={""}>please select home type first</option>
                                  )}
                                </select>
                              </div>
                            </Col>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Plan Bedrooms*</p>
                                <input
                                  onWheel={(e) => e.target.blur()}
                                  placeholder={"No. of bedrooms"}
                                  className={clsx("form-control", styles.textField, mandBedrooms ? "error" : "")}
                                  ref={bedroomsRef}
                                  value={bedrooms}
                                  name="bedrooms"
                                  onChange={(e) => {
                                    if (numberRegex(e.target.value)) {
                                      setBedrooms(e.target.value);
                                      setMandBedrooms(false);
                                    } else {
                                      return;
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Plan Bathrooms*</p>
                                <input
                                  onWheel={(e) => e.target.blur()}
                                  placeholder={"No. of bathrooms"}
                                  className={clsx("form-control", styles.textField, mandBathrooms ? "error" : "")}
                                  ref={bathroomsRef}
                                  value={bathrooms}
                                  name="bathrooms"
                                  onChange={(e) => {
                                    if (numberRegex(e.target.value)) {
                                      setBathrooms(e.target.value);
                                      setMandBathrooms(false);
                                    } else {
                                      return;
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Plan Price</p>
                                {planPriceRange ? (
                                  <Row>
                                    <Col sm={"6"} className={clsx("")}>
                                      <div className={`form-control-wrap`}>
                                        <div className="input-group input-group">
                                          <div className="input-group-prepend">
                                            <span className={`input-group-text`} id="inputGroup-sizing-md">
                                              From
                                            </span>
                                          </div>
                                          <input
                                            className="form-control"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            value={planPriceFrom}
                                            name="planPriceFrom"
                                            onChange={(e) => {
                                              setPlanPriceFrom(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={"6"}>
                                      <div className={`form-control-wrap`}>
                                        <div className="input-group input-group">
                                          <div className="input-group-prepend">
                                            <span className={`input-group-text`} id="inputGroup-sizing-md">
                                              To
                                            </span>
                                          </div>
                                          <input
                                            className="form-control"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            value={planPriceTo}
                                            name="planPriceTo"
                                            onChange={(e) => {
                                              setPlanPriceTo(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col sm={"3"}>
                                      <select
                                        className={clsx("arrows", styles.selectTag)}
                                        style={{
                                          padding: "7px 15px",
                                          border: "0.2px solid #dddddd",
                                        }}
                                        value={planPriceSelect}
                                        name="planPriceSelect"
                                        onChange={(e) => {
                                          setplanPriceSelect(e.target.value);
                                        }}
                                      >
                                        <option value={""}>Select</option>
                                        <option value={"from"}>From</option>
                                        <option value={"low"}>Low</option>
                                        <option value={"mid"}>Mid</option>
                                        <option value={"high"}>High</option>
                                      </select>
                                    </Col>
                                    <Col sm={"9"}>
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder={"Enter Price"}
                                        className={clsx("form-control", styles.textField)}
                                        value={planPrice || ""}
                                        name="planPrice"
                                        onChange={(e) => {
                                          setplanPrice(e.target.value);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                )}
                                <div className={clsx(styles.checkDiv)}>
                                  <input
                                    type="checkbox"
                                    name="planPriceRange"
                                    checked={planPriceRange}
                                    onChange={(e) => {
                                      setPlanPriceRange(e.target.checked);
                                      setplanPrice("");
                                      setPlanPriceFrom("");
                                      setPlanPriceTo("");
                                      setplanPriceSelect("");
                                    }}
                                    id="planPriceRange"
                                  />
                                  <label htmlFor="planPriceRange" className="form-check-label">
                                    Range
                                  </label>
                                </div>
                              </div>
                            </Col>
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx(styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Plan Size - Internal (sqft)*</p>
                                {planSizeRange ? (
                                  <Row>
                                    <Col sm={"6"}>
                                      <div className={`form-control-wrap`}>
                                        <div className="input-group input-group">
                                          <div className="input-group-prepend">
                                            <span className={`input-group-text`} id="inputGroup-sizing-md">
                                              From
                                            </span>
                                          </div>
                                          <input
                                            type="number"
                                            className={clsx("form-control", mandPlanSize ? "error" : "")}
                                            onWheel={(e) => e.target.blur()}
                                            ref={planSizeRef}
                                            value={planSizeFrom}
                                            name="planSizeFrom"
                                            onChange={(e) => {
                                              setPlanSizeFrom(e.target.value);
                                              setMandPlanSize(false);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={"6"}>
                                      <div className={`form-control-wrap`}>
                                        <div className="input-group input-group">
                                          <div className="input-group-prepend">
                                            <span className={`input-group-text`} id="inputGroup-sizing-md">
                                              To
                                            </span>
                                          </div>
                                          <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            className={clsx("form-control")}
                                            value={planSizeTo}
                                            name="planSizeTo"
                                            onChange={(e) => {
                                              setPlanSizeTo(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col sm={"12"}>
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder={"Enter size"}
                                        className={clsx("form-control", styles.textField, mandPlanSize ? "error" : "")}
                                        ref={planSizeRef}
                                        value={planSize}
                                        name="planSize"
                                        onChange={(e) => {
                                          setPlanSize(e.target.value);
                                          setMandPlanSize(false);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                )}
                                <div className={clsx(styles.checkDiv)}>
                                  <input
                                    type="checkbox"
                                    id="planSizeRange"
                                    name="planSizeRange"
                                    checked={planSizeRange}
                                    onChange={(e) => {
                                      setPlanSizeRange(e.target.checked);
                                      setPlanSizeFrom("");
                                      setPlanSizeTo("");
                                      setPlanSize("");
                                    }}
                                  />
                                  <label htmlFor="planSizeRange" className="form-check-label">
                                    Range
                                  </label>
                                </div>
                              </div>
                            </Col>
                            {homeType && homeType !== "63c66c47cec8665e878ce4df" ? (
                              <>
                                <Col sm={"6"} md={"6"} lg={"6"}>
                                  <div className={clsx("", styles.askingPriceDiv)}>
                                    <p className={clsx(styles.heightPara)}>Parking</p>
                                    <select
                                      className={clsx("arrows", styles.selectTag)}
                                      style={{
                                        padding: "7px 15px",
                                        border: "0.2px solid #dddddd",
                                      }}
                                      value={parking}
                                      name="parking"
                                      onChange={(e) => {
                                        setParkingArray([...parkingArray, e.target.value]);
                                        setParking("");
                                      }}
                                    >
                                      <option value={""}>Select</option>
                                      {parkingData?.parking?.map((li, i) =>
                                        !parkingArray?.includes(li?._id) ? (
                                          <option key={`${li?._id}-${i}`} value={li?._id}>
                                            {li?.name}
                                          </option>
                                        ) : null
                                      )}
                                    </select>
                                  </div>
                                  <div className={styles.arrayItemDiv}>
                                    {parkingArray?.length
                                      ? parkingArray?.map((item, index) => {
                                          return (
                                            <p
                                              key={`${Math.random()}-${index}`}
                                              className={styles.para2}
                                              onClick={() => {
                                                let a = parkingArray?.indexOf(item);
                                                if (a > -1) {
                                                  console.log("a: ", a);
                                                  parkingArray?.splice(a, 1);
                                                  setParkingArray([...parkingArray]);
                                                  console.log("parkingArray: ", parkingArray);
                                                }
                                              }}
                                            >
                                              <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                                              <span>
                                                {parkingData?.parking?.filter((li) => li?._id === item)?.[0]?.name}
                                              </span>
                                            </p>
                                          );
                                        })
                                      : null}
                                  </div>
                                </Col>

                                {/* Garage Layout */}
                                {parkingArray?.includes("64410350000d159c246c0a4f") ||
                                parkingArray?.includes("64410350000d159c246c0a50") ? (
                                  <Col sm={"6"} md={"3"} lg={"3"}>
                                    <div className={clsx(styles.askingPriceDiv)}>
                                      <p className={clsx(styles.heightPara)}>Garage Layout</p>
                                      <select
                                        className={clsx("arrows", styles.selectTag)}
                                        style={{
                                          padding: "7px 15px",
                                          border: "0.2px solid #dddddd",
                                        }}
                                        value={garageLayout}
                                        name="garageLayout"
                                        onChange={(e) => {
                                          setGarageLayout(e.target.value);
                                        }}
                                      >
                                        <option value={""}>Select</option>
                                        {garageLayoutData?.garageLayout?.map((li, i) => (
                                          <option key={`${li?._id}-${i}`} value={li?._id}>
                                            {li?.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                ) : null}
                              </>
                            ) : null}
                            {/* Rooftop Deck */}
                            {homeType === "63c66c47cec8665e878ce4df" ? null : (
                              <Col sm={"6"} md={"3"} lg={"3"} className={clsx("")}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p className={clsx(styles.heightPara)}>Rooftop Deck</p>
                                  <select
                                    className={clsx("arrows", styles.selectTag)}
                                    style={{
                                      padding: "7px 15px",
                                      border: "0.2px solid #dddddd",
                                    }}
                                    value={rooftopDeck}
                                    name="rooftopDeck"
                                    onChange={(e) => {
                                      setRooftopDeck(e.target.value);
                                    }}
                                  >
                                    <option value={""}>Select</option>
                                    <option value={"Yes"}>Yes</option>
                                    <option value={"No"}>No</option>
                                  </select>
                                </div>
                              </Col>
                            )}
                            {homeType === "63c66c47cec8665e878ce4df" ? (
                              <Col sm={"6"} md={"6"} lg={"6"}>
                                <div className={clsx(styles.askingPriceDiv)}>
                                  <p className={clsx(styles.heightPara)}>Balcony Size (sqft)</p>

                                  {balconySizeRange ? (
                                    <Row>
                                      <Col sm={"6"}>
                                        <div className={`form-control-wrap`}>
                                          <div className="input-group input-group">
                                            <div className="input-group-prepend">
                                              <span className={`input-group-text`} id="inputGroup-sizing-md">
                                                From
                                              </span>
                                            </div>
                                            <input
                                              type="number"
                                              onWheel={(e) => e.target.blur()}
                                              className={clsx("form-control")}
                                              value={balconySizeFrom}
                                              name="balconySizeFrom"
                                              onChange={(e) => {
                                                setBalconySizeFrom(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm={"6"}>
                                        <div className={`form-control-wrap`}>
                                          <div className="input-group input-group">
                                            <div className="input-group-prepend">
                                              <span className={`input-group-text`} id="inputGroup-sizing-md">
                                                To
                                              </span>
                                            </div>
                                            <input
                                              type="number"
                                              onWheel={(e) => e.target.blur()}
                                              className={clsx("form-control")}
                                              value={balconySizeTo}
                                              name="balconySizeTo"
                                              onChange={(e) => {
                                                setBalconySizeTo(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Row>
                                      <Col sm={"12"}>
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          placeholder={"Enter size"}
                                          className={clsx("form-control", styles.textField)}
                                          value={balconySize}
                                          name="balconySize"
                                          onChange={(e) => {
                                            setBalconySize(e.target.value);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  <div className={clsx(styles.checkDiv)}>
                                    <input
                                      type="checkbox"
                                      id="balconySizeRange"
                                      name="balconySizeRange"
                                      checked={balconySizeRange}
                                      onChange={(e) => {
                                        setBalconySizeRange(e.target.checked);
                                        setBalconySize("");
                                        setBalconySizeFrom("");
                                        setBalconySizeTo("");
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="balconySizeRange">
                                      Range
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                            <Col sm={"6"} md={"6"} lg={"6"}>
                              <div className={clsx("", styles.askingPriceDiv)}>
                                <p className={clsx(styles.heightPara)}>Plan Images</p>
                                <div {...getRootProps()} className={clsx("form-control", styles.uploadInput)}>
                                  <p>{planImage ? planImage?.name : `Select the floor plan image (png or jpeg)`}</p>
                                  <input type="text" name="planImage" {...getInputProps()} />
                                </div>

                                <div className={styles.arrayImageDiv}>
                                  {planImageArray?.length && !trigger
                                    ? planImageArray?.map((item, index) => {
                                        return (
                                          <div key={`${Math.random()}-${index}`}>
                                            <img src={`${process.env.REACT_APP_IMGURL}${item}`} alt={item} />
                                            <span
                                              onClick={() => {
                                                handleDelete(item);
                                              }}
                                            >
                                              <AiOutlineCloseCircle className={clsx(styles.arrayCloseIcon)} />
                                              Delete
                                            </span>
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div className={clsx(styles.seletTag2, "mt-4")}>
                            <Button
                              color="info"
                              style={{ fontSize: "14px", borderRadius: "5px" }}
                              startIcon={isEdit ? null : <MdAddCircleOutline />}
                              onClick={() => {
                                handleAddMorePlans();
                                if (isEdit) setIsEdit(false);
                              }}
                            >
                              <span>{isEdit ? "UPDATE" : "ADD FLOOR PLAN"}</span>
                            </Button>
                          </div>

                          {floorPlans?.length ? (
                            <>
                              <br />
                              <hr style={{ backgroundColor: "#00aeff", width: "100%" }} />
                              <br />

                              <div className={styles.allPlansArray}>
                                {/* Search and Sort */}
                                <h4>Floor Plans ({allPlans?.length})</h4>

                                <SortingBar
                                  search={search}
                                  setSearch={setSearch}
                                  sortBy={sortBy}
                                  setSortBy={setSortBy}
                                  sortByBeds={sortByBeds}
                                  setSortByBeds={setSortByBeds}
                                  allPlans={allPlans}
                                  floorPlans={floorPlans}
                                />

                                {/* Plans */}
                                <div
                                  style={{
                                    marginTop: "2.5rem",
                                  }}
                                >
                                  {allPlans?.length ? (
                                    allPlans?.map((li, i) => {
                                      return (
                                        <FloorPlanArray
                                          key={`${i}-${Math.random()}`}
                                          li={li}
                                          handleEdit={handleEdit}
                                          handleDuplicate={handleDuplicate}
                                          handleDelete={handleDeletePlan}
                                        />
                                      );
                                    })
                                  ) : !allPlans?.length && (sortBy !== "all" || sortByBeds !== "all") ? (
                                    <div className={"notFound"}>
                                      <p>No Floor Plans Found</p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>

            <FloorPlanEdit
              show={openEditModal}
              onHide={handleCloseEditModal}
              editData={editData}
              setEditData={setEditData}
              devData={devData}
              floorPlans={floorPlans}
              setFloorPlans={setFloorPlans}
              planTrigger={planTrigger}
              setPlanTrigger={setPlanTrigger}
            />
          </Card>

          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Back"} color={"secondary"} outline onClick={handleBack} />
            <FooterBtn text={"Next"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Dev5;
