const settingMenu = [
  { icon: "setting", text: "Main Settings", link: "/settings", access: ["superadmin"] },
  { icon: "coins", text: "Amenities", link: "/settings/amenities", access: ["superadmin"] },
  { icon: "coins", text: "Unit Features", link: "/settings/unit-features", access: ["superadmin"] },
  { icon: "coins", text: "Unit Preference", link: "/settings/unit-preferences", access: ["superadmin"] },
  { icon: "users", text: "Admin Team", link: "/settings/admin-team", access: ["superadmin"] },
  {
    icon: "users",
    text: "VIP Agent Subsciption",
    link: "/settings/vip-agent-subscription",
    access: ["superadmin"],
  },
  {
    icon: "users",
    text: "Associate Subsciption",
    link: "/settings/associate-subscription",
    access: ["superadmin"],
  },
];

export default settingMenu;
