import clsx from "clsx";
import React from "react";
import { FormGroup } from "reactstrap";
import styles from "./common.module.scss";

const SEOInputs = ({ titleValue, descriptionValue, titleChange, descriptionChange }) => {
  return (
    <div className="">
      <label style={{ fontSize: "18px" }} className="form-label" htmlFor="seo-content">
        SEO Content
      </label>
      <FormGroup className={clsx("", styles.inputDiv)}>
        <label className="form-label" htmlFor="blog-content">
          SEO Title
        </label>
        <input
          type="text"
          className="form-control"
          id="seo-title"
          value={titleValue}
          onChange={titleChange}
          placeholder="SEO Title"
        />
      </FormGroup>
      <FormGroup className={clsx("", styles.inputDiv)}>
        <label className="form-label" htmlFor="blog-content">
          SEO Description
        </label>
        <textarea
          className="form-control"
          id="seo-description"
          value={descriptionValue}
          onChange={descriptionChange}
          placeholder="SEO Description"
          rows="3"
        ></textarea>
      </FormGroup>
    </div>
  );
};

export default SEOInputs;
