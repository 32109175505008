const menu = [
  { icon: "dashboard", text: "Dashboard", link: "/" },
  { icon: "building", text: "Development", link: "/development" },
  { icon: "building", text: "Community", link: "/community" },
  { icon: "building", text: "Master Community", link: "/master-community" },
  { icon: "building", text: "Assignments", link: "/assignment" },
  { icon: "building", text: "New Homes", link: "/new-homes" },
  // { heading: "Pre-built Pages" },
  {
    icon: "users",
    text: "Accounts",
    active: false,
    link: "",
    subMenu: [
      { text: "Users", link: "/users" },
      { text: "Agents", link: "/agents" },
      { text: "Vip Agents", link: "/vip-agents" },
      { text: "Asscoiates", link: "/associates" },
    ],
  },
  // { heading: "Pre-built Pages" },
  {
    icon: "building",
    text: "Company",
    active: false,
    link: "",
    subMenu: [
      { text: "Building Company", link: "/building-company" },
      { text: "Marketing Company", link: "/marketing-company" },
      { text: "Brokerages", link: "/brokerages" },
    ],
  },
  {
    icon: "map-pin",
    text: "Location",
    active: false,
    link: "",
    subMenu: [
      { text: "Country", link: "/location/Country" },
      { text: "Province", link: "/location/province" },
      { text: "City", link: "/location/city" },
      { text: "Neighbourhood", link: "/location/neighbourhood" },
    ],
  },
  {
    icon: "article",
    text: "Blogs",
    active: false,
    link: "",
    subMenu: [
      { text: "All Blogs", link: "/blogs" },
      { text: "Blog Categories", link: "/blogs/category" },
      { text: "Blog Tags", link: "/blogs/tags" },
    ],
  },
  { icon: "coin", text: "Invoices", active: false, link: "/invoices" },
  { icon: "file-docs", text: "Feedbacks", active: false, link: "/feedbacks" },
  { icon: "chat-fill", text: "Testimony", active: false, link: "/testimony" },
  { icon: "setting", text: "Settings", active: false, link: "/settings", access: ["superadmin"] },
];

export default menu;
