import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getcity = createAsyncThunk("city/cityList", async (query) => {
  const response = await getMethod(`/api/city`, query);
  return response;
});

export const getAllcity = createAsyncThunk("city/allCityList", async () => {
  const response = await getMethod(`/api/city`);
  return response;
});

export const getcityByProvince = createAsyncThunk("city/cityListByProvince", async (id) => {
  const response = await getMethod(`/api/city-province/${id}`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  cities: { loading: false, success: false, error: false, data: null, total: null },
  allCities: { loading: false, success: false, error: false, data: null, total: null },
  cityByProvince: { loading: false, success: false, error: false, data: null, total: null },
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    cityReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getcity.pending, (state) => {
        state.cities.loading = true;
        state.cities.success = false;
        state.cities.error = false;
        state.cities.data = null;
        state.cities.total = null;
      })
      .addCase(getcity.fulfilled, (state, action) => {
        state.cities.loading = false;
        state.cities.success = true;
        state.cities.error = false;
        state.cities.data = action.payload.data;
        state.cities.total = {
          total: action?.payload?.total,
          items_per_page: action?.payload?.items_per_page || 25,
          total_pages: action?.payload?.total_pages || Math.ceil(action?.payload?.total / 25),
        };
      })
      .addCase(getcity.rejected, (state, action) => {
        state.cities.loading = false;
        state.cities.success = false;
        state.cities.data = null;
        state.cities.total = null;
        state.cities.error = true;
      });

    builder
      .addCase(getAllcity.pending, (state) => {
        state.allCities.loading = true;
        state.allCities.success = false;
        state.allCities.error = false;
        state.allCities.data = null;
        state.allCities.total = null;
      })
      .addCase(getAllcity.fulfilled, (state, action) => {
        state.allCities.loading = false;
        state.allCities.success = true;
        state.allCities.error = false;
        state.allCities.data = action.payload.data;
      })
      .addCase(getAllcity.rejected, (state, action) => {
        state.allCities.loading = false;
        state.allCities.success = false;
        state.allCities.data = null;
        state.allCities.total = null;
        state.allCities.error = true;
      });

    builder
      .addCase(getcityByProvince.pending, (state) => {
        state.cityByProvince.loading = true;
        state.cityByProvince.success = false;
        state.cityByProvince.error = false;
        state.cityByProvince.data = null;
        state.cityByProvince.total = null;
      })
      .addCase(getcityByProvince.fulfilled, (state, action) => {
        state.cityByProvince.loading = false;
        state.cityByProvince.success = true;
        state.cityByProvince.error = false;
        state.cityByProvince.data = action.payload.data;
        state.cityByProvince.total = {
          total: action?.payload?.total,
          total_pages: action?.payload?.total_pages,
          items_per_page: action?.payload?.items_per_page,
        };
      })
      .addCase(getcityByProvince.rejected, (state, action) => {
        state.cityByProvince.loading = false;
        state.cityByProvince.success = false;
        state.cityByProvince.data = null;
        state.cityByProvince.total = null;
        state.cityByProvince.error = true;
      });
  },
});

export const { isLoading, isSuccess, isError, cityReset } = citySlice.actions;

export default citySlice.reducer;

export const addCity = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/city`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const updateCity = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/city/${id}`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const deleteCity = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/city/${id}`);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};
