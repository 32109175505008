/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { v4 as uuid } from "uuid";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { getcity } from "../../redux/Apislices/citySlice";
import { handleAddDevelopment } from "../../redux/Apislices/developmentSlice";
import { getGarageLayoutList } from "../../redux/Apislices/garageLayoutSlice";
import { getAllHomeStyle, getHomeStyleByType } from "../../redux/Apislices/homeStyleSlice";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getParkingList } from "../../redux/Apislices/parkingSlice";
import { getSession, removeSession, setSession } from "../../utils/Storage";
import { convertToSlug, numberRegex } from "../../utils/Utils";
import { Button, Col, Row } from "../Component";
import styles from "./dev.module.scss";

const FloorPlanEdit = (props) => {
  const { devData, editData, setEditData, floorPlans, setFloorPlans, planTrigger, setPlanTrigger } = props;

  const dispatch = useDispatch();
  const { homeTypeData, homeStyle, parkingData, garageLayoutData, development, city, profile } = useSelector(
    (state) => ({
      homeTypeData: state?.homeType,
      homeStyle: state?.homeStyle,
      parkingData: state?.parking,
      garageLayoutData: state?.garageLayout,
      development: state?.development,
      city: state?.city,
      profile: state?.profile,
    })
  );

  const { addDevelopment } = development;

  const [status, setStatus] = useState("");
  const [homeType, setHomeType] = useState("");
  const [planTitle, setPlanTitle] = useState("");
  const [planType, setPlanType] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");

  const [planPriceFrom, setPlanPriceFrom] = useState("");
  const [planPriceTo, setPlanPriceTo] = useState("");
  const [planPriceRange, setPlanPriceRange] = useState(false);
  const [planPriceSelect, setplanPriceSelect] = useState("");
  const [planPrice, setplanPrice] = useState("");

  const [planSizeFrom, setPlanSizeFrom] = useState("");
  const [planSizeTo, setPlanSizeTo] = useState("");
  const [planSizeRange, setPlanSizeRange] = useState(false);
  const [planSize, setPlanSize] = useState("");

  const [parking, setParking] = useState("");
  const [parkingArray, setParkingArray] = useState([]);

  const [garageLayout, setGarageLayout] = useState("");
  const [rooftopDeck, setRooftopDeck] = useState("");

  const [balconySizeFrom, setBalconySizeFrom] = useState("");
  const [balconySizeTo, setBalconySizeTo] = useState("");
  const [balconySizeRange, setBalconySizeRange] = useState(false);
  const [balconySize, setBalconySize] = useState("");

  const [mandStatus, setMandStatus] = useState(false);
  const [mandType, setMandType] = useState(false);
  const [mandTitle, setMandTitle] = useState(false);
  const [mandPlanType, setMandPlanType] = useState(false);
  const [mandBedrooms, setMandBedrooms] = useState(false);
  const [mandBathrooms, setMandBathrooms] = useState(false);
  const [mandPlanSize, setMandPlanSize] = useState(false);
  const statusRef = useRef();
  const typeRef = useRef();
  const titleRef = useRef();
  const planTypeRef = useRef();
  const bedroomsRef = useRef();
  const bathroomsRef = useRef();
  const planSizeRef = useRef();

  const [planImageArray, setPlanImageArray] = useState([]);

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setMandStatus(false);
    setMandType(false);
    setMandTitle(false);
    setMandPlanType(false);
    setMandBedrooms(false);
    setMandBathrooms(false);
    setMandPlanSize(false);
  }, []);

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
    }
  }, [trigger]);

  // Uploading images to database and storing it in session storage
  const uploadImage = (formData) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 5000);

    dispatch(
      uploadFile(
        formData,
        (res) => {
          toast.success("Image uploaded");
          setTimeout(() => {
            setTrigger(true);
          }, 2000);

          toast.dismiss(toastId);
          if (getSession("dev-editPlanImage")) {
            let image = getSession("dev-editPlanImage");
            let newImages = res?.data?.map((li) => li?.url);
            let a = [...image, ...newImages];
            let b = [...new Set(a)];
            setSession("dev-editPlanImage", b);
          } else {
            if (planImageArray?.length) {
              let a = [...planImageArray, ...res?.data?.map((li) => li?.url)];
              let b = [...new Set(a)];
              setSession("dev-editPlanImage", b);
            } else {
              setSession(
                "dev-editPlanImage",
                res?.data?.map((li) => li?.url)
              );
            }
          }
          let data = prefillData();
          console.log("data: ", data);
          setEditData(data);
          if (getSession("dev-editPlanImage")) {
            setPlanImageArray(getSession("dev-editPlanImage"));
          }
        },
        () => {
          toast.dismiss(toastId);
          setTrigger(true);
        }
      )
    );
  };

  // deleting plan images from database and session storage
  const handleDelete = (path) => {
    let toastId = toast.loading("Deleting...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);

    dispatch(
      deleteFile(
        { path },
        (res) => {
          let a = planImageArray?.find((li) => li === path);
          if (a) {
            let b = planImageArray?.filter((li) => li !== path);
            setPlanImageArray([...b]);
            setSession("dev-editPlanImage", b);
          }
          let data = prefillData();
          console.log("data: ", data);
          setEditData(data);
          toast.success("Image Deleted");
          toast.dismiss(toastId);
          setTrigger(true);
        },
        () => {
          toast.dismiss(toastId);
          setTrigger(true);
        }
      )
    );
  };

  // uploading image
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImages = acceptedFiles.map((file, index) => {
        let devName = convertToSlug(devData?.name);

        let cityName = city?.city
          ?.find((li) => li?._id === devData?.address?.city)
          ?.name?.split(" ")
          ?.join("-");

        let homeTypeName =
          homeType || devData?.homeTypes?.[0]
            ? `-${homeTypeData?.homeType
                ?.find((li) => li?._id === homeType || devData?.homeTypes?.[0])
                ?.name?.split(" ")
                ?.join("-")}`
            : "";

        let planName = planTitle ? `-${convertToSlug(planTitle)}` : `-${uuid()?.slice(0, 8)}`;

        const newName = `${devName}-${cityName}${homeTypeName}-floor-plan${planName}-${
          !planImageArray?.length ? index + 1 : planImageArray?.length + index + 1
        }.${file.name.split(".").pop()}`;
        console.log("newName: ", newName);
        return new File([file], newName, { type: file.type });
      });

      const formData = new FormData();
      newImages.forEach((image) => {
        formData.append("file", image);
        formData.append("width", 1440);
        formData.append("height", 900);
        formData.append("folder", "development");
      });
      uploadImage(formData);
    },
    [devData, homeTypeData?.homeType, homeType, city?.city, planTitle, planImageArray?.length, uploadImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 10,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 20971520,
  });

  // getting plan images and all floor plans from session storage if an
  useEffect(() => {
    if (getSession("dev-editPlanImage")) {
      setPlanImageArray(getSession("dev-editPlanImage"));
    }
  }, []);

  useEffect(() => {
    if (editData) {
      console.log("editData: ", editData);
      let li = editData;
      setPlanTitle(li?.title);
      setStatus(li?.status);
      setHomeType(li?.homeType);
      setPlanType(li?.type);
      setBedrooms(li?.bedrooms);
      setBathrooms(li?.bathrooms);
      setPlanPriceRange(li?.price?.range);
      setPlanPriceFrom(li?.price?.from);
      setPlanPriceTo(li?.price?.to);
      setplanPriceSelect(li?.price?.priceLabel);
      setplanPrice(li?.price?.price);
      setPlanSize(li?.size?.size);
      setPlanSizeFrom(li?.size?.from);
      setPlanSizeRange(li?.size?.range);
      setPlanSizeTo(li?.size?.to);
      setParkingArray(li?.parking?.map((li) => li) || []);
      setGarageLayout(li?.garageLayout);
      setRooftopDeck(li?.rooftopDeck ? "Yes" : "No");
      setBalconySize(li?.balcony?.size);
      setBalconySizeFrom(li?.balcony?.from);
      setBalconySizeRange(li?.balcony?.range);
      setBalconySizeTo(li?.balcony?.to);
    }
  }, [editData, planImageArray]);

  useEffect(() => {
    if (!getSession("dev-editPlanImage") && editData?.images) {
      console.log("check");
      setSession("dev-editPlanImage", editData?.images);
      setPlanImageArray(editData?.images);
    }
  }, [editData?.images]);

  const prefillData = () => {
    let data = {
      ...editData,
      status: status,
      homeType: homeType,
      title: planTitle,
      type: planType,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      images: planImageArray?.map((li) => li),
    };

    if (homeType === "63c66c47cec8665e878ce4df") {
      if (balconySizeRange === true) {
        data = {
          ...data,
          balcony: {
            range: true,
            from: +balconySizeFrom,
            to: +balconySizeTo,
          },
        };
      } else {
        data = { ...data, balcony: { range: false, size: +balconySize } };
      }
    } else {
      delete data.balcony;
    }

    if (planSizeRange === true) {
      data = {
        ...data,
        size: { range: true, from: +planSizeFrom, to: +planSizeTo },
      };
    } else {
      data = { ...data, size: { range: false, size: +planSize } };
    }

    if (planPriceRange === true) {
      data = {
        ...data,
        price: {
          ...data.price,
          range: true,
          price: 0,
          from: +planPriceFrom,
          to: +planPriceTo,
        },
      };
    } else {
      data = {
        ...data,
        price: {
          ...data.price,
          range: false,
          price: +planPrice,
          priceLabel: planPriceSelect,
        },
      };
    }

    if (homeType !== "63c66c47cec8665e878ce4df") {
      if (parkingArray?.length) {
        data = { ...data, parking: parkingArray?.map((li) => li) };
      }
      if (garageLayout) {
        data = { ...data, garageLayout: garageLayout };
      }
      data = {
        ...data,
        rooftopDeck: rooftopDeck === "Yes" ? true : false,
      };
    } else {
      delete data.parking;
      delete data.garageLayout;
      delete data.rooftopDeck;
    }

    return data;
  };

  // Handling event on CTA "Add More"
  const handleAddMorePlans = () => {
    let data = prefillData();
    if (
      status &&
      homeType &&
      planTitle &&
      planType &&
      (bedrooms || bedrooms === 0) &&
      bathrooms &&
      (planSize || (planSizeFrom && planSizeTo))
    ) {
      if (addDevelopment?.["dev-floorplans"]?.length) {
        let a = [...addDevelopment?.["dev-floorplans"]];
        let add = a?.findIndex((li) => {
          if (li?._id) {
            return li?._id === editData?._id;
          } else if (li?.editId) {
            return li?.editId === editData?.editId;
          }
        });
        if (add !== -1) {
          a[add] = data;
        }
        dispatch(handleAddDevelopment({ ...addDevelopment, "dev-floorplans": a }));
        setPlanTrigger(!planTrigger);
      }
      removeSession("dev-editPlanImage");
      props.onHide();
    } else {
      if (!status) {
        statusRef.current.focus();
      } else if (!homeType) {
        typeRef.current.focus();
      } else if (!planTitle) {
        titleRef.current.focus();
      } else if (!planType) {
        planTypeRef.current.focus();
      } else if (!bedrooms) {
        bedroomsRef?.current?.focus();
      } else if (!bathrooms) {
        bathroomsRef?.current?.focus();
      } else if (!planSize) {
        planSizeRef?.current?.focus();
      }

      if (!status) {
        setMandStatus(true);
      }
      if (!homeType) {
        setMandType(true);
      }
      if (!planTitle) {
        setMandTitle(true);
      }
      if (!planType) {
        setMandPlanType(true);
      }
      if (!bedrooms) {
        setMandBedrooms(true);
      }
      if (!bathrooms) {
        setMandBathrooms(true);
      }
      if (!planSize) {
        setMandPlanSize(true);
      }

      toast.error("Please fill floor plan data");
    }
  };

  useEffect(() => {
    dispatch(getHomeType());
    dispatch(getParkingList());
    dispatch(getAllHomeStyle());
    dispatch(getGarageLayoutList());
    dispatch(getcity());
  }, [dispatch]);

  useEffect(() => {
    if (homeType) {
      dispatch(getHomeStyleByType(homeType));
    }
  }, [dispatch, homeType]);

  return (
    <Modal
      {...props}
      size="lg"
      isOpen={props.show}
      toggle={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.floorPlanEditModal}
    >
      <ModalHeader toggle={props?.onHide}>
        <div
          style={{
            fontSize: "22px",
            margin: "8px",
          }}
        >
          Edit Floorplan
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Status*</p>
              <select
                ref={statusRef}
                className={clsx("arrows", styles.selectTag, mandStatus ? "error" : "")}
                style={{
                  padding: "7px 15px",
                  border: "0.2px solid #dddddd",
                }}
                value={status}
                name="status"
                onChange={(e) => {
                  setStatus(e.target.value);
                  setMandStatus(false);
                }}
              >
                <option value={""}>Select</option>
                <option value={"available"}>Available</option>
                <option value={"sold"}>Sold</option>
                <option value={"onHold"}>On Hold</option>
              </select>
            </div>
          </Col>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Home Type*</p>

              <select
                className={clsx("arrows", styles.selectTag, mandType ? "error" : "")}
                style={{
                  padding: "7px 15px",
                  border: "0.2px solid #dddddd",
                }}
                ref={typeRef}
                value={homeType}
                name="homeType"
                onChange={(e) => {
                  setHomeType(e.target.value);
                  setMandType(false);
                }}
              >
                <option value={""}>Select</option>
                {homeTypeData?.homeType?.length
                  ? homeTypeData?.homeType?.map((li, i) =>
                      devData?.homeTypes?.includes(li?._id) ? (
                        <option key={`${li?._id}-${i}`} value={li?._id}>
                          {li?.name}
                        </option>
                      ) : null
                    )
                  : null}
              </select>
            </div>
          </Col>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Plan Title*</p>
              <input
                type="text"
                placeholder={"Plan name"}
                className={clsx("form-control", styles.textField, mandTitle ? "error" : "")}
                ref={titleRef}
                value={planTitle}
                name="planTitle"
                onChange={(e) => {
                  setPlanTitle(e.target.value);
                  setMandTitle(false);
                }}
              />
            </div>
          </Col>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Plan Type*</p>
              <select
                className={clsx("arrows", styles.selectTag, mandPlanType ? "error" : "")}
                style={{
                  padding: "7px 15px",
                  border: "0.2px solid #dddddd",
                }}
                ref={planTypeRef}
                value={planType}
                name="planType"
                onChange={(e) => {
                  setPlanType(e.target.value);
                  setMandPlanType(false);
                }}
              >
                <option value={""}>Select</option>
                {homeStyle?.homeStyle?.length ? (
                  homeStyle?.homeStyle?.map((li, i) => (
                    <option key={`${li?._id}-${i}`} value={li?._id}>
                      {li?.name}
                    </option>
                  ))
                ) : (
                  <option value={""}>please select home type first</option>
                )}
              </select>
            </div>
          </Col>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Plan Bedrooms*</p>
              <input
                onWheel={(e) => e.target.blur()}
                placeholder={"No. of bedrooms"}
                className={clsx("form-control", styles.textField, mandBedrooms ? "error" : "")}
                ref={bedroomsRef}
                value={bedrooms}
                name="bedrooms"
                onChange={(e) => {
                  if (numberRegex(e.target.value)) {
                    setBedrooms(e.target.value);
                    setMandBedrooms(false);
                  } else {
                    return;
                  }
                }}
              />
            </div>
          </Col>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Plan Bathrooms*</p>
              <input
                onWheel={(e) => e.target.blur()}
                placeholder={"No. of bathrooms"}
                className={clsx("form-control", styles.textField, mandBathrooms ? "error" : "")}
                ref={bathroomsRef}
                value={bathrooms}
                name="bathrooms"
                onChange={(e) => {
                  if (numberRegex(e.target.value)) {
                    setBathrooms(e.target.value);
                    setMandBathrooms(false);
                  } else {
                    return;
                  }
                }}
              />
            </div>
          </Col>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Plan Price</p>
              {planPriceRange ? (
                <Row>
                  <Col sm={"6"} className={clsx("")}>
                    <div className={`form-control-wrap`}>
                      <div className="input-group input-group">
                        <div className="input-group-prepend">
                          <span className={`input-group-text`} id="inputGroup-sizing-md">
                            From
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={planPriceFrom}
                          name="planPriceFrom"
                          onChange={(e) => {
                            setPlanPriceFrom(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={"6"}>
                    <div className={`form-control-wrap`}>
                      <div className="input-group input-group">
                        <div className="input-group-prepend">
                          <span className={`input-group-text`} id="inputGroup-sizing-md">
                            To
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={planPriceTo}
                          name="planPriceTo"
                          onChange={(e) => {
                            setPlanPriceTo(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm={"3"}>
                    <select
                      className={clsx("arrows", styles.selectTag)}
                      style={{
                        padding: "7px 15px",
                        border: "0.2px solid #dddddd",
                      }}
                      value={planPriceSelect}
                      name="planPriceSelect"
                      onChange={(e) => {
                        setplanPriceSelect(e.target.value);
                      }}
                    >
                      <option value={""}>Select</option>
                      <option value={"from"}>From</option>
                      <option value={"low"}>Low</option>
                      <option value={"mid"}>Mid</option>
                      <option value={"high"}>High</option>
                    </select>
                  </Col>
                  <Col sm={"9"}>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder={"Enter Price"}
                      className={clsx("form-control", styles.textField)}
                      value={planPrice || ""}
                      name="planPrice"
                      onChange={(e) => {
                        setplanPrice(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              )}
              <div className={clsx(styles.checkDiv)}>
                <input
                  type="checkbox"
                  name="planPriceRange"
                  checked={planPriceRange}
                  onChange={(e) => {
                    setPlanPriceRange(e.target.checked);
                    setplanPrice("");
                    setPlanPriceFrom("");
                    setPlanPriceTo("");
                    setplanPriceSelect("");
                  }}
                  id="planPriceRange"
                />
                <label htmlFor="planPriceRange" className="form-check-label">
                  Range
                </label>
              </div>
            </div>
          </Col>
          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx(styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Plan Size - Internal (sqft)*</p>
              {planSizeRange ? (
                <Row>
                  <Col sm={"6"}>
                    <div className={`form-control-wrap`}>
                      <div className="input-group input-group">
                        <div className="input-group-prepend">
                          <span className={`input-group-text`} id="inputGroup-sizing-md">
                            From
                          </span>
                        </div>
                        <input
                          type="number"
                          className={clsx("form-control", mandPlanSize ? "error" : "")}
                          onWheel={(e) => e.target.blur()}
                          ref={planSizeRef}
                          value={planSizeFrom}
                          name="planSizeFrom"
                          onChange={(e) => {
                            setPlanSizeFrom(e.target.value);
                            setMandPlanSize(false);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={"6"}>
                    <div className={`form-control-wrap`}>
                      <div className="input-group input-group">
                        <div className="input-group-prepend">
                          <span className={`input-group-text`} id="inputGroup-sizing-md">
                            To
                          </span>
                        </div>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className={clsx("form-control")}
                          value={planSizeTo}
                          name="planSizeTo"
                          onChange={(e) => {
                            setPlanSizeTo(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm={"12"}>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder={"Enter size"}
                      className={clsx("form-control", styles.textField, mandPlanSize ? "error" : "")}
                      ref={planSizeRef}
                      value={planSize}
                      name="planSize"
                      onChange={(e) => {
                        setPlanSize(e.target.value);
                        setMandPlanSize(false);
                      }}
                    />
                  </Col>
                </Row>
              )}
              <div className={clsx(styles.checkDiv)}>
                <input
                  type="checkbox"
                  id="planSizeRange"
                  name="planSizeRange"
                  checked={planSizeRange}
                  onChange={(e) => {
                    setPlanSizeRange(e.target.checked);
                    setPlanSizeFrom("");
                    setPlanSizeTo("");
                    setPlanSize("");
                  }}
                />
                <label htmlFor="planSizeRange" className="form-check-label">
                  Range
                </label>
              </div>
            </div>
          </Col>

          {homeType && homeType !== "63c66c47cec8665e878ce4df" ? (
            <>
              <Col sm={"6"} md={"6"} lg={"6"}>
                <div className={clsx("", styles.askingPriceDiv)}>
                  <p className={clsx(styles.heightPara)}>Parking</p>
                  <div className="row m-0 p-0">
                    <div className="col-12">
                      <select
                        className={clsx("arrows", styles.selectTag)}
                        style={{
                          padding: "7px 15px",
                          border: "0.2px solid #dddddd",
                        }}
                        value={parking}
                        name="parking"
                        onChange={(e) => {
                          setParkingArray([...parkingArray, e.target.value]);
                          setParking("");
                        }}
                      >
                        <option value={""}>Select</option>
                        {parkingData?.parking?.map((li, i) =>
                          !parkingArray?.includes(li?._id) ? (
                            <option key={`${li?._id}-${i}`} value={li?._id}>
                              {li?.name}
                            </option>
                          ) : null
                        )}
                      </select>
                    </div>
                  </div>
                  <div className={styles.arrayItemDiv}>
                    {parkingArray?.length
                      ? parkingArray?.map((item, index) => {
                          return (
                            <p
                              key={`${Math.random()}-${index}`}
                              className={styles.para2}
                              onClick={() => {
                                let a = parkingArray?.indexOf(item);
                                if (a > -1) {
                                  console.log("a: ", a);
                                  parkingArray?.splice(a, 1);
                                  setParkingArray([...parkingArray]);
                                  console.log("parkingArray: ", parkingArray);
                                }
                              }}
                            >
                              <AiOutlineCloseCircle className={styles.arrayCloseIcon} />
                              <span>{parkingData?.parking?.filter((li) => li?._id === item)?.[0]?.name}</span>
                            </p>
                          );
                        })
                      : null}
                  </div>
                </div>
              </Col>

              <Col sm={"6"} md={"6"} lg={"6"}>
                <div className="row m-0 p-0">
                  {/* Garage Layout */}
                  {parkingArray?.includes("64410350000d159c246c0a4f") ||
                  parkingArray?.includes("64410350000d159c246c0a50") ? (
                    <div
                      className={
                        homeType === "63c66c47cec8665e878ce4df"
                          ? "col-lg-12 col-md-12 col-sm-12"
                          : "col-lg-6 col-md-6 col-sm-12"
                      }
                    >
                      <div className={clsx(styles.askingPriceDiv)}>
                        <p className={clsx(styles.heightPara)}>Garage Layout</p>
                        <select
                          className={clsx("arrows", styles.selectTag)}
                          style={{
                            padding: "7px 15px",
                            border: "0.2px solid #dddddd",
                          }}
                          value={garageLayout}
                          name="garageLayout"
                          onChange={(e) => {
                            setGarageLayout(e.target.value);
                          }}
                        >
                          <option value={""}>Select</option>
                          {garageLayoutData?.garageLayout?.map((li, i) => (
                            <option key={`${li?._id}-${i}`} value={li?._id}>
                              {li?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : null}

                  {/* Rooftop Deck */}
                  {homeType === "63c66c47cec8665e878ce4df" ? null : (
                    <div
                      className={clsx(
                        parkingArray?.includes("64410350000d159c246c0a4f") ||
                          parkingArray?.includes("64410350000d159c246c0a50")
                          ? "col-lg-6 col-md-6 col-sm-12"
                          : "col-lg-12 col-md-12 col-sm-12"
                      )}
                    >
                      <div className={clsx(styles.askingPriceDiv)}>
                        <p className={clsx(styles.heightPara)}>Rooftop Deck</p>
                        <select
                          className={clsx("arrows", styles.selectTag)}
                          style={{
                            padding: "7px 15px",
                            border: "0.2px solid #dddddd",
                          }}
                          value={rooftopDeck}
                          name="rooftopDeck"
                          onChange={(e) => {
                            setRooftopDeck(e.target.value);
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </>
          ) : null}

          {homeType === "63c66c47cec8665e878ce4df" ? (
            <Col sm={"6"} md={"6"} lg={"6"}>
              <div className={clsx(styles.askingPriceDiv)}>
                <p className={clsx(styles.heightPara)}>Balcony Size (sqft)</p>

                {balconySizeRange ? (
                  // balcony size range
                  <div className="row m-0 p-0">
                    <div className={clsx("col-lg-6 col-md-6 col-sm-12", styles.inputStart)}>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className={clsx("form-control", styles.textField)}
                        value={balconySizeFrom}
                        name="balconySizeFrom"
                        onChange={(e) => {
                          setBalconySizeFrom(e.target.value);
                        }}
                      />
                      <p className={clsx(styles.start)}>From</p>
                    </div>
                    <div className={clsx("col-lg-6 col-md-6 col-sm-12", styles.inputStart)}>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className={clsx("form-control", styles.textField)}
                        value={balconySizeTo}
                        name="balconySizeTo"
                        onChange={(e) => {
                          setBalconySizeTo(e.target.value);
                        }}
                        style={{
                          paddingLeft: "4.5rem",
                        }}
                      />
                      <p className={clsx(styles.start)}>To</p>
                    </div>
                  </div>
                ) : (
                  // Balcony size single
                  <div className="row m-0 p-0">
                    <div
                      className={clsx(
                        "col-lg-12 col-md-12 col-sm-12"
                        // styles.inputStart
                      )}
                    >
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Enter size"}
                        className={clsx("form-control", styles.textField)}
                        value={balconySize}
                        name="balconySize"
                        onChange={(e) => {
                          setBalconySize(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <input
                    type="checkbox"
                    name="balconySizeRange"
                    checked={balconySizeRange}
                    onChange={(e) => {
                      setBalconySizeRange(e.target.checked);
                      setBalconySize("");
                      setBalconySizeFrom("");
                      setBalconySizeTo("");
                    }}
                  />
                  <label htmlFor="balconySizeRange">Range</label>
                </div>
              </div>
            </Col>
          ) : null}

          <Col sm={"6"} md={"6"} lg={"6"}>
            <div className={clsx("", styles.askingPriceDiv)}>
              <p className={clsx(styles.heightPara)}>Plan Images</p>
              <div {...getRootProps()} className={clsx("form-control", styles.uploadInput)}>
                <p>{`Select the floor plan image (png or jpeg)`}</p>
                <input type="text" name="planImage" {...getInputProps()} />
              </div>

              <div className={styles.arrayImageDiv}>
                {planImageArray?.length && !trigger
                  ? planImageArray?.map((item, index) => {
                      return (
                        <div key={`${Math.random()}-${index}`}>
                          <img src={`${process.env.REACT_APP_IMGURL}${item}`} alt={item} />
                          <span
                            onClick={() => {
                              handleDelete(item);
                            }}
                          >
                            <AiOutlineCloseCircle className={clsx(styles.arrayCloseIcon)} />
                            Delete
                          </span>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "2.5rem",
            margin: "8px",
            // justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#00aeff",
            }}
            onClick={handleAddMorePlans}
          >
            Update
          </Button>

          <Button
            onClick={props.onHide}
            sx={{
              fontSize: "16px",
              color: "#00aeff",
            }}
          >
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default FloorPlanEdit;
