import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getSession, setSession } from "../../utils/Storage";
import styles from "./assignment.module.scss";

const AssignNavTab = ({ stepGoTo, update }) => {
  const [data, setData] = useState([
    { id: 0, label: "Summary" },
    { id: 1, label: "Price" },
    { id: 2, label: "Facts & Features" },
    { id: 3, label: "Area" },
    { id: 4, label: "Media" },
    { id: 5, label: "Listing Agent" },
    // { id: 6, label: "Payment" },
  ]);
  const [maxStep, setMaxStep] = useState("");
  const [currentStep, setCurrentStep] = useState("");

  useEffect(() => {
    if (getSession("assign-max-step")) {
      setMaxStep(+getSession("assign-max-step"));
    } else {
      setMaxStep(0);
    }
    if (getSession("assign-step")) {
      setCurrentStep(+getSession("assign-step"));
    } else {
      setCurrentStep(0);
    }
  }, []);

  const handleToggle = (value) => {
    if (value <= maxStep) {
      if (currentStep === data?.length) {
        stepGoTo(value);
        setSession("assign-step", value);
      } else if (currentStep === data?.length + 1) {
        return;
      } else {
        update((res) => {
          if (res?.success) {
            setSession("assign-step", value);
            stepGoTo(value);
          }
        });
      }
    }
  };

  return (
    <Nav tabs className={classNames("px-4", styles.nav)}>
      {data?.map((li) => (
        <NavItem key={li?.id}>
          <NavLink
            tag="div"
            className={classNames({ active: currentStep === li?.id })}
            style={{
              cursor: li?.id <= maxStep && "pointer",
            }}
            onClick={(ev) => {
              ev.preventDefault();
              if (currentStep !== li?.id) {
                handleToggle(li?.id);
              }
            }}
          >
            <span>{li?.label}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default AssignNavTab;
