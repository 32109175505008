import clsx from "clsx";
import parser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillEdit, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { LuArchiveRestore } from "react-icons/lu";
import { MdErrorOutline, MdOutlineCancel, MdOutlineVerified } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import PlaceholderImg from "../../assets/profile_avatar.png";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  Rating,
  Row,
  UserAvatar,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  addNewTestimony,
  archiveTestimony,
  deleteTestimony,
  getAllTestimony,
  restoreTestimony,
  updateTestimony,
} from "../../redux/Apislices/TestimonySlice";
import styles from "../Feedbacks/feedbacks.module.scss";
import TinyMceEditor from "../components/forms/rich-editor/TinyMceEditor";
import { toast } from "react-toastify";
import { Rating as CreateRating } from "react-simple-star-rating";

const statusOptions = [
  { value: "Approved", label: "Approve" },
  { value: "On Hold", label: "On Hold" },
];

const Testimony = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { testimony } = useSelector((state) => ({ testimony: state.testimony?.testimonies }));
  console.log("testimony: ", testimony);

  const [limit, setLimit] = useState({ value: 25, label: "25" });
  const [sort, setSort] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(25);
  const [status, setStatus] = useState(null);

  const [editId, setEditId] = useState(null);
  const [view, setView] = useState({ edit: false, add: false, details: null });

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [company, setCompany] = useState("");
  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState("");
  const [feedback, setFeedback] = useState("");

  const { errors, register, handleSubmit } = useForm();

  // toggle function to view product details
  const toggle = (type) => setView({ edit: type === "edit" ? true : false, add: type === "add" ? true : false });

  const onFormCancel = () => {
    toggle();
    setName("");
    setImage("");
    setCompany("");
    setRating("");
    setTitle("");
    setFeedback("");
  };

  const editClick = (id) => {
    testimony?.data?.forEach((li) => {
      if (li?._id === id) {
        toggle("edit");
        setEditId(li?._id);
        setName(li?.name);
        setImage(li?.image);
        setCompany(li?.company);
        setRating(li?.rating);
        setTitle(li?.title);
        setFeedback(li?.feedback);
      }
    });
  };

  const fetchData = async () => dispatch(getAllTestimony({ sort, page: currentPage, limit: itemPerPage, status }));

  useEffect(() => {
    fetchData();
  }, [sort, currentPage, itemPerPage, status]);

  const onFormSubmit = async (e) => {
    let data = {
      name,
      image,
      company,
      rating: +rating,
      title,
      feedback,
    };
    dispatch(
      addNewTestimony(
        data,
        (res) => {
          fetchData();
          toast.success("Testimony added successfully");
          onFormCancel();
        },
        (res) => {
          console.log("add error: ", res);
        }
      )
    );
  };

  const onEditSubmit = async (e) => {
    let data = {
      name,
      image,
      company,
      rating: +rating,
      title,
      feedback,
    };
    dispatch(
      updateTestimony(
        editId,
        data,
        (res) => {
          fetchData();
          toast.success("Testimony updated successfully");
          onFormCancel();
        },
        (res) => {
          console.log("edit error: ", res);
        }
      )
    );
  };

  return (
    <>
      <Head title="Testimonies" />

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag={"h1"}>
                Testimony
              </BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content" style={{}}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button className="toggle btn-icon d-md-none" onClick={() => toggle("add")}>
                        <Icon name="plus"></Icon>
                      </Button>

                      <Button
                        className="toggle d-none btn-outline-primary btn-dim d-md-inline-flex"
                        onClick={() => toggle("add")}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Testimony</span>
                      </Button>
                    </li>

                    {(typeof status === "object" && status?.value === "archive") || status === "archive" ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon btn-outline-info d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-info btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("");
                          }}
                        >
                          <Icon name="arrow-long-left"></Icon>
                          <span>All Testimonies</span>
                        </Button>
                      </li>
                    ) : (
                      <li className="nk-block-tools-opt">
                        <Button
                          className="toggle btn-icon d-md-none"
                          color="primary"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                        </Button>

                        <Button
                          className="toggle btn-outline-danger btn-dim d-none d-md-inline-flex"
                          onClick={() => {
                            setStatus("archive");
                          }}
                        >
                          <Icon name="archive"></Icon>
                          <span>Archived</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block className={"mt-4"}>
          {testimony?.loading ? (
            <div
              style={{
                height: "200px",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner type="grow" color="dark" />
            </div>
          ) : testimony?.data?.length ? (
            <Row className={styles.cardContainer}>
              {testimony?.data?.map((li, i) => {
                return <CardBody key={li?._id} li={li} status={status} fetchData={fetchData} editClick={editClick} />;
              })}
            </Row>
          ) : (
            <div
              className="text-center"
              style={{
                padding: "20px",
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
              }}
            >
              <span className="text-silent">No testimonies found</span>
            </div>
          )}
        </Block>
      </Content>

      <Modal
        isOpen={view.add || view.edit}
        toggle={() => {
          onFormCancel();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
            />
          </a>
          <div className="p-2">
            <h5 className="title">{view.edit ? "Edit Testimony" : "Add Testimony"}</h5>
            <div className="mt-4">
              <form onSubmit={handleSubmit(view.edit ? onEditSubmit : onFormSubmit)}>
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="John Doe"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Company
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="CEO @ abcd company"
                          name="company"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Title
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Rating
                      </label>
                      <div className="">
                        <input
                          type="number"
                          style={{
                            width: "40px",
                            border: "none",
                            borderBottom: "1px solid #aeaeae",
                            textAlign: "center",
                            padding: "0",
                          }}
                          placeholder="0"
                          name="rating"
                          value={rating}
                          onChange={(e) => {
                            if (e.target.value > 5 || e.target.value < 0) return;
                            else setRating(e.target.value);
                          }}
                          ref={register({
                            required: "This field is required",
                          })}
                        />{" "}
                        / 5
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Testimony
                      </label>
                      <div className="form-control-wrap">
                        <TinyMceEditor
                          name={"feedback"}
                          value={feedback}
                          getValue={setFeedback}
                          placeholder={"Enter feedback"}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col size="12">
                    <Button color="primary" type="submit">
                      <Icon className="plus"></Icon>
                      <span>{view.edit ? "Edit Testimony" : "Add Testimony"}</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const CardBody = ({ li, status, fetchData, editClick }) => {
  const dispatch = useDispatch();

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const descriptionRef = useRef(null);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement && li?.feedback) {
      const { clientHeight, scrollHeight } = descriptionElement;
      setIsButtonVisible(scrollHeight > clientHeight);
    }
  }, [li?.feedback]);

  const handleUpdate = async (id, data) => {
    dispatch(
      updateTestimony(
        id,
        data,
        (res) => fetchData(),
        (err) => console.log(err)
      )
    );
  };

  const handleDelete = async (id) => {
    dispatch(
      deleteTestimony(
        id,
        (res) => fetchData(),
        (err) => console.log(err)
      )
    );
  };

  const handleRestore = async (id) => {
    dispatch(
      restoreTestimony(
        id,
        (res) => fetchData(),
        (err) => console.log(err)
      )
    );
  };

  const handleArchive = async (id) => {
    dispatch(
      archiveTestimony(
        id,
        (res) => fetchData(),
        (err) => console.log(err)
      )
    );
  };

  return (
    <>
      <Col md={6} lg={4} key={li?._id}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <div>
              <UserAvatar
                className="sq"
                theme={"transparent"}
                image={li?.image ? `${process.env.REACT_APP_IMGURL}${li?.image}` : PlaceholderImg}
              />
              <div className="project-info">
                <h6 className="title">
                  {li?.name}{" "}
                  <span
                    className={styles.status}
                    style={{
                      color:
                        li?.status === "Active"
                          ? "#77c720"
                          : li?.status === "Rejected"
                          ? "#ce6048"
                          : li?.status === "On Hold"
                          ? "#f3a638"
                          : "",
                    }}
                  >
                    {li?.status === "Pending" ? (
                      <MdErrorOutline />
                    ) : li?.status === "Approved" ? (
                      <MdOutlineVerified />
                    ) : li?.status === "Rejected" ? (
                      <MdOutlineCancel />
                    ) : li?.status === "On Hold" ? (
                      <MdErrorOutline />
                    ) : null}
                    {li?.status}
                  </span>
                </h6>
                <span className="sub-text">{li?.company ? li?.company : ""}</span>
              </div>
            </div>

            <div>
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 mr-n1">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                  <ul className="link-list-opt no-bdr">
                    {li?.isDeleted ? null : (
                      <>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#view"
                            onClick={(ev) => {
                              ev.preventDefault();
                              editClick(li?._id);
                            }}
                          >
                            <AiFillEdit style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }} />
                            <span>Edit</span>
                          </DropdownItem>
                        </li>

                        {li?.status === "On Hold" ? (
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#view"
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleUpdate(li?._id, { status: "Active" });
                              }}
                            >
                              <AiOutlineEye style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }} />
                              <span>Active</span>
                            </DropdownItem>
                          </li>
                        ) : (
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#view"
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleUpdate(li?._id, { status: "On Hold" });
                              }}
                            >
                              <AiOutlineEyeInvisible
                                style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }}
                              />
                              <span>Put on Hold</span>
                            </DropdownItem>
                          </li>
                        )}
                      </>
                    )}

                    {status === "archive" ? (
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#view"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleRestore(li?._id, { isDeleted: false });
                          }}
                        >
                          <LuArchiveRestore style={{ fontSize: "18px", marginRight: "15px", color: "#526484" }} />
                          <span>Restore</span>
                        </DropdownItem>
                      </li>
                    ) : (
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#view"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleArchive(li?._id, { isDeleted: true });
                          }}
                        >
                          <Icon name="archived-fill"></Icon>
                          <span>Archive</span>
                        </DropdownItem>
                      </li>
                    )}

                    <li>
                      <DropdownItem
                        tag="a"
                        href="#view"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleDelete(li?._id);
                        }}
                      >
                        <Icon name="trash" />
                        <span>Delete Permanently</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>

          <div
            className={clsx(styles.starDiv, styles.l3)}
            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            {li?.rating ? <CreateRating size={22} readonly allowFraction initialValue={li?.rating} /> : null}
            <p className={styles.p2}>{moment(li?.createdAt).format("DD MMM, YYYY")}</p>
          </div>

          <div className={styles.cardBody}>
            <div className={styles.l1}>
              <p className={styles.title}>{li?.title ? <p className={styles.title}>{li?.title}</p> : null}</p>
            </div>

            <div>
              <div ref={descriptionRef} className={clsx(`${styles.l4} ${showFullDescription ? styles.expanded : ""}`)}>
                <p className={styles.p3}>{parser(li?.feedback)}</p>
              </div>
              {isButtonVisible ? (
                <button className={clsx(styles.showMoreBtn)} onClick={toggleDescription}>
                  {showFullDescription ? "Show Less" : "Show More"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Testimony;
