import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  Row,
} from "../../../components/Component";
import { Card } from "../../../components/Draggable/Card";
import { addAmenities, deleteAmenities, getAmenities, updateAmenities } from "../../../redux/Apislices/amenitySlice";

const Amenities = () => {
  const dispatch = useDispatch();
  const { amenity: data, loading } = useSelector((state) => state.amenity);

  const [sm, updateSm] = useState(false);

  const [name, setName] = useState("");

  const [view, setView] = useState({ add: false, edit: false });
  const [editId, setEditId] = useState(null);

  const toggle = (view) => {
    view === "add"
      ? setView({ add: !view.add, edit: false })
      : view === "edit"
      ? setView({ add: false, edit: !view.edit })
      : setView({ add: false, edit: false });
    setName("");
  };

  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getAmenities());
  }, [dispatch]);

  const handleAdd = () => {
    let data = { name };

    dispatch(
      addAmenities(
        data,
        (res) => {
          dispatch(getAmenities());
          toggle();
          toast.success(res?.message);
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item._id === id) {
        setName(item.name);
        setEditId(id);
      }
    });
  };

  const handleEdit = () => {
    let data = { name };

    dispatch(
      updateAmenities(
        editId,
        data,
        (res) => {
          toast.success(res?.message);
          dispatch(getAmenities());
          toggle();
        },
        (res) => {
          toast.error(res?.message);
        }
      )
    );
  };

  const handleDelete = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteAmenities(
            item?._id,
            (res) => {
              dispatch(getAmenities());
              toast.success(res?.message);
            },
            (res) => {
              toast.error(res?.message);
            }
          )
        );
      }
    });
  };

  return (
    <div>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page tag="h1">
              Amenities
            </BlockTitle>
            {data?.length ? <BlockDes className="text-soft">List of total {data.length} Amenities</BlockDes> : null}
          </BlockHeadContent>

          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="more-v" />
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                    <Button
                      color="primary"
                      onClick={() => {
                        toggle("add");
                      }}
                    >
                      <Icon name="plus" />
                      <span>Add Amenity</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block className={"mt-4"}>
        {loading ? (
          <div
            style={{
              height: "200px",
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spinner type="grow" color="dark" />
          </div>
        ) : data?.length ? (
          <div className="cardContainer">
            {data?.map((item, index) => (
              <div key={index} className="">
                <p>{item.name}</p>
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                      <Icon name="setting"></Icon>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-xs">
                      <ul className="link-check">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle("edit");
                              onEditClick(item._id);
                            }}
                          >
                            <Icon name="edit"></Icon>
                            <span>Edit</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              handleDelete(item);
                            }}
                          >
                            <Icon name="trash"></Icon>
                            <span>Delete</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </Block>

      {/* Add & Edit */}
      <Modal
        isOpen={view.add || view.edit}
        toggle={() => {
          setName("");
          toggle();
        }}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                setName("");
                toggle();
              }}
            ></Icon>
          </a>

          <div className="p-2">
            <h5 className="title">{view.edit ? "Edit Amenity" : "Add Amenity"}</h5>
            <div className="mt-4">
              <form onSubmit={handleSubmit(view.edit ? handleEdit : handleAdd)}>
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Name*
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={(e) => {
                            const str = e.target.value;
                            const con = str
                              .split(" ")
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                              .join(" ");
                            setName(con);
                          }}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>

                  <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />

                  <Col size="12">
                    <Button color="primary" type="submit">
                      <span>{"Save"}</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Amenities;
