import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import amenity from "./Apislices/amenitySlice";
import auth, { fetchProfileData } from "./Apislices/authSlice";
import city from "./Apislices/citySlice";
import constructionMethod from "./Apislices/constructionMethodSlice";
import construction from "./Apislices/constructionSlice";
import country from "./Apislices/countrySlice";
import developmentType from "./Apislices/developmentTypeSlice";
import development from "./Apislices/developmentSlice";
import homeFacts from "./Apislices/homeFactsSlice";
import homeStyle from "./Apislices/homeStyleSlice";
import homeType from "./Apislices/homeTypeSlice";
import landTitle from "./Apislices/landTitleSlice";
import listing from "./Apislices/listingSlice";
import neighbourhood from "./Apislices/neighbourhoodSlice";
import parking from "./Apislices/parkingSlice";
import province from "./Apislices/provinceSlice";
import saleStatus from "./Apislices/saleStatusSlice";
import unitFeatures from "./Apislices/unitFeaturesSlice";
import unitPreference from "./Apislices/unitPreferenceSlice";
import user from "./Apislices/userSlice";
import agent from "./Apislices/agentsSlice";
import community from "./Apislices/communitySlice";
import company from "./Apislices/companySlice";
import aws from "./Apislices/awsSlice";
import project from "./Apislices/projectSlice";
import settings from "./Apislices/settings";
import blogs from "./Apislices/blogSlice";
import blogCategory from "./Apislices/blogCategoryslice";
import tags from "./Apislices/tagsSlice";
import payment from "./Apislices/paymentSlice";
import review from "./Apislices/reviewSlice";
import testimony from "./Apislices/TestimonySlice";
import associate from "./Apislices/associate";

const reducers = combineReducers({
  auth,
  user,
  development,
  listing,
  amenity,
  city,
  country,
  homeFacts,
  homeStyle,
  homeType,
  landTitle,
  neighbourhood,
  parking,
  province,
  saleStatus,
  unitFeatures,
  unitPreference,
  construction,
  developmentType,
  constructionMethod,
  agent,
  company,
  community,
  aws,
  project,
  settings,
  blogs,
  tags,
  blogCategory,
  payment,
  review,
  testimony,
  associate,
});

const persistConfig = {
  key: "root5",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

const initializeApp = async () => {
  await store.dispatch(fetchProfileData());
};

initializeApp();

export const persistor = persistStore(store);
