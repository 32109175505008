import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getAllTags = createAsyncThunk("tags/tagList", async (data) => {
  const response = await getMethod(`/api/admin/tags`, data);
  return response;
});

export const getSingleTag = createAsyncThunk("tags/tag", async (slug) => {
  const response = await getMethod(`/api/admin/tags/${slug}`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  tags: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
  singleTag: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.blogs = action.payload;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    handlesingleTagReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.tags = initialState.tags;
      state.singleTag = initialState.singleTag;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllTags.pending, (state) => {
      state.tags.loading = true;
      state.tags.success = false;
      state.tags.error = false;
    });
    builder.addCase(getAllTags.fulfilled, (state, action) => {
      state.tags.loading = false;
      state.tags.success = true;
      state.tags.error = false;
      state.tags.data = action.payload.data;
      state.tags.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getAllTags.rejected, (state, action) => {
      state.tags.loading = false;
      state.tags.success = false;
      state.tags.error = action?.error?.message;
    });

    builder.addCase(getSingleTag.pending, (state) => {
      state.singleTag.loading = true;
      state.singleTag.success = false;
      state.singleTag.error = false;
    });
    builder.addCase(getSingleTag.fulfilled, (state, action) => {
      state.singleTag.loading = false;
      state.singleTag.success = true;
      state.singleTag.error = false;
      state.singleTag.data = action.payload.data;
    });
    builder.addCase(getSingleTag.rejected, (state, action) => {
      state.singleTag.loading = false;
      state.singleTag.success = false;
      state.singleTag.error = action?.error?.message;
    });
  },
});

export const { isLoading, isSuccess, isError, isReset, handleBlogCategoryReset } = tagsSlice.actions;

export default tagsSlice.reducer;

export const addNewTags = (data, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/tags`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const updateTags = (id, data, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/tags/${id}`, data);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const archiveTags = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/tags/archive/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const restoreTags = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/tags/restore/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
export const deleteTags = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/tags/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
