import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoImagesOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { v4 as uuid } from "uuid";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import { getcityByProvince } from "../../redux/Apislices/citySlice";
import { addCompany, editCompany } from "../../redux/Apislices/companySlice";
import { getCountry } from "../../redux/Apislices/countrySlice";
import { getneighbourhoodByCity } from "../../redux/Apislices/neighbourhoodSlice";
import { getprovinceByCountry } from "../../redux/Apislices/provinceSlice";
import { getLocationData } from "../../utils/location";
import { convertToSlug } from "../../utils/Utils";
import { Button, Col, Icon, Row } from "../Component";
import styles from "./common.module.scss";

const libraries = ["places"];
const GOOGLE_API = process.env.REACT_APP_GOOGLE_MAP_KEY;

const CompanyModal = (props) => {
  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: GOOGLE_API, libraries });
  const { errors, register, handleSubmit } = useForm();
  let url = window.location.href;
  let baseUrl = url.split("/").slice(0, 3).join("/");
  const dispatch = useDispatch();
  const { country: countryObj, province: provinceObj, city: cityObj } = useSelector((state) => state);
  const {
    countries: { data: countryData },
  } = countryObj;
  const {
    provinceByCountry: { data: provinceData },
  } = provinceObj;
  const {
    cityByProvince: { data: cityData },
  } = cityObj;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passState, setPassState] = useState(false);
  const [passState2, setPassState2] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [logo, setLogo] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [locationData, setLocationData] = useState(false);

  useEffect(() => {
    if (props?.editId && props?.data?.length) {
      const company = props?.data.find((item) => item._id === props?.editId);
      if (company) {
        setName(company?.name);
        setEmail(company?.businessEmail);
        setPhone(company?.phone);
        setAddress(company?.address?.firstLine);
        setCountry(company?.address?.country);
        setProvince(company?.address?.province);
        setCity(company?.address?.city);
        setZipCode(company?.address?.zipcode);
        setLogoUrl(company?.logo);
      }
    }
  }, [props?.editId, props?.data]);

  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);
  // setting Canada as default country
  useEffect(() => {
    if (countryData?.length) setCountry("63d76e99b005363c54606967");
    else setCountry("");
  }, [countryData]);
  // get province list from country
  useEffect(() => {
    dispatch(getprovinceByCountry(country));
  }, [country, dispatch]);
  // get city list from province
  useEffect(() => {
    dispatch(getcityByProvince(province));
  }, [province, dispatch]);
  // get neighbourhood list from city
  useEffect(() => {
    dispatch(getneighbourhoodByCity(city));
  }, [city, dispatch]);

  // set country by address or marker
  useEffect(() => {
    if (locationData?.country && countryData?.length) {
      let a = countryData?.filter((li) => li?.name === locationData?.country);
      if (a?.length) setCountry(a[0]?._id);
      else setCountry("");
    }
  }, [locationData?.country, countryData]);
  // set province by address or marker
  useEffect(() => {
    if (locationData?.province && provinceData?.length) {
      let a = provinceData?.filter((li) => li?.name === locationData?.province);
      if (a?.length) setProvince(a[0]?._id);
      else setProvince("");
    }
  }, [locationData?.province, provinceData]);
  // set city by address or marker
  useEffect(() => {
    if (locationData?.city && cityData?.length) {
      let a = cityData?.filter((li) => li?.name === locationData?.city);
      if (a?.length) setCity(a[0]?._id);
      else setCity("");
    }
  }, [locationData?.city, cityData]);

  const onFormReset = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCountry("");
    setProvince("");
    setCity("");
    setZipCode("");
    setLogo("");
    setLogoUrl("");
    setLocationData(false);
    setPassword("");
    setConfirmPassword("");
    props?.toggle();
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 20 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      const newImage = name
        ? acceptedFiles.map((file, index) => {
            let devName = convertToSlug(name);

            const newName = `${devName ? devName : "development"}-${uuid()?.slice(0, 8)}.${file.name.split(".").pop()}`;
            return new File([file], newName, { type: file.type });
          })?.[0]
        : acceptedFiles[0];

      setLogo(newImage);

      // create temp Blob url to view Image
      let blob = URL.createObjectURL(newImage);
      setLogoUrl(blob);
    },
    [name]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: { "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] },
    maxSize: 20971520,
  });

  const handlePlaceChanged = (places, searchBox) => {
    if (places?.length) {
      setAddress(places[0].formatted_address);
      let data = getLocationData(places[0]);
      setAddress(data?.address);
      setZipCode(data?.zipcode);
      setLocationData(data);
    }
  };

  const handlePayload = () => {
    let data = { name, businessEmail: email, companyType: props?.type, phone };

    if (address) data = { ...data, address: { firstLine: address } };
    if (country) data = { ...data, address: { ...data?.address, country } };
    if (province) data = { ...data, address: { ...data?.address, province } };
    if (city) data = { ...data, address: { ...data?.address, city } };
    if (zipCode) data = { ...data, address: { ...data?.address, zipcode: zipCode } };
    if (logoUrl) data = { ...data, logo: logoUrl };

    if (!props?.editId) data = { ...data, password, confirmPassword };

    return data;
  };

  const uploadImg = (logo, cb) => {
    if (logo) {
      let formData = new FormData();
      formData.append("file", logo);
      formData.append("folder", "company");
      formData.append("width", 300);
      formData.append("height", 300);

      dispatch(
        uploadFile(
          formData,
          (res) => {
            cb(res?.data?.[0]?.url);
          },
          (res) => {
            toast.error("Something went wrong!");
          }
        )
      );
    }
  };

  const deleteImg = (path) => {
    dispatch(
      deleteFile(
        { path },
        (res) => {
          setLogo("");
          setLogoUrl("");
        },
        (res) => {
          toast.error("Something went wrong!");
        }
      )
    );
  };

  const handleAdd = async (data) => {
    dispatch(
      addCompany(
        data,
        () => {
          toast.success("Company added successfully");
          onFormReset();
          props?.handleGetData();
        },
        () => {
          toast.error("Something went wrong!");
        }
      )
    );
  };

  const onFormSubmit = async (e) => {
    console.log("e add: ", e);
    let data = handlePayload();

    if (logo && logoUrl) {
      uploadImg(logo, (url) => {
        data.logo = url;
        handleAdd(data);
      });
    } else {
      handleAdd(data);
    }
  };

  const handleEdit = async (data) => {
    dispatch(
      editCompany(
        props?.editId,
        data,
        () => {
          toast.success("Company updated successfully");
          onFormReset();
          props?.handleGetData();
        },
        () => {
          toast.error("Something went wrong!");
        }
      )
    );
  };

  const onEditSubmit = async (e) => {
    console.log("e edit: ", e);
    let data = handlePayload();

    if (logo && logoUrl) {
      console.log("upload data: ", data);
      uploadImg(logo, (url) => {
        data.logo = url;
        handleEdit(data);
      });
    } else {
      console.log("no upload data: ", data);
      handleEdit(data);
    }
  };

  if (loadError) return "Error...";
  if (!isLoaded) {
    return (
      <div className="loadingDiv">
        <Spinner animation="grow" variant="info" style={{ width: "50px", height: "50px" }} />
      </div>
    );
  }

  console.log("props?.editId: ", props?.editId);
  return (
    <Modal
      {...props}
      size="lg"
      isOpen={props.view?.add || props.view?.edit}
      toggle={() => onFormReset()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader toggle={() => onFormReset()}>
        <div style={{ fontSize: "22px", margin: "8px" }}>{props?.view?.edit ? "Edit Company" : "Add Company"}</div>
      </ModalHeader>
      <ModalBody>
        <div className="mt-4">
          <form onSubmit={handleSubmit(props?.editId ? onEditSubmit : onFormSubmit)}>
            <Row className="g-3">
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    Name*
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      ref={register({
                        required: "This field is required",
                      })}
                    />
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </div>
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    Email*
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      ref={register({
                        required: "This field is required",
                      })}
                    />
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </div>
                </div>
              </Col>
              {props?.editId ? null : (
                <>
                  <Col size="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Password*
                      </label>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPassState(!passState);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch ${
                            passState ? "is-hidden" : "is-shown"
                          }`}
                        >
                          <Icon name="eye" className="passcode-icon icon-show"></Icon>

                          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                        </a>
                        <input
                          type={passState ? "text" : "password"}
                          id="password"
                          name="password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          placeholder="Enter your passcode"
                          className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                        />
                        {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col size="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Confirm Password*
                      </label>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPassState2(!passState2);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch ${
                            passState2 ? "is-hidden" : "is-shown"
                          }`}
                        >
                          <Icon name="eye" className="passcode-icon icon-show"></Icon>

                          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                        </a>
                        <input
                          type={passState2 ? "text" : "password"}
                          id="password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          placeholder="Enter your passcode"
                          className={`form-control-lg form-control ${passState2 ? "is-hidden" : "is-shown"}`}
                        />
                        {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                      </div>
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>
                </>
              )}
              <Col size="12">
                <div className="form-group">
                  <label className="form-label m-0" htmlFor="product-title">
                    Logo
                  </label>
                  <div className="form-control-wrap">
                    <div className={clsx(styles.bannerWhite)}>
                      {logo || logoUrl ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                            margin: "auto",
                            gap: "1rem",
                          }}
                        >
                          <img
                            src={logoUrl && logo ? logoUrl : `${process.env.REACT_APP_IMGURL}${logoUrl}`}
                            alt={"aaa"}
                            style={{
                              width: "300px",
                              height: "300px",
                              objectFit: "contain",
                              border: "1px solid #ececec",
                              background: "#ececec",
                            }}
                          />
                          <div
                            style={{ fontSize: "16px", color: "#ce6048", cursor: "pointer" }}
                            onClick={() => {
                              deleteImg(logoUrl);
                            }}
                          >
                            <AiFillCloseCircle style={{ fontSize: "25px" }} /> Delete
                          </div>
                        </div>
                      ) : (
                        <div {...getRootProps({ className: styles.bannerDiv })}>
                          <IoImagesOutline />
                          <input
                            type="file"
                            id="logo"
                            name="logo"
                            style={{ display: "none" }}
                            {...getInputProps()}
                            readOnly
                          />
                          <p className={clsx(styles.para1)}>Drag and drop the logo of your company</p>
                          <p className={clsx(styles.para2)}>(Maximum size 300x300)</p>
                          <button tid="myfile" name="myfile" type="button">
                            Select and Upload
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    Phone Number
                  </label>
                  <div className="form-control-wrap">
                    <PhoneInput
                      onlyCountries={["ca"]}
                      country={"ca"}
                      value={phone}
                      onChange={(phone, data, event, formattedValue) => {
                        setPhone(formattedValue);
                      }}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "0",
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    Address
                  </label>
                  <div className="form-control-wrap">
                    <StandaloneSearchBox
                      onLoad={(searchBox) => {
                        searchBox.addListener("places_changed", () =>
                          handlePlaceChanged(searchBox.getPlaces(), searchBox)
                        );
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Enter Your Company Address"
                        className={clsx("form-control")}
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </StandaloneSearchBox>
                  </div>
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    Country
                  </label>
                  <div className="form-control-wrap">
                    <select
                      className={clsx("", styles.selectTag)}
                      style={{
                        padding: "7px 15px",
                        background: country === "63d76e99b005363c54606967" ? "#e0e0e0" : "",
                      }}
                      value={country}
                      name="country"
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select</option>
                      {countryData?.length
                        ? countryData?.map((item) => (
                            <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                              {item?.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    Province
                  </label>
                  <div className="form-control-wrap">
                    <select
                      className={clsx("", styles.selectTag)}
                      style={{ padding: "7px 15px" }}
                      value={province}
                      name="province"
                      onChange={(e) => setProvince(e.target.value)}
                    >
                      <option value="">Select</option>
                      {provinceData?.length ? (
                        provinceData?.map((item) => (
                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                            {item?.name}
                          </option>
                        ))
                      ) : (
                        <option value="">Please select country first</option>
                      )}
                    </select>
                  </div>
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    City
                  </label>
                  <div className="form-control-wrap">
                    <select
                      className={clsx("", styles.selectTag)}
                      style={{ padding: "7px 15px" }}
                      value={city}
                      name="city"
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {cityData?.length ? (
                        cityData?.map((item) => (
                          <option key={`${Math.random()}-${item?._id}`} value={item?._id}>
                            {item?.name}
                          </option>
                        ))
                      ) : (
                        <option value="">Please select province first</option>
                      )}
                    </select>
                  </div>
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="product-title">
                    Zipcode
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="zipCode"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              <hr style={{ width: "100%", height: "1px", background: "#a0a0a0", color: "#a0a0a0" }} />
              <Col size="12">
                <Button color="primary" type="submit">
                  <span>Save</span>
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CompanyModal;
