import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getAllBlogs = createAsyncThunk("blogs/blogsList", async (data) => {
  const response = await getMethod(`/api/admin/blogs`, data);
  return response;
});

export const getSingleBlog = createAsyncThunk("blogs/blog", async (slug) => {
  const response = await getMethod(`/api/admin/blog/${slug}`);
  return response;
});

export const getBlogCategories = createAsyncThunk("blogs/categories", async (data) => {
  const response = await getMethod(`/api/blog-categories`, data);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  blogs: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
  blog: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
  categories: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: 0,
  },
};

const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.blogs = action.payload;
    },
    setSingleBlog: (state, action) => {
      state.blog.loading = false;
      state.blog.success = true;
      state.blog.error = false;
      state.blog.data = action.payload;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    handleBlogReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.blogs = initialState.blogs;
      state.blog = initialState.blog;
      state.categories = initialState.categories;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllBlogs.pending, (state) => {
      state.blogs.loading = true;
      state.blogs.success = false;
      state.blogs.error = false;
    });
    builder.addCase(getAllBlogs.fulfilled, (state, action) => {
      state.blogs.loading = false;
      state.blogs.success = true;
      state.blogs.error = false;
      state.blogs.data = action.payload.data;
      state.blogs.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
    });
    builder.addCase(getAllBlogs.rejected, (state, action) => {
      state.blogs.loading = false;
      state.blogs.success = false;
      state.blogs.error = action?.error?.message;
    });

    builder.addCase(getSingleBlog.pending, (state) => {
      state.blog.loading = true;
      state.blog.success = false;
      state.blog.error = false;
    });
    builder.addCase(getSingleBlog.fulfilled, (state, action) => {
      state.blog.loading = false;
      state.blog.success = true;
      state.blog.error = false;
      state.blog.data = action.payload.data;
    });
    builder.addCase(getSingleBlog.rejected, (state, action) => {
      state.blog.loading = false;
      state.blog.success = false;
      state.blog.error = action?.error?.message;
    });

    builder.addCase(getBlogCategories.pending, (state) => {
      state.categories.loading = true;
      state.categories.success = false;
      state.categories.error = false;
    });
    builder.addCase(getBlogCategories.fulfilled, (state, action) => {
      state.categories.loading = false;
      state.categories.success = true;
      state.categories.error = false;
      state.categories.data = action.payload.data;
      state.categories.total = action.payload.total;
    });
    builder.addCase(getBlogCategories.rejected, (state, action) => {
      state.categories.loading = false;
      state.categories.success = false;
      state.categories.error = action?.error?.message;
    });
  },
});

export const { isLoading, isSuccess, isError, isReset, setSingleBlog, handleBlogReset } = blogSlice.actions;

export default blogSlice.reducer;

export const addNewBlog = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await postMethod(`/api/admin/blog`, data);
  if (response?.success) {
    dispatch(handleBlogReset());
    SuccessCallback(response);
  } else ErrorCallback(response);
};
export const updateBlog = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/blog/${id}`, data);
  if (response?.success) {
    dispatch(handleBlogReset());
    SuccessCallback(response);
  } else ErrorCallback(response);
};
export const archiveBlog = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/blog/archive/${id}`);
  if (response?.success) {
    SuccessCallback(response);
  } else ErrorCallback(response);
};
export const restoreBlog = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/admin/blog/restore/${id}`);
  if (response?.success) {
    SuccessCallback(response);
  } else ErrorCallback(response);
};
export const deleteBlog = (id, SuccessCallback, ErrorCallback) => async () => {
  setAuthorizationToken(true);
  const response = await deleteMethod(`/api/admin/blog/${id}`);
  if (response?.success) SuccessCallback(response);
  else ErrorCallback(response);
};
