import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getDevelopmentList = createAsyncThunk("development/list", async (query) => {
  try {
    const response = await getMethod(`/api/admin/development`, query);
    return response;
  } catch (error) {
    throw error;
  }
});

export const searchDevelopment = createAsyncThunk("development/search", async (query) => {
  try {
    const response = await getMethod(`/api/development-search`, query);
    return response;
  } catch (error) {
    throw error;
  }
});

export const getDevelopmentById = createAsyncThunk("development/getById", async (id) => {
  try {
    const response = await getMethod(`/api/agent/development/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
});

export const getDevelopmentBySlug = createAsyncThunk("development/getDevelopmentBySlug", async (params) => {
  try {
    setAuthorizationToken(true);
    const response = await getMethod(`/api/company/development/${params}`);
    return response;
  } catch (error) {
    throw error;
  }
});

let initialState = {
  loading: false,
  success: false,
  developmentSearch: { loading: false, success: false, data: null, error: false },
  developmentById: { loading: false, success: false, data: null, error: false },
  development: null,
  developments: { loading: false, success: false, data: null, total: null, error: false },
  error: false,
  addDevelopment: {
    loading: false,
    success: false,
    data: null,
    error: false,
    devStep: 1,
    "dev-aws": null,
    "dev-info": null,
    "dev-floorplans": null,
    "dev-planImage": null,
    "dev-Saleslogo": null,
    "dev-LNinfo": null,
  },
};

export const developmentSlice = createSlice({
  name: "development",
  initialState: initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.development = action.payload;
      state.error = false;
    },
    isError: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    handleAddDevelopment: (state, action) => {
      console.log("action: ", action);
      state.addDevelopment = action.payload;
    },
    handleDevAws: (state, action) => {
      state["dev-aws"] = action.payload;
    },
    handleDevStep: (state, action) => {
      state.addDevelopment.devStep = action.payload;
    },
    handleDevAddReset: (state) => {
      state.addDevelopment = initialState.addDevelopment;
      state.developmentById = initialState.developmentById;
      state.developmentSearch = initialState.developmentSearch;
      state.developments = initialState.developments;
      state.development = initialState.development;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getDevelopmentList.pending, (state) => {
      state.developments.loading = true;
      state.developments.success = false;
      state.developments.data = null;
      state.developments.total = null;
      state.developments.error = false;
    });
    builder.addCase(getDevelopmentList.fulfilled, (state, action) => {
      state.developments.loading = false;
      state.developments.success = true;
      state.developments.data = action.payload.data;
      state.developments.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
      state.developments.error = false;
    });
    builder.addCase(getDevelopmentList.rejected, (state, action) => {
      state.developments.loading = false;
      state.developments.success = false;
      state.developments.data = null;
      state.developments.total = null;
      state.developments.error = action?.error?.message;
    });

    builder.addCase(searchDevelopment.pending, (state) => {
      state.developmentSearch.loading = true;
      state.developmentSearch.success = false;
      state.developmentSearch.data = null;
      state.developmentSearch.error = false;
    });
    builder.addCase(searchDevelopment.fulfilled, (state, action) => {
      state.developmentSearch.loading = false;
      state.developmentSearch.success = true;
      state.developmentSearch.data = action.payload.data;
      state.developmentSearch.error = false;
    });
    builder.addCase(searchDevelopment.rejected, (state, action) => {
      state.developmentSearch.loading = false;
      state.developmentSearch.success = false;
      state.developmentSearch.data = null;
      state.developmentSearch.error = action?.error?.message;
    });

    builder.addCase(getDevelopmentById.pending, (state) => {
      state.developmentById.loading = true;
      state.developmentById.success = false;
      state.developmentById.data = null;
      state.developmentById.error = false;
    });
    builder.addCase(getDevelopmentById.fulfilled, (state, action) => {
      state.developmentById.loading = false;
      state.developmentById.success = true;
      state.developmentById.data = action.payload.data;
      state.developmentById.error = false;
    });
    builder.addCase(getDevelopmentById.rejected, (state, action) => {
      state.developmentById.loading = false;
      state.developmentById.success = false;
      state.developmentById.data = null;
      state.developmentById.error = action?.error?.message;
    });

    builder.addCase(getDevelopmentBySlug.pending, (state) => {
      state.developmentById.loading = true;
      state.developmentById.success = false;
      state.developmentById.data = null;
      state.developmentById.error = false;
    });
    builder.addCase(getDevelopmentBySlug.fulfilled, (state, action) => {
      console.log("action: ", action);
      state.developmentById.loading = false;
      state.developmentById.success = true;
      state.developmentById.data = action.payload.data;
      state.developmentById.error = false;
    });
    builder.addCase(getDevelopmentBySlug.rejected, (state, action) => {
      state.developmentById.loading = false;
      state.developmentById.success = false;
      state.developmentById.data = null;
      state.developmentById.error = action?.error?.message;
    });
  },
});

export const { isLoading, isError, isSuccess, handleAddDevelopment, handleDevAws, handleDevStep, handleDevAddReset } =
  developmentSlice.actions;

export default developmentSlice.reducer;

export const addNewDevelopment = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await postMethod(`/api/development`, data);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};

export const editDevelopment = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await putMethod(`/api/admin/development/${id}`, data);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};

export const duplicateDevelopment = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await postMethod(`/api/duplicate-development/${data}`);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    // dispatch(isError());
  }
};

export const temperoryDeleteDevelopment = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await deleteMethod(`/api/admin/development/delete/${id}`);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};

export const temperoryDeleteMultipleDevelopment = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await postMethod(`/api/admin/development/delete`, data);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};

export const permanentDeleteDevelopment = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await deleteMethod(`/api/admin/development/delete-permanent/${id}`);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};

export const permanentDeleteMultipleDevelopment = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await postMethod(`/api/admin/development/delete-permanent`, data);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};

export const restoreDevelopment = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await putMethod(`/api/admin/development/restore/${id}`);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};

export const restoreMultipleDevelopment = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  try {
    setAuthorizationToken(true);
    dispatch(isLoading());
    const response = await postMethod(`/api/admin/development/restore`, data);
    if (response?.success) {
      SuccessCallback(response);
    } else {
      ErrorCallback(response);
    }
  } catch (error) {
    dispatch(isError());
  }
};
