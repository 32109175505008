import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const Error404Modern = lazy(() => import("./pages/error/404-modern"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<div />}>
            <App />
          </Suspense>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.Fragment>
);

reportWebVitals();
