import { useRef } from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineStar } from "react-icons/ai";
import styles from "./draggable.module.scss";
import { ItemTypes } from "./ItemTypes";
import { useDrag, useDrop } from "react-dnd";

const style = {
  // border: "1px dashed gray",
  // padding: "0.5rem 1rem",
  // marginBottom: ".5rem",
  // backgroundColor: "white",
  cursor: "move",
};

export const Card = ({
  id,
  card,
  cards,
  index,
  moveCard,
  deleteArray,
  setDeleteArray,
  coverImg,
  handleCoverImg,
  handleDelete,
  handleMultiple,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <div className={styles.imgDiv}>
        <img src={`${process.env.REACT_APP_IMGURL}${card}`} alt="media_image" />
        {cards?.length > 1 ? (
          <div className={styles.overlayTop}>
            <input
              className={styles.checkBox}
              type="checkbox"
              checked={deleteArray?.includes(card)}
              value={card}
              onChange={(e) => handleMultiple(e)}
            />
          </div>
        ) : null}
        <div className={styles.overlay}>
          {/* {coverImg === card ? null : (
            <div className={styles.set}>
              <span
                onClick={() => {
                  handleCoverImg(card, index);
                }}
              >
                <AiOutlineStar /> Set as Cover Image
              </span>
            </div>
          )} */}
          <div className={styles.delete}>
            <span
              onClick={() => {
                handleDelete(card);
              }}
            >
              <AiOutlineCloseCircle /> Delete
            </span>
          </div>
        </div>
      </div>
      {coverImg === card ? (
        <div className={styles.cover}>
          <AiOutlineCheckCircle /> Cover Image
        </div>
      ) : (
        <div className={styles.cover}>&nbsp;</div>
      )}
    </div>
  );
};
