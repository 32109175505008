import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Content from "../../layout/content/Content";
import styles from "./dev.module.scss";
import DevNavTab from "../../pages/Development/DevNavTab";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
} from "../Component";
import FooterBtn from "../common/FooterBtn";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteFile, uploadFile } from "../../redux/Apislices/awsSlice";
import {
  addNewDevelopment,
  editDevelopment,
  getDevelopmentBySlug,
  handleAddDevelopment,
  handleDevAddReset,
} from "../../redux/Apislices/developmentSlice";
import { useDropzone } from "react-dropzone";
import { getcity } from "../../redux/Apislices/citySlice";
import { getHomeType } from "../../redux/Apislices/homeTypeSlice";
import { getPaymentById } from "../../redux/Apislices/paymentSlice";
import clsx from "clsx";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import { convertToSlug } from "../../utils/Utils";

const Dev7 = ({ toggleNext, toggleBack, toggleIconTab, activeIconTab }) => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile, development, payment, city, homeTypeData } = useSelector((state) => ({
    profile: state.profile?.profile,
    development: state?.development,
    payment: state?.payment?.payment?.data,
    city: state?.city,
    homeTypeData: state?.homeType,
  }));
  const { developmentById, addDevelopment } = development;

  const [agentCommission, setAgentCommission] = useState("");
  const [bonus, setBonus] = useState("");
  const [agentRemark, setAgentRemark] = useState("");
  const [featured, setFeatured] = useState("No");
  const [featuredMand, setFeaturedMand] = useState(false);

  const [mediaData, setMediaData] = useState("");
  const [mediaName, setMediaName] = useState("");
  const mediaRef = useRef();
  const [mediaArray, setMediaArray] = useState([]);

  const [isEditable, setIsEditable] = useState("");
  const [edit, setEdit] = useState("");

  const [devData, setDevData] = useState("");

  const [terms, setTerms] = useState(false);
  const termRef = useRef(null);
  const [termMand, setTermMand] = useState(false);

  const uploadImage = async (file) => {
    let toastId = toast.loading("Uploading...");
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 10000);
    dispatch(
      uploadFile(
        file,
        (res) => {
          if (type === "edit") {
            let data = {
              type: "presale",
            };
            if (res?.data?.length) {
              if (devData?.vipAgentInformationFolder?.files?.length) {
                data = {
                  ...data,
                  vipAgentInformationFolder: {
                    ...devData?.vipAgentInformationFolder,
                    files: [
                      ...devData?.vipAgentInformationFolder?.files,
                      ...res?.data?.map((li) => ({
                        label: mediaName,
                        fileUrl: li?.url,
                      })),
                    ],
                  },
                };
              } else {
                data = {
                  ...data,
                  vipAgentInformationFolder: {
                    ...devData?.vipAgentInformationFolder,
                    files: [
                      ...res?.data?.map((li) => ({
                        label: mediaName,
                        fileUrl: li?.url,
                      })),
                    ],
                  },
                };
              }
            }
            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  dispatch(getDevelopmentBySlug(res?.data?.slug));
                  toast.dismiss(toastId);
                  toast.success("File(s) Uploaded");
                  setMediaData("");
                  setMediaName("");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            if (addDevelopment?.["dev-LNinfo"]?.length) {
              let image = addDevelopment?.["dev-LNinfo"];
              image = [
                ...image,
                ...res?.data?.map((li, i) => ({
                  editId: `${i}${Math.random()}`,
                  label: mediaName,
                  fileUrl: li?.url,
                })),
              ];
              dispatch(handleAddDevelopment({ ...addDevelopment, "dev-LNinfo": image }));
            } else {
              dispatch(
                handleAddDevelopment({
                  ...addDevelopment,
                  "dev-LNinfo": res?.data?.map((li, i) => ({
                    editId: `${i}${Math.random()}`,
                    label: mediaName,
                    fileUrl: li?.url,
                  })),
                })
              );
            }
            toast.dismiss(toastId);
            toast.success("File(s) Uploaded");
            setMediaData("");
            setMediaName("");
          }
        },
        (res) => {
          toast.dismiss(toastId);
          toast.error("Something Went Wrong! Try Again");
        }
      )
    );
  };

  const handleDelete = async (path) => {
    let toastId = toast.loading("Deleting...");
    dispatch(
      deleteFile(
        { path },
        (res) => {
          if (type === "edit") {
            let data = {
              type: "presale",
              vipAgentInformationFolder: {
                ...devData?.vipAgentInformationFolder,
                files: [...devData?.vipAgentInformationFolder?.files?.filter((li) => li?.fileUrl !== path)],
              },
            };

            dispatch(
              editDevelopment(
                id,
                data,
                (res) => {
                  dispatch(getDevelopmentBySlug(res?.data?.slug));
                  toast.dismiss(toastId);
                  toast.success("File Deleted");
                },
                (res) => {
                  toast.error(res?.message);
                }
              )
            );
          } else {
            let a = mediaArray.findIndex((li) => li?.fileUrl === path);
            if (a !== -1) {
              mediaArray.splice(a, 1);
              setMediaArray([...mediaArray]);
              dispatch(handleAddDevelopment({ ...addDevelopment, "dev-LNinfo": mediaArray }));
            }
            toast.dismiss(toastId);
            toast.success("File Deleted");
          }
        },
        (res) => {
          toast.dismiss(toastId);
          toast.error("Something Went Wrong! Try Again");
        }
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error("File is larger than 5 MB");
          }
          if (err.code === "file-invalid-type") {
            toast.error(err.message);
          }
        });
      });

      setMediaData(acceptedFiles?.[0]);
      setMediaName(acceptedFiles?.[0]?.name);
      if (acceptedFiles?.[0]) {
        setTimeout(() => {
          // document.getElementById("mediaName").focus();
          mediaRef.current.focus();
        }, 500);
      }
      console.log("devData: ", devData);
    },
    [devData]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxSize: 5242880,
  });

  const handleAddUpdate = (CB) => {
    let editData = {
      ...devData,
      vipAgentInformationFolder: {
        files: mediaArray,
        remark: agentRemark,
        sellingCommision: agentCommission,
        bonus: bonus,
      },
      featured: featured,
    };

    let data = { ...editData };

    data.type = "presale";
    if (type === "edit") {
      if (devData?.status === "draft") {
        data.status = "pending";
      } else {
        data.status = devData?.status;
      }
    } else {
      data.status = "pending";
    }

    if (profile?.role === "associate") {
      data = {
        ...data,
        company: {
          ...data?.company,
          associate: {
            associate: profile?._id,
            byAssociate: true,
          },
        },
      };
    }

    console.log("data: ", data);

    if (type === "edit") {
      dispatch(
        editDevelopment(
          id,
          data,
          (res) => {
            CB({ success: true, data: res?.data, type: "update" });
          },
          (res) => {
            toast.success("Something Went Wrong!");
          }
        )
      );
    } else {
      dispatch(
        addNewDevelopment(
          data,
          (response) => {
            CB({ success: true, data: response?.data, type: "add" });
          },
          (response) => {
            toast.error(response?.message);
          }
        )
      );
    }
  };

  const handleNext = () => {
    if (terms) {
      handleAddUpdate(async (res) => {
        console.log("res: ", res?.data);
        if (res?.success) {
          if (type === "edit") toast.success("Development updated");
          else toast.success("Development Added");

          if (res?.data?.featured === "Yes" || res?.data?.featured === "No") {
            dispatch(handleDevAddReset());
            navigate("/development");
          } else {
            toggleNext();
          }
        }
      });
    } else {
      if (!terms) setTermMand(true);
      if (!termMand) {
        termRef.current.focus();
        toast.error("please accept Terms & Conditions to submit");
      }
    }
  };

  const handleBack = () => {
    toggleBack();
  };

  useEffect(() => {
    dispatch(getcity());
    dispatch(getHomeType());
  }, [dispatch]);

  useEffect(() => {
    if (developmentById?.data) setDevData(developmentById?.data);
    else if (addDevelopment?.data) setDevData(addDevelopment?.data);
  }, [developmentById, addDevelopment]);

  useEffect(() => {
    if (devData) {
      console.log("devData 7: ", devData);
      setAgentCommission(devData?.vipAgentInformationFolder?.sellingCommision || agentCommission);
      setBonus(devData?.vipAgentInformationFolder?.bonus || bonus);
      setAgentRemark(devData?.vipAgentInformationFolder?.remark || agentRemark);
      setFeatured(devData?.featured || featured);
    }
  }, [devData]);
  console.log("featured: ", featured);

  useEffect(() => {
    if (devData?.payment) {
      dispatch(getPaymentById(devData?.payment));
    }
  }, [devData?.payment, dispatch]);

  useEffect(() => {
    if (addDevelopment?.["dev-LNinfo"]?.length) setMediaArray(addDevelopment?.["dev-LNinfo"]);
  }, [addDevelopment]);

  useEffect(() => {
    if (type === "edit") setTerms(true);
  }, [type]);

  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {devData?.name ? devData?.name : type === "add" ? "Add Development" : "Edit Development"}
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <DevNavTab toggleIconTab={toggleIconTab} activeIconTab={activeIconTab} update={handleAddUpdate} />

                <div className="card-inner">
                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4">REALTOR® Information Package</BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <Row>
                        <Col sm={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>
                              {`Selling Agent's Commission`} {/* <MdOutlineInfo className={styles.infoIcon} />{" "} */}
                            </p>
                            <input
                              className={clsx("form-control", styles.textField)}
                              value={agentCommission}
                              name="agentCommission"
                              onChange={(e) => setAgentCommission(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col sm={"6"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>Bonus/Incentive </p>
                            <input
                              className={clsx("form-control", styles.textField)}
                              value={bonus}
                              name="bonus"
                              onChange={(e) => setBonus(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col sm={"12"}>
                          <div className={clsx(styles.askingPriceDiv)}>
                            <p className={clsx(styles.heightPara)}>{`Enter remarks, If any.`}</p>
                            <textarea
                              className="form-control"
                              rows={4}
                              type="textarea"
                              id="default-textarea"
                              value={agentRemark}
                              name="agentRemark"
                              onChange={(e) => {
                                setAgentRemark(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={"12"} className={clsx("mt-5")}>
                          <div className={clsx(styles.bannerWhite)}>
                            <h1 className="text-sm-start">
                              Attach the documents you want to share only with the Agents (pdf, png, or jpeg only)
                            </h1>
                            {mediaData || mediaName ? (
                              <div className={clsx(styles.bannerDiv)}>
                                <p className={clsx(styles.para1)}>Change Name of the file uploaded</p>
                                <input
                                  type="text"
                                  id="mediaName"
                                  name="profile1"
                                  ref={mediaRef}
                                  value={mediaName}
                                  className={clsx(styles.uploadInput)}
                                  onChange={(e) => {
                                    setMediaName(e.target.value);
                                  }}
                                  onFocus={(e) => e.target.select()}
                                  onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                      if (mediaData) {
                                        let devName = convertToSlug(devData?.name);

                                        let cityName = convertToSlug(
                                          city?.city?.find((li) => li?._id === devData?.address?.city)?.name
                                        );

                                        let homeTypeName = devData?.homeTypes?.[0]
                                          ? `-${homeTypeData?.homeType
                                              ?.find((li) => li?._id === devData?.homeTypes?.[0])
                                              ?.name?.split(" ")
                                              ?.join("-")}`
                                          : "homes";

                                        let ext = mediaName?.split(".")?.pop();

                                        let newName = `${devName}-${cityName}-${homeTypeName}-info-file-${mediaName
                                          ?.split(".")?.[0]
                                          ?.split(" ")
                                          ?.join("-")}-${mediaArray?.length + 1}.${ext}`;

                                        const updatedFile = new File([mediaData], newName, { type: mediaData.type });
                                        let formData = new FormData();
                                        formData.append("file", updatedFile);
                                        formData.append("folder", "development");
                                        uploadImage(formData);
                                      }
                                    } else if (e.key === "Escape") {
                                      setMediaData("");
                                      setMediaName("");
                                    }
                                  }}
                                />
                                <button
                                  type="file"
                                  id="myfile"
                                  className={clsx(styles.uploadBtn)}
                                  name="myfile"
                                  onClick={() => {
                                    if (mediaData) {
                                      let devName = devData?.name?.split(" ")?.join("-");

                                      let cityName = city?.city
                                        ?.find((li) => li?._id === devData?.address?.city)
                                        ?.name?.split(" ")
                                        ?.join("-");

                                      let homeTypeName = devData?.homeTypes?.[0]
                                        ? `-${homeTypeData?.homeType
                                            ?.find((li) => li?._id === devData?.homeTypes?.[0])
                                            ?.name?.split(" ")
                                            ?.join("-")}`
                                        : "";

                                      let ext = mediaName?.split(".")?.pop();

                                      let newName = `${devName}-${cityName}-${homeTypeName}-info-file-${mediaName
                                        ?.split(".")?.[0]
                                        ?.split(" ")
                                        ?.join("-")}-${mediaArray?.length + 1}.${ext}`;

                                      const updatedFile = new File([mediaData], newName, { type: mediaData.type });
                                      let formData = new FormData();
                                      formData.append("file", updatedFile);
                                      formData.append("folder", "development");
                                      uploadImage(formData);
                                    }
                                  }}
                                >
                                  Change and Upload
                                </button>
                                <Button
                                  variant="text"
                                  className={clsx(styles.cancelBtn)}
                                  onClick={() => {
                                    setMediaData("");
                                    setMediaName("");
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              <div
                                // className={clsx(styles.bannerDiv)}
                                {...getRootProps({ className: styles.bannerDiv })}
                              >
                                <FiUpload className={styles.icon} />

                                <input
                                  type="file"
                                  id="profile1"
                                  name="profile1"
                                  style={{ display: "none" }}
                                  {...getInputProps()}
                                />
                                <p className={clsx(styles.para1)}>Drag and drop the files here</p>
                                <p className={clsx(styles.para2)}>(Maximum size 5 MB)</p>
                                <button className={clsx(styles.uploadBtn)} type="file" id="myfile" name="myfile">
                                  Select and Upload
                                </button>
                              </div>
                            )}

                            <p className={("text-sm-start text-center mt-4 mb-5", styles.para2)}>
                              Uploaded Attachments
                            </p>

                            <div className={styles.arrayUploadItemDiv}>
                              {mediaArray?.length
                                ? mediaArray?.map((item, index) => {
                                    return (
                                      <div key={`${Math.random()}-${index}`} className={styles.para2}>
                                        <div className={styles.inputDiv}>
                                          {index + 1}.{" "}
                                          <span
                                            className={clsx(styles.name)}
                                            onClick={() => {
                                              window.open(`${process.env.REACT_APP_IMGURL}${item?.fileUrl}`, "_blank");
                                            }}
                                          >
                                            {item?.label}
                                          </span>
                                        </div>

                                        <span>
                                          <BiEdit
                                            className={styles.arrayCloseIcon}
                                            style={{
                                              marginRight: "8px",
                                            }}
                                            onClick={() => {
                                              setIsEditable(item?._id || item?.editId);
                                              setEdit(item?.label);
                                            }}
                                          />
                                          <AiOutlineCloseCircle
                                            className={styles.arrayCloseIcon}
                                            onClick={() => {
                                              handleDelete(item?.fileUrl);
                                            }}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                            <Modal
                              isOpen={isEditable}
                              toggle={() => {
                                setIsEditable(null);
                              }}
                              centered
                              className={styles.modal}
                            >
                              <ModalHeader
                                toggle={() => {
                                  setIsEditable(null);
                                }}
                              >
                                Edit Label
                              </ModalHeader>
                              <ModalBody>
                                <div>
                                  <label htmlFor="edit">New Label</label>
                                  <input
                                    type="text"
                                    id={"edit"}
                                    value={edit}
                                    onChange={(e) => {
                                      setEdit(e.target.value);
                                    }}
                                    autoFocus
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter" && e.target.value) {
                                        let a = mediaArray?.map((li) =>
                                          li?._id === isEditable || li?.editId === isEditable
                                            ? { ...li, label: edit }
                                            : li
                                        );
                                        setMediaArray(a);
                                        setIsEditable("");
                                        setEdit("");
                                      } else if (e.key === "Escape") {
                                        setIsEditable("");
                                        setEdit("");
                                      }
                                    }}
                                  />
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <button
                                  onClick={() => {
                                    setIsEditable("");
                                    setEdit("");
                                  }}
                                  className={styles.closeBtn}
                                >
                                  Close
                                </button>
                                <button
                                  className={styles.submitBtn}
                                  onClick={() => {
                                    let a = mediaArray?.map((li) =>
                                      li?._id === isEditable || li?.editId === isEditable ? { ...li, label: edit } : li
                                    );
                                    setMediaArray(a);
                                    setIsEditable("");
                                    setEdit("");
                                  }}
                                >
                                  Save Changes
                                </button>
                              </ModalFooter>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </BlockContent>
                  </Block>

                  {type === "edit" && devData?.featured === "Yes" ? null : (
                    <>
                      <br />
                      <br />
                      <Block>
                        <BlockHead>
                          <BlockTitle tag="h4"></BlockTitle>
                        </BlockHead>
                        <BlockContent>
                          <Row>
                            <Col sm={"12"}>
                              <div className={clsx(styles.bannerWhite)}>
                                <p className={styles.para1}>Do you want to make this development as featured?</p>
                                <div className={clsx(styles.checkContainer)}>
                                  <div className={clsx(styles.checkDiv)}>
                                    <input
                                      type="radio"
                                      name="featured"
                                      id="Pending"
                                      checked={featured === "Pending"}
                                      value={"Pending"}
                                      onChange={(e) => {
                                        setFeatured(e.target.value);
                                        setFeaturedMand(false);
                                      }}
                                    />
                                    <label htmlFor="Pending" className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className={clsx(styles.checkDiv)}>
                                    <input
                                      type="radio"
                                      name="featured"
                                      id="No"
                                      value={"No"}
                                      checked={featured === "No"}
                                      onChange={(e) => {
                                        setFeatured(e.target.value);
                                        setFeaturedMand(false);
                                      }}
                                    />
                                    <label htmlFor="No" className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                                <div className={clsx(styles.featuredDiv)}>
                                  <h6>Why Featured?</h6>
                                  <ul>
                                    <li>Priority display on home page, all category pages, and in search results.</li>
                                    <li>More Home Buyers Views</li>
                                    <li>Email marketing to potential home buyers</li>
                                    <li>Digital media promotion</li>
                                    <li>Faster Sales</li>
                                  </ul>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </BlockContent>
                      </Block>
                    </>
                  )}
                  {/* <br /> */}

                  <Block>
                    <BlockHead>
                      <BlockTitle tag="h4"></BlockTitle>
                    </BlockHead>
                    <BlockContent>
                      <div className={styles.tnc}>
                        <div className={clsx(styles.checkDiv)}>
                          <input
                            type="checkbox"
                            id="termsAndConditions"
                            name="termsAndConditions"
                            onChange={(e) => {
                              setTerms(e.target.checked);
                              if (e.target.checked) {
                                setTermMand(false);
                              }
                            }}
                            className={clsx(termMand && "error")}
                            checked={terms}
                            ref={termRef}
                          />
                          <label htmlFor="termsAndConditions" className="form-check-label">
                            I agree to{" "}
                            <a
                              href={`${process.env.USER_WEB_URL}terms-and-conditions`}
                              passHref
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms & Conditions*
                            </a>
                          </label>
                        </div>
                      </div>
                    </BlockContent>
                  </Block>
                </div>
              </div>
            </div>
          </Card>

          <div style={{ margin: "15px 0", display: "flex", gap: "1rem" }}>
            <FooterBtn text={"Back"} color={"secondary"} outline onClick={handleBack} />
            <FooterBtn text={type === "edit" ? "Update" : "Submit"} color={"info"} onClick={handleNext} />
          </div>
        </Block>
      </Content>
    </>
  );
};

export default Dev7;
