import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";

const libraries = ["visualization", "places"];

const mapContainerStyle = {
  height: "350px",
  minWidth: "250px",
};

const options = {
  // styles: mapStyles,
  fullscreenControl: false,
};

const MapComponent = ({ marker, setMarker, zoom }) => {
  const [center, setCenter] = useState({
    lat: 49.236969,
    lng: -122.609325,
  });

  const onMapClick = useCallback(async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarker({
      lat: lat,
      lng: lng,
    });
  }, []);

  const mapRef = useRef();

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if (marker?.lat && marker?.lng) {
      setCenter({
        lat: marker?.lat,
        lng: marker?.lng,
      });
    }
  }, [marker]);

  const onMarkerDragEnd = useCallback(async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarker({
      lat: lat,
      lng: lng,
    });
  }, []);

  return (
    <div>
      <GoogleMap
        ref={mapRef}
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        center={center}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          gestureHandling: "greedy",
        }}
        onLoad={onMapLoad}
        onClick={onMapClick}
      >
        {marker ? (
          <Marker
            position={marker}
            draggable
            onDragEnd={(coord) => {
              onMarkerDragEnd(coord);
            }}
            name={marker.name}
          />
        ) : null}
      </GoogleMap>
    </div>
  );
};

export default MapComponent;
