import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";

export const getAllProjects = createAsyncThunk("projects/projectsList", async (query) => {
  setAuthorizationToken();
  const response = await getMethod(`/api/project`, query);
  return response;
});
export const getSingleProject = createAsyncThunk("projects/project", async ({ id, query }) => {
  setAuthorizationToken();
  const response = await getMethod(`/api/project/${id}`, query);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  projects: {
    loading: false,
    success: false,
    error: false,
    data: null,
    total: null,
  },
  addProject: {
    loading: false,
    success: false,
    data: null,
    error: false,
    step: 1,
    maxStep: 1,
    "proj-aws": [],
    "info-folder": [],
  },
  project: null,
};

const projectSlice = createSlice({
  name: "project",
  initialState,

  reducers: {
    isLoading: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isSuccessful: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    handleAddProject: (state, action) => {
      state.addProject.data = action.payload;
    },
    handleProjAws: (state, action) => {
      state.addProject["proj-aws"] = action.payload;
    },
    handleProjInfo: (state, action) => {
      state.addProject["info-folder"] = action.payload;
    },
    handleProjStep: (state, action) => {
      state.addProject.step = action.payload.step;
      state.addProject.maxStep = action.payload.maxStep;
    },
    handleProjectAddReset: (state, action) => {
      state.addProject = initialState.addProject;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllProjects.pending, (state) => {
      state.projects.loading = true;
      state.projects.success = false;
      state.projects.data = null;
      state.projects.total = null;
      state.projects.error = false;
    });
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      state.projects.loading = false;
      state.projects.data = action.payload?.data;
      state.projects.total = {
        total: action?.payload?.total,
        total_pages: action?.payload?.total_pages,
        items_per_page: action?.payload?.items_per_page,
      };
      state.projects.success = true;
      state.projects.error = false;
    });
    builder.addCase(getAllProjects.rejected, (state, action) => {
      state.projects.loading = false;
      state.projects.data = null;
      state.projects.total = null;
      state.projects.success = false;
      state.projects.error = action.error?.message;
    });

    builder.addCase(getSingleProject.pending, (state) => {
      state.addProject.loading = true;
      state.addProject.success = false;
      state.addProject.data = null;
      state.addProject.total = null;
      state.addProject.error = false;
    });
    builder.addCase(getSingleProject.fulfilled, (state, action) => {
      state.addProject.loading = false;
      state.addProject.data = action.payload?.data;
      state.addProject.success = true;
      state.addProject.error = false;
    });
    builder.addCase(getSingleProject.rejected, (state, action) => {
      state.addProject.loading = false;
      state.addProject.success = false;
      state.addProject.data = null;
      state.addProject.total = null;
      state.addProject.error = action.error?.message;
    });
  },
});

export const {
  isLoading,
  isSuccessful,
  isError,
  handleAddProject,
  handleProjAws,
  handleProjInfo,
  handleProjStep,
  handleProjectAddReset,
} = projectSlice.actions;

export default projectSlice.reducer;

export const addProject = (data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  let response = await postMethod(`/api/project`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const editProject = (id, data, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  const response = await putMethod(`/api/project/${id}`, data);
  if (response?.success) {
    SuccessCallback(response);
  } else {
    ErrorCallback(response);
  }
};

export const deleteProject = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  const response = await deleteMethod(`/api/project/${id}`);
  if (response?.success) {
    dispatch(isSuccessful());
    SuccessCallback(response);
  } else {
    dispatch(isError());
    ErrorCallback(response);
  }
};

export const deleteProjectPermanently = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  const response = await deleteMethod(`/api/project/permenant/${id}`);
  if (response?.success) {
    dispatch(isSuccessful());
    SuccessCallback(response);
  } else {
    dispatch(isError());
    ErrorCallback(response);
  }
};

export const deleteMultipleProjects = (ids, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  const response = await postMethod(`/api/project/delete/multiple`, ids);
  if (response?.success) {
    dispatch(isSuccessful());
    SuccessCallback(response);
  } else {
    dispatch(isError());
    ErrorCallback(response);
  }
};

export const deleteMultipleProjectsPermanently = (ids, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  const response = await postMethod(`/api/project/delete/multiple-permenant`, ids);
  if (response?.success) {
    dispatch(isSuccessful());
    SuccessCallback(response);
  } else {
    dispatch(isError());
    ErrorCallback(response);
  }
};

export const restoreProject = (id, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());
  const response = await postMethod(`/api/project/restore/${id}`);
  if (response?.success) {
    dispatch(isSuccessful());
    SuccessCallback(response);
  } else {
    dispatch(isError());
    ErrorCallback(response);
  }
};

export const restoreMultipleProjects = (ids, SuccessCallback, ErrorCallback) => async (dispatch) => {
  setAuthorizationToken(true);
  dispatch(isLoading());

  const response = await postMethod(`/api/project/restore/multiple`, ids);
  if (response?.success) {
    dispatch(isSuccessful());
    SuccessCallback(response);
  } else {
    dispatch(isError());
    ErrorCallback(response);
  }
};
