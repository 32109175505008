import moment from "moment";

// export const allDays = [""];
export const allDays = () => {
  let days = [];
  for (let i = 0; i < 31; i++) {
    let a = i + 1;
    days = [...days, a < 10 ? `0${a}` : `${a}`];
  }
  return days;
};

export const allMonths = moment.months();

export const yearsBeforeAfter = (to = 10) => {
  let year = moment().year();
  let date = [];
  let start = year - to;
  for (let i = 0; i <= to - 1; i++) {
    date = [...date, start + i];
  }

  for (let i = 0; i <= to; i++) {
    date = [...date, year + i];
  }
  return date;
};

export const yearsAfter = (to = 10, year = moment().year()) => {
  // let year = moment().year();
  let date = [];

  for (let i = 0; i <= to; i++) {
    date = [...date, year + i];
  }
  return date;
};

export const yearsBefore = (from = 5, year) => {
  const currentYear = moment().year();
  const years = [];

  for (let i = from; i >= 0; i--) {
    if (years?.includes(year)) {
      return years;
    } else {
      years.push(currentYear - i);
    }
  }
  return years;
};

export const intervals = (startTime, endTime, difference) => {
  let start = moment(startTime, "hh:mm");

  let end = moment(endTime, "hh:mm");

  start.minutes(Math.ceil(start.minutes() / difference) * difference);

  let result = [];

  let current = moment(start);

  while (current <= end) {
    result.push(current.format("hh:mm A"));
    current.add(difference, "minutes");
  }

  return result;
};

export const intervalsWithDate = (startTime, endTime, difference) => {
  let now = moment(new Date()).format("YYYY-MM-DD HH:MM");
  let start = moment(startTime, "YYYY-MM-DD HH:mm");

  let end = moment(endTime, "YYYY-MM-DD HH:mm");

  start.minutes(Math.ceil(start.minutes() / difference) * difference);

  let result = [];

  let current = moment(start);

  while (current <= end) {
    result.push(current.format("YYYY-MM-DDTHH:mm"));
    current.add(difference, "minutes");
  }

  return result.filter((item) => moment(item).isAfter(moment(new Date())));
};

export const getNextYears = (numYears = 5, year) => {
  const currentYear = moment().year();
  const startYear = moment(year, "YYYY");
  const lastYear = startYear.clone().add(numYears, "years");

  const yearsArray = [];
  const iterationLimit = lastYear.year() < currentYear ? numYears : currentYear - year;

  for (let i = 0; i <= iterationLimit; i++) {
    const nextYear = startYear.clone().add(i, "years");
    yearsArray.push(nextYear.format("YYYY"));
  }

  return yearsArray;
};
