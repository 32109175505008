import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod } from "../../utils/apiServices";

export const getGarageLayoutList = createAsyncThunk("garageLayout/fetchGarageLayoutList", async () => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/garageLayout`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  garageLayout: null,
};

const garageLayoutSlice = createSlice({
  name: "garageLayout",
  initialState,
  // reducers: {
  //   isLoading: (state) => {
  //     state.loading = true;
  //     state.success = false;
  //     state.error = false;
  //   },
  //   isSuccess: (state, action) => {
  //     state.loading = false;
  //     state.success = true;
  //     state.error = false;
  //     state.garageLayout = action.payload;
  //   },
  //   isError: (state) => {
  //     state.loading = false;
  //     state.success = false;
  //     state.error = true;
  //   },
  // },

  extraReducers: (builder) => {
    builder.addCase(getGarageLayoutList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGarageLayoutList.fulfilled, (state, action) => {
      state.loading = false;
      state.garageLayout = action.payload.data;
    });
    builder.addCase(getGarageLayoutList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

// export const { isLoading, isSuccess, isError } = garageLayoutSlice.actions;

export default garageLayoutSlice.reducer;

// export const getHomeType = () => async (dispatch) => {
//   try {
//     dispatch(isLoading);
//     const response = await getMethod(`/api/homeType`);
//     dispatch(isSuccess(response?.data));
//   } catch (error) {
//     dispatch(isError);
//   }
// };
