import React from "react";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from "../../../components/Component";
import CustomAccordion from "../../../components/Accordion/Accordion";

const VipSubscription = () => {
  return (
    <div>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page tag="h1">
              Vip Agent Subscription
            </BlockTitle>
            <BlockDes className="text-soft"></BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <div className="mt-3">
        <CustomAccordion />
      </div>
    </div>
  );
};

export default VipSubscription;
