import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMethod } from "../../utils/apiServices";

export const getHomeType = createAsyncThunk("homeType/fetchHomeType", async () => {
  // setAuthorizationToken(true);
  const response = await getMethod(`/api/homeType`);
  return response;
});

const initialState = {
  loading: false,
  success: false,
  error: false,
  homeType: null,
};

const homeTypeSlice = createSlice({
  name: "homeType",
  initialState,
  // reducers: {
  //   isLoading: (state) => {
  //     state.loading = true;
  //     state.success = false;
  //     state.error = false;
  //   },
  //   isSuccess: (state, action) => {
  //     state.loading = false;
  //     state.success = true;
  //     state.error = false;
  //     state.homeType = action.payload;
  //   },
  //   isError: (state) => {
  //     state.loading = false;
  //     state.success = false;
  //     state.error = true;
  //   },
  // },

  extraReducers: (builder) => {
    builder.addCase(getHomeType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHomeType.fulfilled, (state, action) => {
      state.loading = false;
      state.homeType = action.payload.data;
    });
    builder.addCase(getHomeType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

// export const { isLoading, isSuccess, isError } = homeTypeSlice.actions;

export default homeTypeSlice.reducer;

// export const getHomeType = () => async (dispatch) => {
//   try {
//     dispatch(isLoading);
//     const response = await getMethod(`/api/homeType`);
//     dispatch(isSuccess(response?.data));
//   } catch (error) {
//     dispatch(isError);
//   }
// };
