import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "../layout/Index";
import { fetchProfileData } from "../redux/Apislices/authSlice";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);

  return auth?.profile && ["idle", "loading", "success"]?.includes(auth?.status) && auth?.success ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : auth?.error ? (
    <Navigate to="/auth-login" />
  ) : null;
};

export default PrivateRoute;
